/* eslint-disable no-underscore-dangle */
import * as d3 from 'd3';
import Chart from './../Chart';

import { BASIS_STOPS, generateStationColors } from '../../helpers/unitPriceColorScale';
import colors from '../../helpers/colors'
import './TimelineChart.css';
import recommendationIcons from '../../assets/fuelrobot/recommendationIconSprite.png';

const SETTIMEOUT_DURATION_MS = 200

function findClosestTimestamp(dataArray, refTfu) {
  const closestElement = dataArray
    .map(d => ({ tfu: d.tfu, timestamp: d.timestamp }))
    .reduce((agg, elem) => {
      if (Math.abs(elem.tfu - refTfu) < agg.diff) {
        agg.diff = Math.abs(elem.tfu - refTfu)
        agg.timestamp = elem.timestamp
      }
      return agg
    }, { diff: Number.MAX_VALUE, timestamp: undefined })
  return closestElement.timestamp
}

const timeoutIds = []
let dataIndex = null

const ICON_MAPPING = {
  recommendation: {
    x: 10, y: 10, width: 90, height: 90, anchorX: 13, anchorY: 90,
  },
  greyRecommendation: {
    x: 120, y: 10, width: 90, height: 90, anchorX: 13, anchorY: 90,
  },
  cancelledRecommendation: {
    x: 230, y: 10, width: 90, height: 90, anchorX: 13, anchorY: 90,
  },
  selectedRecommendation: {
    x: 10, y: 120, width: 90, height: 90, anchorX: 13, anchorY: 90,
  },
  greyCancelledRecommendation: {
    x: 120, y: 120, width: 90, height: 90, anchorX: 13, anchorY: 90,
  },
  selectedCancelledRecommendation: {
    x: 230, y: 120, width: 90, height: 90, anchorX: 13, anchorY: 90,
  },
}
const RECOMMENDATION_PX_OFFSET = 26 * 13 / 90

export default class Timeline extends Chart {
  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();
    this.main = this.svg.append('g')
      .attr('class', 'main');

    this.defs = this.main.append('defs')
    this.defs.append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', this.props.width)
      .attr('height', this.props.height + this.props.margin.top)
      .attr('y', -this.props.margin.top);
    this.defs.append('symbol')
      .attr('id', 'recommendationIcon')
      .attr('viewBox', `${ICON_MAPPING.recommendation.x} ${ICON_MAPPING.recommendation.y} 90 90`)
      .append('image')
      .attr('xlink:href', recommendationIcons)
      .attr('width', 334)
      .attr('height', 213)
    this.defs.append('symbol')
      .attr('id', 'cancelledRecommendationIcon')
      .attr('viewBox', `${ICON_MAPPING.cancelledRecommendation.x} ${ICON_MAPPING.cancelledRecommendation.y} 90 90`)
      .append('image')
      .attr('xlink:href', recommendationIcons)
      .attr('width', 334)
      .attr('height', 213)
    this.defs.append('symbol')
      .attr('id', 'selectedRecommendationIcon')
      .attr('viewBox', `${ICON_MAPPING.selectedRecommendation.x} ${ICON_MAPPING.selectedRecommendation.y} 90 90`)
      .append('image')
      .attr('xlink:href', recommendationIcons)
      .attr('width', 334)
      .attr('height', 213)
    this.defs.append('symbol')
      .attr('id', 'selectedCancelledRecommendationIcon')
      .attr('viewBox', `${ICON_MAPPING.selectedCancelledRecommendation.x} ${ICON_MAPPING.selectedCancelledRecommendation.y} 90 90`)
      .append('image')
      .attr('xlink:href', recommendationIcons)
      .attr('width', 334)
      .attr('height', 213)

    this.focus = this.main.append('g')
      .attr('class', 'focus')
      .attr('transform', `translate(${this.props.margin.left},${this.props.margin.top})`);

    this.context = this.main.append('g')
      .attr('class', 'context')
      .attr('transform', `translate(${this.props.margin2.left},${this.props.margin2.top})`);

    this.focusPrices = this.focus.append('g')
      .attr('class', 'prices');

    this.focusLines = this.focus.append('g')
      .attr('class', 'lines');
    this.focusLines.append('path')
      .attr('class', 'fuelLineBefore')
      .style('stroke', 'dodgerblue')
      .style('fill', 'none')
      .style('stroke-width', 3);
    this.focusLines.append('path')
      .attr('class', 'fuelLineAfter')
      .style('stroke', 'rgb(153, 204, 255)') // lightdodgerblue
      .style('fill', 'none')
      .style('stroke-width', 3);

    this.focusActions = this.focus.append('g')
      .attr('class', 'actionFocus');

    this.focusRecommendations = this.focus.append('g')
      .attr('class', 'recommendationsFocus');

    this.focusPlannings = this.focus.append('g')
      .attr('class', 'planningFocus')
      .style('opacity', 0); // hide planning.

    this.focusRefuel = this.focus.append('g')
      .attr('class', 'refuel');
    this.focusDetourRefuel = this.focus.append('g')
      .attr('class', 'refuel')
    this.focusRefuel.append('circle')
      .attr('class', 'refuel')
      .style('stroke', 'white')
      .style('fill', colors.red)
      .style('stroke-width', 2);
    this.focusDetourRefuel.append('circle')
      .attr('class', 'refuel')
      .style('stroke', 'white')
      .style('fill', colors.orange)
      .style('stroke-width', 2);

    this.fuelDropFocus = this.focus.append('g')
      .attr('class', 'fuelDropFocus')
    this.fuelDropFocus.append('circle')
      .attr('class', 'fuelDropFocus')
      .style('stroke', 'white')
      .style('fill', colors.yellow)
      .style('stroke-width', 2);

    this.contextLines = this.context.append('g')
      .attr('class', 'lines');
    this.contextLines.append('path')
      .attr('class', 'fuelLineBefore2')
      .style('stroke', 'dodgerblue')
      .style('fill', 'none')
      .style('stroke-width', 2);
    this.contextLines.append('path')
      .attr('class', 'fuelLineAfter2')
      .style('stroke', 'rgb(153, 204, 255)') // lightdodgerblue
      .style('fill', 'none')
      .style('stroke-width', 2);

    this.contextActions = this.context.append('g')
      .attr('class', 'actionContext');

    this.contextRefuel = this.context.append('g')
      .attr('class', 'refuel');

    this.contextRefuel.append('circle')
      .attr('class', 'refuel')
      .style('stroke', 'white')
      .style('fill', colors.red)
      .style('stroke-width', 1);

    this.xAxis = this.focus.append('g')
      .attr('class', 'axis-x');

    this.xAxis2 = this.context.append('g')
      .attr('class', 'axis-x');

    this.ySpeedAxis = this.focus.append('g')
      .attr('class', 'y speed axis');

    this.yFuelAxis = this.focus.append('g')
      .attr('class', 'y fuel axis');
    this.yFuelAxisText = this.yFuelAxis
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('fill', '#000')

    this.eventPin = this.main.append('g')
      .attr('transform', `translate(${this.props.margin.left},0)`)
      .attr('class', 'eventPin');

    this.priceTag = this.main.append('g')
      .attr('class', 'priceTag')
      .append('text')
      .attr('color', 'black')
      .attr('font-size', 12)
      .attr('y', 14)
      .attr('text-anchor', 'start');

    this.fuelLevel = this.main.append('g')
      .attr('class', 'fuelLevel')
      .append('text')
      .attr('color', 'black')
      .attr('font-size', 12)
      .attr('y', 14)
      .attr('text-anchor', 'start')
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    this.onFocus = state.onFocus;
    this.onEventChange = state.onEventChange;
    this.axisType = state.axis;
    this.refuel = state.refuel;
    if (state.data && state.data.length > 0) {
      this._drawChart(state);
      this._drawPin(state.currency);
    }
  }

  updatePriceSegments(segments, stationPrices) {
    this.priceSegments = segments;
    let threshold
    if (stationPrices && stationPrices.length > 0) {
      const colorStops = generateStationColors(stationPrices)
      threshold = d3.scaleThreshold()
        .domain(colorStops.map(colorStop => colorStop[0]))
        .range(colorStops.map(colorStop => colorStop[1]))
    } else {
      threshold = d3.scaleThreshold()
        .domain(BASIS_STOPS.map(basisStop => basisStop[0]))
        .range(BASIS_STOPS.map(basisStop => basisStop[1]));
    }
    this.focusPrices.selectAll('.price')
      .data(segments).enter().append('rect')
      .attr('class', 'price')
      .style('fill', d => threshold(d.station.unitPriceWithRefund) || 'transparent')
      .style('opacity', 0.5)
      .attr('x', d => (this.axisType === 'time' ? this.x(d.point.timestamp) : this.x(d.point.tfu)))
      .attr('width', d => (this.axisType === 'time' ? this.x(d.point.nextSegmentTimestamp) - this.x(d.point.timestamp) : this.x(d.point.nextSegmentTfu) - this.x(d.point.tfu)))
      .attr('y', 0)
      .attr('height', this.props.height);
  }

  _drawChart(state) {
    const { data } = state
    const {
      fuelDrop, recommendations, plannings, yLabel,
    } = state
    this.state = state
    this.data = data;
    const self = this;
    if (self.axisType === 'time') {
      this.x = d3.scaleTime().range([0, this.props.width]);
    } else {
      this.x = d3.scaleLinear().range([0, this.props.width]);
    }
    if (self.axisType === 'time') {
      this.x2 = d3.scaleTime().range([0, this.props.width]);
    } else {
      this.x2 = d3.scaleLinear().range([0, this.props.width]);
    }
    const actionsSpan = this._getActionsSpan(data);
    const yFuel = d3.scaleLinear()
      .range([this.props.height, 2]);
    const yFuel2 = d3.scaleLinear()
      .range([this.props.height2, 2]);

    this.xAxisD3 = d3.axisBottom(this.x);
    this.xAxis2D3 = d3.axisBottom(this.x2);
    const yFuelAxis = d3.axisLeft(yFuel).ticks(5);

    this.brush = d3.brushX()
      .extent([[0, 0], [this.props.width, this.props.height2]])
      .on('brush end', () => this.brushed());
    this.zoom = d3.zoom()
      .scaleExtent([1, Infinity])
      .translateExtent([[0, 0], [this.props.width, this.props.height]])
      .extent([[0, 0], [this.props.width, this.props.height]])
      .on('zoom', () => this.zoomed());

    if (self.axisType === 'time') {
      this.x.domain(d3.extent(this.data, d => d.timestamp));
    } else {
      this.x.domain(d3.extent(data, d => d.tfu));
    }
    this.x2.domain(this.x.domain());
    this.currentEvent = this.currentEvent || this.data[dataIndex || 0];

    yFuel.domain([0, 100]);
    yFuel2.domain([0, 100]);

    this.xAxis
      .attr('transform', `translate(0,${this.props.height})`)
      .call(this.xAxisD3);

    this.xAxis2
      .attr('transform', `translate(0,${this.props.height2})`)
      .call(this.xAxis2D3);

    this.yFuelAxis
      .call(yFuelAxis)
    this.yFuelAxisText
      .text(yLabel)

    const contextActionsData = this.contextActions.selectAll('.action')
      .data(actionsSpan)
    const contextActionsEnter = contextActionsData
      .enter().append('rect')
      .attr('class', 'action')
      .style('fill', 'dodgerblue')
      .attr('height', 5);
    contextActionsEnter.merge(contextActionsData)
      .attr('x', d => this.x(d.start.timestamp))
      .attr('width', d => this.x(d.end.timestamp) - this.x(d.start.timestamp))
      .attr('y', this.props.height2 - 4);
    contextActionsData.exit().remove()

    this.fuelLine = d3.line()
      .curve(d3.curveStepAfter)
      .x(d => this.x(self.axisType === 'time' ? d.timestamp : d.tfu))
      .y(d => yFuel(d.fuelPercentage));

    const fuelLine2 = d3.line()
      .curve(d3.curveStepAfter)
      .x(d => this.x2(self.axisType === 'time' ? d.timestamp : d.tfu))
      .y(d => yFuel2(d.fuelPercentage));

    this.dataBefore = data.filter(e => e.tfu <= recommendations[0].tfu)
    this.dataAfter = data.filter(e => e.tfu >= recommendations[0].tfu)
    this.focusLines.select('.fuelLineBefore')
      .transition()
      .attr('d', this.fuelLine(this.dataBefore));
    this.focusLines.select('.fuelLineAfter')
      .transition()
      .attr('d', this.fuelLine(this.dataAfter));

    this.contextLines.select('.fuelLineBefore2')
      .transition()
      .attr('d', fuelLine2(this.dataBefore));
    this.contextLines.select('.fuelLineAfter2')
      .transition()
      .attr('d', fuelLine2(this.dataAfter));

    this.focusActions.select('text')
      .style('opacity', 1)

    const focusActionsData = this.focusActions.selectAll('.action')
      .data(actionsSpan)
    const focusActionsEnter = focusActionsData
      .enter().append('rect')
      .attr('class', 'action')
      .style('fill', 'dodgerblue')
      .attr('x', d => this.x(d.start.timestamp))
      .attr('width', d => this.x(d.end.timestamp) - this.x(d.start.timestamp))
      .attr('y', this.props.height - 10)
      .attr('height', 10);
    focusActionsEnter.merge(focusActionsData)
      .attr('x', d => this.x(d.start.timestamp))
      .attr('width', d => this.x(d.end.timestamp) - this.x(d.start.timestamp))
      .attr('y', this.props.height - 10)
    focusActionsData.exit().remove()

    if (recommendations && Array.isArray(recommendations)) {
      const recommendationsData = this.focusRecommendations
        .selectAll('.recommendation')
        .data(recommendations)
      const recommendationsEnter = recommendationsData
        .enter().append('use')
        .attr('class', 'recommendation')
        .attr('xlink:href', '#selectedRecommendationIcon')
        .attr('width', 26)
        .attr('height', 26)
        .attr('x', d => this.x(this.axisType === 'time' ? new Date(+d.date) : d.tfu) - RECOMMENDATION_PX_OFFSET)
        .attr('y', d => yFuel(d.fuelPercentage) - 26)
      recommendationsEnter.merge(recommendationsData)
        .attr('x', d => this.x(this.axisType === 'time' ? new Date(+d.date) : d.tfu) - RECOMMENDATION_PX_OFFSET)
        .attr('y', d => yFuel(d.fuelPercentage) - 26)
      recommendationsData.exit().remove()
    }
    if (this.axisType === 'time' && plannings && Array.isArray(plannings)) {
      const planningsData = this.focusPlannings
        .selectAll('.planningLine')
        .data(plannings.filter(p => !!p.plannedDate))
      const planningsEnter = planningsData
        .enter()
        .append('line')
        .attr('class', 'planningLine')
        .style('stroke', 'dodgerblue')
        .style('stroke-width', 2)
        .style('stroke-linecap', 'round')
        .style('stroke-dasharray', '6,6')
        .attr('x1', d => this.x(new Date(+d.plannedDate)))
        .attr('x2', d => this.x(new Date(+d.plannedDate)))
        .attr('y1', 2)
        .attr('y2', this.props.height)
      planningsEnter.merge(planningsData)
        .attr('x1', d => this.x(new Date(+d.plannedDate)))
        .attr('x2', d => this.x(new Date(+d.plannedDate)))
      planningsData.exit().remove()
    } else {
      this.focusPlannings
        .selectAll('.planningLine')
        .data([])
        .exit().remove()
    }


    this.focusPrices.selectAll('.price')
      .attr('x', d => (this.axisType === 'time' ? this.x(d.point.timestamp) : this.x(d.point.tfu)))
      .attr('width', d => (this.axisType === 'time' ? this.x(d.point.nextSegmentTimestamp) - this.x(d.point.timestamp) : this.x(d.point.nextSegmentTfu) - this.x(d.point.tfu)));

    if (this.context.selectAll('.brush').empty()) {
      this.context.append('g')
        .attr('class', 'brush')
        .call(this.brush)
        .call(this.brush.move, this.x.range());

      this.main.append('rect')
        .attr('class', 'zoom')
        .attr('width', this.props.width)
        .attr('height', this.props.height)
        .attr('transform', `translate(${this.props.margin.left},${this.props.margin.top})`)
        .call(this.zoom);
    } else {
      this.context.selectAll('.brush')
        .call(this.brush)
        .call(this.brush.move, this.x.range());
    }

    if (this.refuel) {
      this.focusRefuel.select('circle')
        .attr('r', 10)
        .attr('cx', this.x(this.axisType === 'time' ? this.refuel.timestamp : this.refuel.tfu))
        .attr('cy', this.props.height - 5);
      this.contextRefuel.select('circle')
        .attr('r', 4)
        .attr('cx', this.x(this.axisType === 'time' ? this.refuel.timestamp : this.refuel.tfu))
        .attr('cy', this.props.height - 4);
    }
    if (fuelDrop) {
      this.fuelDropFocus.select('circle')
        .attr('r', 10)
        .attr('cx', this.x(this.axisType === 'time' ?
          new Date(fuelDrop.Dh_Timestamp)
          : fuelDrop.Dh_Tfu))
        .attr('cy', this.props.height - 5);
    }

    if (state.detourTfu) {
      this.state.detourTfu = state.detourTfu
      this.focusDetourRefuel.select('circle')
        .attr('r', 14)
        .attr('cx', this.x(this.axisType === 'time' ? findClosestTimestamp(data, state.detourTfu) : state.detourTfu))
        .attr('cy', this.props.height - 5);
    }
    const findNextCurrentEvent = () => {
      const iterableData = state.data
        .filter(d => d.longitude || d.latitude)
      const tentativeNextIndex = iterableData
        .findIndex(e => e.timestamp === self.currentEvent.timestamp) + 1
      dataIndex = tentativeNextIndex === iterableData.length ? 0 : tentativeNextIndex
      return iterableData[dataIndex]
    }
    const playEvents = () => {
      const timeOutId = setTimeout(() => {
        self.currentEvent = findNextCurrentEvent()
        self._updatePin()
        return playEvents()
      }, SETTIMEOUT_DURATION_MS)
      timeoutIds.push(timeOutId)
    }
    timeoutIds.forEach(id => clearTimeout(id))
    if (state.autoPlay) {
      playEvents()
    } else {
      this._updatePin()
    }
  }

  zoomed() {
    const { fuelDrop } = this.state
    if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'brush') return; // ignore zoom-by-brush
    const t = d3.event.transform;
    this.x.domain(t.rescaleX(this.x2).domain());
    // focus:
    this.focusLines.select('.fuelLineBefore')
      .transition().duration(this.zoomTransitionDuration || 0)
      .attr('d', this.fuelLine(this.dataBefore));
    this.focusLines.select('.fuelLineAfter')
      .transition().duration(this.zoomTransitionDuration || 0)
      .attr('d', this.fuelLine(this.dataAfter));
    this.focus.select('.axis-x')
      .transition().duration(this.zoomTransitionDuration || 0)
      .call(this.xAxisD3);
    this.focusActions.selectAll('.action')
      .transition().duration(this.zoomTransitionDuration || 0)
      .attr('x', d => (this.axisType === 'time' ? this.x(d.start.timestamp) : this.x(d.start.tfu)))
      .attr('width', d => (this.axisType === 'time' ? (this.x(d.end.timestamp) - this.x(d.start.timestamp)) : (this.x(d.end.tfu) - this.x(d.start.tfu))));
    this.focusPrices.selectAll('.price')
      .attr('x', d => (this.axisType === 'time' ? this.x(d.point.timestamp) : this.x(d.point.tfu)))
      .attr('width', d => (this.axisType === 'time' ? this.x(d.point.nextSegmentTimestamp) - this.x(d.point.timestamp) : this.x(d.point.nextSegmentTfu) - this.x(d.point.tfu)));
    if (this.refuel) {
      this.focusRefuel.select('circle')
        .attr('cx', d => (this.axisType === 'time' ? this.x(this.refuel.timestamp) : this.x(this.refuel.tfu)));
    }
    if (this.state.detourTfu) {
      this.focusDetourRefuel.select('circle')
        .attr('r', 10)
        .attr('cx', this.x(this.axisType === 'time' ? findClosestTimestamp(this.data, this.state.detourTfu) : this.state.detourTfu))
        .attr('cy', this.props.height - 5);
    }
    if (fuelDrop) {
      this.fuelDropFocus.select('circle')
        .attr('cx', this.x(this.axisType === 'time' ?
          new Date(fuelDrop.Dh_Timestamp)
          : fuelDrop.Dh_Tfu))
    }
    this.focusRecommendations.selectAll('.recommendation')
      .transition().duration(this.zoomTransitionDuration || 0)
      .attr('x', d => this.x(this.axisType === 'time' ? new Date(+d.date) : d.tfu) - RECOMMENDATION_PX_OFFSET)
    if (this.axisType === 'time') {
      this.focusPlannings.selectAll('.planningLine')
        .transition().duration(this.zoomTransitionDuration || 0)
        .attr('x1', d => this.x(new Date(+d.plannedDate)))
        .attr('x2', d => this.x(new Date(+d.plannedDate)))
    }

    // context:
    this.context.select('.brush').call(this.brush.move, this.x.range().map(t.invertX, t));
    const xDomain = this.x.domain();
    this.eventDomain = [
      this.data.find(d => (this.axisType === 'time' ? d.timestamp >= xDomain[0] : d.tfu >= xDomain[0])),
      this.data.find(d => (this.axisType === 'time' ? d.timestamp >= xDomain[1] : d.tfu >= xDomain[1])) || this.data[this.data.length - 1],
    ];
    // TODO FIX THE SLOWDOWN CAUSED BY THIS CALL
    this.onFocus(this.eventDomain);
    if (this.currentEvent) {
      const currentMetric = this.axisType === 'time' ? this.currentEvent.timestamp : this.currentEvent.tfu;
      if (currentMetric < xDomain[0]) {
        this.currentEvent = this.eventDomain[0];
      } else if (currentMetric > xDomain[1]) {
        this.currentEvent = this.eventDomain[1];
      }
    } else this.currentEvent = this.eventDomain[0];
    this._updatePin();
  }

  brushed(values) {
    const { fuelDrop } = this.state
    if (!values && d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom') return; // ignore brush-by-zoom
    const s = values || d3.event.selection || this.x2.range();
    this.x.domain(s.map(this.x2.invert, this.x2));

    this.focusLines.select('.fuelLineBefore')
      .transition().duration(this.zoomTransitionDuration || 0)
      .attr('d', this.fuelLine(this.dataBefore));
    this.focusLines.select('.fuelLineAfter')
      .transition().duration(this.zoomTransitionDuration || 0)
      .attr('d', this.fuelLine(this.dataAfter));
    this.focus.select('.axis-x')
      .transition().duration(this.zoomTransitionDuration || 0)
      .call(this.xAxisD3);
    this.focusActions.selectAll('.action')
      .transition().duration(this.zoomTransitionDuration || 0)
      .attr('x', d => this.x(d.start.timestamp))
      .attr('width', d => this.x(d.end.timestamp) - this.x(d.start.timestamp));
    this.focusPrices.selectAll('.price')
      .attr('x', d => (this.axisType === 'time' ? this.x(d.point.timestamp) : this.x(d.point.tfu)))
      .attr('width', d => (this.axisType === 'time' ? this.x(d.point.nextSegmentTimestamp) - this.x(d.point.timestamp) : this.x(d.point.nextSegmentTfu) - this.x(d.point.tfu)));
    if (this.refuel) {
      this.focusRefuel.select('circle')
        .attr('cx', d => (this.axisType === 'time' ? this.x(this.refuel.timestamp) : this.x(this.refuel.tfu)))
    }
    if (fuelDrop) {
      this.fuelDropFocus.select('circle')
        .transition()
        .attr('cx', this.x(this.axisType === 'time' ?
          new Date(fuelDrop.Dh_Timestamp)
          : fuelDrop.Dh_Tfu))
    }
    this.focusRecommendations.selectAll('.recommendation')
      .transition().duration(this.zoomTransitionDuration || 0)
      .attr('x', d => this.x(this.axisType === 'time' ? new Date(d.date) : d.tfu) - RECOMMENDATION_PX_OFFSET)
    if (this.axisType === 'time') {
      this.focusPlannings.selectAll('.planningLine')
        .transition().duration(this.zoomTransitionDuration || 0)
        .attr('x1', d => this.x(new Date(+d.plannedDate)))
        .attr('x2', d => this.x(new Date(+d.plannedDate)))
    }
    this.main.select('.zoom').call(this.zoom.transform, d3.zoomIdentity
      .scale(this.props.width / (s[1] - s[0]))
      .translate(-s[0], 0));
    const xDomain = this.x.domain();
    this.eventDomain = [
      this.data.find(d => (this.axisType === 'time' ? d.timestamp >= xDomain[0] : d.tfu >= xDomain[0])),
      this.data.find(d => (this.axisType === 'time' ? d.timestamp >= xDomain[1] : d.tfu >= xDomain[1])) || this.data[this.data.length - 1],
    ];
    // TODO FIX THE SLOWDOWN CAUSED BY THIS CALL
    this.onFocus(this.eventDomain);
    if (this.currentEvent) {
      const currentMetric = this.axisType === 'time' ? this.currentEvent.timestamp : this.currentEvent.tfu;
      if (currentMetric < xDomain[0]) {
        this.currentEvent = this.eventDomain[0];
      } else if (currentMetric > xDomain[1]) {
        this.currentEvent = this.eventDomain[1];
      }
    } else this.currentEvent = this.eventDomain[0];
    this._updatePin();
  }

  updateAxis(state) {
    const { fuelDrop, plannings } = state
    this.data = state.data;
    this.axisType = state.axis;

    if (this.axisType === 'time') {
      this.x = d3.scaleTime().range([0, this.props.width]);
    } else {
      this.x = d3.scaleLinear().range([0, this.props.width]);
    }
    if (this.axisType === 'time') {
      this.x2 = d3.scaleTime().range([0, this.props.width]);
    } else {
      this.x2 = d3.scaleLinear().range([0, this.props.width]);
    }

    const yFuel = d3.scaleLinear()
      .range([this.props.height, 2]);
    const yFuel2 = d3.scaleLinear()
      .range([this.props.height2, 2]);
    yFuel.domain([0, 100]);
    yFuel2.domain(yFuel.domain());

    this.xAxisD3 = d3.axisBottom(this.x);
    this.xAxis2D3 = d3.axisBottom(this.x2);

    if (this.axisType === 'time') {
      this.x.domain(d3.extent(this.data, d => d.timestamp));
    } else {
      this.x.domain(d3.extent(this.data, d => d.tfu));
    }
    this.x2.domain(this.x.domain());

    this.xAxis
      .attr('transform', `translate(0,${this.props.height})`)
      .call(this.xAxisD3);

    this.xAxis2
      .attr('transform', `translate(0,${this.props.height2})`)
      .call(this.xAxis2D3);

    this.focusActions.selectAll('rect')
      .transition()
      .attr('x', d => (this.axisType === 'time' ? this.x(d.start.timestamp) : this.x(d.start.tfu)))
      .attr('width', d => (this.axisType === 'time' ? (this.x(d.end.timestamp) - this.x(d.start.timestamp)) : (this.x(d.end.tfu) - this.x(d.start.tfu))));

    this.focusPrices.selectAll('.price')
      .transition()
      .attr('x', d => (this.axisType === 'time' ? this.x(d.point.timestamp) : this.x(d.point.tfu)))
      .attr('width', d => (this.axisType === 'time' ? this.x(d.point.nextSegmentTimestamp) - this.x(d.point.timestamp) : this.x(d.point.nextSegmentTfu) - this.x(d.point.tfu)))

    this.contextActions.selectAll('rect')
      .transition()
      .attr('x', d => (this.axisType === 'time' ? this.x(d.start.timestamp) : this.x(d.start.tfu)))
      .attr('width', d => (this.axisType === 'time' ? (this.x(d.end.timestamp) - this.x(d.start.timestamp)) : (this.x(d.end.tfu) - this.x(d.start.tfu))));

    this.fuelLine = d3.line()
      .curve(d3.curveStepAfter)
      .x(d => this.x(this.axisType === 'time' ? d.timestamp : d.tfu))
      .y(d => yFuel(d.fuelPercentage));

    const fuelLine2 = d3.line()
      .curve(d3.curveStepAfter)
      .x(d => this.x2(this.axisType === 'time' ? d.timestamp : d.tfu))
      .y(d => yFuel2(d.fuelPercentage));

    this.focusLines.select('.fuelLineBefore')
      .transition()
      .attr('d', this.fuelLine(this.dataBefore));
    this.focusLines.select('.fuelLineAfter')
      .transition()
      .attr('d', this.fuelLine(this.dataAfter));

    this.contextLines.select('.fuelLineBefore2')
      .transition()
      .attr('d', fuelLine2(this.dataBefore));
    this.contextLines.select('.fuelLineAfter2')
      .transition()
      .attr('d', fuelLine2(this.dataAfter));

    if (this.refuel) {
      this.focusRefuel.select('circle')
        .transition()
        .attr('cx', this.x(this.axisType === 'time' ? this.refuel.timestamp : this.refuel.tfu));
      this.contextRefuel.select('circle')
        .transition()
        .attr('cx', this.x2(this.axisType === 'time' ? this.refuel.timestamp : this.refuel.tfu));
    }

    if (fuelDrop) {
      this.fuelDropFocus.select('circle')
        .transition()
        .attr('cx', this.x(this.axisType === 'time' ?
          new Date(fuelDrop.Dh_Timestamp)
          : fuelDrop.Dh_Tfu))
    }

    this.focusRecommendations.selectAll('.recommendation')
      .transition()
      .duration(500)
      .attr('x', d => this.x(this.axisType === 'time' ? new Date(d.date) : d.tfu) - RECOMMENDATION_PX_OFFSET)

    if (this.axisType === 'time') {
      const planningsData = this.focusPlannings
        .selectAll('.planningLine')
        .data(plannings.filter(p => !!p.plannedDate))
      const planningsEnter = planningsData
        .enter()
        .append('line')
        .attr('class', 'planningLine')
        .style('stroke', 'dodgerblue')
        .style('stroke-width', 2)
        .style('stroke-linecap', 'round')
        .style('stroke-dasharray', '6,6')
        .attr('x1', d => this.x(new Date(+d.plannedDate)))
        .attr('x2', d => this.x(new Date(+d.plannedDate)))
        .attr('y1', 2)
        .attr('y2', this.props.height)
      planningsEnter.merge(planningsData)
        .attr('x1', d => this.x(new Date(+d.plannedDate)))
        .attr('x2', d => this.x(new Date(+d.plannedDate)))
      planningsData.exit().remove()
    } else {
      this.focusPlannings.selectAll('.planningLine')
        .data([]).exit().remove()
    }

    this.main.select('.zoom')
      .call(this.zoom);

    this.context.select('.brush')
      .call(this.brush)

    setTimeout(
      () => this.context.select('.brush').call(this.brush.move, null),
      1000,
    );

    this._updatePin();
  }

  _drawPin(currency) {
    const self = this;
    const xPos = Math.max(
      0,
      Math.min(
        this.props.width,
        this.x(this.axisType === 'time' ? this.currentEvent.timestamp : this.currentEvent.tfu),
      ),
    );

    this.priceTag
      .attr('x', xPos + 60);
    this.currentFuelLevel = this.currentEvent ? this.currentEvent.fuelPercentage : null
    this.fuelLevel
      .attr('x', xPos) // - 20 ?
      .text(this.currentFuelLevel ? `${this.currentFuelLevel.toFixed(0)}%` : '')

    this.eventPin.selectAll('line').remove()
    this.eventPin.selectAll('circle').remove()
    this.eventPin.append('line')
      .attr('class', 'pinLine')
      .style('stroke', 'dodgerblue')
      .style('stroke-width', 3)
      .style('stroke-linecap', 'round')
      .attr('x1', xPos)
      .attr('x2', xPos)
      .attr('y1', 2)
      .attr('y2', this.props.height + this.props.margin.top);

    this.eventPin.append('circle')
      .attr('class', 'pinCircle')
      .style('stroke', 'dodgerblue')
      .style('stroke-width', 3)
      .style('fill', 'white')
      .attr('r', 8)
      .attr('cx', xPos)
      .attr('cy', 10)
      .call(d3.drag()
        .on('start', function onStartDragging() {
          self.dragStarted(this, self)
        })
        .on('drag', function onDrag(d) {
          self.dragged(this, d, self, currency)
        })
        .on('end', function onStopDragging() {
          self.dragEnded(this, self)
        }))
      .on('mouseover', function onEventPinMouseOver() {
        d3.select(this).style('cursor', 'ew-resize')
      });
  }

  _updatePin() {
    if (this.currentEvent && !this.draggingPin) {
      const xPos = Math.max(
        0,
        Math.min(
          this.props.width,
          this.x(this.axisType === 'time' ? this.currentEvent.timestamp : this.currentEvent.tfu),
        ),
      );

      this.eventPin.select('circle')
        // .transition()
        .attr('cx', xPos);

      this.eventPin.select('line')
        // .transition()
        .attr('x1', xPos)
        .attr('x2', xPos);

      this.priceTag
        .attr('x', xPos + 60);
      // this.fuelLevel
      //   .attr('x', xPos - 20)

      this.currentFuelLevel = this.currentEvent ? this.currentEvent.fuelPercentage : null
      this.fuelLevel
        .attr('x', xPos) // - 20 ?
        .text(this.currentFuelLevel ? `${this.currentFuelLevel.toFixed(0)}%` : '')

      // TODO FIX THE SLOWDOWN CAUSED BY THIS CALL
      this.onEventChange(this.currentEvent);
    }
  }

  _getActionsSpan(data) {
    const actions = [];
    let currentAction = null;
    data.forEach((d) => {
      if (d.speed > 5) {
        if (!currentAction) currentAction = d;
      } else if (currentAction) {
        actions.push({ start: currentAction, end: d });
        currentAction = null
      }
    });
    return actions;
  }

  dragStarted(element, self) {
    self.draggingPin = true;
    d3.select(element)
      .attr('fill', 'grey');
  }

  dragged(element, d, self, currency) {
    const pin = d3.select(element.parentNode);
    const posX = Math.max(0, Math.min(d3.event.x, self.props.width));
    const reversedData = self.data.slice().reverse() // slice to make a copy

    pin.select('circle')
      .attr('cx', posX);

    pin.select('line')
      .attr('x1', posX)
      .attr('x2', posX);

    const x = self.x.invert(posX);
    self.currentEvent = reversedData.find((event) => {
      if (self.axisType === 'time') {
        return event.timestamp <= x
      }
      return event.tfu <= x;
    });

    self.currentStation = this.priceSegments ? this.priceSegments.find(d => (self.axisType === 'time' ? d.point.nextSegmentTimestamp >= x : d.point.nextSegmentTfu >= x)) : null;

    self.currentFuelLevel = self.currentEvent ? self.currentEvent.fuelPercentage : null

    // if currentEvent has no coords, we find some for it
    if (!(self.currentEvent.latitude || self.currentEvent.longitude)) {
      const firstZonePoint = self.currentStation.point // first point having no coord
      const previousEvent = reversedData.find((event) => {
        if (self.axisType === 'time') {
          return event.timestamp < firstZonePoint.timestamp
        }
        return event.tfu < firstZonePoint.tfu;
      }) || { latitude: 0, longitude: 0 }; // stay 0 if there is no previous event, will happen rarely
      self.currentEvent = {
        ...self.currentEvent, // !! make a copy
        latitude: previousEvent.latitude,
        longitude: previousEvent.longitude,
      }
    }

    self.onEventChange(self.currentEvent);

    // self.currentTfu = this

    // this.priceTag
    //   .attr('x', posX + 55)
    //   .text(self.currentStation && self.currentStation.station && self.currentStation.station.unitPriceWithRefund
    //     ? `${self.currentStation.station.unitPriceWithRefund.toFixed(3)} ${currency}`
    //     : '');
    this.fuelLevel
      .attr('x', posX)
      .text(self.currentFuelLevel ? `${self.currentFuelLevel.toFixed(0)}%` : '')
  }

  dragEnded(element, self) {
    self.draggingPin = false;
    d3.select(element)
      .attr('fill', 'white');
  }
}
