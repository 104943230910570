import React from 'react'
import { CSVLink } from 'react-csv'
import { Button } from 'antd'
import Moment from 'moment'
import { injectIntl } from 'react-intl';

import * as strings from '../defaultStrings';
import './CSVExporter.css'

function csvName(dataName) {
  const date = new Moment().format('YYYYMMDD-HHmmss');
  return [dataName, '_', date, '.csv'].join('')
}

export const flatten = (data) => {
  const result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (let i = 0; i < cur.length; i += 1) {
        recurse(cur[i], `${prop}[${i}]`);
      }
      if (cur.length === 0) { result[prop] = []; }
    } else {
      let isEmpty = true;
      Object.keys(cur).forEach((p) => {
        isEmpty = false;
        recurse(cur[p], prop ? `${prop}_${p}` : p);
      })
      if (isEmpty && prop) { result[prop] = {}; }
    }
  }
  recurse(data, '');
  return result;
}

const CSVExporter = ({
  data, fileName, columns, intl: { formatMessage },
}) => (
  <CSVLink
    data={data}
    filename={csvName(fileName)}
    className="export-csv-button"
    headers={columns && columns.map(e => ({ label: e, key: e }))}
  >
    <Button
      size="small"
      icon="download"
      type="link"
      className="export-csv-button-element"
      disabled={data.length === 0}
    >
      {formatMessage(strings.phrase.exportToCSV)}
    </Button>

  </CSVLink>
);

export default injectIntl(CSVExporter)
