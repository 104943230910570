import actionTypes from '../actions/actionTypes';

export const initialState = {
  items: [],
  error: null,
  fetching: false,
  date: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    // get KPIs
    case actionTypes.GET_KPIS_REQUEST:
      return {
        ...state,
        error: null,
        fetching: true,
      };
    case actionTypes.GET_KPIS_SUCCESS: {
      const unsortedItems = [...state.items, ...action.items];
      const newItems = unsortedItems.sort((a, b) => a.creationDateTime - b.creationDateTime);
      return {
        ...state,
        items: newItems,
        fetching: false,
        date: [action.from, action.to],
      }; }
    case actionTypes.GET_KPIS_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    default:
      return state;
  }
}

export default reducer;
