import actionTypes from '../actions/actionTypes'

export const initialState = {
  items: [],
  processing: false,
  deletingId: null,
  addingId: null,
  error: null,
  search: {
    requestid: null,
    results: [],
    processing: false,
    error: null,
  },
}

const mapGasStations = e => ({
  ...e,
  fullAddress: `${e.name}\r\n${e.address}\r\n${[e.zipcode, e.locality, e.country].filter(x => !!x).join(', ')}`,
})

function reducer(state = initialState, action) {
  switch (action.type) {
    // fetch
    case actionTypes.GET_STATIONS_REQUEST:
      return {
        ...state,
        processing: true,
      }
    case actionTypes.GET_STATIONS_SUCCESS:
      return {
        ...state,
        items: action.items.map(mapGasStations),
        processing: false,
      }
    case actionTypes.GET_STATIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      }
    // edit
    case actionTypes.ADD_STATIONS_REQUEST:
      return {
        ...state,
        addingId: action.id,
      }
    case actionTypes.ADD_STATIONS_SUCCESS:
      if (action.id === state.addingId) {
        const addedIds = [...action.items].map(e => e.id)
        const filteredStateList = [...state.items].filter(e => !addedIds.includes(e.id))
        return {
          ...state,
          items: [...action.items, ...filteredStateList],
          addingId: null,
        }
      }
      return state
    case actionTypes.ADD_STATIONS_FAILURE:
      if (action.id === state.addingId) {
        return {
          ...state,
          addingId: null,
          error: action.error,
        }
      }
      return state
    // delete
    case actionTypes.DELETE_STATION_REQUEST:
      return {
        ...state,
        deletingId: action.ids,
      }
    case actionTypes.DELETE_STATION_SUCCESS:
      if (action.ids === state.deletingId) {
        const newItems = state.items.filter(e => action.ids.findIndex(x => x === e.id) < 0)
        return {
          ...state,
          items: newItems,
          deletingId: null,
        }
      }
      return state
    case actionTypes.DELETE_STATION_FAILURE:
      if (action.id === state.deletingId) {
        return {
          ...state,
          deletingId: null,
          error: action.error,
        }
      }
      return state
    // search
    case actionTypes.SEARCH_STATIONS_REQUEST:
      return {
        ...state,
        search: {
          requestid: action.id,
          results: [],
          processing: true,
          error: null,
        },
      }
    case actionTypes.SEARCH_STATIONS_SUCCESS:
      if (state.search.requestid && action.id === state.search.requestid) {
        return {
          ...state,
          search: {
            ...state.search,
            results: action.items,
            processing: false,
          },
        }
      }
      return state
    case actionTypes.SEARCH_STATIONS_FAILURE:
      if (state.search.requestid && action.id === state.search.requestid) {
        return {
          ...state,
          search: {
            ...state.search,
            processing: false,
            error: action.error,
          },
        }
      }
      return state
    case actionTypes.SEARCH_STATIONS_RESET:
      return {
        ...state,
        search: {
          requestid: null,
          results: [],
          processing: false,
          error: null,
        },
      }
    default:
      return state
  }
}

export default reducer
