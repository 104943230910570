import React from 'react';
import { connect } from 'react-redux';
import { Spin, Form, Button, InputNumber, Icon, Popover } from 'antd';
import { injectIntl, intlShape } from 'react-intl';

import * as strings from '../../helpers/defaultStrings';
import actions from '../../actions';

import './Settings.css';

const { Item, createFormField } = Form;

const PercentInput = React.forwardRef((props, ref) => (
  <InputNumber
    innerRef={ref}
    min={0}
    max={100}
    formatter={value => `${value}%`}
    parser={value => value.replace('%', '')}
    {...props}
  />
));

const KiloMeterInput = (
  <InputNumber
    min={0}
    max={100}
    formatter={value => `${value} km`}
    parser={value => value.replace(' km', '')}
  />
);

class Settings extends React.Component {
  componentDidMount() {
    const { getFleetSettings } = this.props;
    getFleetSettings();
  }

  hasErrors = () => {
    const {
      form: { getFieldsError },
    } = this.props;
    const errors = Object.values(getFieldsError());
    return errors && errors.some(x => !!x);
  };

  handleSubmit = (e) => {
    const {
      form: { validateFields },
      saveFleetSettings,
    } = this.props;
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        saveFleetSettings(values);
      }
    });
  };

  render() {
    const {
      fetching, editing, form, intl, close,
    } = this.props;
    const { formatMessage } = intl;
    const { getFieldDecorator, isFieldsTouched } = form;
    const infoContent = (
      <div style={{ maxWidth: 200 }}>
        {formatMessage(strings.description.kmThresholdWithPlanningInfo)}
      </div>
    );
    return (
      <Spin spinning={fetching || editing}>
        <Form onSubmit={this.handleSubmit} layout="vertical">
          <h2 style={{ marginBottom: 16, marginTop: 32 }}>
            <Icon type="control" style={{ marginRight: 8 }} />
            {formatMessage(strings.phrase.fuelBotParameters)}
          </h2>
          <h3>{formatMessage(strings.phrase.truckWithPlanning)}</h3>
          <Item>
            <div className="settings-form-element">
              <p className="settings-form-element-title">
                {formatMessage(strings.phrase.lowerFuelPercentageAtWhichAMessageCanBeSent)}
              </p>
              {getFieldDecorator('fpThresholdWithPlanning', {
                rules: [
                  {
                    required: true,
                    message: formatMessage(strings.message.thisFieldIsMandatory),
                  },
                ],
              })(<PercentInput />)}
            </div>
          </Item>
          <Item>
            <div className="settings-form-element">
              <p className="settings-form-element-title">
                {formatMessage(strings.phrase.kmThresholdWithPlanning)}
                <Popover content={infoContent} placement="right">
                  <Icon
                    type="info-circle"
                    theme="twoTone"
                    twoToneColor="dodgerblue"
                    style={{ marginLeft: 10 }}
                  />
                </Popover>
              </p>
              {getFieldDecorator('kmThresholdWithPlanning', {
                rules: [
                  {
                    required: true,
                    message: formatMessage(strings.message.thisFieldIsMandatory),
                  },
                ],
              })(KiloMeterInput)}
            </div>
          </Item>
          <h3>{formatMessage(strings.phrase.truckWithoutPlanning)}</h3>
          <Item>
            <div className="settings-form-element">
              <p className="settings-form-element-title">
                {formatMessage(strings.phrase.lowerFuelPercentageAtWhichAMessageCanBeSent)}
              </p>
              {getFieldDecorator('fpThresholdWithoutPlanning', {
                rules: [
                  {
                    required: true,
                    message: formatMessage(strings.message.thisFieldIsMandatory),
                  },
                ],
              })(<PercentInput />)}
            </div>
          </Item>
          <Item>
            <div className="settings-form-element">
              <p className="settings-form-element-title">
                {formatMessage(strings.phrase.kmThresholdWithoutPlanning)}
              </p>
              {getFieldDecorator('kmThresholdWithoutPlanning', {
                rules: [
                  {
                    required: true,
                    message: formatMessage(strings.message.thisFieldIsMandatory),
                  },
                ],
              })(KiloMeterInput)}
            </div>
          </Item>
        </Form>
        <div>
          <Button
            type="primary"
            className="settings-form-save-button"
            onClick={this.handleSubmit}
            disabled={!isFieldsTouched() || this.hasErrors()}
          >
            {formatMessage(strings.word.save)}
          </Button>
          <Button className="settings-form-save-button" onClick={close}>
            {formatMessage(strings.word.cancel)}
          </Button>
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(store) {
  return {
    fpThresholdWithPlanning:
      store.fleetSettings.items &&
      store.fleetSettings.items.fp_threshold_with_planning,
    fpThresholdWithoutPlanning:
      store.fleetSettings.items &&
      store.fleetSettings.items.fp_threshold_without_planning,
    kmThresholdWithoutPlanning:
      store.fleetSettings.items &&
      (store.fleetSettings.items.km_threshold_without_planning
        ? store.fleetSettings.items.km_threshold_without_planning
        : 15),
    kmThresholdWithPlanning:
      store.fleetSettings.items &&
      (store.fleetSettings.items.km_threshold_with_planning
        ? store.fleetSettings.items.km_threshold_with_planning
        : 1.5),
    fetching: store.fleetSettings.fetching,
    editing: store.fleetSettings.processing,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFleetSettings: () => dispatch(actions.fleetSettings.getFleetSettings()),
    saveFleetSettings: settings =>
      settings &&
      dispatch(actions.fleetSettings.saveFleetSettings({
        fp_threshold_without_planning: settings.fpThresholdWithoutPlanning,
        fp_threshold_with_planning: settings.fpThresholdWithPlanning,
        km_threshold_without_planning: settings.kmThresholdWithoutPlanning,
        km_threshold_with_planning: settings.kmThresholdWithPlanning,
      })),
  };
}

const SettingsForm = Form.create({
  mapPropsToFields: ({
    fpThresholdWithPlanning,
    fpThresholdWithoutPlanning,
    kmThresholdWithoutPlanning,
    kmThresholdWithPlanning,
  }) => ({
    fpThresholdWithPlanning: createFormField({
      value: fpThresholdWithPlanning,
    }),
    fpThresholdWithoutPlanning: createFormField({
      value: fpThresholdWithoutPlanning,
    }),
    kmThresholdWithoutPlanning: createFormField({
      value: kmThresholdWithoutPlanning,
    }),
    kmThresholdWithPlanning: createFormField({
      value: kmThresholdWithPlanning,
    }),
  }),
})(Settings);

SettingsForm.propTypes = {
  intl: intlShape.isRequired,
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsForm));
