import actionTypes from '../actions/actionTypes';

const initialState = {
  items: null,
  lastEvaluatedKey: 'initial',
  processing: false,
  error: null,
  fullRecommendation: null,
  truckList: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // get recommendation list
    case actionTypes.GET_RECOMMENDATION_LIST_REQUEST: {
      return {
        ...state,
        processing: true,
        error: null,
      };
    }
    case actionTypes.GET_RECOMMENDATION_LIST_SUCCESS: {
      const itemsAfter = state.items || [];
      return {
        ...state,
        items: [...itemsAfter, ...action.items],
        lastEvaluatedKey: action.lastEvaluatedKey,
        processing: false,
        error: null,
      };
    }
    case actionTypes.GET_RECOMMENDATION_LIST_FAILURE: {
      return {
        ...state,
        items: null,
        processing: false,
        error: action.error,
      };
    }
    case actionTypes.GET_DETAILED_RECO_REQUEST: {
      return {
        ...state,
        processing: true,
        error: null,
      };
    }
    case actionTypes.GET_DETAILED_RECO_SUCCESS: {
      return {
        ...state,
        fullRecommendation: action.reco,
        processing: false,
        error: null,
      };
    }
    case actionTypes.GET_DETAILED_RECO_FAILURE: {
      return {
        ...state,
        processing: false,
        fullRecommendation: null,
        error: action.error,
      };
    }
    case actionTypes.GET_TRUCK_LIST_REQUEST: {
      return {
        ...state,
        processing: true,
        error: null,
      };
    }
    case actionTypes.GET_TRUCK_LIST_SUCCESS: {
      const itemsAfter = state.truckList || [];
      return {
        ...state,
        truckList: [...itemsAfter, ...action.items.truckList],
        processing: false,
        error: null,
      };
    }
    case actionTypes.GET_TRUCK_LIST_FAILURE: {
      return {
        ...state,
        truckList: null,
        processing: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
