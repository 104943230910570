import React from 'react'
import { injectIntl, intlShape } from 'react-intl';
import { Icon, Button, Transfer, Spin, Row, Col, Tag, List } from 'antd'
import _ from 'lodash'
import { connect } from 'react-redux'

import actions from '../../actions'
import * as strings from '../../helpers/defaultStrings';
import isoCountryCode from '../../assets/data/countriesMapping.json'
import moment from 'moment';

class ExciseRefunds extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selection: [],
      allExciseRefunds: [],
    }
  }

  componentDidMount() {
    const {
      user, fetching, getExciseRefunds, getFleetSettings, exciseRefundsLoading,
    } = this.props
    if (user) {
      if (!fetching) getFleetSettings()
      if (!exciseRefundsLoading) getExciseRefunds()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      user,
      getFleetSettings,
      getExciseRefunds,
      saved_exciserefunds,
      fetching,
      editing,
      error,
      exciseRefundsError,
      exciseRefundsLoading,
      intl,
      addErrorMessage,
    } = this.props;
    const { formatMessage } = intl

    // fetching data on user auth success
    if (!prevProps.user && !!user) {
      if (!fetching) getFleetSettings()
      if (!exciseRefundsLoading) getExciseRefunds()
    }

    // error and success messages
    if (!fetching && prevProps.fetching) {
      if (error) addErrorMessage(formatMessage(strings.message.failedToGetExciseRefunds))
      else this.onSelect(saved_exciserefunds)
    }
    if (!editing && prevProps.editing) {
      if (error) addErrorMessage(formatMessage(strings.message.failedToSaveExciseRefunds))
    }
    if (!exciseRefundsLoading && prevProps.exciseRefundsLoading) {
      if (exciseRefundsError) addErrorMessage(formatMessage(strings.message.failedToGetAvailableExciseRefunds))
      else this.onExciseRefundsReceived()
    }
  }

  onExciseRefundsReceived = () => {
    const { exciseRefunds } = this.props
    if (exciseRefunds) {
      let allExciseRefunds = [];
      if (exciseRefunds) {
        exciseRefunds.forEach((exciseRefund) => {
          const countryDuplicate = allExciseRefunds.filter(refund => refund.key === exciseRefund.country_code)
          if (countryDuplicate.length > 0) {
            if (moment(countryDuplicate[0].last_modified, 'DD/MM/YYYY').isAfter(moment(exciseRefund.last_modified))) {
              return;
            }
            allExciseRefunds = allExciseRefunds.filter(refund => refund.key !== countryDuplicate[0].key)
          }
          allExciseRefunds.push({
            key: exciseRefund.country_code,
            title: isoCountryCode[exciseRefund.country_code],
            validity_date: moment(new Date(exciseRefund.validity_date)).format('DD/MM/YYYY'),
            last_modified: new Date(exciseRefund.last_modified),
            price: exciseRefund.price,
            currency: exciseRefund.currency || 'EUR',
          })
        })
      }
      allExciseRefunds = allExciseRefunds.sort((a, b) => (a.title > b.title ? 1 : -1))
      this.setState({ allExciseRefunds })
    }
  }

  onSelect = (selection) => { this.setState({ selection }) }
  sanitize = str => (str ? _.camelCase(str).toLowerCase() : '') // camelcase, to avoid all symbols
  reset = () => this.onSelect(this.props.saved_exciserefunds)
  apply = () => this.props.saveExciseRefunds(this.state.selection)

  renderTransferItem = (Item) => {
    const { formatMessage, locale } = this.props.intl;

    return (
      <List.Item.Meta
        title={<span>{Item.title} <Tag color="cyan" style={{ marginLeft: 4 }}>{formatMessage(strings.phrase.validityDate)} {Item.validity_date}</Tag>
          <Tag color="green" style={{ marginLeft: 4 }}>{`${new Intl.NumberFormat(locale, { style: 'currency', currency: Item.currency, maximumSignificantDigits: 6 }).format(Item.price)}`}</Tag>
        </span>}
      />
    )
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { selection, allExciseRefunds } = this.state
    const { saved_exciserefunds, fetching, editing } = this.props

    const isUnchanged = selection.length === saved_exciserefunds.length
      && _.isEqual(_.sortBy(selection), _.sortBy(saved_exciserefunds))
    return (
      <div style={{ height: 'calc(100vh - 64px - 54px - 45px - 56px - 32px - 1em)', overflowY: 'scroll' }} >
        <p style={{ color: 'silver' }}>
          <Icon type="info-circle" style={{ marginRight: 8 }} />
          {formatMessage(strings.description.exciseRefundsSettingsInfoMessage)}
        </p>
        <Spin spinning={fetching || editing}>
          <Transfer
            listStyle={{ minWidth: 300, height: 400, width: 'auto' }}
            dataSource={allExciseRefunds || []}
            render={item => this.renderTransferItem(item)}
            operations={[formatMessage(strings.word.add), formatMessage(strings.word.remove)]}
            titles={['', <strong>{formatMessage(strings.phrase.yourCountries)}</strong>]}
            showSearch
            filterOption={(inputValue, option) => this.sanitize(option.title).includes(this.sanitize(inputValue))}
            targetKeys={selection}
            onChange={this.onSelect}
            className="exciseRefundsTransfer"
            locale={{
              itemUnit: formatMessage(strings.word.country),
              itemsUnit: formatMessage(strings.word.countries),
              notFoundContent: formatMessage(strings.phrase.noCountries),
            }}
          />
          <br />
          <Row type="flex" gutter={16}>
            <Col>
              <Button disabled={isUnchanged} onClick={this.reset} >
                {formatMessage(strings.word.reset)}
              </Button>
            </Col>
            <Col>
              <Button type="primary" disabled={isUnchanged} onClick={this.apply} >
                {formatMessage(strings.word.apply)}
              </Button>
            </Col>
          </Row>
        </Spin>
      </div>
    )
  }
}

ExciseRefunds.propTypes = {
  intl: intlShape.isRequired,
};

function mapStateToProps(store) {
  return {
    user: store.auth.user,
    saved_exciserefunds: store.fleetSettings.items && store.fleetSettings.items.exciserefunds,
    fetching: store.fleetSettings.fetching,
    editing: store.fleetSettings.processing,
    error: store.fleetSettings.error,
    exciseRefunds: store.globalInformations.exciseRefunds && store.globalInformations.exciseRefunds.items,
    exciseRefundsLoading: store.globalInformations.exciseRefunds && store.globalInformations.exciseRefunds.processing,
    exciseRefundsError: store.globalInformations.exciseRefunds && store.globalInformations.exciseRefunds.error,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getFleetSettings: () => dispatch(actions.fleetSettings.getFleetSettings()),
    saveExciseRefunds: exciserefunds => dispatch(actions.fleetSettings.saveFleetSettings({ exciserefunds })),
    addErrorMessage: msg => dispatch(actions.messages.addErrorMessage(msg)),
    getExciseRefunds: () => dispatch(actions.globalInformations.getExciseRefunds()),
  }
}

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExciseRefunds))
