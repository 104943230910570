import actionTypes from '../actions/actionTypes'
import stationBrands from '../assets/data/stationBrandsMapping.json'

export const initialState = {
  items: {
    exciserefunds: [],
    cards: [],
    brands: Object.keys(stationBrands),
    fp_threshold_with_planning: 80,
    fp_threshold_without_planning: 50,
  },
  error: null,
  fetching: false,
  processing: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    // get settings
    case actionTypes.GET_FLEET_SETTINGS_REQUEST:
      return {
        ...state,
        error: null,
        fetching: true,
      }
    case actionTypes.GET_FLEET_SETTINGS_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          ...action.settings,
        },
        fetching: false,
      }
    case actionTypes.GET_FLEET_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      }
    // save settings
    case actionTypes.SAVE_FLEET_SETTINGS_REQUEST:
      return {
        ...state,
        processing: true,
      }
    case actionTypes.SAVE_FLEET_SETTINGS_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          ...action.settings,
        },
        error: null,
        processing: false,
      }
    case actionTypes.SAVE_FLEET_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      }
    default:
      return state
  }
}

export default reducer
