import API from '@aws-amplify/api'

import actionTypes from './actionTypes'

const getSettingsRequest = () => ({ type: actionTypes.GET_SETTINGS_REQUEST })
const getSettingsSuccess = settings => ({ type: actionTypes.GET_SETTINGS_SUCCESS, settings })
const getSettingsFailure = error => ({ type: actionTypes.GET_SETTINGS_FAILURE, error })
const saveSettingsRequest = () => ({ type: actionTypes.SAVE_SETTINGS_REQUEST })
const saveSettingsSuccess = settings => ({ type: actionTypes.SAVE_SETTINGS_SUCCESS, settings })
const saveSettingsFailure = error => ({ type: actionTypes.SAVE_SETTINGS_FAILURE, error })

function getSettings() {
  return (dispatch) => {
    const url = 'settings'
    dispatch(getSettingsRequest())
    return API.get('Backend', url)
      .then(data => dispatch(getSettingsSuccess(data || {})))
      .catch(err => dispatch(getSettingsFailure(err)))
  }
}

function saveSettings(settings = {}) {
  return (dispatch) => {
    const url = 'settings'
    dispatch(saveSettingsRequest())
    return API.post('Backend', url, { body: settings })
      .then(() => dispatch(saveSettingsSuccess(settings)))
      .catch(err => dispatch(saveSettingsFailure(err)))
  }
}

export default {
  getSettings,
  saveSettings,
}
