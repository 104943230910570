
import React from 'react';
import { isNil } from 'lodash';
import { injectIntl } from 'react-intl';
import { Modal, Row, Col, Spin } from 'antd';
import DeckGL, { ScatterplotLayer, PathLayer } from 'deck.gl';
import ReactMapGL, { Popup } from 'react-map-gl';
import { connect } from 'react-redux';
import * as strings from '../../helpers/defaultStrings';
import actions from '../../actions';
import Bar from '../../charts/BarChart';


const { REACT_APP_MAPBOX_ACCESS_TOKEN, REACT_APP_MAPBOX_STYLE } = process.env;
const COLORS = {
  stationGreen: [155, 214, 38],
  lightDodgerblue: [153, 204, 255],
  dodgerblue: [58, 144, 224],
  white: [255, 255, 255],
  orangered: [255, 69, 0],
  orangegrey: [237, 217, 211],
  pink: [255, 65, 185],
  pinkhex: '#FF49A5',
  yellow: [255, 255, 75],
};

class RecommendationJustification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trackLayer: [],
      poisLayer: [],
      recoStationsLayer: [],
      planningPointLayer: [],
      barData: [],
    };
  }
  componentDidMount() {
    const { recommendation, getDetailedReco } = this.props;
    getDetailedReco(recommendation.recommendationid);
  }

  componentDidUpdate(prevProps) {
    const { fullRecommendation, compactEvents } = this.props;
    if (prevProps.fullRecommendation !== fullRecommendation) {
      this.getDeckGLLayers(fullRecommendation, compactEvents);
    }
  }

  onBarHover = (station) => {
    if (!station) {
      this.setState({ tooltip: null });
    }
    this.setState({
      tooltip: (
        <Popup
          longitude={+station.longitude}
          latitude={+station.latitude}
          closeButton
          onClose={() => this.setState({ tooltip: null })}
        >
          <div>
            <h4>{station.brand}</h4>
            <span>{`${station.address}, ${station.locality}`}</span>
            {station.gaz_prices.map((gp) => {
              const formattedPrice = gp.price_eur ? `${gp.price_eur} €` : `${gp.price} ${gp.station_currency ? gp.station_currency : station.currency}`;
              return (
                <div key={gp.name}> {`${gp.name}: ${formattedPrice}`}
                </div>
                );
            })}
          </div>
        </Popup>
      ),
    });
  }

  getDeckGLLayers = (fullRecommendation, compactEvents) => {
    const { creationTfu, plannings } = fullRecommendation;
    const pathBefore = compactEvents.filter(e => e.tfu <= creationTfu / 2);
    const routingPath = fullRecommendation.routing.map(d => [d[1], d[0]]);
    const pathData = [
      {
        path: pathBefore.map(d => [+d.longitude, +d.latitude]),
        color: COLORS.dodgerblue,
      },
      {
        path: routingPath,
        color: COLORS.orangegrey,
      },
    ];

    const trackLayer = new PathLayer({
      id: 'compact-events',
      data: pathData,
      rounded: true,
      widthMinPixels: 4,
      widthMaxPixels: 4,
      getColor: d => d.color,
    });
    const recoStations = fullRecommendation.fuelStations.map(station => station.uuid);
    const discountStations = fullRecommendation.stations_with_discount ? fullRecommendation.stations_with_discount.map(station => station.uuid) : [];
    const preferredStations = fullRecommendation.preferred_stations ? fullRecommendation.preferred_stations.map(station => station.uuid) : [];
    const platts = fullRecommendation.platts ? fullRecommendation.platts.map(station => station.uuid) : [];
    const homebases = fullRecommendation.homebases ? fullRecommendation.homebases.map(station => station.uuid) : [];
    const enrichedStations = fullRecommendation.stations_with_discount.reduce((acc, station) => {
      if (acc.ids.includes(station.uuid)) {
        return acc;
      }
      acc.ids.push(station.uuid);
      acc.stations.push({ ...station, color: this.getStationColor(station, discountStations, preferredStations, platts, homebases, recoStations) });
      return acc;
    }, { ids: [], stations: [] }).stations;

    const poisLayer = new ScatterplotLayer({ // grey with white border
      id: 'pois',
      data: enrichedStations,
      opacity: 1,
      stroked: true,
      filled: true,
      getPosition: d => [+d.longitude, +d.latitude],
      radiusMinPixels: 4,
      radiusMaxPixels: 4,
      lineWidthMinPixels: 1,
      getFillColor: d => d.color.fill,
      getLineColor: d => d.color.line,
    });

    const recoStationsLayer = new ScatterplotLayer({ // grey with white border
      id: 'reco',
      data: fullRecommendation.fuelStations,
      opacity: 1,
      stroked: true,
      filled: true,
      getPosition: d => [+d.x, +d.y],
      radiusMinPixels: 6,
      radiusMaxPixels: 6,
      lineWidthMinPixels: 2,
      getFillColor: COLORS.orangered,
      getLineColor: COLORS.dodgerblue,
    });

    const planningPointLayer = new ScatterplotLayer({ // grey with white border
      id: 'planning-points',
      data: plannings,
      opacity: 1,
      stroked: true,
      filled: true,
      getPosition: d => [+d.X, +d.Y],
      pickable: true,
      onHover: this.onHover,
      radiusMinPixels: 6,
      radiusMaxPixels: 6,
      lineWidthMinPixels: 2,
      getFillColor: COLORS.dodgerblue,
      getLineColor: COLORS.white,
      visible: !isNil(plannings),
    });

    const barData = enrichedStations.reduce((acc, station, index) => {
      acc.push({
        index,
        x: `${station.brand}-${station.locality}-${index}`,
        value: station.gaz_prices.reduce((minimum, price) => {
          const priceEur = price.price_eur ? +price.price_eur : +price.price;
          if (priceEur < minimum) return priceEur;
          return minimum;
        }, 1000),
        color: station.color,
        station,
      });
      return acc;
    }, []);

    this.setState({
      barData, poisLayer, recoStationsLayer, trackLayer, planningPointLayer,
    });
  }

  getStationColor = (station, discountStations, preferredStations, platts, homebases, recoStations) => {
    const { uuid } = station;
    if (recoStations.includes(uuid)) {
      return {
        fill: COLORS.orangered,
        line: COLORS.dodgerblue,
      };
    } else if (homebases.includes(uuid)) {
      return {
        fill: COLORS.stationGreen,
        line: COLORS.dodgerblue,
      };
    } else if (platts.includes(uuid)) {
      return {
        fill: COLORS.pink,
        line: COLORS.dodgerblue,
      };
    } else if (preferredStations.includes(uuid)) {
      return {
        fill: COLORS.lightDodgerblue,
        line: COLORS.dodgerblue,
      };
    } else if (discountStations.includes(uuid)) {
      return {
        fill: COLORS.yellow,
        line: COLORS.dodgerblue,
      };
    }
    return {
      fill: COLORS.white,
      line: COLORS.dodgerblue,
    };
  }

  getBoundingViewState = reco =>
    ({
      longitude: reco.creationLocation.x,
      latitude: reco.creationLocation.y,
      zoom: 8,
      pitch: 0,
      bearing: 0,
    })


  getLegend = () => {
    const { formatMessage } = this.props.intl;
    const { fullRecommendation } = this.props;
    const planningsVisible = !!fullRecommendation && !!fullRecommendation.plannings;
    const legendStyle = {
      backgroundColor: 'white',
      borderRadius: 4,
      boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.1)',
      zIndex: 100,
      margin: 8,
      padding: 8,
    };
    const Dot = ({ fillRgb, lineRgb }) => (
      <span
        style={{
          height: '1em',
          width: '1em',
          backgroundColor: `rgb(${fillRgb.join(',')})`,
          border: `2px solid rgb(${lineRgb.join(',')})`,
          borderRadius: '50%',
          display: 'inline-block',
          marginRight: 8,
        }}
      />
    );
    const Line = ({ rgb }) => (
      <div
        style={{
          height: 4,
          width: 15,
          background: `rgb(${rgb.join(',')})`,
          borderRadius: 2,
          display: 'inline-block',
          marginRight: 8,
          marginBottom: 4,
        }}
      />
    );
    return (
      <Row
        type="flex"
        justify="space-between"
        align="bottom"
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Col style={legendStyle}>
          <h4>{formatMessage(strings.word.legend)}</h4>
          <div>
            <Dot fillRgb={COLORS.orangered} lineRgb={COLORS.dodgerblue} />
            {formatMessage(strings.phrase.recommendedStations)}
          </div>
          <div>
            <Dot fillRgb={COLORS.stationGreen} lineRgb={COLORS.dodgerblue} />
            {formatMessage(strings.phrase.homebaseStations)}
          </div>
          <div>
            <Dot fillRgb={COLORS.pink} lineRgb={COLORS.dodgerblue} />
            {formatMessage(strings.phrase.bulkUploadStations)}
          </div>
          <div>
            <Dot fillRgb={COLORS.lightDodgerblue} lineRgb={COLORS.dodgerblue} />
            {formatMessage(strings.phrase.preferredStations)}
          </div>
          <div>
            <Dot fillRgb={COLORS.yellow} lineRgb={COLORS.dodgerblue} />
            {formatMessage(strings.phrase.stationsWithDiscount)}
          </div>
          {planningsVisible &&
          <div>
            <Dot fillRgb={COLORS.dodgerblue} lineRgb={COLORS.white} />
            {formatMessage(strings.word.planning)}
          </div>}
          <div>
            <Line rgb={COLORS.orangegrey} />
            {formatMessage(strings.phrase.inferredRouting)}
          </div>
          <div>
            <Line rgb={COLORS.dodgerblue} />
            {formatMessage(strings.phrase.trucksPath)}
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    const {
      open,
      onClose,
      fullRecommendation,
      processing,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const {
      trackLayer, poisLayer, recoStationsLayer, barData, tooltip, planningPointLayer,
    } = this.state;
    const viewState = fullRecommendation && this.getBoundingViewState(fullRecommendation);
    return (
      <Modal
        closable
        width="80%"
        title={formatMessage(strings.word.recommendation)}
        visible={open}
        bodyStyle={{
          height: '80vh',
          overflowY: 'auto',
          padding: 'unset',
          }}
        onCancel={onClose}
        footer={null}
      >
        <div style={{
 height: '70%', position: 'relative', textAlign: 'center',
}}
        >
          {processing ?
            <Spin style={{ paddingTop: 200 }} />
            :
            <DeckGL
              // viewState={viewState}
              // onViewStateChange={this.onViewStateChange}
              initialViewState={viewState}
              controller
              layers={[trackLayer, poisLayer, recoStationsLayer, planningPointLayer]}
            >
              <ReactMapGL
                reuseMaps
                mapboxApiAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
                preventStyleDiffing
                mapStyle={REACT_APP_MAPBOX_STYLE}
                ref={(map) => { this.mapRef = map; }}
              >
                {this.getLegend()}
                {tooltip}
              </ReactMapGL>

            </DeckGL>
          }
        </div>
        <div style={{
          position: 'relative',
          overflowX: 'auto',
          width: '100%',
        }}
        >
          <Bar
            data={barData}
            width={barData.length * 50 + 50}
            height={400}
            top={10}
            bottom={200}
            left={50}
            right={0}
            onBarHover={this.onBarHover}
          />
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(store) {
  const {
    recommendations: { items, fullRecommendation, processing },
    settings: { items: { useInternalVehicleId } },
  } = store;
  return {
    processing,
    items,
    fullRecommendation,
    useInternalVehicleId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDetailedReco: recommendationId => dispatch(actions.recommendations.getDetailedReco(recommendationId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RecommendationJustification));
