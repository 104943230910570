import { compact, isNil, mean, min, sum } from 'lodash';

const kpiCompute = (filteredRecommendations) => {
  const orderedRecommendations = filteredRecommendations.sort((a, b) => b.creationOdoMeter - a.creationOdoMeter);
  const totalReco = orderedRecommendations.length;
  const truckDistancesData = orderedRecommendations.reduce((acc, reco) => {
    const { truckId, creationOdoMeter: newOdoMeter } = reco;
    if (acc.trucks.includes(truckId)) {
      acc.data = acc.data.map((data) => {
        if (data.truck === truckId) {
          const kmsCovered = data.lastOdoMeter - newOdoMeter;
          return {
            ...data, lastOdoMeter: newOdoMeter, kmPerReco: [...data.kmPerReco, kmsCovered], totalKm: data.totalKm + kmsCovered,
          };
        }
        return data;
      });
    } else {
      acc.trucks.push(truckId);
      acc.data.push({
        truck: truckId, lastOdoMeter: newOdoMeter, kmPerReco: [], totalKm: 0,
      });
    }
    return acc;
  }, { data: [], trucks: [] });

  const aggregateKmPerReco = mean(compact((truckDistancesData.data.map((data) => {
    if (data.kmPerReco.length > 0) {
      return mean(data.kmPerReco);
    }
    return null;
  }))));
  const aggregateTotalKm = sum(truckDistancesData.data.map(data => data.totalKm));


  const avgStationPrice = orderedRecommendations.reduce((acc, reco) => {
    const stationPrices = [];
    reco.fuelStations.forEach((s) => { s.gaz_prices.forEach(p => stationPrices.push(p.price_eur)); });
    return acc + min(stationPrices);
  }, 0) / totalReco;

  const stationTableData = orderedRecommendations.reduce((acc, reco) => {
    reco.fuelStations.forEach((s, index) => {
      const stationNameAndLocality = `${s.station_name}-${s.locality}`;
      const stationPrices = [];
      s.gaz_prices.forEach(p => stationPrices.push(p.price_eur));
      const minPrice = min(stationPrices);
      if (acc.stations.includes(stationNameAndLocality)) {
        acc.data = acc.data.map((data) => {
          if (data.id === stationNameAndLocality) {
            if (index === 0) {
              return { ...data, firstReco: data.firstReco + 1, prices: [...data.prices, minPrice] };
            }
            return { ...data, secondReco: data.secondReco + 1, prices: [...data.prices, minPrice] };
          }
          return data;
        });
      } else {
        acc.data.push({
          id: stationNameAndLocality,
          station: s.station_name,
          locality: s.locality,
          country: s.country_code,
          firstReco: index === 0 ? 1 : 0,
          secondReco: index === 0 ? 0 : 1,
          prices: [minPrice],
        });
        acc.stations.push(stationNameAndLocality);
      }
    });
    return acc;
  }, { data: [], stations: [] });

  const countryData = orderedRecommendations.reduce((acc, reco) => {
    reco.fuelStations.forEach((s) => {
      if (acc.countries.includes(s.country_code)) {
        acc.data = acc.data.map((data) => {
          if (data.country === s.country_code) {
            return { country: data.country, totalReco: data.totalReco + 1 };
          }
          return data;
        });
      } else {
        acc.data.push({ country: s.country_code, totalReco: 1 });
        acc.countries.push(s.country_code);
      }
    });
    return acc;
  }, { data: [], countries: [] });

  const cardsData = orderedRecommendations.reduce((acc, reco) => {
    reco.fuelStations.forEach((s, index) => {
      if (isNil(s.cards)) return acc;
      s.cards.forEach((card) => {
        if (acc.cards.includes(card)) {
          acc.data = acc.data.map((data) => {
            if (data.title === card) {
              if (index === 0) {
                return { ...data, firstReco: data.firstReco + 1 };
              }
              return { ...data, secondReco: data.secondReco + 1 };
            }
            return data;
          });
        } else {
          acc.data.push({
            title: card,
            firstReco: index === 0 ? 1 : 0,
            secondReco: index === 0 ? 0 : 1,
          });
          acc.cards.push(card);
        }
      });
    });
    return acc;
  }, { data: [], cards: [] });

  const firstRecoTotal = sum(cardsData.data.map(data => data.firstReco));
  const secondRecoTotal = sum(cardsData.data.map(data => data.secondReco));
  const firstRecoCards = cardsData.data.map(data => ({ title: data.title, value: data.firstReco / firstRecoTotal }));
  const secondRecoCards = cardsData.data.map(data => ({ title: data.title, value: data.secondReco / secondRecoTotal }));

  //  const firstRecoCards = [{ title: 'payment_flottencard_eurotrafic', value: 0.5 }, { title: 'payment_bonuscard_lukoil_club', value: 0.5 }];
  // const secondRecoCards = [{ title: 'payment_flottencard_eurotrafic', value: 0.5 }, { title: 'payment_bonuscard_lukoil_club', value: 0.5 }];
  return {
    totalReco, aggregateKmPerReco, aggregateTotalKm, avgStationPrice, stationTableData, countryData, firstRecoCards, secondRecoCards,
  };
};

export default kpiCompute;
