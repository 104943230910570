/* eslint-disable max-len */
import React, { Component } from 'react';
import { Spin, Button, Empty, Tooltip, Row, Col } from 'antd';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import TimelineChart from '../../charts/TimelineChart/TimelineChartComponent';
import currenciesIsoCodes from '../../assets/data/isoCurrenciesData.json'
import { TfuOrTimeSwitch } from '../../helpers'
import * as strings from '../../helpers/defaultStrings';
import actions from '../../actions'

// remains: data, onEventChange, onFocus, refuel, detourTfu, fuelDrop, autoPlay
class FuelLevelHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      autoPlay: false,
    }
  }

  getAutoPlayButtons() {
    const { formatMessage } = this.props.intl
    const { compactEvents: { items } } = this.props
    const { autoPlay } = this.state
    return items && (
      <Row type="flex" justify="center">
        <Button
          shape="round"
          onClick={this.toggleAutoPlay}
          icon={autoPlay ? 'pause' : 'caret-right'}
        >
          {autoPlay ?
            formatMessage(strings.word.pause) :
            formatMessage(strings.phrase.playRecommendation)}
        </Button>
      </Row>
    )
  }

  toggleAutoPlay = () => {
    this.setState({ autoPlay: !this.state.autoPlay })
  }

  render() {
    const {
      compactEvents: { processing, items },
      axis,
      currency,
      recommendation,
      setTruckPosition,
      intl: { formatMessage },
      loadMoreAfter,
      loadMoreBefore,
    } = this.props
    const { autoPlay } = this.state
    if (
      (!processing && !items) ||
      (!processing && items && Array.isArray(items) && items.length === 0)
    ) {
      return <Empty description={formatMessage(strings.message.noDataCurrentSelection)} />
    }
    return (
      <Spin spinning={processing}>
        <Row type="flex" align="middle" justify="space-between" style={{ marginBottom: 8 }} gutter={16}>
          <Col style={{ width: 100 }} /> {/* just to center middle col */}
          <Col>
            <Button
              shape="round"
              onClick={this.toggleAutoPlay}
              icon={autoPlay ? 'pause' : 'caret-right'}
            >
              {autoPlay ? formatMessage(strings.word.pause) : formatMessage(strings.phrase.playRecommendation)}
            </Button>
          </Col>
          <Col>
            <TfuOrTimeSwitch disabled={processing} />
          </Col>
        </Row>
        <Row type="flex" align="middle" justify="space-between">
          <Col>
            <Tooltip title={formatMessage(strings.phrase.loadMore)}>
              <Button shape="circle" icon="left" onClick={loadMoreBefore} />
            </Tooltip>
          </Col>
          <Col style={{ flexGrow: 4 }}>
            <TimelineChart
              height={240}
              axis={axis.toLowerCase()}
              currency={currency}
              autoPlay={autoPlay}
              processing={processing}
              data={items && items.map(e => ({ ...e, timestamp: e.date }))}
              recommendations={[recommendation]}
              onEventChange={setTruckPosition}
              onFocus={() => {}}
              plannings={recommendation.planning}
              yLabel={formatMessage(strings.phrase.fuelPercent)}
            />
          </Col>
          <Col>
            <Tooltip title={formatMessage(strings.phrase.loadMore)}>
              <Button shape="circle" icon="right" onClick={loadMoreAfter} />
            </Tooltip>
          </Col>
        </Row>
      </Spin>
    );
  }
}

FuelLevelHistory.propTypes = {
  intl: intlShape.isRequired,
};

function mapStateToProps(store) {
  const {
    compactEvents,
    settings: { items: { currency, tfuOrTime } },
  } = store
  return {
    compactEvents,
    currency: currenciesIsoCodes[currency].symbol,
    axis: tfuOrTime,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadMoreAfter: () => dispatch(actions.compactEvents.loadMoreAfter()),
    loadMoreBefore: () => dispatch(actions.compactEvents.loadMoreBefore()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FuelLevelHistory))
