import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const format = d3.format('.3f');

const XAxis = ({
  top, bottom, left, right, height, scale, labels,
}) => {
  const axis = useRef(null);

  useEffect(() => {
    d3.select(axis.current)
      .call(d3.axisBottom(scale)
        .tickFormat((d, i) => labels[i].brand))
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '-.4em')
      .attr('transform', 'rotate(-65)')
      .append('tspan')
      .attr('x', 0)
      .attr('dy', '.8em')
      .attr('dx', '-.8em')
      .text((d, i) => labels[i].address);
  });

  return (
    <g
      className="axis x"
      ref={axis}
      transform={`translate(${left}, ${height - bottom})`}
    />
  );
};

const YAxis = ({
  top, bottom, left, right, scale,
}) => {
  const axis = useRef(null);

  useEffect(() => {
    d3.select(axis.current)
      .call(d3.axisLeft(scale).ticks(4));
  });

  return (
    <g className="axis y" ref={axis} transform={`translate(${left}, ${top})`} />
  );
};

const Rect = ({
  data, x, y, height, top, bottom, onBarHover,
}) => (
  <g transform={`translate(${x(data.x)}, ${y(data.value)})`}>
    <rect
      width={x.bandwidth()}
      height={height - bottom - top - y(data.value)}
      fill={`rgb(${data.color.fill.join(',')})`}
      onMouseOver={() => onBarHover(data.station)}
    />
    <text
      transform={`translate(${x.bandwidth() / 2}, ${-2})`}
      textAnchor="middle"
      alignmentBaseline="baseline"
      fill="grey"
      fontSize="10"
    >
      {format(data.value)}
    </text>
  </g>
);

const Bar = (props) => {
  const data = [...props.data].sort((a, b) => a.value - b.value);

  const x = d3
    .scaleBand()
    .range([0, props.width - props.left - props.right])
    .domain(data.map(d => d.x))
    .padding(0.1);
  const xLabels = data.map(d => ({ brand: d.x.split('-')[0], address: d.x.split('-')[1] }));
  const y = d3
    .scaleLinear()
    .range([props.height - props.top - props.bottom, 0])
    .domain([d3.min(data, d => d.value) - 0.05, d3.max(data, d => d.value) + 0.05]);

  return (
    <>
      <svg width={props.width} height={props.height}>
        <XAxis
          scale={x}
          labels={xLabels}
          top={props.top}
          bottom={props.bottom}
          left={props.left}
          right={props.right}
          height={props.height}
        />
        <YAxis
          scale={y}
          top={props.top}
          bottom={props.bottom}
          left={props.left}
          right={props.right}
        />
        <g transform={`translate(${props.left}, ${props.top})`}>
          {data.map((d, i) => (
            <Rect
              key={d.x}
              data={d}
              x={x}
              y={y}
              top={props.top}
              bottom={props.bottom}
              height={props.height}
              onBarHover={props.onBarHover}
            />
          ))}
        </g>
      </svg>
    </>
  );
};

export default Bar;
