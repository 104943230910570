import React, { createRef, Component } from 'react';
import * as d3 from 'd3';

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.createPie = d3
      .pie()
      .value(d => d.value)
      .sort(null);
    this.createArc = d3
      .arc()
      .innerRadius(props.innerRadius)
      .outerRadius(props.outerRadius);
    this.colors = d3.scaleOrdinal(d3.schemeCategory10);
  }
  componentDidMount() {
    const svg = d3.select(this.ref.current);
    const data = this.createPie(this.props.data.filter(d => d.value > 0).sort((a, b) => b.value - a.value));
    const {
      width, height, outerRadius, locale,
    } = this.props;

    svg
      .attr('class', 'chart')
      .attr('width', width)
      .attr('height', height)
      .append('style')
      .attr('overflow', 'overlay');

    const group = svg
      .append('g')
      .attr('transform', `translate(${outerRadius} ${outerRadius})`);

    const groupWithEnter = group
      .selectAll('g.arc')
      .data(data)
      .enter();

    const path = groupWithEnter.append('g').attr('class', 'arc');

    path
      .append('path')
      .attr('class', 'arc')
      .attr('d', this.createArc)
      .attr('fill', (d, i) => this.colors(d.index));

    svg.selectAll('mydots')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', width - 2 * outerRadius + 20)
      .attr('cy', (d, i) => 20 + i * 20)
      .attr('r', 7)
      .style('fill', (d, i) => this.colors(d.index));

    svg.selectAll('mylabels')
      .data(data)
      .enter()
      .append('text')
      .attr('x', width - 2 * outerRadius + 40)
      .attr('y', (d, i) => 20 + i * 20)
      .text(d => (`${d.data.title}: ${new Intl.NumberFormat(locale, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(d.data.value)} `))
      .style('font-size', '14px')
      .style('alignment-baseline', 'middle');
  }

  componentWillUpdate(nextProps) {
    const svg = d3.select(this.ref.current);
    const data = this.createPie(nextProps.data.filter(d => d.value > 0).sort((a, b) => b.value - a.value));
    const {
      width, outerRadius, locale,
    } = nextProps;

    const group = svg
      .select('g')
      .selectAll('g.arc')
      .data(data);

    group.exit().remove();

    const groupWithUpdate = group
      .enter()
      .append('g')
      .attr('class', 'arc');

    const path = groupWithUpdate.append('path').merge(group.select('path.arc'));

    path
      .attr('class', 'arc')
      .attr('d', this.createArc)
      .attr('fill', (d, i) => this.colors(i));

    svg.selectAll('circle').remove();
    svg.selectAll('text').remove();
    svg.selectAll('mydots')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', width - 2 * outerRadius + 20)
      .attr('cy', (d, i) => 20 + i * 20)
      .attr('r', 7)
      .style('fill', (d, i) => this.colors(d.index));

    svg.selectAll('mylabels')
      .data(data)
      .enter()
      .append('text')
      .attr('x', width - 2 * outerRadius + 40)
      .attr('y', (d, i) => 20 + i * 20)
      .text(d => (`${d.data.title}: ${new Intl.NumberFormat(locale, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(d.data.value)} `))
      .style('font-size', '14px')
      .style('alignment-baseline', 'middle');
  }

  render() {
    return <svg ref={this.ref} />;
  }
}

export default PieChart;
