import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Modal, Spin } from 'antd';
import { Route, Redirect, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';

import actions from '../../actions';
import Home from '../Home/Home';
import FuelBot from '../../Components/FuelBot/FuelBot';
import Settings from '../../Components/Settings/Settings';
import SendToken from '../Login/SendToken';
import paths from '../paths';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import zfLogo from '../../assets/images/zf-logo.png';
import * as strings from '../../helpers/defaultStrings';
import './Layout.css';
import PerformanceIndicators from '../../Components/PerformanceIndicators/PerformanceIndicators';

const { Content, Sider } = Layout

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      logoutPrompt: null,
      promptCountdownTime: 30,
      expiresAt: 0,
    };
  }

  componentDidMount() {
    const { checkUserLoggedIn, location } = this.props
    if (!location.pathname.startsWith('/login')) {
      checkUserLoggedIn();
    }
  }

  componentDidUpdate(prevProps) {
    const { user, logout } = this.props;
    const { logoutPrompt } = this.state;

    if (user && (JSON.stringify(prevProps.user) !== JSON.stringify(user))) {
      const expiresAt = ((!user.expires_at) && (user.sessionTtl) && (user.iat) ? ((parseInt(user.sessionTtl, 10) + parseInt(user.iat, 10)) * 1000) : user.expires_at);
      if (expiresAt) {
        if (expiresAt > Date.now()) {
          const timeToPrompt = Math.round((expiresAt - Date.now()) / 1000) - this.state.promptCountdownTime;
          if (logoutPrompt) {
            // shouldnt ever be the case as Layout should only be updated when browser is refreshed
            // in case future feature updates user object we need to be able to clear old prompt here
            clearTimeout(logoutPrompt);
          }

          this.setState({
            logoutPrompt: setTimeout(() => this.logoutCountDown(), timeToPrompt * 1000),
            expiresAt,
          });
        } else {
          logout();
        }
      }
    }
  }

  logoutCountDown() {
    const { logout } = this.props;
    const { formatMessage } = this.props.intl;
    const { promptCountdownTime, expiresAt } = this.state;

    if ((expiresAt > 0) && (expiresAt < Date.now())) {
      logout();
      return;
    }

    let secondsToGo = promptCountdownTime;
    const modal = Modal.warning({
      title: formatMessage(strings.alert.sessionExpiringTitle),
      okText: formatMessage(strings.alert.sessionRenew),
      content: <span><br />{formatMessage(strings.alert.sessionExpiringDescriptionVal, {
        value: secondsToGo,
      })}<br />
        <br />{formatMessage(strings.alert.sessionExpiringActionDetails)}<br />
      </span>,
      onOk: logout,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content: secondsToGo > 0 ? (<span><br />{formatMessage(strings.alert.sessionExpiringDescriptionVal, {
          value: secondsToGo,
        })}<br />
          <br />{formatMessage(strings.alert.sessionExpiringActionDetails)}<br />
        </span>) : (<span>{formatMessage(strings.alert.sessionExpiringLoggingOut)}</span>),
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
      logout();
    }, (secondsToGo + 1) * 1000); // add 1 second to ensure it is after MyTransics session
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed })
  }

  toggleCollapse = () => {
    const { collapsed } = this.state
    this.setState({ collapsed: !collapsed })
  }

  render() {
    const { location: { pathname: pathName }, authProcessing } = this.props
    const { formatMessage } = this.props.intl;
    const { collapsed } = this.state
    const secondSlashIndex = (pathName.length > 2
      ? pathName.substr(1).indexOf('/') : 0);
    const sectionPathName = (secondSlashIndex > 0
      ? pathName.substr(0, secondSlashIndex + 1) : pathName);
    const titles = {
      [paths.home]: formatMessage(strings.word.home),
      [paths.fuelBot]: formatMessage(strings.word.fuelBot),
    };
    return (
      <Layout style={{ height: '100vh' }}>
        <Modal
          visible={authProcessing}
          closable={false}
          title={null}
          width={100}
          footer={null}
          wrapClassName="loadingModal"
          centered
        >
          <Spin size="large" />
        </Modal>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={this.toggleCollapse}
          trigger={null}
          theme="light"
          style={{ boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)', zIndex: 10 }}
        >
          <Link to={paths.home}>
            <img
              src={zfLogo}
              alt="logo"
              style={{
                width: 40,
                margin: collapsed ? '10px 20px' : '10px 80px',
                marginBottom: 10,
              }}
            />
          </Link>
          <NavigationMenu
            currentPath={pathName}
          />
        </Sider>
        <Layout>
          <HeaderMenu
            toggleCollapse={this.toggleCollapse}
            collapsed={collapsed}
            title={titles[sectionPathName]}
            formatMessage={formatMessage}
          />
          <Content
            style={{
              height: 'calc(100vh - 64px)',
              width: '100%',
            }}
          >
            <Switch>
              <Route exact path={paths.home} component={Home} />
              <Route exact path={paths.settings} component={Settings} />
              <Route exact path={paths.fuelBot} component={FuelBot} />
              <Route exact path={`${paths.fuelBot}/:recommendationid`} component={FuelBot} />
              <Route exact path={paths.kpi} component={PerformanceIndicators} />
              <Route exact path="/login" component={SendToken} />
              <Route exact path="/login/:token" component={SendToken} />
              <Route exact path="/login/:env/:token" component={SendToken} />
              <Redirect to={paths.fuelBot} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    )
  }
}

function mapStateToProps(store, props) {
  let selectedColumnKeys = null;
  if (store.settings.items && store.settings.items[`columnSelection${props.id}`]) {
    selectedColumnKeys = store.settings.items[`columnSelection${props.id}`]
  } else if (props.defaultSelectedColumnKeys) {
    selectedColumnKeys = props.defaultSelectedColumnKeys;
  }
  return {
    selectedColumnKeys,
    user: store.auth.user,
    authProcessing: store.auth.processing,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    checkUserLoggedIn: () => dispatch(actions.auth.checkUserLoggedIn()),
    logout: () => dispatch(actions.auth.logout()),
  }
}

AppLayout.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AppLayout))
