import React from 'react'
import { Avatar, Card, Icon, Row, Col } from 'antd'

import gas from './../../assets/icons/gas.png'
import driver from './../../assets/icons/driver.png'
import truck from './../../assets/icons/truck.png'
import transaction from './../../assets/icons/transaction.png'

const styles = {
  controller: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    fontSize: 12,
  },
  activeButton: {
    cursor: 'pointer',
    color: 'black',
  },
  inactiveButton: {
    color: 'lightgrey',
    cursor: 'default',
  },
  controllerText: {
    marginBottom: 0,
    marginLeft: 12,
    marginRight: 12,
  },
}

const icons = {
  truck, gas, driver, transaction,
}

// overrides antd Card's title to add our icon & arrows
// props:
//  type: truck, gas, driver or transaction
//  navigation: null or { previous, next, index, total }
//  title: whatever
const IconCard = ({
  type, navigation, title, ...remainingProps
}) => (
  <Card
    bodyStyle={{ margin: 8, padding: 8 }}
    {...remainingProps}
    title={(
      <Row type="flex" gutter={8} align="middle" justify="start" style={{ padding: 0 }}>
        <Col><Avatar src={icons[type]} /></Col>
        <Col>{title}</Col>
        {navigation && (
          <Col>
            <div style={styles.controller}>
              <Icon
                style={navigation.index === 1
                  ? styles.inactiveButton
                  : styles.activeButton}
                type="left-circle"
                onClick={navigation.previous}
              />
              <p style={styles.controllerText}>
                {`${navigation.index} / ${navigation.total}`}
              </p>
              <Icon
                style={navigation.index === navigation.total
                  ? styles.inactiveButton
                  : styles.activeButton}
                type="right-circle"
                onClick={navigation.next}
              />
            </div>
          </Col>
        )}
      </Row>)}
  />
)

export default IconCard
