import moment from 'moment';
import API from '@aws-amplify/api';
import actionTypes from './actionTypes';

const encodeLastEvaluatedKey = lastEvaluatedKey => `"${lastEvaluatedKey.replaceAll('"', '\\"')}"`;

// mappings to rename/reform hardcoded data
const mapStations = e => ({
  name: e.name,
  latitude: e.y,
  longitude: e.x,
  price: e.price,
  currency: e.currency,
});

const mapPlanningEvents = e => ({
  id: e.Id,
  plannedDate: moment.utc(e.PlannedDateTime, 'YYYY-MM-DD[T]HH:mm:ssZ').local(),
  finishedDate: e.Status === 'FINISHED' ? moment.utc(e.LatestUpdatedTime, 'YYYY-MM-DD[T]HH:mm:ssZ').local() : null,
  longitude: e.X,
  latitude: e.Y,
  status: e.Status, // FINISHED, CANCELLED, PLANNED
  order: +e.Order,
});
export const mapRecommendations = e => ({
  recommendationid: e.recommendationId,
  truckid: e.truckId,
  date: moment.utc(e.creationDateTime, 'YYYY-MM-DD[T]HH:mm:ssZ').local(),
  tfu: e.creationTfu && e.creationTfu / 2,
  platenumber: e.platenumber,
  internalvehicleid: e.internalvehicleid,
  longitude: e.creationLocation.x,
  latitude: e.creationLocation.y,
  driver: e.driverId ? e.driverId : e.personTransicsId ? e.personTransicsId : '',
  driverName: e.driverName ? e.driverName : '',
  fuelPercentage: e.creationFuelPercentage,
  station1: e.fuelStations && e.fuelStations[0] && mapStations(e.fuelStations[0]),
  station2: e.fuelStations && e.fuelStations[1] && mapStations(e.fuelStations[1]),
  planning: e.plannings ? e.plannings.map(mapPlanningEvents).sort((a, b) => a.order - b.order).sort((a, b) => a.plannedDate - b.plannedDate) : [],
  status: e.status,
  type: e.type,
  groups: e.groups,
});

/* eslint-disable max-len */

const getRecommendationListRequest = () => ({ type: actionTypes.GET_RECOMMENDATION_LIST_REQUEST });
const getRecommendationListSuccess = (items, lastEvaluatedKey) => ({ type: actionTypes.GET_RECOMMENDATION_LIST_SUCCESS, items, lastEvaluatedKey });
const getRecommendationListFailure = error => ({ type: actionTypes.GET_RECOMMENDATION_LIST_FAILURE, error });

const getDetailedRecoRequest = () => ({ type: actionTypes.GET_DETAILED_RECO_REQUEST });
const getDetailedRecoSuccess = reco => ({ type: actionTypes.GET_DETAILED_RECO_SUCCESS, reco });
const getDetailedRecoFailure = error => ({ type: actionTypes.GET_DETAILED_RECO_FAILURE, error });

function getRecommendationList() {
  return (dispatch, getState) => {
    const { lastEvaluatedKey } = getState().recommendations;
    if (!lastEvaluatedKey) {
      return; // this means there is no more recommendations to fetch
    }
    const url = 'recommendations';
    const queryStringParameters = {
      lastEvaluatedKey: lastEvaluatedKey === 'initial' ? null : encodeLastEvaluatedKey(lastEvaluatedKey),
    };
    dispatch(getRecommendationListRequest());
    return API.get('Backend', url, { queryStringParameters })
      .then((data) => {
        const { recommendationsList, lastEvaluatedKey } = data;
        const items = recommendationsList
          .map(mapRecommendations)
          .sort((a, b) => +b.date - a.date);
        dispatch(getRecommendationListSuccess(items, lastEvaluatedKey));
      })
      .catch(err => dispatch(getRecommendationListFailure(err)));
  };
}

const getDetailedReco = recommendationId => (dispatch, getState) => {
  const url = `detailed_reco/${recommendationId}`;
  dispatch(getDetailedRecoRequest());
  return API.get('Backend', url)
    .then((data) => {
      dispatch(getDetailedRecoSuccess(data));
    })
    .catch(err => dispatch(getDetailedRecoFailure(err)));
};

export default {
  getRecommendationList,
  getDetailedReco,
};
