import React from 'react'
import { Form, Row, Col, Button, Select, AutoComplete } from 'antd';
import { injectIntl } from 'react-intl';
import { RenderedConstant, getStrId } from '../../../helpers/constants'
import * as strings from '../../../helpers/defaultStrings';
import { INITIAL_FILTER } from './PreferredStations';

const { Option } = Select;

class PreferredStationFilterForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filteredStations: [],
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(null)
  }

    handleReset = () => {
      const { form: { resetFields }, onFilter, onFilterClear } = this.props
      resetFields()
      onFilter(INITIAL_FILTER)
      onFilterClear();
    }

    getAdvancedSelect = (key) => {
      const { options } = this.props
      const { formatMessage } = this.props.intl

      const disabled = !options || !options[key] || options[key].length <= 1

      let placeholder = options && options[key] && options[key].length === 1 ? options[key][0] : null
      let renderOption = x => <Option value={x} key={x}>{x}</Option>
      let optionFilterProp = 'value'
      if (key === 'status' || key === 'type') {
        placeholder = placeholder && <RenderedConstant type={placeholder} />
        optionFilterProp = 'label'
        renderOption = k => (
          <Option
            value={k}
            key={k}
            label={formatMessage(getStrId(k))}
          >
            <RenderedConstant type={k} />
          </Option>
        )
      }
      return (
        <Select
          style={{ minWidth: 200 }}
          allowClear
          showSearch
          optionFilterProp={optionFilterProp}
          disabled={disabled}
          placeholder={placeholder}
        >
          {options && options[key] && options[key].map(renderOption)}
        </Select>
      )
    }

    getAutoCompleteSelect = (key) => {
      const { options } = this.props
      const disabled = !options || !options[key] || options[key].length <= 1
      const driverName = options && options[key] && options[key].length > 0 ? options[key] : []
      const { Option } = AutoComplete;

      const values = driverName.map(opt => (
        <Option key={opt} value={opt} label={opt}>
          {opt}
        </Option>
      ))

      let placeholder = options && options[key] && options[key].length === 1 ? options[key][0] : null
      if (key === 'status' || key === 'type') {
        placeholder = placeholder && <RenderedConstant type={placeholder} />
      }

      return (
        <AutoComplete
          allowClear
          dataSource={values}
          style={{ width: 200 }}
          optionLabelProp="label"
          filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          disabled={disabled}
          placeholder={placeholder}
          defaultOpen={false}
        />
      )
    }

    render() {
      const { applyFilter } = this.props
      const { getFieldDecorator } = this.props.form;
      const { formatMessage } = this.props.intl

      return (
        <Form layout="inline">
          <Row type="flex" justify="space-between">
            <Col>
              <Form.Item label={formatMessage(strings.word.name)}>
                {getFieldDecorator('name')(this.getAutoCompleteSelect('name'))}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={formatMessage(strings.word.locality)}>
                {getFieldDecorator('locality')(this.getAutoCompleteSelect('locality'))}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={formatMessage(strings.word.country)}>
                {getFieldDecorator('country')(this.getAdvancedSelect('country'))}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={formatMessage(strings.word.zipcode)}>
                {getFieldDecorator('zipcode')(this.getAdvancedSelect('zipcode'))}
              </Form.Item>
            </Col>
          </Row>
          {/* buttons */}
          <Row type="flex" justify="space-between" align="bottom">
            <Col span={24} style={{ textAlign: 'right', paddingRight: 16 }}>
              <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                {formatMessage(strings.word.clear)}
              </Button>
              <Button type="primary" style={{ marginLeft: 8 }} onClick={applyFilter}>
                {formatMessage(strings.word.apply)}
              </Button>
            </Col>
          </Row>
        </Form>
      )
    }
}

const WrappedPreferredStationFilterForm = Form.create({
  name: 'filter_preferredstation_form',
  onValuesChange: (props, _, allValues) => props.onFilter(allValues),
})(PreferredStationFilterForm);
export default injectIntl(WrappedPreferredStationFilterForm)
