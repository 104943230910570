import React from 'react'
import { Button } from 'antd'

const styles = {
  buttonContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1,
  },
  buttonSubContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 4,
  },
}

// example usage:
// <MapControlButtons
//   toggleStations={this.toggleStations}
//   station={showStations}
//   toggleSatellite={this.toggleSatellite}
//   satellite={satellite}
//   resetViewState={this.resetViewState}
// />
const MapControlButtons = ({
  satellite, toggleSatellite,
  resetViewState,
  station, toggleStations,
  showLayer, toggleLayer, toggleLayerIcon,
}) => (
  <div style={styles.buttonContainer}>
    <div style={styles.buttonSubContainer}>
      {toggleLayer && (
        <Button
          shape="circle"
          icon={toggleLayerIcon || (showLayer ? 'eye' : 'eye-invisible')}
          onClick={toggleLayer}
          size="small"
        />
      )}
      {toggleStations && (
        <Button
          style={{ marginLeft: 8 }}
          type={station ? 'primary' : ''}
          shape="circle"
          icon="tag-o"
          onClick={toggleStations}
          size="small"
        />
      )}
      {toggleSatellite && (
        <Button
          style={{ marginLeft: 8 }}
          shape="circle"
          icon={satellite ? 'global' : 'camera-o'}
          onClick={toggleSatellite}
          size="small"
        />
      )}
    </div>
    <div style={styles.buttonSubContainer}>
      {resetViewState && (
        <Button
          shape="circle"
          icon="reload"
          size="small"
          onClick={resetViewState}
        />
      )}
    </div>
  </div>
)

export default MapControlButtons
