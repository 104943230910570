import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Timeline from './TimelineChart';

class TimelineChart extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null,
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  componentWillReceiveProps(nextProps) {
    const currentState = this.getChartState(nextProps);
    const shouldUpdateChart = (nextProps.data && !this.props.data &&
      JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) ||
      (nextProps.detourTfu !== this.props.detourTfu) ||
      nextProps.autoPlay === !this.props.autoPlay ||
      JSON.stringify(nextProps.recommendations) !== JSON.stringify(this.props.recommendations)
    if (this.state.chart && shouldUpdateChart) {
      this.state.chart.update(currentState);
    }

    if (this.state.chart && nextProps.axis && nextProps.axis !== this.props.axis) {
      this.state.chart.updateAxis(currentState);
    }
    const shouldUpdatePriceSegments = (nextProps.data && nextProps.priceSegments &&
      JSON.stringify(nextProps.priceSegments) !== JSON.stringify(this.props.priceSegments)) || (
      (nextProps.data && nextProps.stationPrices &&
          JSON.stringify(nextProps.stationPrices) !== JSON.stringify(this.props.stationPrices))
    )
    if (this.state.chart && shouldUpdatePriceSegments) {
      this.state.chart.updatePriceSegments(nextProps.priceSegments, nextProps.stationPrices);
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    // TODO understand why this isn't needed
    // this.state.chart.destroy();
    window.removeEventListener('resize', this.createChart);
  }

  /* eslint-disable class-methods-use-this */
  getChartState(props) {
    return {
      data: props.data,
      onEventChange: props.onEventChange,
      onFocus: props.onFocus,
      axis: props.axis || 'time',
      refuel: props.refuelEvent,
      priceSegments: props.priceSegments,
      stationPrices: props.stationPrices,
      detourTfu: props.detourTfu,
      fuelDrop: props.fuelDrop,
      autoPlay: props.autoPlay,
      currency: props.currency,
      recommendations: props.recommendations,
      selectedRecommendation: props.selectedRecommendation,
      plannings: props.plannings,
      yLabel: props.yLabel || 'Fuel %',
    };
  }

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.chart);
    if (this.state.chart) {
      this.state.chart.destroy();
    }

    const elWidth = el.offsetParent.offsetWidth - 2;
    const height = this.props.height || 300;

    const top = 25
    const focusHeight = height * 180 / 300
    const middle = 20
    const contextHeight = height * 40 / 300
    const bottom = 25

    const right = 10
    const left = 40

    const margin = {
      top,
      right,
      bottom: bottom + contextHeight + middle,
      left,
    };
    const margin2 = {
      top: top + focusHeight + middle,
      right,
      bottom,
      left,
    };

    const props = {
      margin,
      margin2,
      width: elWidth - margin.left - margin.right,
      height: height - margin.top - margin.bottom,
      height2: height - margin2.top - margin2.bottom,
    };

    // Initialise the chart, then render it without transitions.
    this.setState({
      chart: new Timeline(el, props),
    }, function createAndUpdateChart() {
      this.state.chart.create();
      this.state.chart.update(this.getChartState(this.props));
      if (this.props.priceSegments) {
        this.state.chart.updatePriceSegments(this.props.priceSegments, this.props.stationPrices)
      }
      this.state.chart.preventTransitions();
    });
  }
  /* eslint-enable class-methods-use-this */

  render() {
    return (
      <div
        ref="chart"
      />
    );
  }
}

export default TimelineChart
