import React, { Component } from 'react'
import 'babel-polyfill';
import { IntlProvider, addLocaleData } from 'react-intl';
import ar from 'react-intl/locale-data/ar';
import cs from 'react-intl/locale-data/cs';
import da from 'react-intl/locale-data/da';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fr from 'react-intl/locale-data/fr';
import it from 'react-intl/locale-data/it';
import nl from 'react-intl/locale-data/nl';
import no from 'react-intl/locale-data/no';
import pl from 'react-intl/locale-data/pl';
import pt from 'react-intl/locale-data/pt';
import ro from 'react-intl/locale-data/ro';
import ru from 'react-intl/locale-data/ru';
import sk from 'react-intl/locale-data/sk';
import sl from 'react-intl/locale-data/sl';
import sv from 'react-intl/locale-data/sv';
import tr from 'react-intl/locale-data/tr';

import { ConfigProvider } from 'antd';
import ar_EG from 'antd/es/locale/ar_EG';
import cs_CZ from 'antd/es/locale/cs_CZ';
import da_DK from 'antd/es/locale/da_DK';
import de_DE from 'antd/es/locale/de_DE';
import en_GB from 'antd/es/locale/en_GB';
import es_ES from 'antd/es/locale/es_ES';
import fr_FR from 'antd/es/locale/fr_FR';
import it_IT from 'antd/es/locale/it_IT';
import nb_NO from 'antd/es/locale/nb_NO';
import nl_NL from 'antd/es/locale/nl_NL';
import pl_PL from 'antd/es/locale/pl_PL';
import pt_PT from 'antd/es/locale/pt_PT';
import ro_RO from 'antd/es/locale/ro_RO';
import ru_RU from 'antd/es/locale/ru_RU';
import sk_SK from 'antd/es/locale/sk_SK';
import sl_SI from 'antd/es/locale/sl_SI';
import sv_SE from 'antd/es/locale/sv_SE';
import tr_TR from 'antd/es/locale/tr_TR';

import moment from 'moment'
import 'moment/locale/ar';
import 'moment/locale/cs';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nl';
import 'moment/locale/nb';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/sk';
import 'moment/locale/sl';
import 'moment/locale/sv';
import 'moment/locale/tr';

import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import ReactGA from 'react-ga';


import AppLayout from './ComponentsLayout/Layout/Layout'
import localeData from './assets/i18n/locales/data';
import actions from './actions';
import { history } from './configureStore';

const { REACT_APP_GOOGLE_ANALYTICS_CODE } = process.env

if (REACT_APP_GOOGLE_ANALYTICS_CODE) {
  ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_CODE);
}

// If browser doesn't support Intl (i.e. Safari), then we manually import
// the intl polyfill and locale data.
if (!window.intl) {
  require('intl');
  require('intl/locale-data/jsonp/ar.js');
  require('intl/locale-data/jsonp/cs.js');
  require('intl/locale-data/jsonp/da.js');
  require('intl/locale-data/jsonp/de.js');
  require('intl/locale-data/jsonp/en.js');
  require('intl/locale-data/jsonp/es.js');
  require('intl/locale-data/jsonp/fr.js');
  require('intl/locale-data/jsonp/it.js');
  require('intl/locale-data/jsonp/nl.js');
  require('intl/locale-data/jsonp/nb.js'); // doesn't have std no for Norwegian, it has nb: Norwegian Bokmål
  require('intl/locale-data/jsonp/pl.js');
  require('intl/locale-data/jsonp/pt.js');
  require('intl/locale-data/jsonp/ro.js');
  require('intl/locale-data/jsonp/ru.js');
  require('intl/locale-data/jsonp/sk.js');
  require('intl/locale-data/jsonp/sl.js');
  require('intl/locale-data/jsonp/sv.js');
  require('intl/locale-data/jsonp/tr.js');
}

addLocaleData([
  ...ar,
  ...cs,
  ...da,
  ...de,
  ...en,
  ...es,
  ...fr,
  ...it,
  ...nl,
  ...no,
  ...pl,
  ...pt,
  ...ro,
  ...ru,
  ...sk,
  ...sl,
  ...sv,
  ...tr,
]);

// antd locales
const antLocalesMapping = {
  ar: ar_EG,
  cs: cs_CZ,
  da: da_DK,
  de: de_DE,
  en: en_GB,
  es: es_ES,
  fr: fr_FR,
  it: it_IT,
  nb: nb_NO,
  nl: nl_NL,
  pl: pl_PL,
  pt: pt_PT,
  ro: ro_RO,
  ru: ru_RU,
  sk: sk_SK,
  sl: sl_SI,
  sv: sv_SE,
  tr: tr_TR,
}

// moment
const momentLocalesMapping = {
  ar: 'ar',
  cs: 'cs',
  da: 'da',
  de: 'de',
  en: 'en',
  es: 'es',
  fr: 'fr',
  it: 'it',
  nl: 'nl',
  no: 'nb',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  ru: 'ru',
  sk: 'sk',
  sl: 'sl',
  sv: 'sv',
  tr: 'tr',
}

class LocaleContainer extends Component {
  componentDidMount() {
    const { user, getSettings } = this.props
    if (user) getSettings()
  }

  componentDidUpdate(prevProps) {
    const { user, getSettings, locale } = this.props
    if (!prevProps.user && !!user) {
      getSettings()
    }
    if (locale && locale !== prevProps.locale) {
      moment(momentLocalesMapping[locale])
    }
  }

  render() {
    const { locale = 'en' } = this.props
    return (
      <ConfigProvider locale={antLocalesMapping[locale]}>
        <IntlProvider locale={locale} key={locale} messages={localeData[locale] || localeData.en}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route component={AppLayout} />
            </Switch>
          </ConnectedRouter>
        </IntlProvider>
      </ConfigProvider>
    )
  }
}


function mapStateToProps(store) {
  return {
    user: store.auth.user,
    locale: store.settings.items && store.settings.items.locale,
  }
}


function mapDispatchToProps(dispatch) {
  return {
    getSettings: () => dispatch(actions.settings.getSettings()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocaleContainer)
