import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Input, Modal, List, Icon, Col, Select, Button, InputNumber, Typography } from 'antd';
import * as strings from '../../../helpers/defaultStrings';
import { DiscountValue } from '../../../helpers';
import { cleanFleetCardsName, discountTypes } from '../../../helpers/constants';
import currenciesIsoCodes from '../../../assets/data/isoCurrenciesData.json';

const { Option } = Select

const EditStation = (props) => {
  const {
    stationToEdit,
    open,
    close,
    addStations,
    settingsCurrency,
    fleetCards,
    paymentCards,
    editNameAndDiscount,
    editFixedPrice,
  } = props
  const [station, setStation] = useState({
    ...stationToEdit,
    discountValue: stationToEdit.discountValue || 0,
    discountType: editFixedPrice ? discountTypes.FIXED_PRICE : stationToEdit.discountType || discountTypes.PERCENT,
    discountCurrency: stationToEdit.discountCurrency || settingsCurrency,
    fleetCard: stationToEdit.fleetCard || undefined,
  })
  const { formatMessage } = props.intl

  const onOk = () => {
    if (station.discountValue === 0) {
      const {
        discountValue, discountType, discountCurrency, ...stationWithoutDiscount
      } = station // Remove fleetcard also ?
      addStations(stationWithoutDiscount)
    } else {
      addStations(station)
    }
    close()
  }
  const onNameChange = (e) => {
    setStation({ ...station, name: e.target.value })
  }
  const editDiscountValue = discountValue => setStation({ ...station, discountValue })
  const editDiscountType = discountType => setStation({ ...station, discountType })
  const editDiscountCurrency = discountCurrency => setStation({ ...station, discountCurrency })
  const onFleetCardChange = fleetCard => setStation({ ...station, fleetCard })
  const resetDiscount = () => {
    setStation({
      ...station,
      discountValue: 0,
      discountType: discountTypes.PERCENT,
      discountCurrency: settingsCurrency,
      fleetCard: undefined,
    })
  }
  const renderStation = item => (
    <List.Item.Meta
      title={<Input defaultValue={stationToEdit.name} onChange={onNameChange} />}
      description={(
        <div>
          <div>{item.address || formatMessage(strings.phrase.noAddress)}</div>
          <div>{[item.zipcode, item.locality, item.country].filter(x => !!x).join(', ')}</div>
        </div>
        )}
    />
  )

  const title = (
    <span>
      <Icon type="edit" theme="twoTone" twoToneColor="dodgerblue" />
      {`  ${formatMessage(strings.phrase.editStation)}`}
    </span>
  )

  return (
    <Modal
      title={title}
      visible={open}
      onOk={onOk}
      okText={formatMessage(strings.word.save)}
      cancelText={formatMessage(strings.word.cancel)}
      onCancel={close}
      bodyStyle={{ height: 200 }}
    >
      <Col span={12}>
        <Input.Group compact>
          {renderStation(stationToEdit)}
        </Input.Group>
      </Col>
      {editFixedPrice && (<Col span={12}>
        <div style={{
          border: '1px dashed #00000040', borderRadius: 4, padding: 10, textAlign: 'center',
        }}
        >
          <Input.Group compact>
            <InputNumber
              size="small"
              min={0}
              max={99}
              step={0.1}
              value={station.discountValue}
              style={{ width: 60 }}
              onChange={editDiscountValue}
            />
            <Select
              value={station.discountCurrency}
              showSearch
              size="small"
              onChange={editDiscountCurrency}
              style={{ minWidth: 75 }}
            >
              {Object.keys(currenciesIsoCodes).map(currency => (
                <Option key={currency} value={currency}>
                  {currency}
                </Option>
        ))}
            </Select>
          </Input.Group>
          <Typography>{formatMessage(strings.phrase.euroPerLiterAsFixedPrice)}</Typography>
        </div>
      </Col>)}
      {editNameAndDiscount && (<Col span={12}>
        <div style={{
          border: '1px dashed #00000040', borderRadius: 4, padding: 10, textAlign: 'center',
        }}
        >
          <DiscountValue
            editable
            onValueChange={editDiscountValue}
            onTypeChange={editDiscountType}
            onCurrencyChange={editDiscountCurrency}
            value={station.discountValue}
            type={station.discountType}
            currency={station.discountCurrency}
          />
          <Select
            value={station.fleetCard}
            size="small"
            onChange={onFleetCardChange}
            style={{ minWidth: 150, marginTop: 15, marginBottom: 15 }}
            placeholder={formatMessage(strings.phrase.selectFleetCard)}
          >
            {fleetCards.map(c => <Option key={c.card}>{cleanFleetCardsName(paymentCards[c.card])}</Option>)}
          </Select>
          <Button onClick={resetDiscount} size="small" type="danger">{formatMessage(strings.phrase.resetDiscount)}</Button>
        </div>
      </Col>)}
    </Modal>
  )
}

export default injectIntl(EditStation)
