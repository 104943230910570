import actionTypes from '../actions/actionTypes';

export const initialState = {
  items: [],
  error: null,
  fetching: false,
  processing: false,
  plattsStationMapped: {},
  processedData: [],
  plattsEmail: null,
  getFileTemplatefetching: false,
  saveFileTemplateprocessing: false,
  templateConfigurations: [],
  deleteProcess: false,
  deletingFleetCard: '',
  deletingTemplateName: '',
  processedDataStatus: false,
  isBulkUploadEmail: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.MAP_PLATTSSTATION_REQUEST:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.MAP_PLATTSSTATION_SUCCESS:
      return {
        ...state,
        plattsStationMapped: action.plattsStationMapped,
        error: null,
        processing: false,
      };
    case actionTypes.MAP_PLATTSSTATION_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case actionTypes.GET_PLATTSSTATIONMAPPING_REQUEST:
      return {
        ...state,
        fetching: true,
        processedData: [],
        error: null,
      };
    case actionTypes.GET_PLATTSSTATIONMAPPING_SUCCESS:
      return {
        ...state,
        processedData: action.processedData,
        fetching: false,
      };
    case actionTypes.GET_PLATTSSTATIONMAPPING_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    case actionTypes.SET_BULKUPLOADEMAIL_REQUEST:
      return {
        ...state,
        isBulkUploadEmailFetching: true,
      };
    case actionTypes.SET_BULKUPLOADEMAIL_SUCCESS:
      return {
        ...state,
        isBulkUploadEmail: action.processedData,
      };
    case actionTypes.SET_BULKUPLOADEMAIL_FAILURE:
      return {
        ...state,
        isBulkUploadEmail: false,
        isBulkUploadEmailError: action.error,
      };
    case actionTypes.GET_PLATTS_MAPPING_FOR_EXCEL_DATA_REQUEST:
      return {
        ...state,
        fetching: true,
        processedData: [],
        error: null,
        processedDataStatus: false,
      };
    case actionTypes.GET_PLATTS_MAPPING_FOR_EXCEL_DATA_SUCCESS:
      return {
        ...state,
        processedData: action.processedData,
        fetching: false,
        processedDataStatus: true,
      };
    case actionTypes.GET_PLATTS_MAPPING_FOR_EXCEL_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
        processedDataStatus: false,
      };
    case actionTypes.SET_PLATTS_PROCESSED_DATA_STATUS_FOR_EXCEL_DATA:
      return {
        ...state,
        processedDataStatus: false,
      };
    case actionTypes.GET_PLATTSEMAIL_REQUEST:
      return {
        ...state,
        plattsEmail: null,
        error: null,
      };
    case actionTypes.GET_PLATTSEMAIL_SUCCESS:
      return {
        ...state,
        plattsEmail: action.plattsEmail,
      };
    case actionTypes.GET_PLATTSEMAIL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.GET_FILETEMPLATECONFIG_REQUEST:
      return {
        ...state,
        getFileTemplatefetching: true,
        templateConfigurations: [],
        error: null,
      };
    case actionTypes.GET_FILETEMPLATECONFIG_SUCCESS:
      return {
        ...state,
        templateConfigurations: action.templateConfigurations,
        getFileTemplatefetching: false,
      };
    case actionTypes.GET_FILETEMPLATECONFIG_FAILURE:
      return {
        ...state,
        error: action.error,
        getFileTemplatefetching: false,
      };
    case actionTypes.SAVE_FILETEMPLATECONFIG_REQUEST:
      return {
        ...state,
        saveFileTemplateprocessing: true,
      };
    case actionTypes.SAVE_FILETEMPLATECONFIG_SUCCESS:
      return {
        ...state,
        templateConfigurations: action.templateConfigurations,
        error: null,
        saveFileTemplateprocessing: false,
      };
    case actionTypes.SAVE_FILETEMPLATECONFIG_FAILURE:
      return {
        ...state,
        error: action.error,
        saveFileTemplateprocessing: false,
      };
    case actionTypes.DELETE_FILETEMPLATECONFIG_REQUEST:
      return {
        ...state,
        deleteProcess: true,
        deletingFleetCard: action.fleetCard,
        deletingTemplateName: action.template_name,
      };
    case actionTypes.DELETE_FILETEMPLATECONFIG_SUCCESS:
      if (action.fleetCard === state.deletingFleetCard && action.template_name === state.deletingTemplateName) {
        const newItems = state.templateConfigurations.filter(e => e.fleetCard === action.fleetCard && e.template_name !== action.template_name);
        return {
          ...state,
          templateConfigurations: newItems,
          deleteProcess: false,
          deletingFleetCard: null,
          deletingTemplateName: null,
        };
      }
      return state;
    case actionTypes.DELETE_FILETEMPLATECONFIG_FAILURE:
      if (action.fleetCard === state.deletingFleetCard && action.template_name === state.deletingTemplateName) {
        return {
          ...state,
          deleteProcess: false,
          deletingFleetCard: null,
          deletingTemplateName: null,
          error: action.error,
        };
      }
      return state;
    default:
      return state;
  }
}

export default reducer;
