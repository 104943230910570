import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import { push } from 'connected-react-router'
import jwt from 'jsonwebtoken'

import actionTypes from './actionTypes'

const {
  REACT_APP_AUTH_REDIRECT_URL_QA,
  REACT_APP_AUTH_REDIRECT_URL_FT,
  REACT_APP_AUTH_REDIRECT_URL_PA,
  REACT_APP_AUTH_REDIRECT_URL_PB,
  REACT_APP_ENV,
} = process.env

function redirectToPortal(env) {
  if (env) {
    if (env === 'qa') {
      window.location = REACT_APP_AUTH_REDIRECT_URL_QA
    } else if (env === 'pa') {
      window.location = REACT_APP_AUTH_REDIRECT_URL_PA
    } else if (env === 'pb') {
      window.location = REACT_APP_AUTH_REDIRECT_URL_PB
    } else if (env === 'ft') {
      window.location = REACT_APP_AUTH_REDIRECT_URL_FT
    } else {
      window.location = REACT_APP_AUTH_REDIRECT_URL_PB
    }
  } else {
    window.location = REACT_APP_AUTH_REDIRECT_URL_PB
  }
}

/* ************ Logout user */

function logoutRequest() {
  return {
    type: actionTypes.USER_LOGOUT_REQUEST,
  }
}

function logoutSuccess() {
  return {
    type: actionTypes.USER_LOGOUT_SUCCESS,
  }
}

function logoutError(error) {
  return {
    type: actionTypes.USER_LOGOUT_FAILURE,
    error,
  }
}

function logout() {
  return (dispatch) => {
    dispatch(logoutRequest())
    return Auth.signOut()
      .then(() => {
        window.localStorage.removeItem('transics-token')
        dispatch(logoutSuccess())
        const env = window.localStorage.getItem('transics-env');
        redirectToPortal(env)
        return dispatch(push('/'))
      })
      .catch((err) => {
        console.log('err', err)
        return dispatch(logoutError(err))
      })
  }
}

/* *************** Authenticate user ***************** */

function authUserRequest() {
  return {
    type: actionTypes.USER_LOGIN_REQUEST,
  }
}

function authUserSuccess(user) {
  return {
    type: actionTypes.USER_LOGIN_SUCCESS,
    user,
  }
}

function authUserFailure(error) {
  const env = window.localStorage.getItem('transics-env')
  redirectToPortal(env)
  return {
    type: actionTypes.USER_LOGIN_FAILURE,
    error,
  }
}

function authUser(token, env) {
  logout()
  return (dispatch) => {
    dispatch(authUserRequest())

    if (env) {
      window.localStorage.setItem('transics-env', env);
    } else {
      env = window.localStorage.getItem('transics-env');
    }

    return Auth.signOut()
      .then(() => API.get('Backend', `validateEnvToken/${env}/${token}`))
      .then(({ userProfileToken, openIdToken: { Token, IdentityId } }) => {
        window.localStorage.setItem('transics-token', userProfileToken)
        const userProfile = jwt.decode(userProfileToken);
        const expires_at = userProfile.exp
          ? userProfile.exp * 1000
          : 10 * 60 * 60 * 1000 + (userProfile.iat ? userProfile.iat * 1000 : Date.now());
        const user = {
          ...userProfile,
          expires_at,
          identity_id: IdentityId,
        };
        return Auth.federatedSignIn(
          // Initiate federated sign-in with Developer identity provider
          'developer',
          {
            // the JWT token
            token: Token,
            expires_at,
            identity_id: IdentityId,
          },
          // a user object
          user,
        ).then(() => {
          dispatch(authUserSuccess(user));
          if (REACT_APP_ENV.toString().trim().toLowerCase() === 'integration') {
            console.log('Auth Successful: ', user);
          }
          return dispatch(push('/'))
        })
      }).catch(error => dispatch(authUserFailure(error)))
  }
}

function checkUserLoggedIn() {
  return (dispatch) => {
    Auth.currentAuthenticatedUser()
      .then((userProfile) => {
        dispatch(authUserSuccess(userProfile))
        return true;
      })
      .catch((error) => {
        dispatch(authUserFailure(error))
        return false;
      });
  }
}

export default {
  authUser,
  logout,
  checkUserLoggedIn,
}
