/* eslint-disable max-len */
import API from '@aws-amplify/api';
import actionTypes from './actionTypes';

const plattsDataForSave = e => ({
  currency: e.currency,
  fleetcard: e.fleetcard,
  periodEnd: e.periodEnd,
  periodStart: e.periodStart,
  price: e.price,
  product: e.product,
  stationCode: e.stationCode,
  template_name: e.template_name,
  discountValue: e.discountValue,
  mapping_id: e.mapping_id,
  latitude: e.latitude,
  longitude: e.longitude,
});

const getPlattsMappingDetailsRequest = (
  bulkUploadOption,
  fleetCard,
  template_name,
) => ({
  type: actionTypes.GET_PLATTSSTATIONMAPPING_REQUEST,
  bulkUploadOption,
  fleetCard,
  template_name,
});
const getPlattsMappingDetailsSuccess = processedData => ({
  type: actionTypes.GET_PLATTSSTATIONMAPPING_SUCCESS,
  processedData,
});
const getPlattsMappingDetailsFailure = error => ({
  type: actionTypes.GET_PLATTSSTATIONMAPPING_FAILURE,
  error,
});
const mapPlattsStationRequest = () => ({
  type: actionTypes.MAP_PLATTSSTATION_REQUEST,
});
const mapPlattsStationSuccess = plattsStationMapped => ({
  type: actionTypes.MAP_PLATTSSTATION_SUCCESS,
  plattsStationMapped,
});
const mapPlattsStationFailure = error => ({
  type: actionTypes.MAP_PLATTSSTATION_FAILURE,
  error,
});
const getPlattsMappingForExcelDataRequest = () => ({
  type: actionTypes.GET_PLATTS_MAPPING_FOR_EXCEL_DATA_REQUEST,
});
const getPlattsMappingForExcelDataSuccess = processedData => ({
  type: actionTypes.GET_PLATTS_MAPPING_FOR_EXCEL_DATA_SUCCESS,
  processedData,
});
const getPlattsMappingForExcelDataFailure = error => ({
  type: actionTypes.GET_PLATTS_MAPPING_FOR_EXCEL_DATA_FAILURE,
  error,
});
const getPlattsEmailRequest = bulkUploadOption => ({
  type: actionTypes.GET_PLATTSEMAIL_REQUEST,
  bulkUploadOption,
});
const getPlattsEmailSuccess = plattsEmail => ({
  type: actionTypes.GET_PLATTSEMAIL_SUCCESS,
  plattsEmail,
});
const getPlattsEmailFailure = error => ({
  type: actionTypes.GET_PLATTSEMAIL_FAILURE,
  error,
});
const setProcessedDataStatusForExcelDataRequest = () => ({
  type: actionTypes.SET_PLATTS_PROCESSED_DATA_STATUS_FOR_EXCEL_DATA,
});
const setBulkUploadEmailRequest = () => ({
  type: actionTypes.SET_BULKUPLOADEMAIL_REQUEST,
});
const setBulkUploadEmailSuccess = processedData => ({
  type: actionTypes.SET_BULKUPLOADEMAIL_SUCCESS,
  processedData,
});
const setBulkUploadEmailFailure = error => ({
  type: actionTypes.SET_BULKUPLOADEMAIL_FAILURE,
  error,
});

const getFileTemplateConfigRequest = (bulkUploadAction, fleetCard) => ({
  type: actionTypes.GET_FILETEMPLATECONFIG_REQUEST,
  bulkUploadAction,
  fleetCard,
});
const getFileTemplateConfigSuccess = templateConfigurations => ({
  type: actionTypes.GET_FILETEMPLATECONFIG_SUCCESS,
  templateConfigurations,
});
const getFileTemplateConfigFailure = error => ({
  type: actionTypes.GET_FILETEMPLATECONFIG_FAILURE,
  error,
});
const saveFileTemplateConfigRequest = () => ({
  type: actionTypes.SAVE_FILETEMPLATECONFIG_REQUEST,
});
const saveFileTemplateConfigSuccess = templateConfigurations => ({
  type: actionTypes.SAVE_FILETEMPLATECONFIG_SUCCESS,
  templateConfigurations,
});
const saveFileTemplateConfigFailure = error => ({
  type: actionTypes.SAVE_FILETEMPLATECONFIG_FAILURE,
  error,
});
const deleteFileTemplateConfigRequest = (fleetCard, template_name) => ({
  type: actionTypes.DELETE_FILETEMPLATECONFIG_REQUEST,
  fleetCard,
  template_name,
});
const deleteFileTemplateConfigSuccess = (fleetCard, template_name) => ({
  type: actionTypes.DELETE_FILETEMPLATECONFIG_SUCCESS,
  fleetCard,
  template_name,
});
const deleteFileTemplateConfigFailure = (fleetCard, template_name, error) => ({
  type: actionTypes.DELETE_FILETEMPLATECONFIG_FAILURE,
  fleetCard,
  template_name,
  error,
});

function getPlattsMappingDetails(bulkUploadOption, fleetCard, template_name) {
  return (dispatch) => {
    dispatch(getPlattsMappingDetailsRequest());
    const url = `platts/prices?bulkUploadOption=${bulkUploadOption}&fleetcard=${fleetCard}&template_name=${template_name}`;
    return API.get('Backend', url)
      .then((data) => {
        dispatch(getPlattsMappingDetailsSuccess(data || {}));
      })
      .catch(err => dispatch(getPlattsMappingDetailsFailure(err)));
  };
}

function setBulkUploadEmail() {
  return (dispatch) => {
    dispatch(setBulkUploadEmailRequest());
    const url = 'bulkupload/email';
    return API.get('Backend', url)
      .then((data) => {
        dispatch(setBulkUploadEmailSuccess(data || {}));
      })
      .catch(err => dispatch(setBulkUploadEmailFailure(err)));
  };
}

function mapPlattsStation(plattsStation = {}) {
  return (dispatch) => {
    dispatch(mapPlattsStationRequest());
    const url = 'platts/mapping';
    return API.post('Backend', url, { body: plattsStation })
      .then(data => dispatch(mapPlattsStationSuccess(data)))
      .catch(err => dispatch(mapPlattsStationFailure(err)));
  };
}

function getPlattsMappingForExcelData(payloadData) {
  return (dispatch) => {
    dispatch(getPlattsMappingForExcelDataRequest());
    const url = 'platts/prices';
    console.log('debug:: payload data', payloadData);
    const payload = {
      ...payloadData,
      excelData: payloadData.excelData.map(plattsDataForSave),
    };
    return API.post('Backend', url, { body: payload })
      .then(data => dispatch(getPlattsMappingForExcelDataSuccess(data)))
      .catch(err => dispatch(getPlattsMappingForExcelDataFailure(err)));
  };
}

function getPlattsEmail(bulkUploadOption) {
  return (dispatch) => {
    dispatch(getPlattsEmailRequest());
    const url = `platts/email/${bulkUploadOption}`;
    return API.get('Backend', url)
      .then(data => dispatch(getPlattsEmailSuccess(data)))
      .catch(err => dispatch(getPlattsEmailFailure(err)));
  };
}

function getFileTemplateConfig(bulkUploadOption, fleetCard) {
  return (dispatch) => {
    dispatch(getFileTemplateConfigRequest(bulkUploadOption, fleetCard));
    const url = `platts/settings/${fleetCard}/${bulkUploadOption}`;
    return API.get('Backend', url)
      .then((data) => {
        dispatch(getFileTemplateConfigSuccess(data || []));
      })
      .catch(err => dispatch(getFileTemplateConfigFailure(err)));
  };
}

function saveFileTemplateConfig(templateConfigurations = []) {
  return (dispatch) => {
    dispatch(saveFileTemplateConfigRequest());
    const url = 'platts/settings';
    return API.post('Backend', url, { body: templateConfigurations })
      .then(() =>
        dispatch(saveFileTemplateConfigSuccess(templateConfigurations)))
      .catch(err => dispatch(saveFileTemplateConfigFailure(err)));
  };
}

function deleteFileTemplateConfig(fleetCard, template_name) {
  return (dispatch) => {
    dispatch(deleteFileTemplateConfigRequest(fleetCard, template_name));
    const url = `platts/settings?fleetcard=${fleetCard}&template_name=${template_name}`;
    return API.del('Backend', url)
      .then(() =>
        dispatch(deleteFileTemplateConfigSuccess(fleetCard, template_name)))
      .catch(err =>
        dispatch(deleteFileTemplateConfigFailure(fleetCard, template_name, err)));
  };
}

function setProcessedDataStatus() {
  return (dispatch) => {
    dispatch(setProcessedDataStatusForExcelDataRequest());
  };
}

export default {
  getPlattsMappingDetails,
  mapPlattsStation,
  getPlattsMappingForExcelData,
  getPlattsEmail,
  getFileTemplateConfig,
  saveFileTemplateConfig,
  deleteFileTemplateConfig,
  setProcessedDataStatus,
  setBulkUploadEmail,
};
