import { Icon, Modal, Select } from 'antd';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { cleanFleetCardsName } from '../../../helpers/constants';
import * as strings from '../../../helpers/defaultStrings';


const AddFleetCard = ({
  open, onOk, onCancel, fleetCards, intl: { formatMessage },
}) => {
  const [card, setCard] = useState();
  const options = Object.keys(fleetCards).map(key => ({ key, name: cleanFleetCardsName(fleetCards[key]) }));
  const selectCard = () => {
    onOk(card);
  };
  return (
    <Modal
      title={formatMessage(strings.phrase.addFleetCard)}
      centered
      visible={open}
      onOk={selectCard}
      okButtonProps={{ disabled: isNil(card) }}
      onCancel={onCancel}
      okText={formatMessage(strings.word.save)}
      cancelText={formatMessage(strings.word.cancel)}
    >
      <Select
        size="small"
        style={{ minWidth: 180, marginRight: 20 }}
        suffixIcon={<Icon type="credit-card" />}
        showArrow
        allowClear
        showSearch
        optionFilterProp="name"
        onChange={key => setCard(key)}
      >
        {options.map(option => (
          <Select.Option key={option.key} value={option.key} name={option.name}>
            {option.name}
          </Select.Option>
    ))}
      </Select>
    </Modal>
  );
};

export default injectIntl(AddFleetCard);
