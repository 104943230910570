import API from '@aws-amplify/api'

import actionTypes from './actionTypes'

const getFleetSettingsRequest = () => ({ type: actionTypes.GET_FLEET_SETTINGS_REQUEST })
const getFleetSettingsSuccess = settings => ({ type: actionTypes.GET_FLEET_SETTINGS_SUCCESS, settings })
const getFleetSettingsFailure = error => ({ type: actionTypes.GET_FLEET_SETTINGS_FAILURE, error })
const saveFleetSettingsRequest = () => ({ type: actionTypes.SAVE_FLEET_SETTINGS_REQUEST })
const saveFleetSettingsSuccess = settings => ({ type: actionTypes.SAVE_FLEET_SETTINGS_SUCCESS, settings })
const saveFleetSettingsFailure = error => ({ type: actionTypes.SAVE_FLEET_SETTINGS_FAILURE, error })

function getFleetSettings() {
  return (dispatch) => {
    const url = 'fleetSettings'
    dispatch(getFleetSettingsRequest())
    return API.get('Backend', url)
      .then(data => dispatch(getFleetSettingsSuccess(data || {})))
      .catch(err => dispatch(getFleetSettingsFailure(err)))
  }
}

function saveFleetSettings(settings = {}) {
  return (dispatch) => {
    const url = 'fleetSettings'
    dispatch(saveFleetSettingsRequest())
    return API.post('Backend', url, { body: settings })
      .then(() => dispatch(saveFleetSettingsSuccess(settings)))
      .catch(err => dispatch(saveFleetSettingsFailure(err)))
  }
}

export default {
  getFleetSettings,
  saveFleetSettings,
}
