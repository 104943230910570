import React from 'react';
import { connect } from 'react-redux';
import { Spin, Form, Button, Select, Switch, Row, Col, Icon } from 'antd';
import { injectIntl, intlShape } from 'react-intl';
import currenciesIsoCodes from '../../assets/data/isoCurrenciesData.json';
import * as strings from '../../helpers/defaultStrings';
import actions from '../../actions';
import { AVAILABLE_LANGUAGES } from '../../helpers/language';
import FuelBotParameters from './FuelBotParameters';

import './Settings.css';
import moment from 'moment';
import 'moment-timezone';
import RecommendationSettings from './RecommendationSettings';

const { Item, createFormField } = Form;
const { Option } = Select;

class Settings extends React.Component {
  componentDidMount() {
    const { getSettings } = this.props;
    getSettings();
  }

  componentDidUpdate(prevProps) {
    const {
      fetching, editing, error, intl, addSuccessMessage, addErrorMessage,
    } = this.props;
    const { formatMessage } = intl;
    if (!fetching && prevProps.fetching) {
      if (error) addErrorMessage(formatMessage(strings.message.failedToGetSettings));
    }
    if (!editing && prevProps.editing) {
      if (error) addErrorMessage(formatMessage(strings.message.failedToSaveSettings));
      else addSuccessMessage(formatMessage(strings.message.settingsAreSaved));
    }
  }

  hasErrors = () => {
    const { form: { getFieldsError } } = this.props;
    const errors = getFieldsError();
    return Object.values(errors).some(x => !!x);
  }

  handleSubmit = (e) => {
    const { form: { validateFields }, saveSettings } = this.props;
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        saveSettings(values);
      }
    });
  }

  render() {
    const {
      fetching, editing, form, intl, close,
    } = this.props;
    const { formatMessage } = intl;
    const { getFieldDecorator, isFieldsTouched } = form;
    const timezone = moment.tz.guess();
    return (
      <Row type="flex" align="top" justify="center" style={{ overflowY: 'scroll' }}>
        <Col xxl={10} xl={12} lg={14} md={16} sm={22} span={22}>
          <FuelBotParameters />
          <RecommendationSettings />
          <Spin spinning={fetching || editing}>
            <Form
              onSubmit={this.handleSubmit}
              layout="vertical"
            >
              <h2 style={{ marginBottom: 16, marginTop: 32 }}>
                <Icon type="user" style={{ marginRight: 8 }} />
                {formatMessage(strings.phrase.userSettings)}
              </h2>
              <Item>
                <div className="settings-form-element">
                  <p className="settings-form-element-title">
                    {formatMessage(strings.phrase.useInternalVehicleId)}
                  </p>
                  {getFieldDecorator('useInternalVehicleId', {
                    valuePropName: 'checked',
                  })(<Switch />)}
                </div>
              </Item>
              <Item>
                <div className="settings-form-element">
                  <p className="settings-form-element-title">
                    {formatMessage(strings.phrase.useInternalDriverName)}
                  </p>
                  {getFieldDecorator('useInternalDriverName', {
                    valuePropName: 'checked',
                  })(<Switch />)}
                </div>
              </Item>
              <Item>
                <div className="settings-form-element">
                  <p className="settings-form-element-title">{formatMessage(strings.word.language)}</p>
                  {getFieldDecorator('locale', {
                    rules: [{
                      required: true,
                    }],
                  })(<Select
                    className="settings-form-element-input"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().includes(input.toLowerCase())}
                  >
                    {AVAILABLE_LANGUAGES.map(language => (
                      <Option key={language.locale} value={language.locale}>
                        {language.name}
                      </Option>
                    ))}
                     </Select>)}
                </div>
              </Item>
              <Item>
                <div className="settings-form-element">
                  <p className="settings-form-element-title">{formatMessage(strings.word.currency)}</p>
                  {getFieldDecorator('currency', {
                    rules: [{
                      required: true,
                    }],
                  })(<Select
                    className="settings-form-element-input"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().includes(input.toLowerCase())}
                  >
                    {Object.keys(currenciesIsoCodes).map(currency => (
                      <Option key={currency} value={currency}>
                        {currency}
                      </Option>
                    ))}
                     </Select>)}
                </div>
              </Item>
              <Item>
                <div className="settings-form-element">
                  <p className="settings-form-element-title">{formatMessage(strings.phrase.yourTimezone)}</p>
                  <p> {timezone} </p>
                </div>
              </Item>
            </Form>
            <div>
              <Button
                type="primary"
                className="settings-form-save-button"
                onClick={this.handleSubmit}
                disabled={!isFieldsTouched() || this.hasErrors()}
              >
                {formatMessage(strings.word.save)}
              </Button>
              <Button
                className="settings-form-save-button"
                onClick={close}
              >
                {formatMessage(strings.word.cancel)}
              </Button>
            </div>
          </Spin>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(store) {
  return {
    user: store.auth.user,
    useInternalVehicleId: store.settings.items && store.settings.items.useInternalVehicleId,
    useInternalDriverName: store.settings.items && store.settings.items.useInternalDriverName,
    locale: store.settings.items && store.settings.items.locale,
    fetching: store.settings.fetching,
    editing: store.settings.processing,
    error: store.settings.error,
    currency: store.settings.items && store.settings.items.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSettings: () => dispatch(actions.settings.getSettings()),
    saveSettings: settings => dispatch(actions.settings.saveSettings(settings)),
    addSuccessMessage: msg => dispatch(actions.messages.addSuccessMessage(msg)),
    addErrorMessage: msg => dispatch(actions.messages.addErrorMessage(msg)),
  };
}

const SettingsForm = Form.create({
  mapPropsToFields: ({
    useInternalVehicleId,
    useInternalDriverName,
    locale,
    currency,
  }) => ({
    useInternalVehicleId: createFormField({ value: useInternalVehicleId }),
    useInternalDriverName: createFormField({ value: useInternalDriverName }),
    locale: createFormField({ value: locale }),
    currency: createFormField({ value: currency }),
  }),
})(Settings);

SettingsForm.propTypes = {
  intl: intlShape.isRequired,
};
export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsForm));
