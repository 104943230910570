/* eslint-disable max-len */
import React from 'react';
import { Switch, Icon } from 'antd';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import actions from '../../actions'
import * as strings from '../../helpers/defaultStrings'

const TfuOrTimeSwitch = (props) => {
  const { axis, saveSettings, disabled } = props
  const { formatMessage } = props.intl
  return (
    <div style={{ verticalAlign: 'middle' }}>
      {formatMessage(axis === 'TIME' ? strings.word.time : strings.word.tfu).toUpperCase()}
      <Switch
        style={{ marginRight: 16, marginLeft: 10 }}
        checkedChildren={<Icon type="clock-circle-o" />}
        unCheckedChildren={<Icon type="filter" />}
        checked={axis === 'TIME'}
        disabled={disabled}
        onChange={() => saveSettings({ tfuOrTime: axis === 'TIME' ? 'TFU' : 'TIME' })}
      />
    </div>
  )
}

TfuOrTimeSwitch.propTypes = {
  intl: intlShape.isRequired,
};

function mapStateToProps(store) {
  return { axis: store.settings.items.tfuOrTime }
}

function mapDispatchToProps(dispatch) {
  return {
    saveSettings: settings => dispatch(actions.settings.saveSettings(settings)),
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TfuOrTimeSwitch))
