import React from 'react';
import crossfilter from 'crossfilter2';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Row, Col, DatePicker, Select, Card, Spin } from 'antd';
import actions from '../../actions';
import FilterTable from './FilterTables/FilterTable';
import PerformanceIndicatorsRightPanel from './PerformanceIndicatorsRightPanel';
import countriesMapping from '../../assets/data/countriesMapping.json';
import kpiCompute from '../../helpers/kpiHelper';
import KeyNumbers from './KeyNumbers';
import * as strings from '../../helpers/defaultStrings';

const { RangePicker } = DatePicker;
const { Option } = Select;

const timezone = moment.tz.guess();
const DEFAULT_START_DATE = moment({ M: 8, d: 29, y: 2021 }).startOf('day');
const DEFAULT_END_DATE = moment().endOf('day');
const INITIAL_FILTER = {
  driver: null,
  truck: null,
  country: null,
  group: null,
  subgroup: null,
  date: [DEFAULT_START_DATE, DEFAULT_END_DATE],
};

function multivalueFilter(values) {
  if (isNil(values)) {
    return v => true;
  }
  return v => values.indexOf(v) !== -1;
}
class PerformanceIndicators extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredRecommendations: [],
      cfDimensions: null,
      filters: INITIAL_FILTER,
      options: { driver: [], truck: [] },
    };
  }

  componentDidMount() {
    const {
      user, performanceIndicators, getKPIs, paymentCards, getPaymentCards,
    } = this.props;
    const { filters } = this.state;
    if (user && !performanceIndicators.fetching) {
      getKPIs(
        filters.date[0],
        filters.date[1],
      );
    }
    if (isNil(paymentCards) || isEmpty(paymentCards)) getPaymentCards();
    if (performanceIndicators.items) this.initCrossFilter();
  }

  componentDidUpdate(prevProps) {
    const { performanceIndicators, user, getKPIs } = this.props;
    const { filters } = this.state;
    if (!prevProps.user && !!user && !performanceIndicators.fetching) {
      getKPIs(
        filters.date[0],
        filters.date[1],
      );
    }
    if (!performanceIndicators.fetching && prevProps.performanceIndicators.fetching) this.initCrossFilter();
  }

  onChangeKeys = (filter, selectedRowKeys) => {
    const { filters } = this.state;
    if (selectedRowKeys.length > 0) {
      this.onFilter({ ...filters, [filter]: selectedRowKeys });
    } else {
      this.onFilter({ ...filters, [filter]: null });
    }
  }
  onDriverChangeKeys = selectedRowKeys => this.onChangeKeys('driver', selectedRowKeys)
  onTruckChangeKeys = selectedRowKeys => this.onChangeKeys('truck', selectedRowKeys)
  onCountryChangeKeys = selectedRowKeys => this.onChangeKeys('country', selectedRowKeys)
  onGroupFilter = (group) => {
    const { filters } = this.state;
    this.onFilter({ ...filters, group });
  }
  onSubgroupFilter = (subgroup) => {
    const { filters } = this.state;
    this.onFilter({ ...filters, subgroup });
  }

  onDateFilter = (range) => {
    const { filters } = this.state;
    const { getKPIs } = this.props;
    getKPIs(range[0], range[1]);
    this.onFilter({ ...filters, date: range });
  }

  onFilter = (filters) => {
    const { cfDimensions } = this.state;
    const { date: dateFilter, ...otherFilters } = filters;
    Object.entries(otherFilters)
      .forEach(([key, value]) => cfDimensions[key].filterFunction(multivalueFilter(value)));
    // datefilter
    if (dateFilter && Array.isArray(dateFilter) && dateFilter.length === 2) {
      cfDimensions.date.filter([moment(dateFilter[0]).utc(), moment(dateFilter[1]).utc()]);
    } else {
      cfDimensions.date.filter(null);
    }


    const filteredRecommendations = cfDimensions.truck.top(Infinity);
    const options = {
      driver: cfDimensions.driver.group().all().filter(e => e.value && e.key !== '').map(e => e.key),
      truck: cfDimensions.truck.group().all().filter(e => e.value).map(e => e.key),
      country:
        cfDimensions.country.group().all().filter(e => e.value).map(e => e.key),
      group: cfDimensions.group.group().all().filter(e => e.value).map(e => e.key),
      subgroup: cfDimensions.subgroup.group().all().filter(e => e.value).map(e => e.key),
    };
    this.setState({ filteredRecommendations, options, filters });
  }

  disabledDate = current =>
  // Can not select days before 29/09/2021
    current && current < moment({ M: 8, d: 28, y: 2021 }).endOf('day')


  initCrossFilter = () => {
    const { performanceIndicators, useInternalDriverName, useInternalVehicleId } = this.props;
    const { filters } = this.state;
    if (performanceIndicators.items) {
      const cf = crossfilter(performanceIndicators.items);
      const cfDimensions = {
        date: cf.dimension(d => moment(d.creationDateTime * 1000)),
        driver: cf.dimension((d) => {
          if (useInternalDriverName && d.driverName) return d.driverName;
          if (d.driverId) return d.driverId;
          return '';
        }),
        truck: cf.dimension((d) => {
          if (useInternalVehicleId) return d.internalTruckId;
          return d.platenumber;
        }),
        country: cf.dimension(d => countriesMapping[d.fuelStations[0].country_code]),
        group: cf.dimension(d => ((d.groups && !isEmpty(d.groups)) ? Object.keys(d.groups) : [null]), true),
        subgroup: cf.dimension((d) => {
          if (d.groups && !isEmpty(d.groups)) {
            const groups = Object.keys(d.groups);
            return groups.reduce((acc, group) => [...acc, ...d.groups[group]], []);
          }
          return [null];
        }, true),
      };
      this.setState({ cfDimensions }, () => this.onFilter(filters));
    }
  }

  render() {
    const {
      filteredRecommendations, options, filters,
    } = this.state;
    const {
      performanceIndicators: { fetching },
      intl: { locale, formatMessage },
      paymentCards,
    } = this.props;
    const {
      totalReco,
      aggregateKmPerReco,
      aggregateTotalKm,
      avgStationPrice,
      stationTableData,
      countryData,
      firstRecoCards,
      secondRecoCards,
    } = kpiCompute(filteredRecommendations);

    return (
      <div
        style={{
        height: '100%',
        backgroundColor: 'white',
        padding: 16,
      }}
      >
        <Row type="flex" gutter={16} style={{ height: '100%' }}>
          {/* Left Side */}
          <Col span={12} style={{ padding: 16, height: '100%', overflowY: 'scroll' }}>
            <Spin spinning={fetching}>
              <Row>
                <Card style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-evenly' }}>
                  <RangePicker
                    style={{ marginRight: 20, minWidth: 300 }}
                    format="ll"
                    onChange={this.onDateFilter}
                    defaultValue={[filters.date[0].tz(timezone), filters.date[1].tz(timezone)]}
                    allowClear={false}
                    disabledDate={this.disabledDate}
                  />
                  <Select
                    style={{ minWidth: 170, marginRight: 20 }}
                    allowClear
                    showSearch
                    placeholder={options.group && options.group.length === 1 ? options.group[0] : formatMessage(strings.word.groups)}
                    disabled={isNil(options.group) || options.group.length < 2}
                    onChange={v => this.onGroupFilter(v)}
                  >
                    {options.group && options.group.reduce((acc, v) => {
                      if (!isNil(v)) {
                        acc.push(<Option key={v} value={v}> {v} </Option>);
                      }
                      return acc;
                      }, [])
                    }
                  </Select>
                  <Select
                    style={{ minWidth: 170, marginRight: 20 }}
                    allowClear
                    showSearch
                    placeholder={options.subgroup && options.subgroup.length === 1 ? options.subgroup[0] : formatMessage(strings.word.subgroups)}
                    disabled={isNil(options.subgroup) || options.subgroup.length < 2 || (isNil(filters.group) && options.group && options.group.length > 1)}
                    onChange={this.onSubgroupFilter}
                  >
                    {options.subgroup && options.subgroup.map(v => (<Option key={v} value={v}> {v} </Option>))}
                  </Select>
                </Card>
              </Row>
              <Row>
                <Col span={8}>
                  <FilterTable
                    data={options.driver}
                    onChangeKeys={this.onDriverChangeKeys}
                    title={formatMessage(strings.word.driver)}
                  />
                </Col>
                <Col span={8}>
                  <FilterTable
                    data={options.truck}
                    onChangeKeys={this.onTruckChangeKeys}
                    title={formatMessage(strings.word.truck)}
                  />
                </Col>
                <Col span={8}>
                  <FilterTable
                    data={options.country}
                    onChangeKeys={this.onCountryChangeKeys}
                    title={formatMessage(strings.word.country)}
                  />
                </Col>
              </Row>
              {!fetching && totalReco > 0 && (
              <Row>
                <KeyNumbers
                  locale={locale}
                  formatMessage={formatMessage}
                  totalReco={totalReco}
                  aggregateTotalKm={aggregateTotalKm}
                  aggregateKmPerReco={aggregateKmPerReco}
                  avgStationPrice={avgStationPrice}
                />
              </Row>)
              }
            </Spin>
          </Col>
          {/* Right Side */}
          {!fetching && paymentCards && !paymentCards.processing && totalReco > 0 &&
          <PerformanceIndicatorsRightPanel
            formatMessage={formatMessage}
            countryData={countryData}
            stationTableData={stationTableData}
            firstRecoCards={firstRecoCards}
            secondRecoCards={secondRecoCards}
            locale={locale}
            paymentCards={paymentCards.items}
          />}
        </Row>
      </div>
    );
  }
}

function mapStateToProps(store) {
  const {
    performanceIndicators,
    auth: { user },
    settings: {
      items: {
        useInternalVehicleId,
        useInternalDriverName,
      },
    },
    globalInformations: {
      paymentCards,
    },
  } = store;
  return {
    performanceIndicators,
    user,
    useInternalDriverName,
    useInternalVehicleId,
    paymentCards,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getKPIs: (from, to) => dispatch(actions.performanceIndicators.getKPIs(from, to)),
    getPaymentCards: () => dispatch(actions.globalInformations.getPaymentCards()),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PerformanceIndicators));
