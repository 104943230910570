import actionTypes from '../actions/actionTypes'

export const initialUserState = {
  processing: false,
  error: null,
  user: null,
}

function userReducer(state = initialUserState, action) {
  switch (action.type) {
    case actionTypes.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
        processing: false,
      }
    case actionTypes.USER_LOGOUT_REQUEST:
      return {
        ...state,
        processing: true,
      }
    case actionTypes.USER_LOGOUT_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      }
    case actionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        processing: false,
      }
    case actionTypes.USER_LOGIN_REQUEST:
      return {
        ...state,
        processing: true,
      }
    case actionTypes.USER_LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      }
    default:
      return state
  }
}

export default userReducer
