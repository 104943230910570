import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import {
  Table,
  Button,
  Carousel,
  Row,
  Col,
  Icon,
  Upload,
  message,
  Spin,
  Modal,
  Form,
  Select,
  Tooltip,
  Input,
  Popconfirm,
} from 'antd';
import { ExcelRenderer } from 'react-excel-renderer';
import { EditableFormRow } from '../../helpers/EditableTable/EditableFormRow';
import actions from '../../actions';
import EditableCell from '../../helpers/EditableTable/EditableCell';
import SearchStationModal from './PreferredStationsSection/SearchStationModal';
import * as strings from '../../helpers/defaultStrings';
import {
  preferredStationType,
  RenderedConstant,
} from '../../helpers/constants';
import './Settings.css';
import moment from 'moment';
import * as XLSX from 'xlsx';
import _ from 'lodash';

const { PLATTS } = preferredStationType;
const { Option } = Select;
const INITIAL_CONFIG_POPUP = {
  stationCode: null,
  periodStart: null,
  periodEnd: null,
  product: null,
  price: null,
  discountValue: null,
  discountType: null,
  currency: null,
  firstheadercolumn: null,
  template_name: null,
  template: null,
  latitude: null,
  longitude: null,
};
const STATIONMAPSTATUS = {
  NOTMAPPED: 1,
  NEWLYMAPPED: 2,
  MAPPED: 3,
};
const contentStyle = {
  height: '80px',
  textAlign: 'center',
  marginBottom: '10px',
};
class BulkUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mappingStationModalOpen: false,
      selectedRow: {},
      rows: [],
      excelData: [],
      errorMessage: null,
      validateData: [],
      isConfigModalVisible: false,
      firstheadercolumn: '',
      dynamicHeaderColumns: [],
      availableColumns: [],
      templateConfigurations: [],
      selectedTemplateConfiguration: {},
      templates: [],
      selectedFleetCards: [],
      errorInfo: [],
      isPlattsEmailModalVisible: false,
      selectedBulkUploadOption: '',
      showDiscount: false,
    };
    this.columns = [];
    this.errTableColumns = [];
    this.startRowNumber = 0;
    this.carousel = createRef();
  }

  componentDidMount() {
    const {
      getFleetSettings, getPaymentCards, bulkUploadEmail, setEmail,
    } =
      this.props;
    getPaymentCards();
    getFleetSettings();
    this.setTableColumnNames();
    if (!bulkUploadEmail) {
      setEmail();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetching,
      error,
      intl,
      addErrorMessage,
      processing,
      paymentCardsLoading,
      getFileTemplatefetching,
      templateConfigurations,
      saveFileTemplateprocessing,
      addSuccessMessage,
      getFleetSettings,
      fleetSettingsProcessing,
      getStations,
      deleteProcess,
      processedDataStatus,
    } = this.props;
    const { formatMessage } = intl;
    if (!fetching && prevProps.fetching) {
      if (error) {
        addErrorMessage(formatMessage(strings.message.failedToGetMappedStationDetails));
      } else this.updateMappingIDtoExcelData();
    }

    if (
      (!processing && prevProps.processing) ||
      (!deleteProcess && prevProps.deleteProcess) ||
      (!fetching && prevProps.fetching)
    ) {
      getStations();
    }
    if (processedDataStatus && !prevProps.processedDataStatus) {
      this.updateMappedStationStatus();
    }

    if (!paymentCardsLoading && prevProps.paymentCardsLoading) {
      this.loadPaymentCards();
    }

    if (!fleetSettingsProcessing && prevProps.fleetSettingsProcessing) {
      getFleetSettings();
      this.loadPaymentCards();
    }

    if (
      (!getFileTemplatefetching && prevProps.getFileTemplatefetching) ||
      (!saveFileTemplateprocessing && prevProps.saveFileTemplateprocessing) ||
      (!deleteProcess && prevProps.deleteProcess)
    ) {
      if (!error) {
        const objtemplateConfigurations = templateConfigurations.map((e, index) => ({ ...e, id: index + 1 }));
        if (templateConfigurations) {
          this.setState({ templates: [] });
          const objTemplates = [];
          if (templateConfigurations && templateConfigurations.length) {
            templateConfigurations.forEach((template) => {
              objTemplates.push({
                value: template.template_name,
                key: template.template_name,
              });
            });
          }
          this.setState({
            templates: objTemplates,
            templateConfigurations: objtemplateConfigurations,
          });
        }
      }
    }

    if (!saveFileTemplateprocessing && prevProps.saveFileTemplateprocessing) {
      if (
        !this.props.form.getFieldValue('template') ||
        this.props.form.getFieldValue('template') === ''
      ) {
        this.props.form.setFieldsValue({
          template: this.props.form.getFieldValue('template_name'),
        });
      }
    }

    if (!deleteProcess && prevProps.deleteProcess) {
      if (error) {
        addErrorMessage(formatMessage(strings.message.failedToDeleteTemplateConfig));
      } else {
        addSuccessMessage(formatMessage(strings.message.fileTemplateConfigIsDeleted));
        this.setState({
          selectedTemplateConfiguration: {},
          firstheadercolumn: '',
          rows: [],
        });
        this.props.form.setFieldsValue(INITIAL_CONFIG_POPUP);
      }
    }
  }

  componentWillUnmount() {
    this.props.setProcessedDataStatus();
  }

  setTableColumnNames() {
    const { formatMessage } = this.props.intl;
    this.columns = [
      {
        title: formatMessage(strings.phrase.action),
        dataIndex: 'stationMapped',
        className: 'actionColumn',
        defaultSortOrder: 'ascend',
        sortOrder: 'false',
        filters: [
          {
            text: formatMessage(strings.phrase.notmapped),
            value: STATIONMAPSTATUS.NOTMAPPED,
          },
          {
            text: formatMessage(strings.phrase.mapped),
            value: STATIONMAPSTATUS.MAPPED,
          },
          {
            text: formatMessage(strings.phrase.newlymapped),
            value: STATIONMAPSTATUS.NEWLYMAPPED,
          },
        ],
        sorter: (a, b) => a.stationMapped - b.stationMapped,
        onFilter: (value, record) => record.stationMapped === value,
        render: (text, record) => {
          const stationMapped = record.stationMapped;
          const { formatMessage } = this.props.intl;
          return (
            <div>
              {stationMapped === STATIONMAPSTATUS.NOTMAPPED ? (
                <div>
                  <Button
                    type="dashed"
                    icon="link"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.mapStation(record);
                    }}
                  >
                    {formatMessage(strings.word.map)}
                  </Button>
                </div>
              ) : null}
            </div>
          );
        },
      },
      {
        title: formatMessage(strings.phrase.stationid),
        dataIndex: 'stationCode',
        className: 'dataColumns',
        editable: false,
      },
      {
        title: formatMessage(strings.phrase.periodStart),
        dataIndex: 'periodStart',
        className: 'dataColumns',
        editable: false,
      },
      {
        title: formatMessage(strings.phrase.periodEnd),
        dataIndex: 'periodEnd',
        className: 'dataColumns',
        editable: false,
      },
      {
        title: formatMessage(strings.phrase.product),
        dataIndex: 'product',
        className: 'dataColumns',
        editable: false,
      },
      {
        title: formatMessage(strings.phrase.price),
        dataIndex: 'price',
        className: 'dataColumns',
        editable: false,
      },
      {
        title: formatMessage(strings.phrase.currency),
        dataIndex: 'currency',
        className: 'dataColumns',
        editable: false,
      },
      {
        title: 'Latitude',
        dataIndex: 'latitude',
        className: 'dataColumns',
        editable: false,
      },
      {
        title: 'Longitude',
        dataIndex: 'longitude',
        className: 'dataColumns',
        editable: false,
      },
    ];

    this.errTableColumns = [
      {
        title: formatMessage(strings.phrase.linenumber),
        dataIndex: 'linenumber',
        className: 'dataColumns',
        editable: false,
        width: '10%',
      },
      {
        title: formatMessage(strings.phrase.errordescription),
        dataIndex: 'errordescription',
        className: 'dataColumns',
        editable: false,
      },
    ];
  }

  loadPaymentCards() {
    const { cards, paymentCards } = this.props;
    if (paymentCards) {
      const cleanName = str =>
        str
          .replace('Fleet Card ', '')
          .replace('Petrol Card ', '')
          .replace('Bonus Card ', '')
          .replace('Credit Card ', '')
          .replace('Discount Card ', '')
          .replace('Prepaid Card ', '')
          .trim();
      const allCards =
        paymentCards &&
        Object.entries(paymentCards)
          .map(([key, value]) => ({ key, value: cleanName(value) }))
          .sort((a, b) => (a.value > b.value ? 1 : -1));
      const fleetCards = allCards.filter(card => cards.map(c => c.card).findIndex(x => x === card.key) >= 0);
      this.setState({ selectedFleetCards: fleetCards });
    }
  }

  updateMappingIDtoExcelData() {
    const { processedData } = this.props;
    if (processedData && processedData.length) {
      processedData.forEach((row, index) => {
        row.stationMapped =
          row.mapping_id && row.mapping_id !== ''
            ? STATIONMAPSTATUS.MAPPED
            : STATIONMAPSTATUS.NOTMAPPED;
        row.key = index;
        row.periodStart = row.periodStart
          ? moment(new Date(row.periodStart)).format('DD/MM/YYYY')
          : row.periodStart;
        row.periodEnd = row.periodEnd
          ? moment(new Date(row.periodEnd)).format('DD/MM/YYYY')
          : row.periodEnd;
      });
    }
    this.setState({
      rows: processedData,
      errorMessage: null,
    });
  }

  updateMappedStationStatus() {
    const { addSuccessMessage, plattsStationMapped, intl } = this.props;
    const { formatMessage } = intl;
    let newRows = [];
    newRows = this.state.rows;
    if (plattsStationMapped.mapping_id) {
      newRows.map(row =>
        (row.stationMapped =
            plattsStationMapped.stationCode === row.stationCode
              ? STATIONMAPSTATUS.NEWLYMAPPED
              : row.stationMapped));
      this.setState({
        rows: newRows,
        errorMessage: null,
      });
      addSuccessMessage(formatMessage(strings.message.stationMappedSuccessfully));
    }
    if (
      newRows.length > 0 &&
      newRows.filter(row => row.stationMapped === STATIONMAPSTATUS.NOTMAPPED)
        .length === 0
    ) {
      this.setState({ isPlattsEmailModalVisible: true });
    }
  }

  getStartRowFromExcel(f) {
    const reader = new FileReader();
    const this_ = this;
    reader.onload = (f) => {
      const { errorInfo } = this_.state;
      const bstr = f.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      if (ws && ws['!ref']) {
        this_.startRowNumber = Number(ws['!ref'].split(':')[0].replace(/[^0-9]+/g, ''));
        errorInfo.forEach((row) => {
          row.linenumber =
            row.linenumber !== '#'
              ? Number(row.linenumber) + Number(this_.startRowNumber)
              : '#';
        });
        this_.setState({ errorInfo });
      }
    };
    reader.readAsBinaryString(f);
  }

  fileHandler = (fileList) => {
    const { intl } = this.props;
    const { formatMessage } = intl;

    const fileObj = fileList;
    if (!fileObj) {
      this.setState({
        errorMessage: formatMessage(strings.message.nofileuploaded),
      });
      message.error(formatMessage(strings.message.nofileuploaded));
      return false;
    }

    if (
      !(
        fileObj.type === 'application/vnd.ms-excel' ||
        fileObj.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      this.setState({
        errorMessage: formatMessage(strings.message.unknownfileformatOnlyExcel),
      });
      message.error(formatMessage(strings.message.unknownfileformatOnlyExcel));
      return false;
    }

    ExcelRenderer(fileObj, (err, resp) => {
      this.getStartRowFromExcel(fileObj);
      const { formatMessage } = this.props.intl;
      if (err) {
        message.error(err);
      } else {
        const newRows = [];
        resp.rows.forEach((row) => {
          newRows.push(row);
        });
        if (newRows.length === 0) {
          this.setState({
            errorMessage: formatMessage(strings.message.nodatainfile),
            rows: [],
          });
          return false;
        }
        this.setState({
          excelData: newRows,
          errorMessage: null,
          errorInfo: [],
        });
        this.configurePlattsTemplate();
        if (
          !this.props.form.getFieldValue('template') ||
          this.props.form.getFieldValue('template') === ''
        ) {
          this.setState({ isConfigModalVisible: true });
        } else {
          const { selectedTemplateConfiguration } = this.state;
          this.setTableColumnsAndData(selectedTemplateConfiguration);
        }
      }
    });
    return false;
  };

  handleChange = (info) => {
    const { formatMessage } = this.props.intl;

    const fileObj =
      info.fileList && info.fileList.length > 0
        ? info.fileList[info.fileList.length - 1]
        : [];
    if (
      !(
        fileObj.type === 'application/vnd.ms-excel' ||
        fileObj.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      this.setState({
        errorMessage: formatMessage(strings.message.unknownfileformatOnlyExcel),
      });
      return false;
    }
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map((file) => {
      if (file.response) {
        file.name = file.response.name;
      }
      return file;
    });
    this.setState({ fileList, fileInfo: info });
  };

  configurePlattsTemplate() {
    const { excelData, firstheadercolumn, availableColumns } = this.state;
    const columnValues = [];
    excelData.forEach((row, index) => {
      if (row[0] && row[0].toString().trim() !== '' && index < 20) {
        const uniqueValueIndex = columnValues.findIndex(x => x.value === row[0]);
        if (uniqueValueIndex === -1) {
          columnValues.push({
            value: row[0],
            key: index,
          });
        }
      }
    });
    this.setState({ dynamicHeaderColumns: columnValues });

    if (!availableColumns || availableColumns.length > 0) {
      const firstheadercolumnIdx = excelData.findIndex(row => row[0] === firstheadercolumn);
      if (firstheadercolumnIdx >= 0) {
        const headerColumns = excelData[firstheadercolumnIdx];
        const objavailableColumns = [];
        headerColumns.forEach((obj) => {
          objavailableColumns.push({
            value: obj,
            key: obj,
          });
        });
        this.setState({ availableColumns: objavailableColumns });
      }
    }
  }

  returnIndexValue(source, value) {
    return source.findIndex(x => x === value);
  }

  setTableColumnsAndData(templateconfig) {
    const { excelData, firstheadercolumn } = this.state; // , startRowNumber
    const { formatMessage } = this.props.intl;

    const dataRows = [];
    let startRow = -1;
    let isStartRow = false;
    const errorInfo = [];
    let headerColumns = [];
    let unmatchedcolumns = '';
    excelData.forEach((row, index) => {
      if (row && row !== 'undefined') {
        if (
          row[0] &&
          row[0].toString().toUpperCase() ===
            firstheadercolumn.toString().toUpperCase() &&
          isStartRow === false
        ) {
          isStartRow = true;
          headerColumns = row;
          startRow = index + 1;
          return;
        }
        if (isStartRow) {
          let errMsg = '';
          if (
            row[0] &&
            row[0].toString().trim() !== '' &&
            !row[0]
              .toString()
              .toUpperCase()
              .includes(firstheadercolumn.toString().toUpperCase()) &&
            !row[0]
              .toString()
              .toUpperCase()
              .includes('PRICES REFERRED TO CUSTOMER/GROUP')
          ) {
            const rowData = {};
            rowData.key = index;
            rowData.mapping_id = '';
            rowData.stationCode = '';
            if (templateconfig && templateconfig.stationCode) {
              for (let i = 0; i <= templateconfig.stationCode.length - 1; i++) {
                if (
                  templateconfig.stationCode[i] &&
                  templateconfig.stationCode[i].toString().trim() !== ''
                ) {
                  const colIndex = this.returnIndexValue(
                    headerColumns,
                    templateconfig.stationCode[i],
                  );
                  if (colIndex >= 0) {
                    rowData.stationCode +=
                      rowData.stationCode !== ''
                        ? ` ${row[colIndex]}`
                        : row[colIndex];
                  } else if (startRow === index) {
                    unmatchedcolumns +=
                      (unmatchedcolumns === '' ? '' : ', ') +
                      templateconfig.stationCode[i];
                  }
                }
              }
            }

            if (
              templateconfig.periodStart &&
              templateconfig.periodStart.toString().trim() !== ''
            ) {
              const periodStartIdx = headerColumns.findIndex(x => x === templateconfig.periodStart);
              if (periodStartIdx >= 0) {
                rowData.periodStart = row[periodStartIdx];
                if (
                  !rowData.periodStart ||
                  rowData.periodStart.toString().trim() === ''
                ) {
                  errMsg += `${this.props.form.getFieldValue('periodStart')}(${formatMessage(strings.phrase.periodStart)})`;
                }
              } else if (startRow === index) {
                unmatchedcolumns +=
                  (unmatchedcolumns === '' ? '' : ', ') +
                  templateconfig.periodStart;
              }
            }

            if (
              templateconfig.periodEnd &&
              templateconfig.periodEnd.toString().trim() !== ''
            ) {
              const periodEndIdx = headerColumns.findIndex(x => x === templateconfig.periodEnd);
              if (periodEndIdx >= 0) {
                rowData.periodEnd = row[periodEndIdx];
              } else if (startRow === index) {
                unmatchedcolumns +=
                  (unmatchedcolumns === '' ? '' : ', ') +
                  templateconfig.periodEnd;
              }
            }

            if (
              templateconfig.product &&
              templateconfig.product.toString().trim() !== ''
            ) {
              const productIdx = headerColumns.findIndex(x => x === templateconfig.product);
              if (productIdx >= 0) {
                rowData.product = row[productIdx];
                if (
                  !rowData.product ||
                  rowData.product.toString().trim() === ''
                ) {
                  errMsg += `${
                    (errMsg !== '' ? ', ' : '') +
                    this.props.form.getFieldValue('product')
                  }(${formatMessage(strings.phrase.product)})`;
                }
              } else if (startRow === index) {
                unmatchedcolumns +=
                  (unmatchedcolumns === '' ? '' : ', ') +
                  templateconfig.product;
              }
            }

            if (
              templateconfig.price &&
              templateconfig.price.toString().trim() !== ''
            ) {
              const priceIdx = headerColumns.findIndex(x => x === templateconfig.price);
              if (priceIdx >= 0) {
                rowData.price = row[priceIdx];
                if (!rowData.price || rowData.price.toString().trim() === '') {
                  errMsg += `${
                    (errMsg !== '' ? ', ' : '') +
                    this.props.form.getFieldValue('price')
                  }(${formatMessage(strings.phrase.price)})`;
                }
              } else if (startRow === index) {
                unmatchedcolumns +=
                  (unmatchedcolumns === '' ? '' : ', ') + templateconfig.price;
              }
            }

            if (
              templateconfig.currency &&
              templateconfig.currency.toString().trim() !== ''
            ) {
              const currencyIdx = headerColumns.findIndex(x => x === templateconfig.currency);
              if (currencyIdx >= 0) {
                rowData.currency = row[currencyIdx];
                if (
                  !rowData.currency ||
                  rowData.currency.toString().trim() === ''
                ) {
                  errMsg += `${
                    (errMsg !== '' ? ', ' : '') +
                    this.props.form.getFieldValue('currency')
                  }(${formatMessage(strings.phrase.currency)})`;
                }
              } else if (startRow === index) {
                unmatchedcolumns +=
                  (unmatchedcolumns === '' ? '' : ', ') +
                  templateconfig.currency;
              }
            }

            if (
              templateconfig.discountValue &&
              templateconfig.discountValue.toString().trim() !== ''
            ) {
              const discountValueIdx = headerColumns.findIndex(x => x === templateconfig.discountValue);
              if (discountValueIdx >= 0) {
                rowData.discountValue = row[discountValueIdx];
                if (
                  !rowData.discountValue ||
                  rowData.discountValue.toString().trim() === ''
                ) {
                  errMsg += `${
                    (errMsg !== '' ? ', ' : '') +
                    this.props.form.getFieldValue('discountValue')
                  }(${formatMessage(strings.word.discount)})`;
                }
              } else if (startRow === index) {
                unmatchedcolumns +=
                  (unmatchedcolumns === '' ? '' : ', ') +
                  templateconfig.discountValue;
              }
            }

            if (
              templateconfig.latitude &&
              templateconfig.latitude.toString().trim() !== ''
            ) {
              const latitudeIdx = headerColumns.findIndex(x => x === templateconfig.latitude);
              if (latitudeIdx >= 0) {
                rowData.latitude = row[latitudeIdx];
              } else if (startRow === index) {
                unmatchedcolumns +=
                  (unmatchedcolumns === '' ? '' : ', ') +
                  templateconfig.latitude;
              }
            }

            if (
              templateconfig.longitude &&
              templateconfig.longitude.toString().trim() !== ''
            ) {
              const longitudeIdx = headerColumns.findIndex(x => x === templateconfig.longitude);
              if (longitudeIdx >= 0) {
                rowData.longitude = row[longitudeIdx];
              } else if (startRow === index) {
                unmatchedcolumns +=
                  (unmatchedcolumns === '' ? '' : ', ') +
                  templateconfig.longitude;
              }
            }

            if (errMsg !== '') {
              errorInfo.push({
                key: Number(index) + Number(this.startRowNumber),
                linenumber: Number(index) + Number(this.startRowNumber),
                errordescription: `${errMsg} ${formatMessage(strings.message.cannotbeempty)}`,
              });
            }

            if (rowData.periodStart) {
              const isValidStartDate = this.validateDate(
                rowData.periodStart,
                'DD/MM/YYYY',
              );
              if (!isValidStartDate) {
                errorInfo.push({
                  key: `${Number(index) + Number(this.startRowNumber)}_enddate`,
                  linenumber: Number(index) + Number(this.startRowNumber),
                  errordescription: `${formatMessage(strings.message.invaliddateformat)} ${this.props.form.getFieldValue('periodStart')}(${formatMessage(strings.phrase.periodStart)})`,
                });
              } else if (!isNaN(rowData.periodStart)) {
                rowData.periodStart = moment(new Date((rowData.periodStart - 25569) * 86400 * 1000)).format('DD/MM/YYYY');
              } else {
                const dateParts = rowData.periodStart.split('/');
                if (dateParts && dateParts.length === 3) {
                  if (dateParts[1] > 12) {
                    rowData.periodStart = moment(new Date(new Date(`${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`))).format('DD/MM/YYYY');
                  } else {
                    rowData.periodStart = moment(new Date(new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`))).format('DD/MM/YYYY');
                  }
                }
              }
            }

            if (rowData.periodEnd) {
              const isValidEndDate = this.validateDate(
                rowData.periodEnd,
                'DD/MM/YYYY',
              );
              if (!isValidEndDate) {
                errorInfo.push({
                  key: `${
                    Number(index) + Number(this.startRowNumber)
                  }_fromdate`,
                  linenumber: Number(index) + Number(this.startRowNumber),
                  errordescription: `${formatMessage(strings.message.invaliddateformat)} ${this.props.form.getFieldValue('periodEnd')}(${formatMessage(strings.phrase.periodEnd)})`,
                });
              } else if (!isNaN(rowData.periodEnd)) {
                rowData.periodEnd = moment(new Date((rowData.periodEnd - 25569) * 86400 * 1000)).format('DD/MM/YYYY');
              } else {
                const dateParts = rowData.periodEnd.split('/');
                if (dateParts && dateParts.length === 3) {
                  if (dateParts[1] > 12) {
                    rowData.periodEnd = moment(new Date(new Date(`${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`))).format('DD/MM/YYYY');
                  } else {
                    rowData.periodEnd = moment(new Date(new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`))).format('DD/MM/YYYY');
                  }
                }
              }
            }

            if (
              ((!this.state.showDiscount &&
                rowData.periodStart &&
                rowData.product &&
                rowData.price) ||
                (this.state.showDiscount && rowData.discountValue)) &&
              rowData.stationCode &&
              rowData.currency
            ) {
              dataRows.push(rowData);
            }
          }
        }
      }
    });

    if (!isStartRow) {
      message.error(formatMessage(strings.message.invalidFile));
      return;
    }

    if (unmatchedcolumns !== '') {
      errorInfo.push({
        key: 999999,
        linenumber: '#',
        errordescription: `${formatMessage(strings.message.mappedcolumns)} ${unmatchedcolumns} ${formatMessage(strings.message.missing)}`,
      });
    }

    this.setState({ errorInfo });

    if (dataRows.length === 0) {
      this.setState({
        errorMessage: formatMessage(strings.message.nodatainfile),
        rows: [],
      });
      return false;
    }

    if (!errorInfo.length) {
      const dataToProcess = [];
      dataRows.forEach((row) => {
        const rowIndex = dataToProcess.findIndex(data => data.stationCode === row.stationCode);
        if (rowIndex >= 0) {
          if (
            dataToProcess[rowIndex].price &&
            dataToProcess[rowIndex].price.toString().replace(',', '.') >
              row.price.toString().replace(',', '.')
          ) {
            dataToProcess[rowIndex].price = row.price;
          }
        } else {
          dataToProcess.push({
            key: row.key,
            mapping_id: '',
            currency: row.currency,
            stationCode: row.stationCode,
            price: row.price,
            periodStart: row.periodStart,
            periodEnd: row.periodEnd,
            product: row.product,
            discountValue: row.discountValue,
            latitude: row.latitude,
            longitude: row.longitude,
          });
        }
      });
      this.setState({
        rows: dataToProcess,
        errorMessage: null,
      });
    }
    this.setState({ isConfigModalVisible: false });
  }

  validateDate(value, format) {
    try {
      if (!isNaN(value)) {
        value = moment(new Date((value - 25569) * 86400 * 1000)).format('MM/DD/YYYY');
      }
      const dateParts = value.split('/');
      if (dateParts && dateParts.length === 3) {
        const dateFormat = format;
        const toDateFormat =
          dateParts[1] > 12
            ? moment(new Date(`${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`)).format(dateFormat)
            : moment(new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`)).format(dateFormat);
        return moment(toDateFormat, dateFormat, true).isValid();
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  handleConfigModelOk = () => {
    const { templateConfigurations } = this.props;
    const { formatMessage } = this.props.intl;
    const { validateFields } = this.props.form;
    validateFields((err, values) => {
      if (!err) {
        const configObj = {};
        configObj.template_name = values.template_name;
        configObj.firstheadercolumn = values.firstheadercolumn;
        configObj.stationCode = values.stationCode;
        configObj.periodStart = values.periodStart;
        configObj.periodEnd = values.periodEnd;
        configObj.product = values.product;
        configObj.price = values.price;
        configObj.currency = values.currency;
        configObj.fleetCard = values.fleetCard;
        configObj.discountValue = values.discountValue;
        configObj.discountType = values.discountType;
        configObj.id = values.id;
        configObj.latitude = values.latitude;
        configObj.longitude = values.longitude;

        const validateTemplateConfigurations = [];
        templateConfigurations.forEach((template) => {
          validateTemplateConfigurations.push(template);
        });
        if (
          this.props.form.getFieldValue('template') &&
          this.props.form.getFieldValue('template') !== ''
        ) {
          const editIndex = validateTemplateConfigurations.findIndex(temp => temp.id === configObj.id);
          validateTemplateConfigurations[editIndex] = configObj;
        } else {
          validateTemplateConfigurations.push(configObj);
        }

        const checkDuplicateTemplateNames = validateTemplateConfigurations.map(template => template.template_name.toString().trim());
        if (
          new Set(checkDuplicateTemplateNames).size !==
          validateTemplateConfigurations.length
        ) {
          message.error(formatMessage(strings.message.duplicateTemplateName));
        } else {
          this.setTableColumnsAndData(values);
          this.setState({
            selectedTemplateConfiguration: configObj,
            isConfigModalVisible: false,
          });
        }
      }
    });
  };

  handleConfigModelCancel = () => {
    const configObj = this.state.selectedTemplateConfiguration;
    this.setFormFieldsValue(configObj);
    this.setState({ isConfigModalVisible: false });
  };

  onFirstColumnHeaderChange = (value, event) => {
    if (value) {
      const { excelData } = this.state;
      this.setState({ firstheadercolumn: value });
      const headerRowIndex = event.key;
      const availableColumns = [];
      if (headerRowIndex) {
        for (let i = 0; i < 30; i++) {
          if (
            excelData[headerRowIndex][i] &&
            excelData[headerRowIndex][i].toString().trim() !== ''
          ) {
            availableColumns.push({
              value: excelData[headerRowIndex][i],
              key: excelData[headerRowIndex][i],
            });
          } else {
            break;
          }
        }
        this.setState({
          availableColumns,
          firstheadercolumn: excelData[headerRowIndex][0],
        });
      }
    } else {
      this.setState({ availableColumns: [], firstheadercolumn: '' });
    }
  };

  onfleetCardChange = (value, event) => {
    const { getFileTemplateConfig } = this.props;
    this.setFormFieldsValue(null);
    this.setState({
      templates: [],
      rows: [],
      fileList: [],
      firstheadercolumn: '',
      errorInfo: [],
      selectedTemplateConfiguration: {},
    });
    if (!event) {
      const bulkUploadOption =
        this.props.form.getFieldValue('bulkUploadOption');
      this.props.form.resetFields();
      this.props.form.setFieldsValue({ bulkUploadOption });
    } else {
      getFileTemplateConfig(
        this.props.form.getFieldValue('bulkUploadOption'),
        value.toString().trim(),
      );
      this.carousel.current.next();
    }
  };

  onTemplateChange = (value, event) => {
    const { getPlattsMappingDetails, templateConfigurations, getPlattsEmail } =
      this.props;
    this.setState({
      rows: [],
      fileList: [],
      firstheadercolumn: '',
      errorInfo: [],
      selectedTemplateConfiguration: {},
    });
    const bulkUploadOption = this.props.form.getFieldValue('bulkUploadOption');
    const valFleetCard = this.props.form.getFieldValue('fleetCard');
    if (!event) {
      this.props.form.resetFields();
      this.props.form.setFieldsValue({
        fleetCard: valFleetCard,
        bulkUploadOption,
      });
    } else {
      const selectedTemplateConfiguration = templateConfigurations.filter(template =>
        template.fleetCard === this.props.form.getFieldValue('fleetCard') &&
          template.template_name === value);
      if (
        selectedTemplateConfiguration &&
        selectedTemplateConfiguration.length > 0
      ) {
        this.setFormFieldsValue(selectedTemplateConfiguration[0]);
        this.setState({
          selectedTemplateConfiguration: selectedTemplateConfiguration[0],
        });
        getPlattsMappingDetails(bulkUploadOption, valFleetCard, value);
        getPlattsEmail(bulkUploadOption);
      }
    }
  };

  onBulkUploadChange = (value, event) => {
    const { formatMessage } = this.props.intl;
    this.props.form.resetFields();
    const discValueIndex = this.columns.findIndex(column => column.dataIndex === 'discountValue');
    // const priceIndex = this.columns.findIndex(
    //   (column) => column.dataIndex === "price"
    // );
    this.setState({ selectedBulkUploadOption: value });
    if (event && event.key !== 'platts') {
      this.setState({ showDiscount: true });
      // if (priceIndex > 0) {
      //   this.columns[priceIndex].title = formatMessage(strings.word.discount);
      //   this.columns[priceIndex].dataIndex = "discountValue";
      // }
      this.columns.push({
        title: formatMessage(strings.word.discount),
        dataIndex: 'discountValue',
        className: 'dataColumns',
        editable: false,
      });
    } else {
      this.setState({ showDiscount: false });
      if (discValueIndex >= 0) {
        // this.columns[discValueIndex].title = formatMessage(strings.phrase.price);
        // this.columns[discValueIndex].dataIndex = "price";
        this.columns.pop(discValueIndex);
      }
    }
  };

  onsettingsClick = () => {
    const { processing, fetching } = this.props;
    if (processing || fetching) {
      return;
    }
    this.setState({ isConfigModalVisible: true });
  };

  getAdvancedSelect = (key) => {
    const {
      availableColumns,
      dynamicHeaderColumns,
      selectedFleetCards,
      templates,
    } = this.state;
    const { formatMessage } = this.props.intl;
    let optionsource = [];
    if (key === 'firstheadercolumn') {
      optionsource = dynamicHeaderColumns;
    } else if (key === 'fleetCard') {
      optionsource = selectedFleetCards;
    } else if (key === 'template') {
      optionsource = templates;
    } else if (key === 'bulkUploadOption') {
      optionsource = [
        { key: 'platts', value: formatMessage(strings.phrase.plattsPricing) },
        {
          key: 'discPerStation',
          value: formatMessage(strings.phrase.discountsPerStation),
        },
        {
          key: 'discOnListPrice',
          value: formatMessage(strings.phrase.discountsListPrice),
        },
      ];
    } else if (key === 'discountType') {
      optionsource = [
        {
          key: 'percent',
          value: formatMessage(strings.phrase.percentDiscount),
        },
        {
          key: 'euroInCents',
          value: formatMessage(strings.phrase.discountInEuroCents),
        },
        { key: 'euro', value: formatMessage(strings.phrase.discountInEuro) },
      ];
    } else {
      optionsource = availableColumns;
    }
    const disabled =
      !optionsource ||
      optionsource.length <= 0 ||
      (key === 'fleetCard' &&
        !this.props.form.getFieldValue('bulkUploadOption'));
    let optionFilterProp = 'value';
    optionFilterProp = 'label';

    return (
      <Select
        style={{ minWidth: 200 }}
        allowClear
        showSearch
        optionFilterProp={optionFilterProp}
        disabled={disabled}
        onChange={
          key === 'firstheadercolumn'
            ? (value, event) => this.onFirstColumnHeaderChange(value, event)
            : key === 'fleetCard'
            ? (value, event) => this.onfleetCardChange(value, event)
            : key === 'template'
            ? (value, event) => this.onTemplateChange(value, event)
            : key === 'bulkUploadOption'
            ? (value, event) => this.onBulkUploadChange(value, event)
            : ''
        }
        mode={key === 'stationCode' ? 'multiple' : ''}
      >
        {optionsource.map(column => (
          <Option
            key={column.key}
            value={
              key === 'bulkUploadOption' || key === 'discountType'
                ? column.key
                : column.value
            }
          >
            {column.value}
          </Option>
        ))}
      </Select>
    );
  };

  setFormFieldsValue(dataObj) {
    if (dataObj) {
      this.props.form.setFieldsValue({
        periodStart:
          dataObj && dataObj.periodStart ? dataObj.periodStart : null,
        periodEnd: dataObj && dataObj.periodEnd ? dataObj.periodEnd : null,
        product: dataObj && dataObj.product ? dataObj.product : null,
        price: dataObj && dataObj.price ? dataObj.price : null,
        currency: dataObj && dataObj.currency ? dataObj.currency : null,
        discountValue:
          dataObj && dataObj.discountValue ? dataObj.discountValue : null,
        discountType:
          dataObj && dataObj.discountType ? dataObj.discountType : null,
        firstheadercolumn:
          dataObj && dataObj.firstheadercolumn
            ? dataObj.firstheadercolumn
            : null,
        template_name:
          dataObj && dataObj.template_name ? dataObj.template_name : null,
        latitude: dataObj && dataObj.latitude ? dataObj.latitude : null,
        longitude: dataObj && dataObj.longitude ? dataObj.longitude : null,
      });
      if (dataObj && dataObj.stationCode) {
        // separately assigned value to avoid empty value binding to Station ID multi select dropdown
        this.props.form.setFieldsValue({ stationCode: dataObj.stationCode });
      }
      this.setState({
        firstheadercolumn:
          dataObj && dataObj.firstheadercolumn ? dataObj.firstheadercolumn : '',
      });
    } else {
      const bulkUploadOption =
        this.props.form.getFieldValue('bulkUploadOption');
      this.props.form.resetFields();
      this.props.form.setFieldsValue({ bulkUploadOption });
      this.setState({ selectedTemplateConfiguration: {} });
    }
  }

  openMappingStationModal = () =>
    this.setState({ mappingStationModalOpen: true });
  closeMappingStationModal = () =>
    this.setState({ mappingStationModalOpen: false });
  addMappingStation = (stations) => {
    const { mapPlattsStation } = this.props;
    const { selectedRow } = this.state;
    if (stations && stations.length > 0 && selectedRow) {
      const mapObject = {
        stationCode: selectedRow.stationCode,
        mapping_id: stations[0].id,
        latitude: stations[0].latitude,
        longitude: stations[0].longitude,
        zipcode: stations[0].zipcode,
        locality: stations[0].locality,
        brand: stations[0].brand,
        address: stations[0].address,
        station_name: stations[0].name,
        country_code: stations[0].country,
      };
      mapPlattsStation(mapObject);
    }
  };

  mapStation(row) {
    this.setState({ mappingStationModalOpen: true, selectedRow: row });
  }

  copyCodeToClipboard = () => {
    const el = this.refs.plattsEmailAddress;
    const textField = document.createElement('textarea');
    textField.innerText = el.innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    this.setState({ isPlattsEmailModalVisible: false });
  };

  onInfoIconClicked = () => {
    this.setState({ isPlattsEmailModalVisible: true });
  };

  handlePlattsEmailModalCancel = () => {
    this.setState({ isPlattsEmailModalVisible: false });
  };

  onDeleteTemplateClick = () => {
    const { deleteFileTemplateConfig } = this.props;
    deleteFileTemplateConfig(
      this.props.form.getFieldValue('fleetCard'),
      this.props.form.getFieldValue('template'),
    );
    this.setState({ isConfigModalVisible: false });
  };

  onSaveAndProcessClick = () => {
    const {
      saveFileTemplateConfig,
      getPlattsMappingForExcelData,
      getPlattsEmail,
    } = this.props;
    const {
      rows,
      templateConfigurations,
      selectedTemplateConfiguration,
      fileList,
    } = this.state;

    const saveTemplateConfigurations = [];
    const bulkUploadOption = this.props.form.getFieldValue('bulkUploadOption');

    templateConfigurations.forEach((template) => {
      saveTemplateConfigurations.push(template);
    });
    if (
      this.props.form.getFieldValue('template') &&
      this.props.form.getFieldValue('template') !== ''
    ) {
      const editIndex = saveTemplateConfigurations.findIndex(temp => temp.id === selectedTemplateConfiguration.id);
      saveTemplateConfigurations[editIndex] = selectedTemplateConfiguration;
      if (fileList && fileList[0]) {
        saveTemplateConfigurations[editIndex].latestfileuploade =
          fileList[0].name;
        saveTemplateConfigurations[editIndex].latestfileuploadedDatetime =
          moment(new Date()).format('DD/MM/YYYY HH:mm');
      }
      saveTemplateConfigurations[editIndex].bulkUploadOption = bulkUploadOption;
    } else if (fileList && fileList[0]) {
      const objTemplate = selectedTemplateConfiguration;
      objTemplate.latestfileuploaded = fileList[0].name;
      objTemplate.latestfileuploadedDatetime = moment(new Date()).format('DD/MM/YYYY HH:mm');
      objTemplate.bulkUploadOption = bulkUploadOption;
      this.setState({ selectedTemplateConfiguration: objTemplate });
      saveTemplateConfigurations.push(objTemplate);
    }
    if (rows && rows.length > 0) {
      const dataToValidate = rows.map(e => ({
        ...e,
        fleetcard: this.props.form.getFieldValue('fleetCard'),
        template_name: this.props.form.getFieldValue('template_name'),
      }));
      const payloadData = {
        discountType: selectedTemplateConfiguration.discountType,
        bulkUploadOption,
        excelData: dataToValidate,
      };
      getPlattsMappingForExcelData(payloadData);
      getPlattsEmail(bulkUploadOption);
    }
    saveFileTemplateConfig(saveTemplateConfigurations);
  };

  plattsTableFooter = () => {
    const { rows } = this.state;
    const { formatMessage } = this.props.intl;
    const data = {
      NOTMAPPED: rows.filter(e => e.stationMapped === STATIONMAPSTATUS.NOTMAPPED).length,
      MAPPED: rows.filter(e =>
        e.stationMapped === STATIONMAPSTATUS.MAPPED ||
          e.stationMapped === STATIONMAPSTATUS.NEWLYMAPPED).length,
      NEWLYMAPPED: rows.filter(e => e.stationMapped === STATIONMAPSTATUS.NEWLYMAPPED).length,
    };
    return (
      <Row
        gutter={16}
        type="flex"
        style={{ display: rows && rows.length > 0 ? '' : 'none' }}
      >
        <Col style={{ display: 'flex', flexWrap: 'nowrap' }}>
          {`${formatMessage(strings.phrase.totalgasstations)}:`}&nbsp;
          {rows.length}
        </Col>
        <Col style={{ display: 'flex', flexWrap: 'nowrap' }}>
          {`${formatMessage(strings.phrase.notmapped)}:`}&nbsp;
          <div className="text-red">{data.NOTMAPPED}</div>
        </Col>
        <Col style={{ display: 'flex', flexWrap: 'nowrap' }}>
          {`${formatMessage(strings.phrase.mapped)}:`}&nbsp;
          <div style={{ color: '#25c725' }}>{data.MAPPED}</div>
        </Col>
        {data.NEWLYMAPPED > 0 ? (
          <Col style={{ display: 'flex', flexWrap: 'nowrap' }}>
            {`${formatMessage(strings.phrase.newlymapped)}:`}&nbsp;
            <div style={{ color: '#25c725' }}>{data.NEWLYMAPPED}</div>
          </Col>
        ) : (
          ''
        )}
      </Row>
    );
  };

  onRemovingFile = () => {
    if (
      this.props.form.getFieldValue('template') &&
      this.props.form.getFieldValue('template') !== ''
    ) {
      this.setState({ rows: [], fileList: [], errorInfo: [] });
    } else {
      this.setState({
        rows: [],
        fileList: [],
        errorInfo: [],
        firstheadercolumn: '',
        dynamicHeaderColumns: [],
        availableColumns: [],
        selectedTemplateConfiguration: {},
      });

      const valFleetCard = this.props.form.getFieldValue('fleetCard');
      const bulkUploadOption =
        this.props.form.getFieldValue('bulkUploadOption');
      this.props.form.resetFields();
      this.props.form.setFieldsValue({
        fleetCard: valFleetCard,
        bulkUploadOption,
      });
    }
  };

  render() {
    const {
      fetching,
      processing,
      paymentCardsLoading,
      plattsEmail,
      getFileTemplatefetching,
      deleteProcess,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const { getFieldDecorator } = this.props.form;
    const { mappingStationModalOpen, rows, selectedTemplateConfiguration } =
      this.state;
    const enableInfoIcon =
      rows &&
      rows.length > 0 &&
      rows.filter(row => !row.mapping_id || row.mapping_id === '').length ===
        0;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const columns = this.columns;
    const errTableColumns = this.errTableColumns;

    const rules = {
      template_name: [
        {
          required: true,
          message: `${formatMessage(strings.phrase.templatename)} ${formatMessage(strings.message.isRequired)}`,
        },
      ],
      firstheadercolumn: [
        {
          required: true,
          message: `${formatMessage(strings.phrase.firstheadercolumn)} ${formatMessage(strings.message.isRequired)}`,
        },
      ],
      stationCode: [
        {
          required: true,
          message: `${formatMessage(strings.phrase.stationid)} ${formatMessage(strings.message.isRequired)}`,
        },
      ],
      periodStart: [
        {
          required: true,
          message: `${formatMessage(strings.phrase.periodStart)} ${formatMessage(strings.message.isRequired)}`,
        },
      ],
      product: [
        {
          required: true,
          message: `${formatMessage(strings.phrase.product)} ${formatMessage(strings.message.isRequired)}`,
        },
      ],
      price: [
        {
          required: true,
          message: `${formatMessage(strings.phrase.price)} ${formatMessage(strings.message.isRequired)}`,
        },
      ],
      currency: [
        {
          required: true,
          message: `${formatMessage(strings.phrase.currency)} ${formatMessage(strings.message.isRequired)}`,
        },
      ],
      discountValue: [
        {
          required: true,
          message: `${formatMessage(strings.word.discount)} ${formatMessage(strings.message.isRequired)}`,
        },
      ],
      discountType: [
        {
          required: true,
          message: `${formatMessage(strings.word.discountType)} ${formatMessage(strings.message.isRequired)}`,
        },
      ],
      latitude: [{ required: false }],
      longitude: [{ required: false }],
    };

    return (
      <Spin
        spinning={
          paymentCardsLoading ||
          fetching ||
          processing ||
          getFileTemplatefetching ||
          deleteProcess
        }
      >
        <Carousel ref={this.carousel} effect="scrollx" dotPosition="bottom">
          <div>
            <Row type="flex" gutter={16} style={contentStyle}>
              <Col>
                <Form.Item
                  label={formatMessage(strings.phrase.bulkUploadOption)}
                  className="fuelcardControl"
                >
                  {getFieldDecorator('bulkUploadOption')(this.getAdvancedSelect('bulkUploadOption'))}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={formatMessage(strings.phrase.fleetCard)}
                  className="fuelcardControl"
                >
                  {getFieldDecorator('fleetCard')(this.getAdvancedSelect('fleetCard'))}
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div>
            <Row type="flex" gutter={16} style={contentStyle}>
              <Col>
                <Upload
                  fileList={this.state.fileList}
                  disabled={
                    fetching ||
                    processing ||
                    !this.props.form.getFieldValue('fleetCard')
                  }
                  name="file"
                  beforeUpload={this.fileHandler}
                  onRemove={this.onRemovingFile}
                  onChange={this.handleChange}
                  multiple={false}
                >
                  <Button
                    className={
                      fetching ||
                      processing ||
                      !this.props.form.getFieldValue('fleetCard')
                        ? 'disableUpload'
                        : 'uploadButton'
                    }
                  >
                    <Icon type="upload" />{' '}
                    {formatMessage(strings.message.dragAndDropFileHere)}
                  </Button>
                </Upload>
              </Col>
              <Col style={{ display: 'flex' }}>
                <Form.Item
                  label={formatMessage(strings.phrase.template)}
                  className="templateControl"
                >
                  {getFieldDecorator('template')(this.getAdvancedSelect('template'))}
                </Form.Item>
                <Tooltip
                  title={formatMessage(strings.phrase.configurefiletemplate)}
                  placement="top"
                >
                  <Icon
                    type="setting"
                    onClick={this.onsettingsClick}
                    style={{
                      display:
                        this.props.form.getFieldValue('template') ||
                        this.props.form.getFieldValue('template_name')
                          ? 'flex'
                          : 'none',
                    }}
                    className={
                      fetching || processing
                        ? 'disableIcon'
                        : this.props.form.getFieldValue('template') ||
                          this.props.form.getFieldValue('template_name')
                        ? 'settingsButtonActive'
                        : 'settingsButton'
                    }
                  />
                </Tooltip>
              </Col>
              <Col>
                <div className="btnSaveAndProcess">
                  <Button
                    type="primary"
                    onClick={() => this.onSaveAndProcessClick()}
                    disabled={this.state.errorInfo.length > 0}
                  >
                    {formatMessage(strings.phrase.saveAndProcess)}
                  </Button>
                </div>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '50px',
                }}
              >
                <Icon
                  type="exclamation-circle"
                  onClick={this.onInfoIconClicked}
                  className="infoIcon"
                  style={{ display: enableInfoIcon ? 'flex' : 'none' }}
                />
              </Col>
            </Row>
          </div>
        </Carousel>
        <div>
          <Row>
            <Col>
              {selectedTemplateConfiguration &&
              selectedTemplateConfiguration.latestfileuploaded &&
              selectedTemplateConfiguration.latestfileuploaded !== '' ? (
                <div style={{ marginBottom: 5 }}>
                  <span className="lastuploadedfileinfo">
                    {`${formatMessage(strings.message.latestFileUploaded)} '${
                      selectedTemplateConfiguration.latestfileuploaded
                    }' ${formatMessage(strings.word.on)} ${
                      selectedTemplateConfiguration.latestfileuploadedDatetime
                    }`}
                  </span>
                </div>
              ) : (
                ''
              )}
              <Table
                className="platts-table"
                rowKey="key"
                style={{
                  display: this.state.errorInfo.length === 0 ? 'block' : 'none',
                }}
                components={components}
                dataSource={this.state.rows}
                columns={columns}
                rowClassName={record =>
                  (record.stationMapped === STATIONMAPSTATUS.NOTMAPPED
                    ? 'editable-row text-red'
                    : record.stationMapped === STATIONMAPSTATUS.NEWLYMAPPED
                    ? 'editable-row text-green'
                    : 'editable-row')
                }
                footer={this.plattsTableFooter}
              />
              <span
                className="errorLabel"
                style={{
                  display: this.state.errorInfo.length > 0 ? 'block' : 'none',
                }}
              >
                {formatMessage(strings.message.errorMsg)}
              </span>
              <Table
                className="platts-table"
                rowKey="key"
                style={{
                  display: this.state.errorInfo.length > 0 ? 'block' : 'none',
                }}
                components={components}
                dataSource={this.state.errorInfo}
                columns={errTableColumns}
              />
            </Col>
          </Row>
          <SearchStationModal
            title={
              <RenderedConstant
                type={PLATTS}
                prefix={formatMessage(strings.word.add)}
              />
            }
            close={this.closeMappingStationModal}
            open={mappingStationModalOpen}
            addStations={this.addMappingStation}
          />
          <Modal
            title={formatMessage(strings.phrase.configurefiletemplate)}
            visible={this.state.isConfigModalVisible}
            width={810}
            onCancel={this.handleConfigModelCancel}
            footer={[
              <Popconfirm
                key="templateDeletePopup"
                title={formatMessage(strings.phrase.areYouSureToRemoveTemplateConfig)}
                onConfirm={() => this.onDeleteTemplateClick()}
                okText={formatMessage(strings.word.yes)}
                cancelText={formatMessage(strings.word.cancel)}
                placement="top"
              >
                <Button
                  key="templateDelete"
                  type="danger"
                  style={{
                    display:
                      !this.props.form.getFieldValue('template') ||
                      this.props.form.getFieldValue('template') === ''
                        ? 'none'
                        : '',
                  }}
                >
                  {_.capitalize(formatMessage(strings.word.delete))}
                </Button>
              </Popconfirm>,
              <Button
                key="templateApply"
                type="primary"
                onClick={this.handleConfigModelOk}
              >
                {formatMessage(strings.word.apply)}
              </Button>,
              <Button
                key="templateCancel"
                onClick={this.handleConfigModelCancel}
              >
                {' '}
                {formatMessage(strings.word.cancel)}{' '}
              </Button>,
            ]}
          >
            <Form layout="inline">
              <Row type="flex" justify="space-between">
                <Col>
                  <Form.Item label={formatMessage(strings.phrase.templatename)}>
                    {getFieldDecorator('template_name', {
                      rules: rules.template_name,
                    })(<Input
                      disabled={
                          this.props.form.getFieldValue('template') &&
                          this.props.form.getFieldValue('template') !== ''
                        }
                    />)}
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label={formatMessage(strings.phrase.firstheadercolumn)}
                  >
                    {getFieldDecorator('firstheadercolumn', {
                      rules: rules.firstheadercolumn,
                    })(this.getAdvancedSelect('firstheadercolumn'))}
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col span={24}>
                  <Form.Item label={formatMessage(strings.phrase.stationid)}>
                    {getFieldDecorator('stationCode', {
                      rules: rules.stationCode,
                    })(this.getAdvancedSelect('stationCode'))}
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col>
                  <Form.Item label={formatMessage(strings.phrase.periodStart)}>
                    {getFieldDecorator('periodStart', {
                      rules: !this.state.showDiscount ? rules.periodStart : '',
                    })(this.getAdvancedSelect('periodStart'))}
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label={formatMessage(strings.phrase.periodEnd)}>
                    {getFieldDecorator('periodEnd')(this.getAdvancedSelect('periodEnd'))}
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col>
                  <Form.Item label={formatMessage(strings.phrase.product)}>
                    {getFieldDecorator('product', {
                      rules: !this.state.showDiscount ? rules.product : '',
                    })(this.getAdvancedSelect('product'))}
                  </Form.Item>
                </Col>
                {this.state.selectedBulkUploadOption !== 'discPerStation' && (
                  <Col>
                    <Form.Item label={formatMessage(strings.phrase.price)}>
                      {getFieldDecorator('price', { rules: rules.price })(this.getAdvancedSelect('price'))}
                    </Form.Item>
                  </Col>
                )}
              </Row>
              {this.state.showDiscount && (
                <Row type="flex" justify="space-between">
                  <Col>
                    <Form.Item label={formatMessage(strings.word.discount)}>
                      {getFieldDecorator('discountValue', {
                        rules: rules.discountValue,
                      })(this.getAdvancedSelect('discountValue'))}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label={formatMessage(strings.word.discountType)}>
                      {getFieldDecorator('discountType', {
                        rules: rules.discountType,
                      })(this.getAdvancedSelect('discountType'))}
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row type="flex" justify="space-between">
                <Col>
                  <Form.Item label={formatMessage(strings.phrase.currency)}>
                    {getFieldDecorator('currency', { rules: rules.currency })(this.getAdvancedSelect('currency'))}
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col>
                  <Form.Item label="Latitude">
                    {getFieldDecorator('latitude', { rules: rules.latitude })(this.getAdvancedSelect('latitude'))}
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Longitude">
                    {getFieldDecorator('longitude', { rules: rules.longitude })(this.getAdvancedSelect('longitude'))}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Modal
            visible={this.state.isPlattsEmailModalVisible && plattsEmail !== ''}
            width={750}
            header={null}
            footer={null}
            onCancel={this.handlePlattsEmailModalCancel}
          >
            <Form layout="inline">
              <Row type="flex" justify="space-between">
                <Col>
                  <span className="fontBig">
                    {formatMessage(strings.message.congratulations)}
                  </span>
                  <br />
                  <span style={{ paddingLeft: 30 }}>
                    {formatMessage(strings.message.plattsMappingCompletedMsg)}
                  </span>
                  <br />
                  <span className="emailWithCopy">
                    <label
                      ref="plattsEmailAddress"
                      style={{
                        paddingRight: 10,
                        cursor: 'text',
                        fontSize: '12pt',
                      }}
                    >
                      {plattsEmail}
                    </label>
                    <Tooltip
                      title={formatMessage(strings.phrase.copy)}
                      placement="top"
                    >
                      <Button
                        icon="copy"
                        onClick={() => this.copyCodeToClipboard()}
                      />
                    </Tooltip>
                  </span>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>
      </Spin>
    );
  }
}

BulkUpload.propTypes = {
  intl: intlShape.isRequired,
};

function mapStateToProps(store) {
  return {
    plattsStations: store.bulkUpload && store.bulkUpload.items,
    station: store.bulkUpload && store.bulkUpload.station,
    plattsStationMapped:
      store.bulkUpload && store.bulkUpload.plattsStationMapped,
    processedData: store.bulkUpload && store.bulkUpload.processedData,
    processedDataStatus:
      store.bulkUpload && store.bulkUpload.processedDataStatus,
    processing: store.bulkUpload.processing,
    fetching: store.bulkUpload.fetching,
    editing: store.bulkUpload.processing,
    error: store.bulkUpload.error,
    plattsEmail: store.bulkUpload && store.bulkUpload.plattsEmail,
    cards: store.fleetSettings.items && store.fleetSettings.items.cards,
    fleetSettingsProcessing: store.fleetSettings.processing,
    paymentCards:
      store.globalInformations.paymentCards &&
      store.globalInformations.paymentCards.items,
    paymentCardsLoading:
      store.globalInformations.paymentCards &&
      store.globalInformations.paymentCards.processing,
    saveFileTemplateprocessing: store.bulkUpload.saveFileTemplateprocessing,
    getFileTemplatefetching: store.bulkUpload.getFileTemplatefetching,
    templateConfigurations: store.bulkUpload.templateConfigurations,
    deletingTemplateName: store.bulkUpload.deletingTemplateName,
    deleteProcess: store.bulkUpload.deleteProcess,
    bulkUploadEmail: store.bulkUpload.isBulkUploadEmail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPlattsMappingDetails: (bulkUploadOption, fleetCard, template_name) =>
      dispatch(actions.bulkUploadActions.getPlattsMappingDetails(
        bulkUploadOption,
        fleetCard,
        template_name,
      )),
    getPlattsMappingForExcelData: payloadData =>
      dispatch(actions.bulkUploadActions.getPlattsMappingForExcelData(payloadData)),
    mapPlattsStation: plattsStationMapped =>
      dispatch(actions.bulkUploadActions.mapPlattsStation(plattsStationMapped)),
    addSuccessMessage: msg =>
      dispatch(actions.messages.addSuccessMessage(msg)),
    addErrorMessage: msg => dispatch(actions.messages.addErrorMessage(msg)),
    getPlattsEmail: bulkUploadOption =>
      dispatch(actions.bulkUploadActions.getPlattsEmail(bulkUploadOption)),
    getFleetSettings: () => dispatch(actions.fleetSettings.getFleetSettings()),
    getPaymentCards: () =>
      dispatch(actions.globalInformations.getPaymentCards()),
    getFileTemplateConfig: (bulkUploadAction, fleetCard) =>
      dispatch(actions.bulkUploadActions.getFileTemplateConfig(
        bulkUploadAction,
        fleetCard,
      )),
    saveFileTemplateConfig: templateConfigurations =>
      dispatch(actions.bulkUploadActions.saveFileTemplateConfig(templateConfigurations)),
    deleteFileTemplateConfig: (fleetCard, template_name) =>
      dispatch(actions.bulkUploadActions.deleteFileTemplateConfig(
        fleetCard,
        template_name,
      )),
    getStations: () => dispatch(actions.stations.getStations()),
    setProcessedDataStatus: () =>
      dispatch(actions.bulkUploadActions.setProcessedDataStatus()),
    setEmail: () => dispatch(actions.bulkUploadActions.setBulkUploadEmail()),
  };
}
const WrappedBulkUpload = Form.create({ name: 'bulk_upload' })(BulkUpload);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(WrappedBulkUpload));
