import Amplify from '@aws-amplify/core'

const { REACT_APP_BASE_ROUTE, REACT_APP_IDENTITY_POOL } = process.env

export const configureAuth = () => {
  // Amplify.Logger.LOG_LEVEL = 'DEBUG';
  Amplify.configure({
    Auth: {
      identityPoolId: REACT_APP_IDENTITY_POOL,
      region: 'eu-west-1',
    },
  });
}

export const configureAPI = () => {
  Amplify.configure({
    API: {
      endpoints: [
        {
          name: 'Backend',
          endpoint: `${REACT_APP_BASE_ROUTE}/`,
          region: 'eu-west-1',
          custom_header: async () => {
            const transicsToken = window.localStorage.getItem('transics-token')
            return transicsToken && { 'x-transics-token': transicsToken }
          },
        },
      ],
    },
  })
};
