/* eslint-disable max-len */
import API from '@aws-amplify/api'

import actionTypes from './actionTypes'

const getDiscountsRequest = () => ({ type: actionTypes.GET_DISCOUNTS_REQUEST })
const getDiscountsSuccess = items => ({ type: actionTypes.GET_DISCOUNTS_SUCCESS, items })
const getDiscountsFailure = error => ({ type: actionTypes.GET_DISCOUNTS_FAILURE, error })
const saveDiscountRequest = id => ({ type: actionTypes.SAVE_DISCOUNT_REQUEST, id })
const saveDiscountSuccess = (id, item) => ({ type: actionTypes.SAVE_DISCOUNT_SUCCESS, id, item })
const saveDiscountFailure = (id, error) => ({ type: actionTypes.SAVE_DISCOUNT_FAILURE, id, error })
const deleteDiscountRequest = id => ({ type: actionTypes.DELETE_DISCOUNT_REQUEST, id })
const deleteDiscountSuccess = id => ({ type: actionTypes.DELETE_DISCOUNT_SUCCESS, id })
const deleteDiscountFailure = (id, error) => ({ type: actionTypes.DELETE_DISCOUNT_FAILURE, id, error })

const uuidToid = ({ uuid, ...e }) => ({ ...e, id: uuid })
const idToUuid = ({ id, ...e }) => ({ ...e, uuid: id })

function getDiscounts() {
  return (dispatch) => {
    dispatch(getDiscountsRequest())
    const url = 'discounts/'
    return API.get('Backend', url)
      .then(data => dispatch(getDiscountsSuccess(data.map(uuidToid))))
      .catch(err => dispatch(getDiscountsFailure(err)))
  }
}

function saveDiscount(item) {
  return (dispatch) => {
    dispatch(saveDiscountRequest(item.id))
    const url = `discounts/${item.id}`
    return API.post('Backend', url, { body: idToUuid(item) })
      .then(data => dispatch(saveDiscountSuccess(item.id, uuidToid(data))))
      .catch(err => dispatch(saveDiscountFailure(item.id, err)))
  }
}

function batchSaveDiscount(items) {
  return dispatch => new Promise(async (callback) => {
    for (const discount of items) {
      await dispatch(saveDiscount(discount))
    }
    callback()
  })
}

function deleteDiscount(id) {
  return (dispatch) => {
    dispatch(deleteDiscountRequest(id))
    const url = `discounts/${id}`
    return API.del('Backend', url)
      .then(() => dispatch(deleteDiscountSuccess(id)))
      .catch(err => dispatch(deleteDiscountFailure(id, err)))
  }
}

export default {
  getDiscounts, saveDiscount, batchSaveDiscount, deleteDiscount,
}
