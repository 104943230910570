import actionTypes from '../actions/actionTypes'

export const initialState = {
  items: [],
  error: null,
  fetching: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    // get groups
    case actionTypes.GET_GROUPS_REQUEST:
      return {
        ...state,
        error: null,
        fetching: true,
      }
    case actionTypes.GET_GROUPS_SUCCESS:
      return {
        ...state,
        items: [...action.items],
        fetching: false,
      }
    case actionTypes.GET_GROUPS_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      }
    default:
      return state
  }
}

export default reducer
