

export const customStyle = {
    formButton: {
        margin: 8
    },
    h2: {
        marginBottom: 16, 
        marginTop: 32
    },
    settingsFormElement: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    settingsFormElementTitle: {
        marginRight: "16px",
        verticalAlign: "middle",
        width: "80%"
    }
};

