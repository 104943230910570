import React from 'react';
import { Icon } from 'antd';
import { injectIntl } from 'react-intl';

import * as strings from './defaultStrings';

export const recommendationPlanningTypes = {
  AVAILABLE: 'PLANNED_REFUELING',
  REROUTING: 'REROUTING',
  UNAVAILABLE: 'NO_PLANNING',
};
export const discountTypes = {
  PERCENT: 'percent',
  EURO: 'euro',
  FIXED_PRICE: 'fixedPrice',
};
export const recommendationStatus = {
  CANCELLED: 'CANCELLED',
  CREATED: 'CREATED',
};
export const preferredStationType = {
  HOMEBASE: 'homebase',
  TOAVOID: 'avoid',
  VOLUMEDISCOUNT: 'volumediscount',
  PREFERRED: 'preferred',
  PLATTS: 'platts',
};

export const newReleaseInfo = {
  VERSION: 'V2020.01.01',
};

const strIdMapping = {
  [recommendationPlanningTypes.AVAILABLE]: strings.word.available,
  [recommendationPlanningTypes.REROUTING]: strings.word.rerouting,
  [recommendationPlanningTypes.UNAVAILABLE]: strings.word.unavailable,
  [discountTypes.PERCENT]: strings.phrase.percentDiscount,
  [discountTypes.EURO]: strings.phrase.euroDiscount,
  [discountTypes.FIXED_PRICE]: strings.phrase.euroPerLiterAsFixedPrice,
  [recommendationStatus.CANCELLED]: strings.word.cancelled,
  [recommendationStatus.CREATED]: strings.word.created,
  [preferredStationType.PREFERRED]: strings.phrase.preferredStations,
  [preferredStationType.VOLUMEDISCOUNT]: strings.phrase.stationsWithVolumeDiscount,
  [preferredStationType.HOMEBASE]: strings.phrase.homebaseStations,
  [preferredStationType.TOAVOID]: strings.phrase.stationsToAvoid,
  [preferredStationType.PLATTS]: strings.phrase.bulkUploadStations,
};
export const getStrId = key => strIdMapping[key];

const iconMapping = {
  [recommendationPlanningTypes.AVAILABLE]: <Icon type="carry-out" />,
  [recommendationPlanningTypes.UNAVAILABLE]: <Icon type="stop" />,
  [recommendationPlanningTypes.REROUTING]: <Icon type="pull-request" />,
  [recommendationStatus.CREATED]: <Icon type="check" style={{ color: '#52c41a' }} />,
  [recommendationStatus.CANCELLED]: <Icon type="close" style={{ color: 'tomato' }} />,
  [preferredStationType.PREFERRED]: <Icon type="star" theme="twoTone" twoToneColor="orange" />,
  [preferredStationType.VOLUMEDISCOUNT]: <Icon type="pie-chart" theme="twoTone" twoToneColor="#52c41a" />,
  [preferredStationType.HOMEBASE]: <Icon type="home" theme="twoTone" twoToneColor="dodgerblue" />,
  [preferredStationType.TOAVOID]: <Icon type="stop" theme="twoTone" twoToneColor="red" />,
  [preferredStationType.PLATTS]: <Icon type="euro" theme="twoTone" twoToneColor="#1c4bac" />,
};

export const getIcon = key => iconMapping[key];

export const RenderedConstant = injectIntl((props) => {
  const { formatMessage } = props.intl;
  const {
    type, prefix, suffix, iconAfter, iconBefore = !iconAfter,
  } = props;
  return (
    <span>
      {iconBefore && getIcon(type)}
      {iconBefore && ' '}
      {prefix}
      {prefix && ' '}
      {formatMessage(getStrId(type))}
      {suffix && ' '}
      {suffix}
      {iconAfter && ' '}
      {iconAfter && getIcon(type)}
    </span>
  );
});

export const cleanFleetCardsName = str => str
  .replace('Fleet Card ', '')
  .replace('Petrol Card ', '')
  .replace('Bonus Card ', '')
  .replace('Credit Card ', '')
  .replace('Discount Card ', '')
  .replace('Prepaid Card ', '');
