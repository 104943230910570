import actionTypes from '../actions/actionTypes'

export const initialSettingsState = {
  items: {
    locale: 'en',
    currency: 'EUR',
    tfuOrTime: 'TIME',
    useInternalVehicleId: false,
    useInternalDriverName: false,
  },
  error: null,
  fetching: false,
  processing: false,
}

function userReducer(state = initialSettingsState, action) {
  switch (action.type) {
    // get settings
    case actionTypes.GET_SETTINGS_REQUEST:
      return {
        ...state,
        error: null,
        fetching: true,
      }
    case actionTypes.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          ...action.settings,
        },
        fetching: false,
      }
    case actionTypes.GET_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      }
    // save settings
    case actionTypes.SAVE_SETTINGS_REQUEST:
      return {
        ...state,
        processing: true,
      }
    case actionTypes.SAVE_SETTINGS_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          ...action.settings,
        },
        error: null,
        processing: false,
      }
    case actionTypes.SAVE_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      }
    default:
      return state
  }
}

export default userReducer
