import React, { Component } from 'react';
import DeckGL, { ScatterplotLayer, PathLayer, IconLayer, WebMercatorViewport } from 'deck.gl';
import ReactMapGL, { FlyToInterpolator } from 'react-map-gl';
import * as d3 from 'd3';
import { Row, Col, Button, Tooltip } from 'antd';
import { injectIntl, FormattedNumber } from 'react-intl';

import * as strings from '../../helpers/defaultStrings';
import recommendationIcons from '../../assets/fuelrobot/mockupIconsSprite.png';
import { recommendationStatus } from '../../helpers/constants';
import { MapClock } from '../../helpers';
import moment from 'moment';
import 'moment-timezone';

const timezone = moment.tz.guess();
const { REACT_APP_MAPBOX_ACCESS_TOKEN, REACT_APP_MAPBOX_STYLE } = process.env;
const ICON_MAPPING = {
  recommendation: {
    x: 0, y: 0, width: 90, height: 90, anchorX: 13, anchorY: 90,
  },
  station1: {
    x: 0, y: 100, width: 90, height: 90, anchorX: 45, anchorY: 45,
  },
  station2: {
    x: 100, y: 100, width: 90, height: 90, anchorX: 45, anchorY: 45,
  },
};
const COLORS = {
  stationGreen: [155, 214, 38],
  lightDodgerblue: [153, 204, 255],
  dodgerblue: [58, 144, 224],
  white: [255, 255, 255],
  orangered: [255, 69, 0],
  orangegrey: [237, 217, 211],
  pink: [255, 65, 185],
  pinkhex: '#FF49A5',
};

const defaultViewState = {
  longitude: 4.35,
  latitude: 50.85,
  zoom: 7,
  pitch: 0,
  bearing: 0,
};

class DetailsMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewState: defaultViewState,
      isMapRefReady: false,
      pathData: [],
      planningPathData: [],
      planningVisible: true,
      tooltip: {
        hoveredObject: null,
        x: 0,
        y: 0,
      },
    };
  }

  componentDidMount() {
    const { recommendation, compactEventsHash } = this.props;
    if (recommendation) {
      this.onDataReceived();
      this.preComputePlanningPath();
    }
    if (compactEventsHash) {
      this.preComputePath();
      this.onDataReceived();
    }
  }

  componentDidUpdate(prevProps) {
    const { recommendation, compactEventsHash } = this.props;
    if (recommendation &&
      (!prevProps.recommendation
        || prevProps.recommendation.recommendationid !== recommendation.recommendationid)) {
      this.onDataReceived();
      this.preComputePlanningPath();
    }
    if (compactEventsHash &&
      (!prevProps.compactEventsHash || compactEventsHash !== prevProps.compactEventsHash)) {
      this.preComputePath();
      this.onDataReceived();
    }
  }

  togglePlanningVisible = () => this.setState({ planningVisible: !this.state.planningVisible })

  onViewStateChange = ({ viewState }) => {
    this.setState({ viewState });
  }

  preComputePlanningPath = () => {
    const { recommendation } = this.props;
    if (!recommendation || !recommendation.planning) return;
    const path = [
      [+recommendation.longitude, +recommendation.latitude],
      ...recommendation.planning.map(d => [+d.longitude, +d.latitude]),
    ];
    const planningPathData = [{ path }];
    this.setState({ planningPathData });
  }

  preComputePath = () => {
    const { compactEvents, recommendation } = this.props;
    if (!compactEvents || !recommendation || !recommendation.tfu) return;
    const { tfu } = recommendation;
    const pathBefore = compactEvents.filter(e => e.tfu <= tfu);
    const pathAfter = compactEvents.filter(e => e.tfu >= tfu);
    const pathData = [
      {
        path: pathAfter.map(d => [+d.longitude, +d.latitude]),
        color: COLORS.lightDodgerblue,
      },
      {
        path: pathBefore.map(d => [+d.longitude, +d.latitude]),
        color: COLORS.dodgerblue,
      },
    ];
    this.setState({ pathData });
  }

  onDataReceived = () => {
    const { recommendation, compactEvents } = this.props;
    if (!recommendation) return;
    const data = [
      recommendation,
      recommendation.station2,
      recommendation.station1,
      ...recommendation.planning,
    ].filter(x => !!x);
    if (compactEvents) data.push(...compactEvents);
    this.setState({
      viewState: this.getBoundingViewState(data, true),
    });
  }

  /* when mapRef is received we make the viewState fit "data" */
  onMapRefReceived = (mapRef) => {
    const { isMapRefReady } = this.state;
    const { recommendation } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    if (!isMapRefReady && mapRef && mapRef.getMap().style.lineAtlas.width && mapRef.getMap().style.lineAtlas.height) {
      this.setState({ isMapRefReady: true }, () => {
        if (recommendation) {
          this.onDataReceived();
        }
      });
    }
  }

  onHover = ({ x, y, object }) => {
    this.setState({ tooltip: { x, y, hoveredObject: object } });
  }

  getBoundingViewState = (data, mustFly) => {
    const { viewState } = this.state;
    if (!data || data.length === 0 || !this.mapRef) return viewState;
    // we don't use this.state.isMapRefReady because onMapRefReceived wants to
    const p = 0.001;

    // compute bounds
    const lat = data.map(el => el.latitude);
    const long = data.map(el => el.longitude);
    const c1 = [Math.min(...long), Math.min(...lat)];
    const c2 = [Math.max(...long), Math.max(...lat)];
    const bounds = [c1, c2]; // [[lon, lat], [lon, lat]]

    // prevent errors when coords are the same
    if (bounds[0][0] === bounds[1][0]) {
      bounds[0][0] -= p;
      bounds[1][0] += p;
    }
    if (bounds[0][1] === bounds[1][1]) {
      bounds[0][1] -= p;
      bounds[1][1] += p;
    }

    // create new viewState
    const viewStateWithWidthHeight = {
      ...viewState,
      width: this.mapRef.getMap().style.lineAtlas.width,
      height: this.mapRef.getMap().style.lineAtlas.height,
    };
    const vp = this.mapRef.getMap().style.lineAtlas.height * 0.2;
    const hp = this.mapRef.getMap().style.lineAtlas.width * 0.2;
    const { longitude, latitude, zoom } = new WebMercatorViewport(viewStateWithWidthHeight)
      .fitBounds(bounds, {
        padding: {
          top: vp, bottom: vp, left: hp, right: hp,
        },
      });
    const newViewState = {
      ...viewState,
      longitude,
      latitude,
      zoom,
      transitionDuration: mustFly && 2500,
      transitionInterpolator: mustFly && new FlyToInterpolator(),
      transitionEasing: mustFly && d3.easeCubic,
    };
    if (newViewState.zoom > 17.5) newViewState.zoom = 17.5; // limit zoom
    return newViewState;
  }

  getLegend = () => {
    const { formatMessage } = this.props.intl;
    const legendStyle = {
      backgroundColor: 'white',
      borderRadius: 4,
      boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.1)',
      zIndex: 100,
      margin: 8,
      padding: 8,
    };
    const Dot = ({ rgb }) => (
      <span
        style={{
          height: '1em',
          width: '1em',
          backgroundColor: `rgb(${rgb.join(',')})`,
          borderRadius: '50%',
          display: 'inline-block',
          marginRight: 8,
        }}
      />
    );
    return (
      <Row
        type="flex"
        justify="space-between"
        align="bottom"
        style={{
 position: 'absolute', bottom: 0, left: 0, right: 0,
}}
      >
        <Col style={legendStyle}>
          <h4>{formatMessage(strings.word.legend)}</h4>
          <div>
            <Dot rgb={COLORS.dodgerblue} />
            {formatMessage(strings.description.trajectoryTruckPlanningPoints)}
          </div>
          <div>
            <Dot rgb={COLORS.pink} />
            {formatMessage(strings.description.recommendationRecommendedStation)}
          </div>
        </Col>
      </Row>
    );
  }

  getTooltip = () => {
    const { tooltip: { x, y, hoveredObject } } = this.state;
    const { formatMessage } = this.props.intl;
    if (!hoveredObject) {
      return null;
    }
    const tooltipStyle = {
      position: 'absolute',
      borderRadius: 3,
      padding: '7px 10px',
      fontSize: 12,
      background: 'white',
      boxShadow: '0 2px 4px rgba(0,0,0,0.5)',
      textAlign: 'left',
      zIndex: 1,
      pointerEvents: 'none',
      maxWidth: 300,
    };
    /* eslint-disable no-underscore-dangle */
    const position = {};
    if (this.mapRef && (x > this.mapRef.getMap().style.lineAtlas.width / 2)) {
      position.right = `calc(100% - ${x}px)`;
    } else position.left = x;
    if (this.mapRef && (y > this.mapRef.getMap().style.lineAtlas.height / 2)) {
      position.bottom = `calc(100% - ${y}px)`;
    } else position.top = y;
    /* eslint-enable no-underscore-dangle */

    if (hoveredObject.plannedDate) { // planning
      return (
        <div style={{ ...tooltipStyle, ...position }}>
          <h4>{formatMessage(strings.phrase.planningPoint)}</h4>
          <div>{formatMessage(strings.phrase.orderN)} <strong style={{ color: 'dodgerblue' }}>{hoveredObject.order || formatMessage(strings.word.unknown)}</strong></div>
          <div>{`${formatMessage(strings.phrase.plannedFor)} ${hoveredObject.plannedDate.tz(timezone).format('lll')}`}</div>
          <div>{hoveredObject.finishedDate && `${formatMessage(strings.phrase.completedOn)} ${hoveredObject.finishedDate.tz(timezone).format('lll')}`}</div>
        </div>
      );
    } else if (hoveredObject.recommendationid) { // recommendation
      return (
        <div style={{ ...tooltipStyle, ...position }}>
          <h4>{formatMessage(strings.word.recommendation)}</h4>
          <div>{formatMessage(strings.phrase.receivedOn)} <strong style={{ color: COLORS.pinkhex }}>{hoveredObject.date.tz(timezone).format('lll')}</strong></div>
          <div>{formatMessage(strings.word.tfu)}: <FormattedNumber value={hoveredObject.tfu} maximumFractionDigits={1} /></div>
          <div>{formatMessage(strings.phrase.fuelLevel)}: {hoveredObject.fuelPercentage} %</div>
          {hoveredObject.status === recommendationStatus.CANCELLED && <div>({formatMessage(strings.phrase.thisRecommendationWasCancelled)})</div>}
        </div>
      );
    }
    return ( // else: station
      <div style={{ ...tooltipStyle, ...position }}>
        <h4>{formatMessage(strings.phrase.recommendedStation)}</h4>
        {hoveredObject.name}
      </div>
    );
  }

  getControlButtons = () => {
    const { formatMessage } = this.props.intl;
    const { planningVisible } = this.state;
    return (
      <div style={{
 position: 'absolute', top: 16, right: 16, zIndex: 1,
}}
      >
        <Tooltip
          placement="left"
          title={planningVisible
            ? formatMessage(strings.phrase.hidePlanning)
            : formatMessage(strings.phrase.showPlanning)}
        >
          <Button
            shape="circle"
            size="small"
            icon={planningVisible ? 'eye' : 'eye-invisible'}
            onClick={this.togglePlanningVisible}
            style={{ display: 'block', marginBottom: 8 }}
          />
        </Tooltip>
        <Tooltip
          placement="left"
          title={formatMessage(strings.phrase.resetView)}
        >
          <Button
            shape="circle"
            size="small"
            icon="reload"
            onClick={this.onDataReceived}
          />
        </Tooltip>
      </div>
    );
  }

  render() {
    const {
      viewState, pathData, planningPathData, planningVisible,
    } = this.state;
    const { recommendation, truckPosition } = this.props;
    if (!recommendation) return null;

    const trackLayer = new PathLayer({
      id: 'compact-events',
      data: pathData,
      rounded: true,
      widthMinPixels: 4,
      widthMaxPixels: 4,
      getColor: d => d.color,
    });
    const planningPointLayer = new ScatterplotLayer({ // grey with white border
      id: 'planning-points',
      data: recommendation.planning,
      opacity: 1,
      stroked: true,
      filled: true,
      getPosition: d => [+d.longitude, +d.latitude],
      pickable: true,
      onHover: this.onHover,
      radiusMinPixels: 6,
      radiusMaxPixels: 6,
      lineWidthMinPixels: 2,
      getFillColor: COLORS.dodgerblue,
      getLineColor: COLORS.white,
      visible: planningVisible,
    });
    const planningPathLayer = new PathLayer({ // grey with white border
      id: 'planning-path',
      data: planningPathData,
      getDashArray: [2, 2],
      rounded: true,
      widthMinPixels: 4,
      widthMaxPixels: 4,
      getColor: COLORS.dodgerblue,
      visible: planningVisible,
    });
    const iconsData = [
      { ...recommendation, icon: 'recommendation' },
      recommendation.station2 && { ...recommendation.station2, icon: 'station2' },
      recommendation.station1 && { ...recommendation.station1, icon: 'station1' },
    ].filter(x => !!x);
    const iconLayer = new IconLayer({
      id: 'icons',
      data: iconsData,
      pickable: true,
      onHover: this.onHover,
      iconAtlas: recommendationIcons,
      iconMapping: ICON_MAPPING,
      getIcon: d => d.icon,
      getSize: 48,
      opacity: 1,
      getPosition: d => [+d.longitude, +d.latitude],
    });
    const truckPointLayer = new ScatterplotLayer({ // grey with white border
      id: 'truck-point',
      data: truckPosition ? [truckPosition] : [],
      opacity: 1,
      stroked: true,
      filled: true,
      getPosition: d => [+d.longitude, +d.latitude],
      radiusMinPixels: 8,
      radiusMaxPixels: 8,
      lineWidthMinPixels: 3,
      getFillColor: COLORS.white,
      getLineColor: COLORS.dodgerblue,
    });

    // return JSX
    return (
      <div>
        <DeckGL
          viewState={viewState}
          onViewStateChange={this.onViewStateChange}
          layers={[
            trackLayer,
            iconLayer,
            planningPathLayer,
            planningPointLayer,
            truckPointLayer,
          ]}
          controller
        >
          <ReactMapGL
            reuseMaps
            mapboxApiAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
            preventStyleDiffing
            mapStyle={REACT_APP_MAPBOX_STYLE}
            ref={(map) => { this.mapRef = map; this.onMapRefReceived(map); }}
          >
            {this.getControlButtons()}
            {truckPosition && <MapClock time={new Date(+truckPosition.date)} />}
            {this.getLegend()}
            {this.getTooltip()}
          </ReactMapGL>
        </DeckGL>
      </div>
    );
  }
}

export default injectIntl(DetailsMap);
