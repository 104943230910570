import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from './auth'
import settings from './settings'
import fleetSettings from './fleetSettings'
import recommendations from './recommendations'
import recommendationSettings from './recommendationSettings'
import compactEvents from './compactEvents'
import stations from './stations'
import discounts from './discounts'
import globalInformations from './globalInformations'
import bulkUpload from './bulkUpload'
import groups from './groups'
import performanceIndicators from './performanceIndicators'

export default history => combineReducers({
  router: connectRouter(history),
  auth,
  settings,
  fleetSettings,
  recommendations,
  recommendationSettings,
  compactEvents,
  stations,
  discounts,
  globalInformations,
  bulkUpload,
  groups,
  performanceIndicators,
})
