import React from "react";
import { connect } from 'react-redux';
import { Spin, Form, Button, Icon, Switch, Table, Checkbox, Input } from 'antd';
import { injectIntl, intlShape } from 'react-intl';
import * as strings from '../../helpers/defaultStrings';
import actions from '../../actions';
import './Settings.css';
import {customStyle} from "./Settings.style";

class Settings extends React.Component {

  state = {
    selectedRowKeys: [],
    trucksListState: [],
    isItemsTouched: false,
    truckTableDisplayState: "none",
    activateAppRecommendationsCheckedState: undefined,
    messagesToDriversDisabledState: true,
    activateMessagesToDriversCheckedState: undefined,
    searchText: "",
  }

  componentDidMount() {
    const { getTruckList } = this.props;
    getTruckList();
    this.setState({ isItemsTouched: false })
  }
  
  componentDidUpdate() {
    const { trucks_list, activateAppRecommendations, activateMessagesToDrivers } = this.props;
    const { activateAppRecommendationsCheckedState, activateMessagesToDriversCheckedState, trucksListState } = this.state;

    if(activateAppRecommendations !== undefined && activateAppRecommendationsCheckedState === undefined){
      this.setState({
        activateAppRecommendationsCheckedState : activateAppRecommendations
      })

      if(activateAppRecommendations === true){
        this.setState({ truckTableDisplayState: "block", messagesToDriversDisabledState: false })
      }
      else{
        this.setState({ truckTableDisplayState: "none", messagesToDriversDisabledState: true })
      }
    }

    if(activateMessagesToDrivers !== undefined && activateMessagesToDriversCheckedState === undefined){
      this.setState({
        activateMessagesToDriversCheckedState : activateMessagesToDrivers
      })
    }

    if(trucks_list && trucks_list !== trucksListState){
      this.setState({
        trucksListState: trucks_list,
        selectedRowKeys: trucks_list.filter(item => item.chosen).map(item => item.platenumber)
      })
    }
  }

  handleSubmit = (e) => {
    const { saveFleetSettings, trucks_list, insertAndDeleteTrucks } = this.props;
    const { selectedRowKeys, activateAppRecommendationsCheckedState, activateMessagesToDriversCheckedState } = this.state;

    e.preventDefault();
    saveFleetSettings({
      activateAppRecommendations: activateAppRecommendationsCheckedState,
      activateMessagesToDrivers: activateMessagesToDriversCheckedState
    })
    
    const req_list = [];
    trucks_list.forEach((item) => {
      if(selectedRowKeys.includes(item.platenumber))
      req_list.push(item.truckId);
    });
    insertAndDeleteTrucks(req_list);
    this.setState({ isItemsTouched: false });
  };

  handleCancel = (e) => {
    e.preventDefault()
    const { activateAppRecommendations, activateMessagesToDrivers } = this.props;
    
    this.setState({
      activateAppRecommendationsCheckedState: activateAppRecommendations,
      activateMessagesToDriversCheckedState: activateAppRecommendations ? activateMessagesToDrivers : false,
      truckTableDisplayState: activateAppRecommendations ? "block" : "none",
      messagesToDriversDisabledState: activateAppRecommendations ? false : true
    })

    if(activateAppRecommendations)
      this.handleAppRecommendationsOnChange(activateAppRecommendations)

    this.componentDidMount()
  }; 

  handleAppRecommendationsOnChange = (selected) => {
    if(selected) {
       this.setState({
        activateAppRecommendationsCheckedState: selected,
        messagesToDriversDisabledState: !selected,
        truckTableDisplayState: "block",
        isItemsTouched: true,
      })
    }
    else{
      this.setState({
        activateAppRecommendationsCheckedState: selected,
        messagesToDriversDisabledState: !selected,
        truckTableDisplayState: "none",
        activateMessagesToDriversCheckedState: false,
        isItemsTouched: true,
      })
    }
  };

  handleMessagesToDriversOnChange = (selected) => {
    this.setState({
      activateMessagesToDriversCheckedState: selected,
      isItemsTouched: true
    });
  };

  handleSelect = (record, selected) => {
    if (selected) {
      this.setState({ 
        selectedRowKeys: this.state.selectedRowKeys.concat(record.platenumber),
        isItemsTouched: true,
      });
    } else {
      var array = [...this.state.selectedRowKeys];
      var index = array.indexOf(record.platenumber)
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({
          selectedRowKeys: array,
          isItemsTouched: true,
        });
      }
    }
  };

  toggleSelectAll = () => {
    const { trucks_list } = this.props;
    const { selectedRowKeys } = this.state;
    this.setState({ 
      selectedRowKeys: selectedRowKeys.length === trucks_list.length ? [] : trucks_list.map((r) => r.platenumber),
      isItemsTouched: true,
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: () => (
      <div style={{ display: "none" }}/>
    ),
    filterIcon: filtered => (
      <div style={{ padding: 10, width: "fit-content", backgroundColor: "#fafafa"}}>
        <Input.Search
          placeholder={`Search plate number`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) => {
            this.setState({searchText: e.target.value})
          }}
          allowClear
        />
      </div>
    ),
    onFilter: (value, record) => {
      return String(record.platenumber).toLowerCase().includes(value.toLowerCase())
    },
  });
  
  render() {
    const { fetching, editing, intl, trucks_list, trucks_listLoading } = this.props;
    const { formatMessage } = intl;
    const { selectedRowKeys, truckTableDisplayState, searchText, isItemsTouched,
      activateAppRecommendationsCheckedState, activateMessagesToDriversCheckedState, messagesToDriversDisabledState
    } = this.state;
    
    const headerCheckbox = (
      <Checkbox
        checked={selectedRowKeys.length}
        indeterminate={
          selectedRowKeys.length > 0 && selectedRowKeys.length < trucks_list.length
        }
        onChange={this.toggleSelectAll}
      />
    );

    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      onSelect: this.handleSelect,
      columnTitle: headerCheckbox,
    };

    return (
      <Spin spinning={fetching || editing}>
        <Form onSubmit={this.handleSubmit} layout="vertical">
          <h2 style={customStyle.h2}>
            <Icon type="bulb" style={{ marginRight: 8 }} />
            {formatMessage(strings.phrase.recommendationSettings)}
          </h2>
          <Form.Item>
            <div style={customStyle.settingsFormElement}>
              <p style={customStyle.settingsFormElementTitle}>
                { formatMessage(strings.phrase.activateAppRecommendations) }
              </p>
                <Switch
                  checked = { activateAppRecommendationsCheckedState }
                  onChange={ selected => this.handleAppRecommendationsOnChange(selected) }
                />
            </div>
          </Form.Item>
          <Form.Item>
            <div style={customStyle.settingsFormElement}>
              <p style={customStyle.settingsFormElementTitle}>
                {formatMessage(strings.phrase.activateMessagesToDrivers)}
              </p>
                <Switch
                  checked = { activateMessagesToDriversCheckedState }
                  disabled = { messagesToDriversDisabledState }
                  onChange={ selected => this.handleMessagesToDriversOnChange(selected) }
                />
            </div>
          </Form.Item>

          <Form.Item>
            <Table
              style={{ display: truckTableDisplayState }}
              className="trucks-table"
              rowKey="platenumber"
              dataSource={ trucks_list }
              columns={[
                {
                  title: formatMessage(strings.phrase.truck),
                  dataIndex: 'platenumber',
                  filteredValue: [searchText],
                  ...this.getColumnSearchProps('platenumber'),
                }
              ]}
              loading={ trucks_listLoading }
              rowSelection={ rowSelection }
              pagination={{
                pageSize: 10,
                showTotal: (total, range) => formatMessage(strings.phrase.valueOfTotalItems, { value: `${range[0]}-${range[1]}`, total }),
              }}
              scroll={{ y: 270 }}
            />
          </Form.Item>

        </Form>
        <div>
          <Button
            type="primary"
            style={customStyle.formButton}
            onClick={this.handleSubmit}
            disabled={ !isItemsTouched }
          >
            {formatMessage(strings.word.save)}
          </Button>
          <Button 
            style={customStyle.formButton}
            onClick={this.handleCancel}
            disabled={ !isItemsTouched }
          >
            {formatMessage(strings.word.cancel)}
          </Button>
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(store) {
  return {
    activateAppRecommendations: store.fleetSettings.items && store.fleetSettings.items.activate_app_recommendations,
    activateMessagesToDrivers: store.fleetSettings.items && store.fleetSettings.items.activate_messages_to_drivers,
    fetching: store.fleetSettings.fetching,
    editing: store.fleetSettings.processing,
    trucks_list: store.recommendationSettings.truckList,
    trucks_listLoading: store.recommendationSettings.processing,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFleetSettings: () => dispatch(actions.fleetSettings.getFleetSettings()),
    saveFleetSettings: fleetSettings =>
      fleetSettings &&
      dispatch(actions.fleetSettings.saveFleetSettings({
        activate_app_recommendations: fleetSettings.activateAppRecommendations,
        activate_messages_to_drivers: fleetSettings.activateMessagesToDrivers,
      })),
    getTruckList: () => dispatch(actions.recommendationSettings.getTruckList()),
    insertAndDeleteTrucks: truckList =>
    truckList && 
      dispatch(actions.recommendationSettings.insertAndDeleteTrucks(truckList)),
  };
}

const SettingsForm = Form.create({
  mapPropsToFields: ({
    activateAppRecommendations,
    activateMessagesToDrivers,
  }) => ({
    activateAppRecommendations: Form.createFormField({ value: activateAppRecommendations }),
    activateMessagesToDrivers: Form.createFormField({ value: activateMessagesToDrivers }),
  }),
})(Settings);

SettingsForm.propTypes = {
  intl: intlShape.isRequired,
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsForm));
