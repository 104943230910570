import React from 'react';
import { Tabs, Icon, Row, Col } from 'antd';
import { injectIntl, intlShape } from 'react-intl';
import { goBack } from 'connected-react-router';
import { connect } from 'react-redux';

import * as strings from '../../helpers/defaultStrings';
import Discounts from './Discounts';
import PreferredStations from './PreferredStationsSection/PreferredStations';
import FleetCards from './FleetCards/FleetCards';
import UserSettings from './UserSettings';
import BulkUpload from './BulkUpload';
import ExciseRefunds from './ExciseRefunds';
import { RiRefund2Line } from 'react-icons/ri';

const { TabPane } = Tabs;

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { close } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className="settings-container">
        <Row type="flex" justify="center" align="top">
          <Col span={24} className="settings-close-button-container">
            <Icon type="close" onClick={close} className="settings-close-button" />
          </Col>
          <Col span={22}>
            <Tabs
              defaultActiveKey="fleetcards"
              tabBarStyle={{ textAlign: 'center', marginBottom: 32 }}
              destroyInactiveTabPane
            >
              <TabPane
                key="fleetcards"
                tab={(
                  <span style={{ textTransform: 'capitalize' }}>
                    <Icon type="credit-card" style={{ marginRight: 8 }} />
                    {formatMessage(strings.phrase.fleetCards)}
                  </span>
                )}
              >
                <FleetCards />
              </TabPane>
              <TabPane
                key="discounts"
                tab={(
                  <span style={{ textTransform: 'capitalize' }}>
                    <Icon type="reconciliation" style={{ marginRight: 8 }} />
                    {formatMessage(strings.word.discounts)}
                  </span>
                )}
              >
                <Discounts />
              </TabPane>
              <TabPane
                key="bulkUpload"
                tab={(
                  <span>
                    <Icon type="upload" style={{ marginRight: 8 }} />
                    {formatMessage(strings.phrase.bulkUpload)}
                  </span>
                )}
              >
                <BulkUpload close={close} />
              </TabPane>
              <TabPane
                key="preferredstations"
                tab={(
                  <span style={{ textTransform: 'capitalize' }}>
                    <Icon type="star" style={{ marginRight: 8 }} />
                    {formatMessage(strings.phrase.preferredStations)}
                  </span>
                )}
              >
                <PreferredStations />
              </TabPane>
              <TabPane
                key="exciserefunds"
                tab={(
                  <span>
                    <RiRefund2Line className="tabIcons" style={{ marginRight: 8 }} />
                    {formatMessage(strings.phrase.exciseRefunds)}
                  </span>
                )}
              >
                <ExciseRefunds close={close} />
              </TabPane>
              <TabPane
                key="usersettings"
                tab={(
                  <span>
                    <Icon type="setting" style={{ marginRight: 8 }} />
                    {formatMessage(strings.phrase.userSettings)}
                  </span>
                )}
              >
                <UserSettings close={close} />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}

Settings.propTypes = {
  intl: intlShape.isRequired,
};

function mapStateToProps(store) {
  return {
    user: store.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => dispatch(goBack()),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Settings));

