export default {
  USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  USER_LOGOUT_REQUEST: 'USER_LOGOUT_REQUEST',
  USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  USER_LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',

  ADD_MESSAGE: 'ADD_MESSAGE',

  SAVE_SETTINGS_REQUEST: 'SAVE_SETTINGS_REQUEST',
  SAVE_SETTINGS_SUCCESS: 'SAVE_SETTINGS_SUCCESS',
  SAVE_SETTINGS_FAILURE: 'SAVE_SETTINGS_FAILURE',
  GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',

  GET_FLEET_SETTINGS_REQUEST: 'GET_FLEET_SETTINGS_REQUEST',
  GET_FLEET_SETTINGS_SUCCESS: 'GET_FLEET_SETTINGS_SUCCESS',
  GET_FLEET_SETTINGS_FAILURE: 'GET_FLEET_SETTINGS_FAILURE',
  SAVE_FLEET_SETTINGS_REQUEST: 'SAVE_FLEET_SETTINGS_REQUEST',
  SAVE_FLEET_SETTINGS_SUCCESS: 'SAVE_FLEET_SETTINGS_SUCCESS',
  SAVE_FLEET_SETTINGS_FAILURE: 'SAVE_FLEET_SETTINGS_FAILURE',

  GET_DISCOUNTS_REQUEST: 'GET_DISCOUNTS_REQUEST',
  GET_DISCOUNTS_SUCCESS: 'GET_DISCOUNTS_SUCCESS',
  GET_DISCOUNTS_FAILURE: 'GET_DISCOUNTS_FAILURE',
  SAVE_DISCOUNT_REQUEST: 'SAVE_DISCOUNT_REQUEST',
  SAVE_DISCOUNT_SUCCESS: 'SAVE_DISCOUNT_SUCCESS',
  SAVE_DISCOUNT_FAILURE: 'SAVE_DISCOUNT_FAILURE',
  DELETE_DISCOUNT_REQUEST: 'DELETE_DISCOUNT_REQUEST',
  DELETE_DISCOUNT_SUCCESS: 'DELETE_DISCOUNT_SUCCESS',
  DELETE_DISCOUNT_FAILURE: 'DELETE_DISCOUNT_FAILURE',

  GET_STATIONS_REQUEST: 'GET_STATIONS_REQUEST',
  GET_STATIONS_SUCCESS: 'GET_STATIONS_SUCCESS',
  GET_STATIONS_FAILURE: 'GET_STATIONS_FAILURE',
  ADD_STATIONS_REQUEST: 'ADD_STATIONS_REQUEST',
  ADD_STATIONS_SUCCESS: 'ADD_STATIONS_SUCCESS',
  ADD_STATIONS_FAILURE: 'ADD_STATIONS_FAILURE',
  DELETE_STATION_REQUEST: 'DELETE_STATION_REQUEST',
  DELETE_STATION_SUCCESS: 'DELETE_STATION_SUCCESS',
  DELETE_STATION_FAILURE: 'DELETE_STATION_FAILURE',
  SEARCH_STATIONS_REQUEST: 'SEARCH_STATIONS_REQUEST',
  SEARCH_STATIONS_SUCCESS: 'SEARCH_STATIONS_SUCCESS',
  SEARCH_STATIONS_FAILURE: 'SEARCH_STATIONS_FAILURE',
  SEARCH_STATIONS_RESET: 'SEARCH_STATIONS_RESET',

  GET_RECOMMENDATION_LIST_REQUEST: 'GET_RECOMMENDATION_LIST_REQUEST',
  GET_RECOMMENDATION_LIST_SUCCESS: 'GET_RECOMMENDATION_LIST_SUCCESS',
  GET_RECOMMENDATION_LIST_FAILURE: 'GET_RECOMMENDATION_LIST_FAILURE',

  GET_DETAILED_RECO_REQUEST: 'GET_DETAILED_RECO_REQUEST',
  GET_DETAILED_RECO_SUCCESS: 'GET_DETAILED_RECO_SUCCESS',
  GET_DETAILED_RECO_FAILURE: 'GET_DETAILED_RECO_FAILURE',

  GET_TRUCK_LIST_REQUEST: 'GET_TRUCK_LIST_REQUEST',
  GET_TRUCK_LIST_SUCCESS: 'GET_TRUCK_LIST_SUCCESS',
  GET_TRUCK_LIST_FAILURE: 'GET_TRUCK_LIST_FAILURE',
  INSERT_AND_DELETE_REQUEST: 'INSERT_AND_DELETE_REQUEST',
  INSERT_AND_DELETE_SUCCESS: 'INSERT_AND_DELETE_SUCCESS',
  INSERT_AND_DELETE_FAILURE: 'INSERT_AND_DELETE_FAILURE',

  GET_COMPACT_EVENTS_REQUEST: 'GET_COMPACT_EVENTS_REQUEST',
  GET_COMPACT_EVENTS_SUCCESS: 'GET_COMPACT_EVENTS_SUCCESS',
  GET_COMPACT_EVENTS_FAILURE: 'GET_COMPACT_EVENTS_FAILURE',

  GET_GLOBAL_INFORMATION_REQUEST: 'GET_GLOBAL_INFORMATION_REQUEST',
  GET_GLOBAL_INFORMATION_SUCCESS: 'GET_GLOBAL_INFORMATION_SUCCESS',
  GET_GLOBAL_INFORMATION_FAILURE: 'GET_GLOBAL_INFORMATION_FAILURE',

  GET_PLATTSSTATIONMAPPING_REQUEST: 'GET_PLATTSSTATIONMAPPING_REQUEST',
  GET_PLATTSSTATIONMAPPING_SUCCESS: 'GET_PLATTSSTATIONMAPPING_SUCCESS',
  GET_PLATTSSTATIONMAPPING_FAILURE: 'GET_PLATTSSTATIONMAPPING_FAILURE',
  MAP_PLATTSSTATION_REQUEST: 'MAP_PLATTSSTATION_REQUEST',
  MAP_PLATTSSTATION_SUCCESS: 'MAP_PLATTSSTATION_SUCCESS',
  MAP_PLATTSSTATION_FAILURE: 'MAP_PLATTSSTATION_FAILURE',
  GET_PLATTS_MAPPING_FOR_EXCEL_DATA_REQUEST: 'GET_PLATTS_MAPPING_FOR_EXCEL_DATA_REQUEST',
  GET_PLATTS_MAPPING_FOR_EXCEL_DATA_SUCCESS: 'GET_PLATTS_MAPPING_FOR_EXCEL_DATA_SUCCESS',
  GET_PLATTS_MAPPING_FOR_EXCEL_DATA_FAILURE: 'GET_PLATTS_MAPPING_FOR_EXCEL_DATA_FAILURE',
  SET_PLATTS_PROCESSED_DATA_STATUS_FOR_EXCEL_DATA: 'SET_PLATTS_PROCESSED_DATA_STATUS_FOR_EXCEL_DATA',
  GET_PLATTSEMAIL_REQUEST: 'GET_PLATTSEMAIL_REQUEST',
  GET_PLATTSEMAIL_SUCCESS: 'GET_PLATTSEMAIL_SUCCESS',
  GET_PLATTSEMAIL_FAILURE: 'GET_PLATTSEMAIL_FAILURE',
  SET_BULKUPLOADEMAIL_REQUEST: 'SET_BULKUPLOADEMAIL_REQUEST',
  SET_BULKUPLOADEMAIL_SUCCESS: 'SET_BULKUPLOADEMAIL_SUCCESS',
  SET_BULKUPLOADEMAIL_FAILURE: 'SET_BULKUPLOADEMAIL_FAILURE',

  GET_FILETEMPLATECONFIG_REQUEST: 'GET_FILETEMPLATECONFIG_REQUEST',
  GET_FILETEMPLATECONFIG_SUCCESS: 'GET_FILETEMPLATECONFIG_SUCCESS',
  GET_FILETEMPLATECONFIG_FAILURE: 'GET_FILETEMPLATECONFIG_FAILURE',
  SAVE_FILETEMPLATECONFIG_REQUEST: 'SAVE_FILETEMPLATECONFIG_REQUEST',
  SAVE_FILETEMPLATECONFIG_SUCCESS: 'SAVE_FILETEMPLATECONFIG_SUCCESS',
  SAVE_FILETEMPLATECONFIG_FAILURE: 'SAVE_FILETEMPLATECONFIG_FAILURE',
  DELETE_FILETEMPLATECONFIG_REQUEST: 'DELETE_FILETEMPLATECONFIG_REQUEST',
  DELETE_FILETEMPLATECONFIG_SUCCESS: 'DELETE_FILETEMPLATECONFIG_SUCCESS',
  DELETE_FILETEMPLATECONFIG_FAILURE: 'DELETE_FILETEMPLATECONFIG_FAILURE',

  GET_GROUPS_REQUEST: 'GET_GROUPS_REQUEST',
  GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
  GET_GROUPS_FAILURE: 'GET_GROUPS_FAILURE',

  GET_KPIS_REQUEST: 'GET_KPIS_REQUEST',
  GET_KPIS_SUCCESS: 'GET_KPIS_SUCCESS',
  GET_KPIS_FAILURE: 'GET_KPIS_FAILURE',
};
