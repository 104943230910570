import { Card, Col, Row, Table, Typography } from 'antd';
import { mean } from 'lodash';
import React from 'react';
import EuropeMap from '../../charts/EuropeMap/EuropeMapComponent';
import PieChart from '../../charts/PieChart';
import * as strings from '../../helpers/defaultStrings';
import { cleanFleetCardsName } from '../../helpers/constants';

const overflowStyle = { textOverflow: 'ellipsis', overflow: 'hidden' };

const PerformanceIndicatorsRightPanel = ({
  formatMessage,
  stationTableData,
  countryData,
  firstRecoCards,
  secondRecoCards,
  locale,
  paymentCards,
}) => {
  const cleanCardNames = data => data.map(d => ({ ...d, title: cleanFleetCardsName(paymentCards[d.title]) }));

  const stationsColumns = [
    {
      title: formatMessage(strings.phrase.topStations),
      key: 'stationId',
      align: 'left',
      render: (_, record) => (<div style={overflowStyle}>{record.station}</div>),
      ellipsis: true,
    },
    {
      title: formatMessage(strings.word.locality),
      key: 'locality',
      align: 'left',
      render: (_, record) => (<div style={overflowStyle}>{`${record.locality} ${record.country}`}</div>),
      ellipsis: true,
    },
    {
      title: `${formatMessage(strings.phrase.averagePrice)} (€)`,
      key: 'avgPrice',
      align: 'center',
      render: (_, record) => (<div>{new Intl.NumberFormat(locale, { minimumFractionDigits: 3, maximumFractionDigits: 3 }).format(mean(record.prices))}</div>),
      sorter: (a, b) => mean(a.prices) - mean(b.prices),
    },
    {
      title: formatMessage(strings.phrase.firstRecommendation),
      key: 'firstRecos',
      align: 'center',
      defaultSortOrder: 'descend',
      render: (_, record) => (<div>{record.firstReco}</div>),
      sorter: (a, b) => a.firstReco - b.firstReco,
    },
    {
      title: formatMessage(strings.phrase.secondRecommendation),
      key: 'secondRecos',
      align: 'center',
      render: (_, record) => (<div>{record.secondReco}</div>),
      sorter: (a, b) => a.secondReco - b.secondReco,
    },
  ];
  return (
    <Col span={12} style={{ padding: 16, height: '100%', overflowY: 'auto' }}>
      <Row>
        <Typography.Title level={4} style={{ textAlign: 'center' }}>{formatMessage(strings.phrase.recommendationsMap)}</Typography.Title>
        <EuropeMap
          data={countryData.data}
          countryCodeAccessor="country"
          countryCodeType="iso_a2"
          valueAccessor="totalReco"
          loading={false}
          height={400}
        />
      </Row>
      <Row>
        <Table
          dataSource={stationTableData.data}
          columns={stationsColumns}
          showHeader
          size="small"
          rowKey={r => r.id}
          pagination={{ pageSize: 5, simple: true }}
        />
      </Row>
      <Row>
        <Card >
          <Card.Grid hoverable={false} style={{ width: '50%', display: 'inline-block' }}>
            <Typography.Title level={4} style={{ textAlign: 'center' }}>{formatMessage(strings.phrase.firstSuppliers)}</Typography.Title>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <PieChart
                data={cleanCardNames(firstRecoCards)}
                width={400}
                height={200}
                innerRadius={50}
                outerRadius={100}
                locale={locale}
              />
            </div>
          </Card.Grid>
          <Card.Grid hoverable={false} style={{ width: '50%', display: 'inline-block' }}>
            <Typography.Title level={4} style={{ textAlign: 'center' }}>{formatMessage(strings.phrase.secondSuppliers)}</Typography.Title>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <PieChart
                data={cleanCardNames(secondRecoCards)}
                width={400}
                height={200}
                innerRadius={50}
                outerRadius={100}
                locale={locale}
              />
            </div>
          </Card.Grid>
        </Card>
      </Row>
    </Col>
  );
};


export default PerformanceIndicatorsRightPanel;
