import React, { Component } from 'react'
import ReactMapGL from 'react-map-gl'
import DeckGL, { ScatterplotLayer } from 'deck.gl'

const { REACT_APP_MAPBOX_ACCESS_TOKEN, REACT_APP_MAPBOX_STYLE } = process.env

const defaultViewState = {
  longitude: 4.35,
  latitude: 50.85,
  zoom: 7,
  pitch: 0,
  bearing: 0,
}


class CoordSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewState: defaultViewState,
    }
  }

  onViewStateChange = ({ viewState }) => {
    this.props.onChange({
      longitude: viewState.longitude,
      latitude: viewState.latitude,
    })
    this.setState({ viewState })
  }


  render() {
    const { viewState } = this.state
    const { value } = this.props

    const hasValue = value && (value.longitude || value.latitude)

    const pointLayer = new ScatterplotLayer({ // grey with white border
      id: 'selected-point-layer',
      data: hasValue ? [value] : [],
      opacity: 1,
      stroked: true,
      getPosition: d => [+d.longitude, +d.latitude],
      radiusMinPixels: 7,
      radiusMaxPixels: 7,
      lineWidthMinPixels: 2,
      getFillColor: [30, 144, 255],
      getLineColor: [255, 255, 255],
    })
    const centeredViewState = hasValue ? { ...viewState, ...value } : viewState

    // return JSX
    return (
      <DeckGL
        viewState={centeredViewState}
        onViewStateChange={this.onViewStateChange}
        layers={[pointLayer]}
        controller
        style={{ height: 300, width: '100%' }}
      >
        <ReactMapGL
          reuseMaps
          mapboxApiAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
          preventStyleDiffing
          mapStyle={REACT_APP_MAPBOX_STYLE}
          ref={(map) => { this.mapRef = map }}
        />
      </DeckGL>
    )
  }
}

export default CoordSelect;
