import API from '@aws-amplify/api'

import actionTypes from './actionTypes'


const getGlobalInformationRequest = key => ({ type: actionTypes.GET_GLOBAL_INFORMATION_REQUEST, key })
const getGlobalInformationSuccess = (key, items) => ({ type: actionTypes.GET_GLOBAL_INFORMATION_SUCCESS, key, items })
const getGlobalInformationFailure = (key, error) => ({ type: actionTypes.GET_GLOBAL_INFORMATION_FAILURE, key, error })

const pathVariables = {
  otherServices: 'opisnavx/other_services',
  paymentCards: 'opisnavx/payment_services',
  exciseRefunds: 'fde/excise_refunds',
}

function getGlobalInformation(key) {
  return (dispatch) => {
    if (!pathVariables[key]) return;
    const url = `external/${pathVariables[key]}`
    dispatch(getGlobalInformationRequest(key))
    return API.get('Backend', url)
      .then(data => dispatch(getGlobalInformationSuccess(key, data || {})))
      .catch(err => dispatch(getGlobalInformationFailure(key, err)))
  }
}

function getPaymentCards() {
  return getGlobalInformation('paymentCards')
}

function getExciseRefunds() {
  return getGlobalInformation('exciseRefunds')
}


export default {
  getPaymentCards, getExciseRefunds,
}
