import actionTypes from '../actions/actionTypes'

export const initialState = {
  items: [],
  processing: false,
  deletingId: null,
  editingId: null,
  error: null,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    // fetch
    case actionTypes.GET_DISCOUNTS_REQUEST:
      return {
        ...state,
        processing: true,
        items: [],
        error: null,
      }
    case actionTypes.GET_DISCOUNTS_SUCCESS:
      return {
        ...state,
        items: action.items,
        processing: false,
      }
    case actionTypes.GET_DISCOUNTS_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      }
    // edit
    case actionTypes.SAVE_DISCOUNT_REQUEST:
      return {
        ...state,
        editingId: action.id,
        processing: true,
        error: null,
      }
    case actionTypes.SAVE_DISCOUNT_SUCCESS:
      if (action.id === state.editingId) {
        const newItems = action.id === -1
          ? [action.item, ...state.items] // add
          : state.items.map(item => (item.id === action.item.id ? action.item : item)) // replace
        return {
          ...state,
          items: newItems,
          processing: false,
          editingId: null,
        }
      }
      return state
    case actionTypes.SAVE_DISCOUNT_FAILURE:
      if (action.id === state.editingId) {
        return {
          ...state,
          processing: false,
          editingId: null,
          error: action.error,
        }
      }
      return state
    // edit
    case actionTypes.DELETE_DISCOUNT_REQUEST:
      return {
        ...state,
        deletingId: action.id,
        processing: true,
        error: null,
      }
    case actionTypes.DELETE_DISCOUNT_SUCCESS:
      if (action.id === state.deletingId) {
        const newItems = state.items.filter(e => e.id !== action.id)
        return {
          ...state,
          items: newItems,
          processing: false,
          deletingId: null,
        }
      }
      return state
    case actionTypes.DELETE_DISCOUNT_FAILURE:
      if (action.id === state.deletingId) {
        return {
          ...state,
          processing: false,
          deletingId: null,
          error: action.error,
        }
      }
      return state
    default:
      return state
  }
}

export default reducer
