import React, { Component } from 'react'
import { Avatar, Popover } from 'antd'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import actions from '../../actions'
import UserDropdown from './UserDropdown';
import './UserMenu.css'

class UserMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  toggleMenuOpen = () => {
    const { open } = this.state
    return this.setState({ open: !open })
  }

  onLogout = () => {
    const { logout } = this.props
    this.setState({ open: false })
    return logout()
  }

  goToSettings = () => {
    const { navigateToSettings } = this.props
    this.setState({ open: false })
    return navigateToSettings()
  }

  render() {
    const { user, formatMessage } = this.props
    const { open } = this.state
    if (!user) {
      return null
    }
    const name = user.displayName
      || (typeof user.name === 'string' && user.name)
      || (typeof user.name === 'object' && user.name.givenName)
      || user.companyName
      || (user.emails && user.emails[0])
      || null
    return (
      <Popover
        trigger="click"
        placement="bottomRight"
        content={(
          <UserDropdown
            userName={name}
            logOut={this.onLogout}
            goToSettings={this.goToSettings}
            formatMessage={formatMessage}
          />
        )}
        visible={open}
        onVisibleChange={this.toggleMenuOpen}
      >
        <Avatar
          style={{ marginLeft: 16 }}
          icon="user"
          size="large"
        />
      </Popover>
    )
  }
}

function mapStateToProps(store) {
  return {
    user: store.auth.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(actions.auth.logout()),
    navigateToSettings: () => dispatch(push('/settings')),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
