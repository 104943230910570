import API from '@aws-amplify/api';
import { isNil } from 'lodash';
import 'moment-timezone';

import actionTypes from './actionTypes';

const getKPIsRequest = () => ({ type: actionTypes.GET_KPIS_REQUEST });
const getKPIsSuccess = (items, from, to) => ({
  type: actionTypes.GET_KPIS_SUCCESS, items, from, to,
});
const getKPIsFailure = error => ({ type: actionTypes.GET_KPIS_FAILURE, error });

function getKPIs(from, to) {
  return (dispatch, getState) => {
    const { date } = getState().performanceIndicators;
    if (!isNil(date) && from.isSameOrAfter(date[0]) && to.isSameOrBefore(date[1])) {
      return;
    }
    const url = 'kpis';
    const getArray = [];
    if (isNil(date)) {
      const queryStringParameters = {
        from: from.tz('UTC').format('YYYY-MM-DD'),
        to: to.tz('UTC').format('YYYY-MM-DD'),
      };
      getArray.push(API.get('Backend', url, { queryStringParameters }));
    } else {
      if (from.isBefore(date[0])) {
        const queryStringParameters = {
          from: from.tz('UTC').format('YYYY-MM-DD'),
          to: date[0].subtract(1, 'days').tz('UTC').format('YYYY-MM-DD'),
        };
        getArray.push(API.get('Backend', url, { queryStringParameters }));
      }
      if (to.isAfter(date[1])) {
        const queryStringParameters = {
          from: date[1].add(1, 'days').tz('UTC').format('YYYY-MM-DD'),
          to: to.tz('UTC').format('YYYY-MM-DD'),
        };
        getArray.push(API.get('Backend', url, { queryStringParameters }));
      }
    }

    const newFrom = isNil(date) || from.isBefore(date[0]) ? from : date[0];
    const newTo = isNil(date) || to.isAfter(date[1]) ? to : date[1];
    dispatch(getKPIsRequest());
    Promise.all(getArray).then((datas) => {
      const flattenedData = datas.flat();
      dispatch(getKPIsSuccess(flattenedData, newFrom, newTo));
    }).catch(err => dispatch(getKPIsFailure(err)));
  };
}

export default {
  getKPIs,
};
