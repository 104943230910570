/* eslint-disable max-len */
import API from '@aws-amplify/api'

import actionTypes from './actionTypes'

// const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env

const mapStationItem = e => ({
  id: e.uuid,
  brand: e.brand,
  latitude: +e.latitude,
  longitude: +e.longitude,
  name: e.station_name,
  address: e.address,
  zipcode: e.zipcode,
  locality: e.locality,
  country: e.country_code,
  type: e.type,
  discountValue: e.discount_value,
  discountType: e.discount_type,
  discountCurrency: e.discount_currency,
  fleetCard: e.fleet_card,
})

const inverseMapStationItem = e => ({
  uuid: e.id,
  brand: e.brand,
  latitude: e.latitude && e.latitude.toString(),
  longitude: e.longitude && e.longitude.toString(),
  station_name: e.name,
  address: e.address,
  zipcode: e.zipcode,
  locality: e.locality,
  country_code: e.country,
  type: e.type,
  discount_value: e.discountValue,
  discount_type: e.discountType,
  discount_currency: e.discountCurrency,
  fleet_card: e.fleetCard,
})

const getStationsRequest = () => ({ type: actionTypes.GET_STATIONS_REQUEST })
const getStationsSuccess = items => ({ type: actionTypes.GET_STATIONS_SUCCESS, items })
const getStationsFailure = error => ({ type: actionTypes.GET_STATIONS_FAILURE, error })
const addStationsRequest = id => ({ type: actionTypes.ADD_STATIONS_REQUEST, id })
const addStationsSuccess = (id, items) => ({ type: actionTypes.ADD_STATIONS_SUCCESS, id, items })
const addStationsFailure = (id, error) => ({ type: actionTypes.ADD_STATIONS_FAILURE, id, error })
const deleteStationRequest = ids => ({ type: actionTypes.DELETE_STATION_REQUEST, ids })
const deleteStationSuccess = ids => ({ type: actionTypes.DELETE_STATION_SUCCESS, ids })
const deleteStationFailure = (ids, error) => ({ type: actionTypes.DELETE_STATION_FAILURE, ids, error })
const searchStationsRequest = id => ({ type: actionTypes.SEARCH_STATIONS_REQUEST, id })
const searchStationsSuccess = (id, items) => ({ type: actionTypes.SEARCH_STATIONS_SUCCESS, id, items })
const searchStationsFailure = (id, error) => ({ type: actionTypes.SEARCH_STATIONS_FAILURE, id, error })

function getStations() {
  return (dispatch) => {
    dispatch(getStationsRequest())
    const url = 'stations/preferred'
    return API.get('Backend', url)
      .then((data) => {
        const cleanedData = data.map(mapStationItem)
        return dispatch(getStationsSuccess(cleanedData))
      })
      .catch(err => dispatch(getStationsFailure(err)))
  }
}

function addStations(items) {
  return (dispatch) => {
    const actionid = items.map(e => e.id)
    dispatch(addStationsRequest(actionid))
    const url = 'stations/preferred'
    return API.post('Backend', url, { body: items.map(inverseMapStationItem) })
      .then(() => dispatch(addStationsSuccess(actionid, items)))
      .catch(err => dispatch(addStationsFailure(actionid, err)))
  }
}

function deleteStation(ids) {
  return (dispatch) => {
    dispatch(deleteStationRequest(ids))
    const url = 'stations/preferred'
    return API.del('Backend', url, { body: ids })
      .then(() => dispatch(deleteStationSuccess(ids)))
      .catch(err => dispatch(deleteStationFailure(ids, err)))
  }
}

function searchStations(params) { // either { bbox } either { address }
  return (dispatch) => {
    const id = JSON.stringify(params)
    dispatch(searchStationsRequest(id))
    const url = 'stations/search'
    return API.post('Backend', url, { body: params })
      .then((data) => {
        const cleanedData = data.map(mapStationItem)
        return dispatch(searchStationsSuccess(id, cleanedData))
      })
      .catch(err => dispatch(searchStationsFailure(id, err)))
  }
}

function searchHomeBaseStations(params) {
  return async (dispatch) => {
    const id = JSON.stringify(params)
    dispatch(searchStationsRequest(id))
    const url = 'stations/searchHomeBased'
    return API.post('Backend', url, { body: params })
      .then(data => dispatch(searchStationsSuccess(id, data)))
      .catch(err => dispatch(searchStationsFailure(id, err)))
  }
}

function resetSearch() {
  return dispatch => dispatch({ type: actionTypes.SEARCH_STATIONS_RESET })
}

export default {
  getStations, addStations, deleteStation, searchStations, resetSearch, searchHomeBaseStations,
}
