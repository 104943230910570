import React from 'react'
import Clock from 'react-clock';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Row, Col } from 'antd';

import './react-clock.css'

const styles = {
  container: {
    position: 'absolute',
    top: 10,
    left: 10,
    zIndex: 1,
    fontSize: 12,
  },
  clockanddate: {
    backgroundColor: 'rgba(255,255,255, 0.8)',
    borderRadius: 200,
    padding: 8,
  },
  dateandtime: {
    padding: '0 10px',
  },
}

// time should be a Date object
const MapClock = ({ time }) => time && typeof time.getHours === 'function' && (
  <div style={styles.container}>
    <div style={styles.clockanddate}>
      <Row type="flex">
        <Col>
          <Clock
            size={40}
            renderMinuteMarks={false}
            renderSecondHand={false}
            minuteMarksWidth={1}
            hourMarksWidth={1}
            minuteHandWidth={1}
            hourHandWidth={2}
            value={time}
          />
        </Col>
        <Col style={styles.dateandtime}>
          <div>
            <FormattedTime
              value={time}
            />
          </div>
          <div>
            <FormattedDate
              value={time}
              year="numeric"
              month="long"
              day="2-digit"
            />
          </div>
        </Col>
      </Row>
    </div>
  </div>
)
export default MapClock
