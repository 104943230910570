
export const constructTreeFromGroups = (groups) => {
  const ret = groups.map(g => ({
    title: g.group,
    key: g.group,
    children: g.subgroups.map(sg => ({ title: sg, key: `${g.group}$%-%$${sg}` })),
  }));
  return ret;
};

export const constructKeysFromGroups = (groups) => {
  const keys = [];
  groups.forEach(g => g.subgroups.forEach(sg => keys.push(`${g.group}$%-%$${sg}`)));
  return keys;
};

export const groupsArrayToObject = arr => arr.reduce((obj, item) => {
  const [group, subgroup] = item.split('$%-%$');
  if (!subgroup) {
    return obj;
  }
  if (!(group in obj)) {
    obj[group] = [subgroup];
  } else {
    obj[group].push(subgroup);
  }
  return obj;
}, {});

export const groupsObjectToArray = (obj) => {
  const keys = [];
  Object.keys(obj).forEach((group) => {
    const subgroups = obj[group];
    subgroups.forEach((subgroup) => {
      keys.push(`${group}$%-%$${subgroup}`);
    });
  });
  return keys;
};

