import React, { Component } from 'react'
import DeckGL, { ScatterplotLayer, WebMercatorViewport } from 'deck.gl'
import ReactMapGL, { FlyToInterpolator } from 'react-map-gl'
import * as d3 from 'd3'

const { REACT_APP_MAPBOX_ACCESS_TOKEN, REACT_APP_MAPBOX_STYLE } = process.env

const defaultViewState = {
  longitude: 4.35,
  latitude: 50.85,
  zoom: 7,
  pitch: 0,
  bearing: 0,
}

const styles = {
  tooltip: {
    position: 'absolute',
    borderRadius: 3,
    padding: '7px 10px',
    fontSize: 12,
    background: 'white',
    boxShadow: '0 2px 4px rgba(0,0,0,0.5)',
    textAlign: 'left',
    zIndex: 1,
    pointerEvents: 'none',
    maxWidth: 300,
  },
  unknownText: { color: 'silver' },
}

class SearchStationMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewState: defaultViewState,
      tooltip: {
        hoveredObject: null,
        x: 0,
        y: 0,
      },
    }
  }

  componentDidUpdate(prevProps) {
    const { stations } = this.props
    if (stations &&
      stations.length > 0 &&
      (!prevProps.stations || prevProps.stations.length === 0 || JSON.stringify(stations) !== JSON.stringify(prevProps.stations))) {
      this.setState({ viewState: this.getBoundingViewState(stations) })
    }
  }

  onViewStateChange = ({ viewState }) => {
    this.props.saveViewState(viewState)
    this.setState({ viewState });
  }

  onMapRefReceived = (mapRef) => {
    this.mapRef = mapRef
    if (!mapRef || !mapRef.getMap().style.lineAtlas.width || !mapRef.getMap().style.lineAtlas.height) return
    const { width, height } = this.mapRef.getMap().style.lineAtlas
    const { viewState } = this.state
    this.props.saveViewState({ ...viewState, width, height }) // because initial viewstate don't have height and width
  }

  onHover = ({ x, y, object }) => {
    this.setState({ tooltip: { x, y, hoveredObject: object } });
  }

  getBoundingViewState = (data, mustFly = true) => {
    const { viewState } = this.state
    if (!data || data.length === 0 || !this.mapRef) return viewState

    // compute bounds
    const lat = data.map(el => el.latitude)
    const long = data.map(el => el.longitude)
    const c1 = [Math.min(...long), Math.min(...lat)]
    const c2 = [Math.max(...long), Math.max(...lat)]
    const bounds = [c1, c2] // [[lon, lat], [lon, lat]]

    // prevent errors when coords are the same
    const p = 0.001
    if (bounds[0][0] === bounds[1][0]) {
      bounds[0][0] -= p;
      bounds[1][0] += p;
    }
    if (bounds[0][1] === bounds[1][1]) {
      bounds[0][1] -= p;
      bounds[1][1] += p;
    }

    // create new viewState
    const { width, height } = this.mapRef.getMap().style.lineAtlas
    const viewStateWithWidthHeight = { ...viewState, width, height }
    const paddingOptions = (width > 150 && height > 150) ? { padding: 50 } : {}
    const viewPort = new WebMercatorViewport(viewStateWithWidthHeight)
    const { longitude, latitude, zoom } = viewPort.fitBounds(bounds, paddingOptions);
    const newViewState = {
      ...viewState,
      longitude,
      latitude,
      zoom,
      transitionDuration: mustFly && 2500,
      transitionInterpolator: mustFly && new FlyToInterpolator(),
      transitionEasing: mustFly && d3.easeCubic,
    }
    return newViewState;
  }

  showTooltip() {
    const {
      renderTooltip,
    } = this.props
    const { tooltip: { x, y, hoveredObject } } = this.state
    if (!this.mapRef) return

    const position = {}
    if (this.mapRef && x > this.mapRef.getMap().style.lineAtlas.width - 200) {
      position.right = `calc(100% - ${x}px)`
    } else position.left = x
    if (this.mapRef && y > this.mapRef.getMap().style.lineAtlas.height - 100) {
      position.bottom = `calc(100% - ${y}px)`
    } else position.top = y

    return renderTooltip && hoveredObject && (
      <div style={{ ...styles.tooltip, ...position }}>
        {renderTooltip(hoveredObject)}
      </div>
    )
  }

  render() {
    const { viewState } = this.state
    const { onClick = () => {}, stations, selectedStations } = this.props // default gold
    const searchedStationsLayer = new ScatterplotLayer({ // grey with white border
      id: 'searched-stations-layer',
      data: stations,
      pickable: true,
      opacity: 1,
      stroked: true,
      filled: true,
      getPosition: d => [+d.longitude, +d.latitude],
      onHover: this.onHover,
      onClick: e => onClick(e.object),
      radiusMinPixels: 7,
      radiusMaxPixels: 7,
      lineWidthMinPixels: 2,
      getFillColor: d => (d.type ? [204, 204, 204] : [209, 177, 117]),
      getLineColor: [255, 255, 255],
    })
    const selectedStationsLayer = new ScatterplotLayer({ // grey with white border
      id: 'selected-stations-layer',
      data: selectedStations,
      pickable: true,
      opacity: 1,
      stroked: true,
      filled: true,
      getPosition: d => [+d.longitude, +d.latitude],
      onHover: this.onHover,
      onClick: e => onClick(e.object),
      radiusMinPixels: 7,
      radiusMaxPixels: 7,
      lineWidthMinPixels: 2,
      getFillColor: [30, 144, 255],
      getLineColor: [255, 255, 255],
    })

    // return JSX
    return (
      <span>
        <DeckGL
          viewState={viewState}
          onViewStateChange={this.onViewStateChange}
          layers={[searchedStationsLayer, selectedStationsLayer]}
          controller
        >
          <ReactMapGL
            reuseMaps
            mapboxApiAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
            preventStyleDiffing
            mapStyle={REACT_APP_MAPBOX_STYLE}
            ref={this.onMapRefReceived}
          />
        </DeckGL>
        {this.showTooltip()}
      </span>
    )
  }
}

export default SearchStationMap;
