import { createStore, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from './reducers/index'
import middlewares from './middelwares/index'

export const history = createBrowserHistory()


const composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  createRootReducer(history),
  composeWithDevTools(applyMiddleware(
    routerMiddleware(history),
    thunk,
    ...middlewares,
  )),
)

export default store
