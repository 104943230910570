import React from 'react'
import { injectIntl } from 'react-intl';
import { Select, Input, InputNumber } from 'antd';
import currenciesIsoCodes from '../assets/data/isoCurrenciesData.json';
import { discountTypes, getStrId } from './constants';

const { Option } = Select

const DiscountValue = (props) => {
  const {
    onValueChange,
    onTypeChange,
    onCurrencyChange,
    value,
    type,
    currency,
    editable,
  } = props
  const { formatMessage } = props.intl

  return editable ? (
    <Input.Group compact>
      <InputNumber
        size="small"
        min={0}
        max={99}
        step={0.1}
        value={value}
        style={{ width: 60 }}
        onChange={onValueChange}
      />
      {(type === discountTypes.EURO || type === discountTypes.FIXED_PRICE) && (
        <Select
          value={currency}
          showSearch
          size="small"
          onChange={onCurrencyChange}
          style={{ minWidth: 75 }}
        >
          {Object.keys(currenciesIsoCodes).map(currency => (
            <Option key={currency} value={currency}>
              {currency}
            </Option>
        ))}
        </Select>
      )}
      <Select
        value={type}
        size="small"
        onChange={onTypeChange}
        style={{ minWidth: 150 }}
      >
        {Object.values(discountTypes)
          .map(k => <Option key={k}>{formatMessage(getStrId(k))}</Option>)}
      </Select>
    </Input.Group>
  ) : `${value} ${(type === discountTypes.EURO || type === discountTypes.FIXED_PRICE) ? currency : ''}${type === discountTypes.EURO ? ' ' : ''}${formatMessage(getStrId(type))}`
}

export default injectIntl(DiscountValue)
