import * as d3 from 'd3';
import Chart from '../Chart';

import world from './world.geo.json';

const europeAsia = Object.assign(
  {},
  world,
  {
    features: world.features
      .filter(c => c.properties.continent === 'Asia' || c.properties.continent === 'Europe'),
  },
)
const europe = Object.assign(
  {},
  world,
  {
    features: europeAsia.features
      .filter(c => c.properties.continent === 'Europe'),
  },
)


export default class EuropeMapChart extends Chart {
  create() {
    this.svg = super.createRoot();
    this.main = this.svg.append('g')
      .attr('class', 'main')
      .attr('transform', `translate(${this.props.margin.left},${this.props.margin.top})`);

    this.map = this.main.append('g')
      .attr('class', 'map');

    // this.labels = this.main.append('g')
    //  .attr('class', 'labels');
  }

  update(state) {
    this.countryCodeType = state.countryCodeType;
    this.countryCodeAccessor = state.countryCodeAccessor;
    if (state.data &&
      (JSON.stringify(this.data) !== JSON.stringify(state.data) ||
      this.valueAccessor !== state.valueAccessor)) {
      this.valueAccessor = state.valueAccessor;
      this.data = state.data;
      this._drawMap(state.data);
    }
  }

  _drawMap(data) {
    const dataDomain = d3.extent(data, d => d[this.valueAccessor]);
    const opacityScale = d3.scaleLinear()
      .range([0.1, 1])
      .domain([dataDomain[0], dataDomain[1]]);
      // .interpolator(d3ScaleChromatic.interpolateGnBu);

    const dataMapping = data.reduce((agg, v) => {
      agg[v[this.countryCodeAccessor]] = v[this.valueAccessor];
      return agg;
    }, {});

    // create a first guess for the projection
    let projection = d3.geoMercator()
      .scale(1)
      .translate([0, 0]);

    // create the path
    let path = d3.geoPath()
      .projection(projection);

    // using the path determine the bounds of the current map and use
    // these to determine better values for the scale and translation
    const europeWithoutRussia =
      Object.assign(
        {},
        europe,
        {
          features: europe.features
            .filter(c => c.properties.name !== 'Russia'),
        },
      )
    const bounds = path.bounds(europeWithoutRussia);
    const scale = 0.95 / Math.max(
      (bounds[1][0] - bounds[0][0]) / this.props.width,
      (bounds[1][1] - bounds[0][1]) / this.props.height,
    );

    const offset = [
      (this.props.width - (scale * (bounds[1][0] + bounds[0][0]))) / 2,
      (this.props.height - (scale * (bounds[1][1] + bounds[0][1]))) / 2,
    ];

    // new projection
    projection = d3.geoMercator()
      .scale(scale)
      .translate(offset);
    path = path.projection(projection);

    this.path = path;

    const map = this.map
      .selectAll('path')
      .data(europeWithoutRussia.features)

    const mapEnter = map.enter().append('path')
      .attr('class', 'country');

    mapEnter
      .attr('d', path)
      .style('stroke', 'white')
      .style('stroke-width', 1)
      .merge(map)
      .transition()
      .style('opacity', d => (dataMapping[d.properties[this.countryCodeType]] !== undefined ?
        opacityScale(dataMapping[d.properties[this.countryCodeType]]) : 1))
      .style('fill', d => (dataMapping[d.properties[this.countryCodeType]] !== undefined ?
        'dodgerblue' : '#F0F0F0'));
  }
}
