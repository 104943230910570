import { Button, Icon, Input, Table } from 'antd';
import { isEmpty, toLower } from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import * as strings from '../../../helpers/defaultStrings';
import './FilterTable.css';

const FilterTable = ({
  data,
  onChangeKeys,
  title,
  intl: {
    formatMessage,
  },
}) => {
  const [filteredData, setFilteredData] = useState(data);
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    if (!isEmpty(data)) setFilteredData(data);
  }, [data, setFilteredData]);

  const onFilter = () => {
    setFilteredData(data.filter(d => toLower(d).includes(toLower(searchText))));
  };
  const filterColumns = [
    {
      title,
      dataIndex: 'filterId',
      key: 'filterId',
      filterDropdown: (
        <div className="custom-filter-dropdown">
          <Input
            value={searchText}
            onChange={event => setSearchText(event.target.value)}
            onPressEnter={onFilter}
          />
          <Button type="primary" onClick={onFilter}>{formatMessage(strings.word.search)}</Button>
        </div>
      ),
      filterIcon: <Icon type="search" style={{ color: 'dodgerblue' }} />,
      render: (_, record) => <div>{record}</div>,
    },
  ];
  return (
    <Table
      dataSource={filteredData}
      showHeader
      columns={filterColumns}
      size="small"
      rowKey={r => r}
      pagination={{ pageSize: 5 }}
      rowSelection={{
        onChange: (_, selectedRows) => {
          onChangeKeys(selectedRows);
        },
        type: 'checkbox',
      }}
      style={{ maxWidth: 300 }}
    />
  );
};

export default injectIntl(FilterTable);
