import React from 'react';
import { pickBy } from 'lodash';
import { Tag, Table, Row, Col, Card, Button, Badge, notification } from 'antd';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { push } from 'react-router-redux';
import { Route } from 'react-router';
import crossfilter from 'crossfilter2';
import moment from 'moment';
import 'moment-timezone';

import actions from '../../actions';
import FilterForm from './FilterForm';
import Details from './Details';
import { RenderedConstant, newReleaseInfo } from '../../helpers/constants';
import paths from '../../ComponentsLayout/paths';
import * as strings from '../../helpers/defaultStrings';
import { CSVExporter } from '../../helpers';
import { flatten } from '../../helpers/CSVExporter/CSVExporter';


const { VERSION } = newReleaseInfo;
const GREY = 'rgba(0,0,0,.45)';
export const INITIAL_FILTER = {
  recommendationid: null,
  date: null,
  truckid: null,
  internalvehicleid: null,
  platenumber: null,
  driver: null,
  driverName: null,
  type: null,
  status: null,
  groups: null,
  subgroups: null,
};

class FuelBot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredRecommendations: [],
      cfDimensions: null,
      filters: INITIAL_FILTER,
    };
  }

  componentDidMount() {
    const { user, recommendations, getRecommendationList } = this.props;
    if (user && !recommendations.processing) getRecommendationList();
    if (recommendations.items) this.initCrossfilter();
  }

  componentDidUpdate(prevProps) {
    // const { user, recommendations, getRecommendationList, version, isInfoRead } = this.props
    const { user, recommendations, getRecommendationList } = this.props;
    if (!prevProps.user && !!user && !recommendations.processing) {
      getRecommendationList();
    }
    if (!recommendations.processing && prevProps.recommendations.processing) {
      this.initCrossfilter();
      // Informaion popup working fine will be enabled in the next release
      // if((version !== VERSION) || (!isInfoRead && !user.isInfoClosed)){
      //   this.openNotification()
      // }
    }
  }

  onFilter = (filters) => {
    const { closeDetails } = this.props;
    const { cfDimensions } = this.state;
    const { date: dateFilter, ...simpleFilters } = filters;
    // apply simple filters & groups filters
    Object.entries(simpleFilters)
      .forEach(([key, value]) => cfDimensions[key].filter(value));
    // apply date filter
    if (filters.date && Array.isArray(filters.date) && filters.date.length === 2) {
      cfDimensions.date.filter([moment(filters.date[0]).startOf('day'), moment(filters.date[1]).endOf('day')]);
    } else {
      cfDimensions.date.filter(null);
    }

    // update state
    const filteredRecommendations = cfDimensions.recommendationid.top(Infinity)
      .sort((a, b) => +b.date - a.date);
    const options = {
      date: cfDimensions.date.group().all().filter(e => e.value).map(e => e.key),
      truckid: cfDimensions.truckid.group().all().filter(e => e.value).map(e => e.key),
      internalvehicleid:
        cfDimensions.internalvehicleid.group().all().filter(e => e.value).map(e => e.key),
      platenumber: cfDimensions.platenumber.group().all().filter(e => e.value).map(e => e.key),
      driver: cfDimensions.driver.group().all().filter(e => e.value).map(e => e.key),
      driverName: cfDimensions.driverName.group().all().filter(e => e.value).map(e => e.key),
      type: cfDimensions.type.group().all().filter(e => e.value).map(e => e.key),
      status: cfDimensions.status.group().all().filter(e => e.value).map(e => e.key),
      groups: cfDimensions.groups.group().all().filter(e => e.value).map(e => e.key),
      subgroups: cfDimensions.subgroups.group().all().filter(e => e.value).map(e => e.key),
    };
    this.setState({ filteredRecommendations, options, filters });

    // if selected recommendation is not in the list anymore, remove details
    const selectedRecommendationId = this.props.match.params.recommendationid;
    if (selectedRecommendationId &&
        !filteredRecommendations.map(e => e.recommendationid).includes(selectedRecommendationId)) {
      closeDetails();
    }
  }

  getInfoMsg = () => {
    const { getRecommendationList, recommendations: { items, lastEvaluatedKey } } = this.props;
    const { filters, filteredRecommendations } = this.state;
    const { formatMessage } = this.props.intl;

    const hasFilters = filters && Object.values(filters).some(x => !!x);
    if (!items) {
      return null;
    }

    const cleanRecoForCSV = filteredRecommendations.map((rec) => {
      const recWithoutUndefined = pickBy(rec, v => v !== undefined && v !== []);
      const {
        planning, groups, subgroups, ...recWithoutPlanning
      } = recWithoutUndefined;
      return flatten({ ...recWithoutPlanning, date: recWithoutPlanning.date.format() });
    });

    const hasMoreRecommendations = !!lastEvaluatedKey;
    const loadMoreBtn = (
      <Button
        disabled={!hasMoreRecommendations}
        size="small"
        type="link"
        onClick={getRecommendationList}
        icon="reload"
        style={{ paddingInline: 15 }}
      >
        {formatMessage(strings.phrase.loadMore)}
      </Button>
    );

    return (
      <span>
        {!hasFilters && <Badge
          color="green"
          style={{ color: 'rgba(0, 0, 0, 0.45)' }}
          text={formatMessage(
            strings.phrase.showingAllTotalRecommendations,
            { total: items.length },
          )}
        />}
        {loadMoreBtn}
        {filteredRecommendations && filteredRecommendations.length > 0 && (
          <CSVExporter
            data={cleanRecoForCSV}
            fileName="recommendations_export"
            intl={{ formatMessage }}
          />
        )}
      </span>
    );
  }

  initCrossfilter() {
    const { recommendations } = this.props;
    const { filters } = this.state;
    if (recommendations.items) {
      const cf = crossfilter(recommendations.items);
      const cfDimensions = {
        recommendationid: cf.dimension(d => d.recommendationid),
        date: cf.dimension(d => moment(d.date).startOf('day').add(12, 'hours')),
        truckid: cf.dimension(d => d.truckid),
        internalvehicleid: cf.dimension(d => d.internalvehicleid),
        platenumber: cf.dimension(d => d.platenumber),
        driver: cf.dimension(d => d.driver),
        driverName: cf.dimension(d => d.driverName),
        type: cf.dimension(d => d.type),
        status: cf.dimension(d => d.status),
        groups: cf.dimension(d => (d.groups && Object.keys(d.groups)) || [], true),
        subgroups: cf.dimension((d) => {
          if (d.groups) {
            const groups = Object.keys(d.groups);
            return groups.reduce((acc, group) => [...acc, ...d.groups[group]], []);
          }
          return [];
        }, true),
      };
      this.setState(
        { cfDimensions },
        () => this.onFilter(filters),
      );
    }
  }

  removeNotification() {
    const { saveSettings } = this.props;
    this.props.user.isInfoClosed = true;
    const settings = {};
    settings.version = VERSION;
    settings.isInfoRead = false;
    saveSettings(settings);
  }

  openNotification() {
    const { saveSettings } = this.props;
    const { formatMessage } = this.props.intl;
    const key = `open${Date.now()}`;
    const updateInfoRead = () => {
      const settings = {};
      settings.version = VERSION;
      settings.isInfoRead = true;
      saveSettings(settings);
    };
    const btn = (
      <Button type="primary" size="small" onClick={() => { updateInfoRead(); notification.close(key); }}>
        {formatMessage(strings.word.dismiss)}
      </Button>
    );

    notification.info({
      message: formatMessage(strings.message.newReleaseTitle),
      description: formatMessage(strings.description.newReleaseDescription),
      btn,
      key,
      duration: 0,
      onClose: () => this.removeNotification(),
    });
  }

  formatDriverName = (useInternalDriverName, item) => {
    const { formatMessage } = this.props.intl;
    if (item.driver === '') {
      return formatMessage(strings.message.noDriverInfo);
    }
    return useInternalDriverName ? item.driverName : item.driver;
  }

  renderTruck = (item) => {
    const { useInternalVehicleId, useInternalDriverName } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <div>{formatMessage(strings.word.truck)} {item.truckid}</div>
        <div style={{ color: GREY }}>
          {useInternalVehicleId ?
            <span>
              {formatMessage(strings.phrase.internalId)}: <strong>{item.internalvehicleid}</strong>
            </span>
            :
            <span>
              {formatMessage(strings.phrase.plateNumber)}: <Tag>{item.platenumber}</Tag>
            </span>
          }
        </div>
        <div style={{ color: GREY }}>
          <div>
            {formatMessage(strings.word.driver)}: {this.formatDriverName(useInternalDriverName, item)}
          </div>
        </div>
      </div>
    );
  }

  renderState = (item) => {
    const { formatMessage } = this.props.intl;
    return (
      <div style={{ color: GREY }}>
        <div>{formatMessage(strings.word.planning)}: <RenderedConstant type={item.type} /></div>
        <div>{formatMessage(strings.word.status)}: <RenderedConstant type={item.status} /></div>
      </div>
    );
  }

  renderWhen = (item) => {
    const { formatMessage } = this.props.intl;
    const timezone = moment.tz.guess();
    return (
      <div style={{ padding: 8 }}>
        <div>{item.date.tz(timezone).format('lll')}</div>
        <div style={{ color: GREY }}>
          <div>{formatMessage(strings.phrase.fuelLevel)}: {item.fuelPercentage} %</div>
        </div>
      </div>
    );
  }

  render() {
    const { recommendations: { processing }, goToDetails } = this.props;
    const { filteredRecommendations, options, filters } = this.state;
    const { formatMessage } = this.props.intl;
    const columns = [
      {
        key: 'when',
        render: (_, item) => this.renderWhen(item),
      },
      {
        dataIndex: 'truck',
        render: (_, item) => this.renderTruck(item),
      },
      {
        key: 'state',
        render: (_, item) => this.renderState(item),
      },
    ];
    const selectedRecommendationId = this.props.match.params.recommendationid;
    return (
      <div
        style={{
          height: '100%',
          backgroundColor: 'white',
          padding: 16,
        }}
      >
        <Row type="flex" gutter={16} style={{ height: '100%' }}>
          <Col span={12} style={{ padding: 16, height: '100%', overflowY: 'scroll' }}>
            <Card
              style={{
                background: '#fbfbfb',
                border: '1px solid #d9d9d9',
                borderRadius: 6,
              }}
            >
              <FilterForm onFilter={this.onFilter} options={options} info={this.getInfoMsg()} filters={filters} />
            </Card>
            {(!filteredRecommendations || filteredRecommendations.length === 0) &&
            <br />} {/* space if no data */}
            <Table
              loading={processing}
              dataSource={filteredRecommendations}
              size="small"
              showHeader={false}
              columns={columns}
              scroll={{ x: true }}
              pagination={{
                position: 'top',
                showTotal: (total, range) => formatMessage(strings.phrase.valueOfTotalItems, { value: `${range[0]}-${range[1]}`, total }),
              }}
              onRow={record => ({ onClick: () => goToDetails(record.recommendationid) })}
              // onRow={{ onClick: e => goToDetails(e.recommendationid) }}
              rowKey="recommendationid"
              rowClassName={e => (e.recommendationid === selectedRecommendationId ? 'selectable-table-row highlighted' : 'selectable-table-row')}
            />
          </Col>
          <Col span={12} style={{ padding: 16, height: '100%' }}>
            <Route
              path={`${paths.fuelBot}/:recommendationid`}
              component={Details}
            />
            <Route
              path={paths.fuelBot}
              exact
              render={() => (
                <div style={{ color: GREY }}>
                  {formatMessage(strings.phrase.clickOnARecommendationToInspect)}
                </div>
              )}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

FuelBot.propTypes = {
  intl: intlShape.isRequired,
};

function mapStateToProps(store) {
  const {
    recommendations,
    auth: { user },
    settings: {
      items: {
        useInternalVehicleId, useInternalDriverName, version, isInfoRead,
      },
    },
  } = store;
  return {
    recommendations,
    user,
    useInternalVehicleId,
    useInternalDriverName,
    version,
    isInfoRead,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRecommendationList: () => dispatch(actions.recommendations.getRecommendationList()),
    goToDetails: recommendationId => dispatch(push(`${paths.fuelBot}/${recommendationId}`)),
    closeDetails: () => dispatch(push(paths.fuelBot)),
    saveSettings: settings => dispatch(actions.settings.saveSettings(settings)),
    getSettings: () => dispatch(actions.settings.getSettings()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FuelBot));

