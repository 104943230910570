import React, { Component } from 'react'
import DeckGL, { ScatterplotLayer, WebMercatorViewport } from 'deck.gl'
import ReactMapGL, { FlyToInterpolator } from 'react-map-gl'
import * as d3 from 'd3'
import MapControlButtons from '../MapControlButtons/MapControlButtons'

const { REACT_APP_MAPBOX_ACCESS_TOKEN, REACT_APP_MAPBOX_STYLE } = process.env

const defaultViewState = {
  longitude: 4.35,
  latitude: 50.85,
  zoom: 7,
  pitch: 0,
  bearing: 0,
}

const styles = {
  tooltip: {
    position: 'absolute',
    borderRadius: 3,
    padding: '7px 10px',
    fontSize: 12,
    background: 'white',
    boxShadow: '0 2px 4px rgba(0,0,0,0.5)',
    textAlign: 'left',
    zIndex: 1,
    pointerEvents: 'none',
    maxWidth: 300,
  },
  unknownText: { color: 'silver' },
}

/**
 * A generic map showing yellow points with a tooltip,
 * supporting zooms and drags, and that flyto when changing data
 * (default viewState is Belgium).
 * @param {array} data : list of json obj having at least latitude and longitude fields
 * @param {function} renderTooltip
 * @param {array} tooltipFields : used if !renderTooltip
 * @param {array} color : rgb of the points color (default: gold)
 *
 * @example :
 * <TrajectoriesMap
 *   data={data}
 *   tooltipFields={['name', 'longitude', 'latitude']}
 *   or renderTooltip={ record => record.name }
 *   or tooltipRenderedFields={[ { title, dataIndex, key, render, nullname }, ... ]}
 * />
 */
class SimplePointMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewState: defaultViewState,
      tooltip: {
        hoveredObject: null,
        x: 0,
        y: 0,
      },
      isMapRefReady: false,
      data: [],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { data: prevData } = prevState
    const { data: newData } = this.props
    // if (newData && newData.length > 0
    //   && (!prevData || prevData.length === 0 || JSON.stringify(newData) !== JSON.stringify(prevData))) {
    if (newData && (!prevData || JSON.stringify(newData) !== JSON.stringify(prevData))) {
      this.onDataReceived(newData)
    }
  }

  onViewStateChange = ({ viewState }) => {
    this.setState({ viewState });
  }

  onDataReceived = (data) => {
    this.setState({
      viewState: this.getBoundingViewState(data, true),
      data,
    })
  }

  /* when mapRef is received we make the viewState fit "data" */
  onMapRefReceived = (mapRef) => {
    const { isMapRefReady } = this.state
    const { data } = this.props
    // eslint-disable-next-line no-underscore-dangle
    if (!isMapRefReady && data && data.length > 0 && mapRef && mapRef.getMap().style.lineAtlas.width && mapRef.getMap().style.lineAtlas.height) {
      this.setState({
        isMapRefReady: true,
        viewState: this.getBoundingViewState(data, true), // suppose we always have data...
      })
    }
  }

  onHover = ({ x, y, object }) => {
    this.setState({ tooltip: { x, y, hoveredObject: object } });
  }

  getBoundingViewState = (data, mustFly) => {
    const { viewState } = this.state
    if (!data || data.length === 0 || !this.mapRef) return viewState
    // we don't use this.state.isMapRefReady because onMapRefReceived wants to
    // use getBoundingViewState before setting isMapRefReady to true
    const p = 0.001

    // compute bounds
    const lat = data.map(el => el.latitude)
    const long = data.map(el => el.longitude)
    const c1 = [Math.min(...long), Math.min(...lat)]
    const c2 = [Math.max(...long), Math.max(...lat)]
    const bounds = [c1, c2] // [[lon, lat], [lon, lat]]

    // prevent errors when coords are the same
    if (bounds[0][0] === bounds[1][0]) {
      bounds[0][0] -= p;
      bounds[1][0] += p;
    }
    if (bounds[0][1] === bounds[1][1]) {
      bounds[0][1] -= p;
      bounds[1][1] += p;
    }

    // create new viewState
    const viewStateWithWidthHeight = {
      ...viewState,
      width: this.mapRef.getMap().style.lineAtlas.width,
      height: this.mapRef.getMap().style.lineAtlas.height,
    }
    const { longitude, latitude, zoom } = new WebMercatorViewport(viewStateWithWidthHeight)
      .fitBounds(bounds, {
        padding: 50,
      });
    const newViewState = {
      ...viewState,
      longitude,
      latitude,
      zoom,
      transitionDuration: mustFly && 2500,
      transitionInterpolator: mustFly && new FlyToInterpolator(),
      transitionEasing: mustFly && d3.easeCubic,
    }
    if (newViewState.zoom > 17.5) newViewState.zoom = 17.5 // limit zoom
    return newViewState;
  }

  getRenderTooltipFields = () => {
    const { tooltipFields } = this.props
    return obj => (tooltipFields.map((key) => {
      const value = obj[key] ? obj[key].toString() : (<span style={styles.unknownText}> unknown </span>)
      return (
        <span key={key}>
          <strong>{`${key}: `}</strong>
          {value}
          <br />
        </span>
      )
    }))
  }

  getRenderTooltipRenderedFields = () => {
    const { tooltipRenderedFields } = this.props
    return record => (tooltipRenderedFields.map(({
      title, dataIndex, key, render, nullname,
    }) => {
      const value = record[dataIndex]
      let renderedValue = value
      if (render) renderedValue = render(value, record)
      else if (nullname && !value) {
        renderedValue = (
          <span style={styles.unknownText}>
            {nullname || 'unknown'}
          </span>
        )
      }
      return (
        <span key={key || dataIndex}>
          <strong>{`${title || dataIndex}: `}</strong>
          {renderedValue}
          <br />
        </span>
      )
    }))
  }

  resetViewState = () => {
    const { data } = this.props
    this.setState({ viewState: this.getBoundingViewState(data, true) })
  }

  showTooltip() {
    const {
      renderTooltip,
      tooltipFields,
      tooltipRenderedFields,
    } = this.props
    const { tooltip: { x, y, hoveredObject }, isMapRefReady } = this.state

    const tooltipFunc = renderTooltip
      || (tooltipRenderedFields && this.getRenderTooltipRenderedFields())
      || (tooltipFields && this.getRenderTooltipFields())
      || null

    const position = {}
    if (isMapRefReady && x > this.mapRef.getMap().style.lineAtlas.width - 200) {
      position.right = `calc(100% - ${x}px)`
    } else position.left = x
    if (isMapRefReady && y > this.mapRef.getMap().style.lineAtlas.height - 100) {
      position.bottom = `calc(100% - ${y}px)`
    } else position.top = y

    return tooltipFunc && hoveredObject && (
      <div style={{ ...styles.tooltip, ...position }}>
        {tooltipFunc(hoveredObject)}
      </div>
    )
  }

  render() {
    const { viewState, data } = this.state
    const { color = [255, 215, 0], onClick = () => {} } = this.props // default gold

    const pointLayer = new ScatterplotLayer({ // grey with white border
      id: 'alldata-layer',
      data,
      pickable: true,
      opacity: 1,
      stroked: true,
      filled: true,
      getPosition: d => [+d.longitude, +d.latitude],
      onHover: this.onHover,
      onClick: e => onClick(e.object),
      radiusMinPixels: 7,
      radiusMaxPixels: 7,
      lineWidthMinPixels: 2,
      getFillColor: color,
      getLineColor: [255, 255, 255],
    })

    // return JSX
    return (
      <span>
        <DeckGL
          viewState={viewState}
          onViewStateChange={this.onViewStateChange}
          layers={[pointLayer]}
          controller
        >
          <ReactMapGL
            reuseMaps
            mapboxApiAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
            preventStyleDiffing
            mapStyle={REACT_APP_MAPBOX_STYLE}
            ref={(map) => { this.mapRef = map; this.onMapRefReceived(map) }}
          >
            <MapControlButtons resetViewState={() => this.resetViewState()} />
          </ReactMapGL>

        </DeckGL>
        {this.showTooltip()}
      </span>
    )
  }
}

export default SimplePointMap;
