import actionTypes from '../actions/actionTypes'

export const initialState = {}

function reducer(state = initialState, action) {
  switch (action.type) {
    // get settings
    case actionTypes.GET_GLOBAL_INFORMATION_REQUEST:
      return {
        ...state,
        [action.key]: {
          items: null,
          error: null,
          processing: true,
        },
      }
    case actionTypes.GET_GLOBAL_INFORMATION_SUCCESS:
      return {
        ...state,
        [action.key]: {
          items: action.items,
          error: null,
          processing: false,
        },
      }
    case actionTypes.GET_GLOBAL_INFORMATION_FAILURE:
      return {
        ...state,
        [action.key]: {
          items: null,
          error: action.error,
          processing: false,
        },
      }
    default:
      return state
  }
}

export default reducer
