import { Table, Popconfirm, Typography, Tooltip, Button, Tag, Icon } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import actions from '../../../actions';
import { cleanFleetCardsName } from '../../../helpers/constants';
import countriesMapping from '../../../assets/data/countriesMapping.json';
import { TooltipButton } from '../../../helpers';
import * as strings from '../../../helpers/defaultStrings';
import EditFleetCard from './EditFleetCard';
import { flatten, isEmpty, isNil, toLower } from 'lodash';
import AddFleetCard from './AddFleetCard';


class FleetCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editOpen: false,
      addOpen: false,
      selectedCard: null,
    };
  }

  componentDidMount() {
    const {
      getPaymentCards, getFleetSettings, paymentCards, cards, groups, getGroups,
    } = this.props;
    if (isNil(paymentCards) || isEmpty(paymentCards)) getPaymentCards();
    if (isNil(cards) || isEmpty(cards)) getFleetSettings();
    if (isNil(groups) || isEmpty(groups)) getGroups();
  }


  onAddOk = (card) => {
    const {
      paymentCards, groups, saveCards, cards,
    } = this.props;
    const allGroups = groups.reduce((acc, obj) => ({ ...acc, [obj.group]: obj.subgroups }), {});
    const selectedCard = {
      card,
      restrictions: [{
        groups: allGroups, brands: [], countries: [], notbrands: [], notcountries: [],
      }],
    };
    saveCards([...cards, selectedCard]);
    this.setState({
      addOpen: false,
      editOpen: true,
      selectedCard: { ...selectedCard, cleanName: cleanFleetCardsName(paymentCards[card]) },
    });
  }
  onEditOk = (card) => {
    const { cards, saveCards } = this.props;
    const cardsToSave = cards.map((c) => {
      if (c.card === card.card) {
        return card;
      }
      return c;
    });
    saveCards(cardsToSave);
    this.setState({ editOpen: false });
  }
  onDeleteOk = (card) => {
    const { cards, saveCards } = this.props;
    saveCards(cards.filter(c => c.card !== card.card));
  }

  getColumns() {
    const { paymentCards, paymentCardsLoading, intl: { formatMessage } } = this.props;

    const columns = [
      {
        title: 'Card',
        key: 'card',
        render: (_, record) => <Tag color="blue" style={{ fontSize: 16, fontWeight: 700 }}>{paymentCardsLoading ? cleanFleetCardsName(record.card) : cleanFleetCardsName(paymentCards[record.card]) }</Tag>,
        width: 100,
        fixed: 'left',
      },
      {
        title: 'Groups',
        key: 'groups',
        render: (_, record) => record.restrictions.map((restriction, index) => this.contentLine(Object.keys(restriction.groups), index, record.restrictions.length, 'groups')),
        className: 'fleetCardsColumn',
        align: 'center',
        width: 400,
      },
      {
        title: 'Subgroups',
        key: 'subgroups',
        render: (_, record) => record.restrictions.map((restriction, index) => this.contentLine(Object.values(restriction.groups).flat(), index, record.restrictions.length, 'subgroups', Object.keys(restriction.groups))),
        className: 'fleetCardsColumn',
        align: 'center',
        width: 400,
      },
      {
        title: 'Brands',
        key: 'Brands',
        render: (_, record) => record.restrictions.map((restriction, index) => {
          const brandsKeyBool = isNil(restriction.notbrands) || isEmpty(restriction.notbrands);
          return (this.contentLine(
            brandsKeyBool ? restriction.brands : restriction.notbrands,
            index,
            record.restrictions.length,
            brandsKeyBool ? 'brands' : 'notbrands',
          ));
        }),
        className: 'fleetCardsColumn',
        align: 'center',
        width: 400,
      },
      {
        title: 'Countries',
        key: 'Countries',
        render: (_, record) => record.restrictions.map((restriction, index) => {
          const countriesKeyBool = isNil(restriction.notcountries) || isEmpty(restriction.notcountries);
          return (this.contentLine(
            countriesKeyBool ? restriction.countries.map(c => countriesMapping[c]) : restriction.notcountries.map(c => countriesMapping[c]),
            index,
            record.restrictions.length,
            countriesKeyBool ? 'countries' : 'notcountries',
          ));
        }),
        className: 'fleetCardsColumn',
        align: 'center',
        width: 400,
      },
      {
        key: 'actions',
        render: (_, record) => (
          <div>
            <TooltipButton
              icon="edit"
              onClick={() => this.openEdit(record)}
              tooltip={formatMessage(strings.word.edit)}
            />
            <Popconfirm
              title={formatMessage(strings.message.warningMessageForFleetCardDelete)}
              onConfirm={() => this.onDeleteOk(record)}
              onCancel={() => {}}
              okText={formatMessage(strings.word.yes)}
              cancelText={formatMessage(strings.word.cancel)}
            >
              <TooltipButton icon="delete" tooltip={formatMessage(strings.word.remove)} />
            </Popconfirm>
          </div>
        ),
        align: 'center',
        width: 100,
        fixed: 'right',
      },
    ];
    return columns;
  }

  contentLine = (content, index, restrictionsLength, column, groupsArray) => {
    const { groups: groupsData } = this.props;
    const groups = !isEmpty(groupsData) ? groupsData.map(g => g.group) : [];
    const subgroupsData = !isEmpty(groupsData) ? flatten(groupsData.map(g => g.subgroups)) : [];
    const { formatMessage } = this.props.intl;
    let renderString = formatMessage(strings.word.none);
    let tooltip = null;
    const contentLength = content.length;
    switch (column) {
      case 'groups':
        if (contentLength > 0) renderString = content.join(', ');
        // check if a group hasn't been modified
        content.forEach(((c) => {
          if (!groups.includes(c)) {
            tooltip = [formatMessage(strings.message.groupModification)];
            renderString = <Icon type="warning" theme="filled" style={{ color: 'red' }} />;
          }
        }));
        break;
      case 'subgroups':
        if (contentLength === 1) {
          [renderString] = content;
        } else {
          tooltip = content;
          const allSubgroupsNumber = groupsArray.reduce((acc, group) => {
            if (!isEmpty(groupsData) && !isNil(groupsData.find(g => g.group === group))) {
              const subgroups = (groupsData.find(g => g.group === group)).subgroups;
              return acc + subgroups.length;
            }
            return acc;
          }, 0);
          if (contentLength === allSubgroupsNumber) {
            renderString = formatMessage(strings.phrase.allSubgroups);
          } else {
            renderString = `${contentLength} ${toLower(formatMessage(strings.word.subgroups))}`;
          }
        }
        // check if a subgroup hasn't been modified
        content.forEach(((c) => {
          if (!subgroupsData.includes(c)) {
            renderString = <Icon type="warning" theme="filled" style={{ color: 'red' }} />;
            tooltip = [formatMessage(strings.message.subgroupModification)];
          }
        }));
        break;
      case 'brands':
        if (contentLength === 0) {
          renderString = formatMessage(strings.phrase.allBrands);
        } else {
          renderString = content.join(', ');
          tooltip = content;
        }
        break;
      case 'notbrands':
        renderString = `${formatMessage(strings.phrase.allBrands)} ${formatMessage(strings.word.except)} ${content.join(', ')}`;
        tooltip = content;
        break;
      case 'countries':
        if (contentLength === 0) {
          renderString = formatMessage(strings.phrase.allCountries);
        } else {
          renderString = content.join(', ');
          tooltip = content;
        }
        break;
      case 'notcountries':
        renderString = `${formatMessage(strings.phrase.allCountries)} ${formatMessage(strings.word.except)} ${content.join(', ')}`;
        tooltip = content;
        break;
      default:
        break;
    }
    return (
      <div
        key={`${content[0]}-${index}`}
        style={{
        paddingTop: 20,
        paddingBottom: 20,
        borderBottom: index === restrictionsLength - 1 ? 'none' : '1px dashed #cccccc',
        }}
      >
        <Tooltip
          title={!isNil(tooltip) && tooltip.join(', ')}
        >
          <Typography
            style={{
            textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10,
           }}
          >
            {renderString}
          </Typography>
        </Tooltip>
      </div>
    );
  }
  openEdit = (record) => {
    const { paymentCards } = this.props;
    this.setState({ editOpen: true, selectedCard: { ...record, cleanName: cleanFleetCardsName(paymentCards[record.card]) } });
  }
  closeEdit = () => this.setState({ editOpen: false })
  openAdd = () => this.setState({ addOpen: true })
  closeAdd = () => this.setState({ addOpen: false })


  render() {
    const {
      paymentCardsLoading, fleetSettingsFetching, cards, groups, groupsLoading, brands, paymentCards, intl: { formatMessage },
    } = this.props;
    const { editOpen, selectedCard, addOpen } = this.state;
    const cardsYouCanSelect = { ...paymentCards };
    cards.map(card => card.card).forEach(c => delete cardsYouCanSelect[c]);
    return (
      <div>
        <Button style={{ marginBottom: 10 }} onClick={this.openAdd}> {formatMessage(strings.phrase.addFleetCard)} </Button>
        <Table
          className="fleet-cards-table"
          style={{ width: 'calc(100% - 20px)' }}
          loading={paymentCardsLoading || fleetSettingsFetching || groupsLoading}
          rowKey="card"
          showHeader
          dataSource={cards}
          columns={this.getColumns()}
          pagination={{ hideOnSinglePage: true }}
          scroll={{ x: 'max-content' }}
        />
        {editOpen && <EditFleetCard
          open={editOpen}
          onOk={card => this.onEditOk(card)}
          onCancel={this.closeEdit}
          card={selectedCard}
          groups={groups}
          brands={brands}
        />}
        {
          addOpen && <AddFleetCard
            open={addOpen}
            onOk={card => this.onAddOk(card)}
            onCancel={this.closeAdd}
            fleetCards={cardsYouCanSelect}
          />
        }
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    cards: store.fleetSettings.items && store.fleetSettings.items.cards,
    brands: store.fleetSettings.items && store.fleetSettings.items.brands,
    fleetSettingsFetching: store.fleetSettings.fetching,
    fleetSettingsProcessing: store.fleetSettings.processing,
    paymentCards: store.globalInformations.paymentCards && store.globalInformations.paymentCards.items,
    paymentCardsLoading: store.globalInformations.paymentCards && store.globalInformations.paymentCards.processing,
    paymentCardsError: store.globalInformations.paymentCards && store.globalInformations.paymentCards.error,
    groups: store.groups.items,
    groupsLoading: store.groups.fetching,
    groupsError: store.groups.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFleetSettings: () => dispatch(actions.fleetSettings.getFleetSettings()),
    saveCards: cards => dispatch(actions.fleetSettings.saveFleetSettings({ cards })),
    addSuccessMessage: msg => dispatch(actions.messages.addSuccessMessage(msg)),
    addErrorMessage: msg => dispatch(actions.messages.addErrorMessage(msg)),
    getPaymentCards: () => dispatch(actions.globalInformations.getPaymentCards()),
    getGroups: () => dispatch(actions.groups.getGroups()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FleetCards));
