import API from '@aws-amplify/api'

import actionTypes from './actionTypes'

const getTruckListRequest = () => ({ type: actionTypes.GET_TRUCK_LIST_REQUEST });
const getTruckListSuccess = items => ({ type: actionTypes.GET_TRUCK_LIST_SUCCESS, items });
const getTruckListFailure = error => ({ type: actionTypes.GET_TRUCK_LIST_FAILURE, error });
const insertAndDeleteTrucksRequest = () => ({ type: actionTypes.INSERT_AND_DELETE_REQUEST })
const insertAndDeleteTrucksSuccess = trucks => ({ type: actionTypes.INSERT_AND_DELETE_SUCCESS, trucks })
const insertAndDeleteTrucksFailure = error => ({ type: actionTypes.INSERT_AND_DELETE_FAILURE, error })

function getTruckList() {
    return (dispatch) => {
      dispatch(getTruckListRequest());
      const url = `trucks`;
      return API.get('Backend', url)
      .then((data) => {
        dispatch(getTruckListSuccess(data));
      })
      .catch(err => dispatch(getTruckListFailure(err)));
    };
  };
  
  function insertAndDeleteTrucks(truckList) {
    return (dispatch) => {
      dispatch(insertAndDeleteTrucksRequest());
      const url = `trucks`;
      return API.post('Backend', url, { body: truckList })
      .then(() => dispatch(insertAndDeleteTrucksSuccess(truckList)))
      .catch(err => dispatch(insertAndDeleteTrucksFailure(truckList, err)));
    };
  };

export default {
    getTruckList,
    insertAndDeleteTrucks,
}
