import actionTypes from '../actions/actionTypes';

const initialState = {
    processing: false,
    error: null,
    truckList: null,
  };


export default function reducer(state = initialState, action) {
    switch (action.type) {
      case actionTypes.GET_TRUCK_LIST_REQUEST: {
        return {
          ...state,
          processing: true,
          error: null,
        };
      }
      case actionTypes.GET_TRUCK_LIST_SUCCESS: {
        return {
          ...state,
          truckList: [...action.items.truckList],
          processing: false,
          error: null,
        };
      }
      case actionTypes.GET_TRUCK_LIST_FAILURE: {
        return {
          ...state,
          truckList: null,
          processing: false,
          error: action.error,
        };
      }
      default:
        return state;
    }
  }
  