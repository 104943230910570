import React from 'react'
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from 'antd'

import * as strings from '../../../helpers/defaultStrings';

const SUPPORT_EMAIL = 'support@aloalto.com'
const NEW_LINE = '%0d%0a'

const SendMailBtn = ({ intl, user, ...remainingProps }) => {
  const { formatMessage } = intl
  if (!user) {
    return <Button disabled>{formatMessage(strings.mail.sendMail)}</Button>
  }
  // const name = user.name ? user.name.givenName : null
  const {
    skyId, displayName, name, emails = [],
  } = user
  const senderName = displayName || (name && `${name.givenName} ${name.familyName}`) || ''
  const body = [
    formatMessage(strings.mail.body1),
    NEW_LINE,
    `<${formatMessage(strings.mail.body2)}>`,
    NEW_LINE,
    NEW_LINE,
    formatMessage(strings.mail.body3, { name: senderName, skyId }),
  ].join('')
  const mailto = `
    mailto:${SUPPORT_EMAIL}
    ?subject=${formatMessage(strings.word.fuelBot)}: ${formatMessage(strings.mail.subject)}
    &cc=${emails.join(', ')}
    &body=${body}`
  return (
    <Button
      {...remainingProps}
      href={mailto}
    >
      {formatMessage(strings.mail.sendMail)}
    </Button>
  )
}


function mapStateToProps(store) {
  const {
    auth: { user },
  } = store
  return {
    user,
  }
}

export default connect(mapStateToProps)(injectIntl(SendMailBtn))
