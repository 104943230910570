import API from '@aws-amplify/api'

import actionTypes from './actionTypes'

const getGroupsRequest = () => ({ type: actionTypes.GET_GROUPS_REQUEST })
const getGroupsSuccess = items => ({ type: actionTypes.GET_GROUPS_SUCCESS, items })
const getGroupsFailure = error => ({ type: actionTypes.GET_GROUPS_FAILURE, error })

function getGroups() {
  return (dispatch) => {
    const url = 'groups'
    dispatch(getGroupsRequest())
    return API.get('Backend', url)
      .then(data => dispatch(getGroupsSuccess(data || {})))
      .catch(err => dispatch(getGroupsFailure(err)))
  }
}

export default {
  getGroups,
}
