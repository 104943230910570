import { Card, Typography } from 'antd';
import React from 'react';
import * as strings from '../../helpers/defaultStrings';

const gridStyle = {
  width: '50%',
  textAlign: 'center',
  borderStyle: 'none !important',
};


const KeyNumbers = ({
  locale, formatMessage, totalReco, aggregateTotalKm, aggregateKmPerReco, avgStationPrice,
}) => (
  <Card style={{ marginTop: 100 }}>
    <Card.Grid hoverable={false} style={gridStyle}>
      <p>{formatMessage(strings.phrase.totalRecommendations)}</p>
      <Typography.Title level={3} style={{ color: 'dodgerblue' }}>{new Intl.NumberFormat(locale).format(totalReco) }</Typography.Title>
    </Card.Grid>
    <Card.Grid hoverable={false} style={gridStyle}>
      <p>{formatMessage(strings.phrase.totalDistance)}</p>
      <Typography.Title level={3} style={{ color: 'dodgerblue' }}>{`${new Intl.NumberFormat(locale).format(aggregateTotalKm)} km` }
      </Typography.Title>
    </Card.Grid>
    <Card.Grid hoverable={false} style={gridStyle}>
      <p>{formatMessage(strings.phrase.averageDistance)}</p>
      <Typography.Title level={3} style={{ color: 'dodgerblue' }}>{`${aggregateKmPerReco ? new Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(aggregateKmPerReco) : ' -'} km` }</Typography.Title>
    </Card.Grid>
    <Card.Grid hoverable={false} style={gridStyle}>
      <p>{formatMessage(strings.phrase.averagePrice)}</p>
      <Typography.Title level={3} style={{ color: 'dodgerblue' }}>{new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 3 }).format(avgStationPrice) }</Typography.Title>
    </Card.Grid>
  </Card>
);

export default KeyNumbers;
