import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Input, Modal, Icon, Col, Select, Button } from 'antd';
import * as strings from '../../../helpers/defaultStrings';
import { DiscountValue } from '../../../helpers';
import { cleanFleetCardsName, discountTypes } from '../../../helpers/constants';

const { Option } = Select

const EditMultipleStations = (props) => {
  const {
    stationsToEdit,
    open,
    close,
    addStations,
    settingsCurrency,
    fleetCards,
    paymentCards,
    editNameAndDiscount,
  } = props
  const [editValues, setEditValues] = useState({
    name: undefined,
    discountValue: 0,
    discountType: discountTypes.PERCENT,
    discountCurrency: settingsCurrency,
    fleetCard: undefined,
  })
  const { formatMessage } = props.intl

  const onOk = () => {
    if (editValues.discountValue === 0) {
      const {
        discountValue, discountType, discountCurrency, ...stationWithoutDiscount
      } = editValues // Remove fleetcard also ?
      addStations(stationsToEdit.map(station => ({ ...station, ...stationWithoutDiscount })))
    } else {
      addStations(stationsToEdit.map(station => ({ ...station, ...editValues })))
    }
    close()
  }
  const onNameChange = (e) => {
    setEditValues({ ...editValues, name: e.target.value })
  }
  const editDiscountValue = discountValue => setEditValues({ ...editValues, discountValue })
  const editDiscountType = discountType => setEditValues({ ...editValues, discountType })
  const editDiscountCurrency = discountCurrency => setEditValues({ ...editValues, discountCurrency })
  const onFleetCardChange = fleetCard => setEditValues({ ...editValues, fleetCard })
  const resetDiscount = () => {
    setEditValues({
      ...editValues,
      discountValue: 0,
      discountType: discountTypes.PERCENT,
      discountCurrency: settingsCurrency,
      fleetCard: undefined,
    })
  }

  const title = (
    <span>
      <Icon type="edit" theme="twoTone" twoToneColor="dodgerblue" />
      {`  ${formatMessage(strings.phrase.editStation)} multiple`}
    </span>
  )

  return (
    <Modal
      title={title}
      visible={open}
      onOk={onOk}
      okText={formatMessage(strings.word.save)}
      cancelText={formatMessage(strings.word.cancel)}
      onCancel={close}
      bodyStyle={{ height: 200 }}
    >
      <Col span={12}>
        <Input placeholder={formatMessage(strings.phrase.stationsName)} onChange={onNameChange} style={{ maxWidth: 200 }} />
      </Col>
      {editNameAndDiscount && (<Col span={12}>
        <div style={{
          border: '1px dashed #00000040', borderRadius: 4, padding: 10, textAlign: 'center',
        }}
        >
          <DiscountValue
            editable
            onValueChange={editDiscountValue}
            onTypeChange={editDiscountType}
            onCurrencyChange={editDiscountCurrency}
            value={editValues.discountValue}
            type={editValues.discountType}
            currency={editValues.discountCurrency}
          />
          <Select
            value={editValues.fleetCard}
            size="small"
            onChange={onFleetCardChange}
            style={{ minWidth: 150, marginTop: 15, marginBottom: 15 }}
            placeholder={formatMessage(strings.phrase.selectFleetCard)}
          >
            {fleetCards.map(c => <Option key={c.card}>{cleanFleetCardsName(paymentCards[c.card])}</Option>)}
          </Select>
          <Button onClick={resetDiscount} size="small" type="danger">{formatMessage(strings.phrase.resetDiscount)}</Button>
        </div>
      </Col>)}
    </Modal>
  )
}

export default injectIntl(EditMultipleStations)
