import React from 'react'
import _ from 'lodash';
import { Form, Row, Col, Button, Icon, DatePicker, Select, AutoComplete } from 'antd';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { RenderedConstant, getStrId } from '../../helpers/constants'

import * as strings from '../../helpers/defaultStrings';
import { INITIAL_FILTER } from './FuelBot';


const { RangePicker } = DatePicker;
const { Option } = Select;

class FilterForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  handleReset = () => {
    const { form: { resetFields }, onFilter } = this.props
    resetFields()
    onFilter(INITIAL_FILTER)
  }

  toggleExpanded = () => this.setState({ expanded: !this.state.expanded })

  getAdvancedSelect = (key) => {
    const { options, filters } = this.props
    const { formatMessage } = this.props.intl
    const disabled = !options || !options[key] || options[key].length <= 1 || (options && key === 'subgroups' && _.isNil(filters.groups) && options.groups.length > 1)

    let placeholder = options && options[key] && options[key].length === 1 ? options[key][0] : null
    let renderOption = x => <Option value={x} key={x}>{x}</Option>
    let optionFilterProp = 'value'
    if (key === 'status' || key === 'type') {
      placeholder = placeholder && <RenderedConstant type={placeholder} />
      optionFilterProp = 'label'
      renderOption = k => (
        <Option
          value={k}
          key={k}
          label={formatMessage(getStrId(k))}
        >
          <RenderedConstant type={k} />
        </Option>
      )
    }
    return (
      <Select
        style={{ minWidth: 200 }}
        allowClear
        showSearch
        optionFilterProp={optionFilterProp}
        disabled={disabled}
        placeholder={placeholder}
      >
        {options && options[key] && options[key].map(renderOption)}
      </Select>
    )
  }

  getAutoCompleteSelect = (key) => {
    const { options } = this.props
    const disabled = !options || !options[key] || options[key].length <= 1
    const driverName = options && options[key] && options[key].length > 0 ? options[key] : []
    const { Option: AutoCompleteOption } = AutoComplete;

    const values = driverName.map(opt => (
      <AutoCompleteOption key={opt} value={opt} label={opt}>
        {opt}
      </AutoCompleteOption>
    ))

    let placeholder = options && options[key] && options[key].length === 1 ? options[key][0] : null
    if (key === 'status' || key === 'type') {
      placeholder = placeholder && <RenderedConstant type={placeholder} />
    }

    return (
      <AutoComplete
        allowClear
        dataSource={values}
        style={{ width: 200 }}
        optionLabelProp="label"
        filterOption={(inputValue, option) =>
          option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        disabled={disabled}
        placeholder={placeholder}
        defaultOpen={false}
      />
    )
  }

  getDateSelector = () => {
    const { options } = this.props
    if (!options || !options.date || options.date.length === 0) { // no options
      return <RangePicker disabled />
    } else if (options.date.length === 1) { // only one option
      const defaultValue = [options.date[0].format('ll'), options.date[0].format('ll')]
      return <RangePicker disabled placeholder={defaultValue} />
    }
    return (
      <RangePicker
        format="ll"
        disabledDate={x => !options.date.some(e => e.isSame(x, 'day'))}
      />
    )
  }

  render() {
    const { useInternalVehicleId, useInternalDriverName, info } = this.props
    const { expanded } = this.state
    const { getFieldDecorator } = this.props.form;
    const { formatMessage } = this.props.intl

    return (
      <Form layout="inline">
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item label={formatMessage(strings.word.date)}>
              {getFieldDecorator('date')(this.getDateSelector())}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={formatMessage(strings.phrase.truckN)}>
              {getFieldDecorator('truckid')(this.getAdvancedSelect('truckid'))}
            </Form.Item>
          </Col>
          <Col style={{ display: expanded ? 'block' : 'none' }}>
            {useInternalVehicleId ? (
              <Form.Item label={formatMessage(strings.phrase.truckInternalId)}>
                {getFieldDecorator('internalvehicleid')(this.getAdvancedSelect('internalvehicleid'))}
              </Form.Item>
            ) : (
              <Form.Item label={formatMessage(strings.phrase.plateNumber)}>
                {getFieldDecorator('platenumber')(this.getAdvancedSelect('platenumber'))}
              </Form.Item>
            )}
          </Col>
          <Col style={{ display: expanded ? 'block' : 'none' }}>
            <Form.Item label={formatMessage(strings.phrase.driverId)}>
              {getFieldDecorator('driver')(this.getAdvancedSelect('driver'))}
            </Form.Item>
          </Col>
          <Col style={{ display: expanded && useInternalDriverName ? 'block' : 'none' }}>
            <Form.Item label={formatMessage(strings.phrase.driverName)}>
              {getFieldDecorator('driverName')(this.getAutoCompleteSelect('driverName'))}
            </Form.Item>
          </Col>
          <Col style={{ display: expanded ? 'block' : 'none' }}>
            <Form.Item label={formatMessage(strings.phrase.planningType)}>
              {getFieldDecorator('type')(this.getAdvancedSelect('type'))}
            </Form.Item>
          </Col>
          <Col style={{ display: expanded ? 'block' : 'none' }}>
            <Form.Item label={formatMessage(strings.word.status)}>
              {getFieldDecorator('status')(this.getAdvancedSelect('status'))}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col style={{ display: expanded ? 'block' : 'none' }}>
            <Form.Item label={formatMessage(strings.word.groups)}>
              {getFieldDecorator('groups')(this.getAdvancedSelect('groups'))}
            </Form.Item>
          </Col>
          <Col style={{ display: expanded ? 'block' : 'none' }}>
            <Form.Item label={formatMessage(strings.word.subgroups)}>
              {getFieldDecorator('subgroups')(this.getAdvancedSelect('subgroups'))}
            </Form.Item>
          </Col>
        </Row>
        {/* buttons */}
        <Row type="flex" justify="space-between" align="bottom">
          <Col span={12}>
            {info}
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
              {formatMessage(strings.word.clear)}
            </Button>
            <Button
              style={{
                marginLeft: 8, fontSize: 12, background: 'none', border: 'none', color: '#1890ff',
              }}
              onClick={this.toggleExpanded}
            >
              {expanded ? formatMessage(strings.word.less) : formatMessage(strings.word.more)} <Icon type={expanded ? 'up' : 'down'} />
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

function mapStateToProps(store) {
  const {
    recommendations,
    settings: { items: { useInternalVehicleId, useInternalDriverName } },
  } = store
  return {
    recommendations,
    useInternalVehicleId,
    useInternalDriverName,
  }
}

const WrappedFilterForm = Form.create({
  name: 'filter_recommendations_form',
  onValuesChange: (props, _, allValues) => props.onFilter(allValues),
})(FilterForm);
export default connect(mapStateToProps)(injectIntl(WrappedFilterForm))
