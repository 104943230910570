import actionTypes from '../actions/actionTypes';

const initialState = {
  truckid: null,
  minTfu: null,
  maxTfu: null,
  items: null,
  processing: false,
  error: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // get recommendation list
    case actionTypes.GET_COMPACT_EVENTS_REQUEST: {
      return {
        ...state,
        truckid: action.truckid,
        minTfu: action.minTfu,
        maxTfu: action.maxTfu,
        items: null,
        processing: true,
        error: null,
      }
    }
    case actionTypes.GET_COMPACT_EVENTS_SUCCESS: {
      return (state.truckid === action.truckid
          && state.minTfu === action.minTfu
          && state.maxTfu === action.maxTfu)
        ? {
          ...state,
          items: action.items,
          processing: false,
          error: null,
        } : state
    }
    case actionTypes.GET_COMPACT_EVENTS_FAILURE: {
      return (state.truckid === action.truckid
          && state.minTfu === action.minTfu
          && state.maxTfu === action.maxTfu)
        ? {
          ...state,
          items: null,
          processing: false,
          error: action.error,
        } : state
    }
    default:
      return state;
  }
}
