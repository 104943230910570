import React from 'react'
import { Icon, Divider } from 'antd'

import * as strings from '../../helpers/defaultStrings';
import './UserMenu.css'

const UserDropdown = (props) => {
  const {
    userName, goToSettings, logOut, formatMessage,
  } = props;
  return (
    <div>
      <p className="user-menu-display-name">
        {formatMessage(strings.phrase.userGreetingVal, { value: userName })}
      </p>
      <Divider />
      <div>
        <div
          role="link"
          className="user-menu-menu-item"
          onClick={goToSettings}
        >
          <Icon type="setting" style={{ marginRight: 8 }} />
          {formatMessage(strings.word.settings)}
        </div>
        <div
          role="link"
          className="user-menu-menu-item"
          onClick={logOut}
        >
          <Icon type="logout" style={{ marginRight: 8 }} />
          {formatMessage(strings.word.logOut)}
        </div>
      </div>
    </div>
  )
}

export default UserDropdown
