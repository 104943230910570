import React from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import {
  Table,
  Button,
  Popconfirm,
  Select,
  Icon,
  Tooltip,
  Empty,
  Tag,
  Row,
  Col,
  Alert,
} from "antd";
import _ from "lodash";

import * as strings from "../../helpers/defaultStrings";
import actions from "../../actions";
import countries from "../../assets/data/countriesMapping.json";
import stationBrands from "../../assets/data/stationBrandsMapping.json";
import { DiscountValue, TooltipButton } from "../../helpers";
import { cleanFleetCardsName } from "../../helpers/constants";

const cleanedObj = (obj) =>
  _.pickBy(obj, (e) => (Array.isArray(e) ? e.length > 0 : !!e));
const COLHEIGHT = "calc(100vh - 64px - 32px - 56px - 45px - 32px)";
const NEVER_APPLICABLE = "neverApplicable";
const ALWAYS_APPLICABLE = "alwaysApplicable";

const InvalidItem = ({ value, ...rest }) => (
  <span {...rest}>
    <Icon
      type="exclamation-circle"
      theme="filled"
      style={{ color: "silver" }}
    />
    <span style={{ marginLeft: 4 }}>{value}</span>
  </span>
);

const MiniMultiChoose = ({ icon, options, ...props }) => (
  <Select
    size="small"
    mode="multiple"
    tokenSeparators={[","]}
    style={{ minWidth: 180 }}
    suffixIcon={<Icon type={icon} />}
    showArrow
    allowClear
    optionFilterProp="name"
    {...props}
  >
    {options.map((option) => (
      <Select.Option key={option.key} value={option.key} name={option.name}>
        {option.invalid ? (
          <InvalidItem style={{ color: "silver" }} value={option.name} />
        ) : (
          option.name
        )}
      </Select.Option>
    ))}
  </Select>
);

class Discounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tempDiscount: null,
      discountsWithInvalidCards: [],
      discountsWithInvalidBrands: [],
      invalidities: {},
      allowedBrands: [],
      allowedCountries: [],
    };
  }

  componentDidMount() {
    this.props.getDiscounts();
  }

  componentDidUpdate(prevProps) {
    const { tempDiscount } = this.state;
    const {
      discounts: { error, processing },
      fleetSettingsProcessing,
      fleetSettingsFetching,
      addErrorMessage,
      intl: { formatMessage },
    } = this.props;
    if (
      tempDiscount &&
      prevProps.discounts.editingId &&
      tempDiscount.id === prevProps.discounts.editingId &&
      !this.props.discounts.editingId
    ) {
      this.cancelEditing();
    }
    if (error && !prevProps.discounts.error) {
      if (prevProps.discounts.editingId)
        addErrorMessage(formatMessage(strings.message.failedToSaveDiscount));
      else if (prevProps.discounts.deletingId)
        addErrorMessage(formatMessage(strings.message.failedToDeleteDiscount));
      else
        addErrorMessage(formatMessage(strings.message.failedToGetDiscountList));
    }
    if (
      (prevProps.discounts.processing && !processing) ||
      (prevProps.fleetSettingsProcessing && !fleetSettingsProcessing) ||
      (prevProps.fleetSettingsFetching && !fleetSettingsFetching)
    ) {
      this.onValiditiesChanged();
    }
  }

  edit(item) {
    if (
      item.conditions &&
      (item.conditions.payment || item.conditions.notpayment)
    ) {
      const key =
        item.conditions && item.conditions.notpayment
          ? "notpayment"
          : "payment";
      const value =
        item.conditions && item.conditions.notpayment
          ? item.conditions.notpayment
          : item.conditions.payment;
      this.applyFleetSettings(key, value, item);
    } else {
      this.setState({ tempDiscount: item });
    }
  }

  duplicate(item) {
    this.edit({ ...item, id: -1 });
  }
  createItem() {
    const { currency } = this.props;
    this.edit({
      id: -1,
      value: 0,
      type: "percent",
      conditions: {},
      currency,
    });
  }

  validateEditing() {
    this.props.saveDiscount(this.state.tempDiscount);
  }
  cancelEditing() {
    this.setState({ tempDiscount: null });
  }

  onValiditiesChanged() {
    const {
      discounts: { items },
      fleetSettings,
    } = this.props;

    const discountsWithInvalidBrands = [];
    const discountsWithInvalidCards = [];
    const invalidities = {};

    // not in all valid brand is never applicable, not in 0 brand is always applicable.. etc
    const maxBrands = fleetSettings ? fleetSettings.brands.length : 0;
    const maxCards = fleetSettings ? fleetSettings.cards.length : 0;
    const getInvalidity = {
      notbrand: (len) =>
        len === maxBrands
          ? NEVER_APPLICABLE
          : len === 0
          ? ALWAYS_APPLICABLE
          : null,
      brand: (len) =>
        len === maxBrands
          ? ALWAYS_APPLICABLE
          : len === 0
          ? NEVER_APPLICABLE
          : null,
      notpayment: (len) =>
        len === maxCards
          ? NEVER_APPLICABLE
          : len === 0
          ? ALWAYS_APPLICABLE
          : null,
      payment: (len) =>
        len === maxCards
          ? ALWAYS_APPLICABLE
          : len === 0
          ? NEVER_APPLICABLE
          : null,
    };

    items.forEach((discount) => {
      const { id, conditions: oldConditions } = discount;
      for (const keyToClean of ["brand", "notbrand", "payment", "notpayment"]) {
        if (oldConditions && oldConditions[keyToClean]) {
          const oldList = oldConditions[keyToClean];
          const validator = keyToClean.includes("brand")
            ? this.isValidBrand
            : this.isValidCard;
          const validList = oldList.filter(validator);

          // storing for later if some brands/cards are invalid
          if (validList.length !== oldList.length) {
            const conditions = cleanedObj({
              ...oldConditions,
              [keyToClean]: validList,
            });
            const newDiscount = { ...discount, conditions };
            const resultArray = keyToClean.includes("brand")
              ? discountsWithInvalidBrands
              : discountsWithInvalidCards;
            resultArray.push(newDiscount);
          }

          // checking always applicable / never aapplicable
          const invalidity = getInvalidity[keyToClean](validList.length);
          if (invalidity) {
            if (!invalidities[id]) invalidities[id] = {};
            invalidities[id][keyToClean] = invalidity;
          }
        }
      }
    });
    this.setState({
      discountsWithInvalidCards,
      discountsWithInvalidBrands,
      invalidities,
    });
  }

  cleanAllConditionsBrand = () =>
    this.props.batchSaveDiscount(this.state.discountsWithInvalidBrands);
  cleanAllConditionsCard = () =>
    this.props.batchSaveDiscount(this.state.discountsWithInvalidCards);

  getBradOptions = (value, condition, discountObj) => {
    const { cards, fleetSettings } = this.props;
    const selectedCards = value;
    let isRestrictionsAvailable = false;

    const brandKey =
      discountObj.conditions && discountObj.conditions.notbrand
        ? "notbrand"
        : "brand";

    let brandOptions = fleetSettings.brands.map((key) => ({
      key,
      name: stationBrands[key],
    }));
    if (discountObj.conditions && discountObj.conditions[brandKey]) {
      const invalidOptions = discountObj.conditions[brandKey]
        .filter((e) => !this.isValidBrand(e))
        .map((key) => ({ key, name: stationBrands[key], invalid: true }));
      brandOptions = brandOptions.concat(invalidOptions);
    }

    const filteredBrands = cards.filter((c) => {
      if (condition === "payment") {
        return selectedCards.find((sc) => {
          return sc === c.card;
        });
      } else {
        return !selectedCards.find((sc) => {
          return sc === c.card;
        });
      }
    });

    isRestrictionsAvailable = filteredBrands.find((fc) => {
      return fc.restrictions.find((res) => {
        return res.brands.find((brand) => {
          if (brand) return true;
          else return false;
        });
      });
    });

    if (filteredBrands.length) {
      let allowedBrands = [];
      let restrictedBrands = [];

      if (isRestrictionsAvailable) {
        filteredBrands.forEach((item, index) => {
          if (item.restrictions) {
            item.restrictions.forEach((res) => {
              if (index === 0) {
                if (res.brands && res.brands.length) {
                  restrictedBrands = res.brands;
                } else {
                  restrictedBrands = brandOptions.map((c) => c.key);
                }
              } else {
                if (res.brands && res.brands.length) {
                  restrictedBrands = restrictedBrands.filter((fb) => {
                    return res.brands.find((brand) => {
                      return fb === brand;
                    });
                  });
                }
              }
            });
          }
        });

        allowedBrands = restrictedBrands
          ? brandOptions.filter((brand) => {
              return restrictedBrands.find((resBrand) => {
                return resBrand === brand.key;
              });
            })
          : [];
      } else {
        allowedBrands = brandOptions;
      }

      const notAllowedBrands = brandOptions.filter((card) => {
        return filteredBrands.find((fc) => {
          return fc.restrictions.find((res) => {
            return res.notbrands.find((notbrand) => {
              return notbrand === card.key;
            });
          });
        });
      });

      return allowedBrands.filter((allowedBand) => {
        return !notAllowedBrands.find(
          (notAllowedBand) => notAllowedBand.key === allowedBand.key
        );
      });
    } else {
      return brandOptions;
    }
  };

  getCountryOptions = (value, condition) => {
    const { cards } = this.props;
    const selectedCards = value;
    let isRestrictionsAvailable = false;
    const countriesOptions = Object.keys(countries).map((key) => ({
      key,
      name: countries[key],
    }));

    const filteredBrands = cards.filter((c) => {
      if (condition === "payment") {
        return selectedCards.find((sc) => {
          return sc === c.card;
        });
      } else {
        return !selectedCards.find((sc) => {
          return sc === c.card;
        });
      }
    });

    isRestrictionsAvailable = filteredBrands.find((fc) => {
      return fc.restrictions.find((res) => {
        return res.countries.find((country) => {
          if (country) return true;
          else return false;
        });
      });
    });

    if (filteredBrands.length) {
      let allowedCountries = [];
      let restrictedCountries = [];

      if (isRestrictionsAvailable) {
        filteredBrands.forEach((item, index) => {
          if (item.restrictions) {
            item.restrictions.forEach((res) => {
              if (index === 0) {
                if (res.countries && res.countries.length) {
                  restrictedCountries = res.countries;
                } else {
                  restrictedCountries = countriesOptions.map((c) => c.key);
                }
              } else {
                if (res.countries && res.countries.length) {
                  restrictedCountries = restrictedCountries.filter((fc) => {
                    return res.countries.find((country) => {
                      return fc === country;
                    });
                  });
                }
              }
            });
          }
        });

        allowedCountries = restrictedCountries
          ? countriesOptions.filter((country) => {
              return restrictedCountries.find((resCountry) => {
                return resCountry === country.key;
              });
            })
          : [];
      } else {
        allowedCountries = countriesOptions;
      }

      const notAllowedCountries = countriesOptions.filter((country) => {
        return filteredBrands.find((fc) => {
          return fc.restrictions.find((res) => {
            return res.notcountries.find((notcountry) => {
              return notcountry === country.key;
            });
          });
        });
      });

      return allowedCountries.filter((allowedCountry) => {
        return !notAllowedCountries.find(
          (notAllowedCountry) => notAllowedCountry.key === allowedCountry.key
        );
      });
    } else {
      return countriesOptions;
    }
  };

  editDiscount = (key, value) => {
    this.setState({
      tempDiscount: { ...this.state.tempDiscount, [key]: value },
    });
  };

  editDiscountCondition = (key, value) => {
    const { tempDiscount } = this.state;

    if (key === "payment" || key === "notpayment") {
      this.applyFleetSettings(key, value, tempDiscount);
    } else {
      const newConditions = {
        ...tempDiscount.conditions,
        [key]: value,
      };
      this.editDiscount("conditions", cleanedObj(newConditions));
    }
  };

  applyFleetSettings = (key, value, discountObj) => {
    const allowedBrands = this.getBradOptions(value, key, discountObj);
    const allowedCountries = this.getCountryOptions(value, key);

    const brandKey =
      discountObj.conditions && discountObj.conditions.notbrand
        ? "notbrand"
        : "brand";
    const countryKey =
      discountObj.conditions && discountObj.conditions.notcountry
        ? "notcountry"
        : "country";

    const selectedBrands = discountObj.conditions[brandKey] || [];
    const selectedCountries = discountObj.conditions[countryKey] || [];
    const correctedBrands = selectedBrands.filter((brand) => {
      return allowedBrands.find((allowedBand) => allowedBand.key === brand);
    });
    const correctedCountries = selectedCountries.filter((country) => {
      return allowedCountries.find(
        (allowedCountry) => allowedCountry.key === country
      );
    });

    const newConditions = {
      ...discountObj.conditions,
      [key]: value,
      [brandKey]: correctedBrands,
      [countryKey]: correctedCountries,
    };
    this.setState({
      tempDiscount: {
        ...discountObj,
        conditions: cleanedObj(newConditions),
      },
      allowedBrands: allowedBrands,
      allowedCountries: allowedCountries,
    });
  };

  swapDiscount = (oldKey) => {
    const { tempDiscount } = this.state;
    const newConditions = { ...tempDiscount.conditions };
    const newKey = oldKey.includes("not")
      ? oldKey.replace("not", "")
      : `not${oldKey}`;
    newConditions[newKey] = tempDiscount.conditions[oldKey] || []; // copy value
    delete newConditions[oldKey]; // remove old key

    if (oldKey === "payment" || oldKey === "notpayment") {
      const discountObj = {
        ...this.state.tempDiscount,
        conditions: newConditions,
      };
      const key =
        discountObj.conditions && discountObj.conditions.notpayment
          ? "notpayment"
          : "payment";
      const value =
        discountObj.conditions && discountObj.conditions.notpayment
          ? discountObj.conditions.notpayment
          : discountObj.conditions.payment;

      this.applyFleetSettings(key, value, discountObj);
    } else {
      this.editDiscount("conditions", newConditions);
    }
  };

  getConditionEditor = () => {
    const { formatMessage } = this.props.intl;
    const { fleetSettings, fleetSettingsFetching, paymentCards } = this.props;
    const { tempDiscount } = this.state;

    let isPaymentCardSelected =
      (tempDiscount.conditions.payment &&
        tempDiscount.conditions.payment.length) ||
      (tempDiscount.conditions.notpayment &&
        tempDiscount.conditions.notpayment.length)
        ? true
        : false;

    if (!tempDiscount) return null;
    const swapBtn = (type) => (
      <Tooltip
        title={formatMessage(strings.phrase.invertSelection)}
        placement="right"
      >
        <Button
          size="small"
          icon="swap"
          type="dashed"
          style={{ marginLeft: 4 }}
          onClick={() => this.swapDiscount(type)}
        />
      </Tooltip>
    );

    // paymentKey is either 'notpayment' or 'payment', same for others
    const paymentKey =
      tempDiscount.conditions && tempDiscount.conditions.notpayment
        ? "notpayment"
        : "payment";
    const brandKey =
      tempDiscount.conditions && tempDiscount.conditions.notbrand
        ? "notbrand"
        : "brand";
    const countryKey =
      tempDiscount.conditions && tempDiscount.conditions.notcountry
        ? "notcountry"
        : "country";

    // for cards and brand, we take the options from fleetsettings
    // if any already selected value is invalid, we add it to the option list so that the value renders well
    let cardOptions = fleetSettings.cards.map((card) => ({
      key: card.card,
      name: cleanFleetCardsName(paymentCards[card.card]),
    }));
    if (tempDiscount.conditions && tempDiscount.conditions[paymentKey]) {
      const invalidOptions = tempDiscount.conditions[paymentKey]
        .filter((e) => !this.isValidCard(e))
        .map((key) => ({
          key,
          name: cleanFleetCardsName(paymentCards[key]),
          invalid: true,
        }));
      cardOptions = cardOptions.concat(invalidOptions);
    }

    return (
      <ul style={{ marginBottom: 0 }}>
        <li style={{ marginBottom: 4 }}>
          {paymentKey === "payment"
            ? formatMessage(strings.phrase.acceptsPaymentCard)
            : formatMessage(strings.phrase.doesNotAcceptsPaymentCard)}
          {" : "}
          <MiniMultiChoose
            icon="credit-card"
            loading={fleetSettingsFetching}
            notFoundContent={
              <Empty
                description={formatMessage(strings.phrase.noFleetCardAvailable)}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            }
            options={cardOptions}
            placeholder={formatMessage(strings.word.any)}
            value={
              tempDiscount.conditions && tempDiscount.conditions[paymentKey]
            }
            onChange={(value) => this.editDiscountCondition(paymentKey, value)}
          />
          {swapBtn(paymentKey)}
        </li>
        <li style={{ marginBottom: 4 }}>
          {brandKey === "brand"
            ? formatMessage(strings.phrase.isFromTheBrand)
            : formatMessage(strings.phrase.isNotFromTheBrand)}
          {" : "}
          <MiniMultiChoose
            icon="shop"
            disabled={!isPaymentCardSelected}
            loading={fleetSettingsFetching}
            notFoundContent={
              <Empty
                description={formatMessage(
                  strings.phrase.noAcceptedBrandAvailable
                )}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            }
            options={this.state.allowedBrands}
            placeholder={formatMessage(strings.word.any)}
            value={tempDiscount.conditions && tempDiscount.conditions[brandKey]}
            onChange={(value) => this.editDiscountCondition(brandKey, value)}
          />
          {swapBtn(brandKey)}
        </li>
        <li style={{ marginBottom: 4 }}>
          {countryKey === "country"
            ? formatMessage(strings.phrase.isIn)
            : formatMessage(strings.phrase.isNotIn)}
          {" : "}
          <MiniMultiChoose
            icon="global"
            disabled={!isPaymentCardSelected}
            options={this.state.allowedCountries}
            placeholder={formatMessage(strings.word.any)}
            value={
              tempDiscount.conditions && tempDiscount.conditions[countryKey]
            }
            onChange={(value) => this.editDiscountCondition(countryKey, value)}
          />
          {swapBtn(countryKey)}
        </li>
      </ul>
    );
  };

  isValidBrand = (brand) =>
    !this.props.fleetSettings ||
    this.props.fleetSettings.brands.includes(brand);
  isValidCard = (card) =>
    !this.props.fleetSettings ||
    this.props.fleetSettings.cards.map((c) => c.card).includes(card);

  getList = (keyList, type) => {
    const { paymentCards } = this.props;
    const { formatMessage } = this.props.intl;
    let elementList = [];
    if (type === "brand") {
      elementList = keyList.map((k) =>
        !this.isValidBrand(k) ? (
          <Tooltip
            title={formatMessage(strings.phrase.notInYourAcceptedBrandList)}
            key={k}
          >
            <Tag>
              <InvalidItem value={stationBrands[k]} />
            </Tag>
          </Tooltip>
        ) : (
          stationBrands[k]
        )
      );
    } else if (type === "card") {
      elementList = keyList.map((k) =>
        !this.isValidCard(k) ? (
          <Tooltip
            title={formatMessage(strings.phrase.notInYourFleetCardList)}
            key={k}
          >
            <Tag>
              <InvalidItem value={cleanFleetCardsName(paymentCards[k])} />
            </Tag>
          </Tooltip>
        ) : (
          cleanFleetCardsName(paymentCards[k])
        )
      );
    } else if (type === "country") {
      elementList = keyList.map((k) => countries[k]);
    }
    const elementListWithCommas = elementList.reduce((acc, elem, i) => {
      if (i === 0) return [elem];
      // first
      else if (i === elementList.length - 1)
        return [...acc, ` ${formatMessage(strings.word.or)} `, elem]; // last
      return [...acc, ", ", elem];
    }, []);
    return elementListWithCommas;
  };

  getInvalidityTag = (invalidity) => {
    const { formatMessage } = this.props.intl;
    if (invalidity === NEVER_APPLICABLE) {
      return (
        <Tooltip title={formatMessage(strings.phrase.thisWillNeverBeTrue)}>
          <Tag color="red" style={{ margin: "0px 4px" }}>
            <Icon type="warning" theme="filled" />
          </Tag>
        </Tooltip>
      );
    }
    return null;
  };

  getColumns() {
    const { formatMessage } = this.props.intl;
    const { deleteDiscount, currency } = this.props;
    const { tempDiscount, invalidities } = this.state;
    return [
      {
        key: "value",
        render: (_, item) => {
          if (tempDiscount && tempDiscount.id === item.id) {
            return (
              <DiscountValue
                onValueChange={(e) => this.editDiscount("value", e)}
                onTypeChange={(e) => this.editDiscount("type", e)}
                onCurrencyChange={(e) => this.editDiscount("currency", e)}
                value={tempDiscount.value}
                type={tempDiscount.type}
                currency={
                  tempDiscount.currency ? tempDiscount.currency : currency
                }
                editable
              />
            );
          }
          return (
            <strong>
              <DiscountValue
                value={item.value}
                type={item.type}
                currency={item.currency ? item.currency : currency}
              />
            </strong>
          );
        },
      },
      {
        key: "ifstationis",
        render: () => formatMessage(strings.phrase.ifTheStation),
      },
      {
        title: "If",
        dataIndex: "conditions",
        render: (conditions, item) => {
          if (tempDiscount && tempDiscount.id === item.id) {
            return this.getConditionEditor();
          }
          if (!conditions || Object.entries(conditions).length === 0) {
            return (
              <span style={{ color: "silver" }}>
                {formatMessage(strings.phrase.noConditions)}
              </span>
            );
          }
          const conditionInvalidities = invalidities[item.id];
          return (
            <ul style={{ marginBottom: 0 }}>
              {conditions.payment && conditions.payment.length > 0 && (
                <li>
                  {formatMessage(strings.phrase.acceptsPaymentCard)}{" "}
                  {this.getList(conditions.payment, "card")}
                  {this.getInvalidityTag(
                    conditionInvalidities && conditionInvalidities.payment
                  )}
                </li>
              )}
              {conditions.notpayment && conditions.notpayment.length > 0 && (
                <li>
                  {formatMessage(strings.phrase.doesNotAcceptsPaymentCard)}{" "}
                  {this.getList(conditions.notpayment, "card")}
                  {this.getInvalidityTag(
                    conditionInvalidities && conditionInvalidities.notpayment
                  )}
                </li>
              )}
              {conditions.brand && conditions.brand.length > 0 && (
                <li>
                  {formatMessage(strings.phrase.isFromTheBrand)}{" "}
                  {this.getList(conditions.brand, "brand")}
                  {this.getInvalidityTag(
                    conditionInvalidities && conditionInvalidities.brand
                  )}
                </li>
              )}
              {conditions.notbrand && conditions.notbrand.length > 0 && (
                <li>
                  {formatMessage(strings.phrase.isNotFromTheBrand)}{" "}
                  {this.getList(conditions.notbrand, "brand")}
                  {this.getInvalidityTag(
                    conditionInvalidities && conditionInvalidities.notbrand
                  )}
                </li>
              )}
              {conditions.country && conditions.country.length > 0 && (
                <li>
                  {formatMessage(strings.phrase.isIn)}{" "}
                  {this.getList(conditions.country, "country")}
                  {this.getInvalidityTag(
                    conditionInvalidities && conditionInvalidities.country
                  )}
                </li>
              )}
              {conditions.notcountry && conditions.notcountry.length > 0 && (
                <li>
                  {formatMessage(strings.phrase.isNotIn)}{" "}
                  {this.getList(conditions.notcountry, "country")}
                  {this.getInvalidityTag(
                    conditionInvalidities && conditionInvalidities.notcountry
                  )}
                </li>
              )}
            </ul>
          );
        },
      },
      {
        dataIndex: "id",
        render: (id, item) => {
          if (tempDiscount && tempDiscount.id === item.id) {
            return (
              <span>
                <Button
                  icon="check"
                  type="link"
                  onClick={() => this.validateEditing()}
                />
                <Button
                  icon="close"
                  type="link"
                  onClick={() => this.cancelEditing()}
                />
              </span>
            );
          }
          return (
            <span>
              <TooltipButton
                icon="edit"
                disabled={!!tempDiscount}
                onClick={() => this.edit(item)}
                tooltip={formatMessage(strings.word.edit)}
              />
              <TooltipButton
                icon="copy"
                disabled={!!tempDiscount}
                onClick={() => this.duplicate(item)}
                tooltip={formatMessage(strings.word.duplicate)}
              />
              <Popconfirm
                title={formatMessage(
                  strings.phrase.areYouSureToDeleteThisDiscount
                )}
                onConfirm={() => deleteDiscount(id)}
                onCancel={() => {}}
                okText={formatMessage(strings.word.yes)}
                cancelText={formatMessage(strings.word.cancel)}
              >
                <TooltipButton
                  icon="delete"
                  tooltip={formatMessage(strings.word.remove)}
                />
              </Popconfirm>
            </span>
          );
        },
        align: "right",
      },
      {
        key: "error",
        render: (_, item) => {
          const { value, conditions, id } = item;
          const conditionInvalidities = invalidities[id];

          const noConditions = (
            <Alert
              message={formatMessage(strings.message.discountAlwaysApplicable)}
              type="warning"
              showIcon
            />
          );
          const neverApplicable = (
            <Alert
              message={formatMessage(
                strings.message.discountNeverApplicableCurrentSettings
              )}
              type="error"
              showIcon
            />
          );
          const useless = (
            <Alert
              message={formatMessage(strings.message.discountUseless)}
              type="info"
              showIcon
            />
          );

          if (!value) return useless;
          else if (!conditions || Object.keys(conditions).length === 0)
            return noConditions;
          else if (!conditionInvalidities) return null;
          else if (
            Object.values(conditionInvalidities).some(
              (e) => e === NEVER_APPLICABLE
            )
          )
            return neverApplicable;
          return null;
        },
      },
    ];
  }

  render() {
    const { formatMessage } = this.props.intl;
    const {
      discounts: { items, processing },
      paymentCardsLoading,
    } = this.props;
    const {
      tempDiscount,
      discountsWithInvalidCards,
      discountsWithInvalidBrands,
    } = this.state;
    const localItems =
      items && tempDiscount && tempDiscount.id === -1
        ? [tempDiscount, ...items]
        : items;
    const mainBtns = (
      <Row type="flex" gutter={16} justify="space-between">
        <Col>
          <Button
            type="dashed"
            icon="plus"
            onClick={() => this.createItem()}
            disabled={!!tempDiscount}
          >
            {formatMessage(strings.phrase.addNewDiscount)}
          </Button>
        </Col>
        <Col>
          <Button
            icon="disconnect"
            onClick={() => this.cleanAllConditionsCard()}
            disabled={discountsWithInvalidCards.length === 0}
          >
            {formatMessage(strings.phrase.removeInvalidCards)}
          </Button>{" "}
          <Button
            icon="disconnect"
            onClick={() => this.cleanAllConditionsBrand()}
            disabled={discountsWithInvalidBrands.length === 0}
          >
            {formatMessage(strings.phrase.removeInvalidBrands)}
          </Button>
        </Col>
      </Row>
    );
    return (
      <div style={{ height: COLHEIGHT, overflowY: "scroll" }}>
        <p style={{ color: "silver" }}>
          <Icon type="info-circle" style={{ marginRight: 8 }} />
          {formatMessage(strings.description.discountSettingsInfoMessage)}
        </p>
        <Table
          style={{ width: "calc(100% - 20px)" }}
          title={() => mainBtns}
          loading={processing || paymentCardsLoading}
          rowKey="id"
          showHeader={false}
          dataSource={localItems}
          columns={this.getColumns()}
          pagination={{ hideOnSinglePage: true }}
        />
      </div>
    );
  }
}

Discounts.propTypes = {
  intl: intlShape.isRequired,
};

function mapStateToProps(store) {
  return {
    cards: store.fleetSettings.items && store.fleetSettings.items.cards,
    discounts: store.discounts,
    fleetSettings: store.fleetSettings.items,
    fleetSettingsFetching: store.fleetSettings.fetching,
    fleetSettingsProcessing: store.fleetSettings.processing,
    paymentCards:
      store.globalInformations.paymentCards &&
      store.globalInformations.paymentCards.items
        ? store.globalInformations.paymentCards.items
        : {},
    paymentCardsLoading:
      store.globalInformations.paymentCards &&
      store.globalInformations.paymentCards.processing,
    currency: store.settings.items && store.settings.items.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDiscounts: () => dispatch(actions.discounts.getDiscounts()),
    saveDiscount: (item) => dispatch(actions.discounts.saveDiscount(item)),
    batchSaveDiscount: (items) =>
      dispatch(actions.discounts.batchSaveDiscount(items)),
    deleteDiscount: (id) => dispatch(actions.discounts.deleteDiscount(id)),
    addErrorMessage: (msg) => dispatch(actions.messages.addErrorMessage(msg)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Discounts));
