import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Icon, Button, Modal, Tree, Card, Typography, Select, Tooltip } from 'antd';
import { flatten, isEmpty, isNil } from 'lodash';

import * as strings from '../../../helpers/defaultStrings';
import { constructTreeFromGroups, groupsObjectToArray, groupsArrayToObject } from '../../../helpers/groupsTreeHelper';
import stationBrands from '../../../assets/data/stationBrandsMapping.json';
import countries from '../../../assets/data/countriesMapping.json';
import { TooltipButton } from '../../../helpers';


const MiniMultiChoose = ({ icon, options, ...props }) => (
  <Select
    size="small"
    mode="multiple"
    tokenSeparators={[',']}
    style={{ minWidth: 180 }}
    suffixIcon={<Icon type={icon} />}
    showArrow
    allowClear
    optionFilterProp="name"
    {...props}
  >
    {options.map(option => (
      <Select.Option key={option.key} value={option.key} name={option.name}>
        {option.name}
      </Select.Option>
    ))}
  </Select>
);

const SwapBtn = (props) => {
  const { onClick } = props;
  const { formatMessage } = props.intl;
  return (
    <Tooltip title={formatMessage(strings.phrase.invertSelection)} placement="right">
      <Button
        size="small"
        icon="swap"
        type="dashed"
        style={{ marginLeft: 8 }}
        onClick={onClick}
      />
    </Tooltip>
  );
};

const SwapButton = injectIntl(SwapBtn);
const blockStyle = {
  border: '1px dashed #aaaaaa',
  borderRadius: 4,
  padding: 10,
  width: '30%',
};
const titleStyle = {
  textAlign: 'center',
  fontSize: 15,
  fontWeight: 700,
  marginBottom: 5,
};

const getSelectedSubgroups = restrictions => restrictions.reduce((acc, r) => { acc.push(...flatten(Object.values(r.groups))); return acc; }, []);

const getNumberOfSubgroups = groups => groups.reduce((acc, g) => acc + g.subgroups.length, 0);

const RestrictionBlock = (props) => {
  const {
    formatMessage,
    groupsData,
    brandOptions,
    countryOptions,
    restriction,
    index,
    restrictionsState,
    setRestrictionsState,
  } = props;

  // Disable groups already chosen in other restrictions
  const otherRestrictions = restrictionsState.filter((_, i) => i !== index);
  const subgroupsAlreadyChosen = getSelectedSubgroups(otherRestrictions);
  const groupsWithDisabledSubgroups = groupsData.map(s => (
    {
      ...s,
      children: s.children.map(c => (
        {
          ...c,
          disableCheckbox: !!subgroupsAlreadyChosen.includes(c.title),
        })),
    }));

  const [brandsKey, setBrandsKey] = useState(!isNil(restriction.notbrands) && !isEmpty(restriction.notbrands) ? 'notbrands' : 'brands');
  const [countriesKey, setCountriesKey] = useState(!isNil(restriction.notcountries) && !isEmpty(restriction.notcountries) ? 'notcountries' : 'countries');

  const updateRestrictionsState = (key, value) => {
    const newState = [...restrictionsState];
    newState[index] = { ...newState[index], [key]: value };
    setRestrictionsState(newState);
  };

  const swapKey = (type) => {
    if (type === 'brands') {
      const oldKey = brandsKey;
      const newKey = brandsKey === 'brands' ? 'notbrands' : 'brands';
      const newState = [...restrictionsState];
      newState[index] = { ...newState[index], [newKey]: restrictionsState[index][oldKey], [oldKey]: [] };
      setRestrictionsState(newState);
      setBrandsKey(newKey);
    } else if (type === 'countries') {
      const oldKey = countriesKey;
      const newKey = countriesKey === 'countries' ? 'notcountries' : 'countries';
      const newState = [...restrictionsState];
      newState[index] = { ...newState[index], [newKey]: restrictionsState[index][oldKey], [oldKey]: [] };
      setRestrictionsState(newState);
      setCountriesKey(newKey);
    }
  };

  const deleteRestriction = () => {
    setRestrictionsState(otherRestrictions);
  };

  return (
    <Card
      title={
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
        >
          {`${formatMessage(strings.word.rule)} ${index + 1}`}
          <TooltipButton
            icon="close"
            onClick={deleteRestriction}
            tooltip={formatMessage(strings.word.delete)}
          />
        </div>
      }
      style={{ marginBottom: 20 }}
      bodyStyle={{ display: 'flex', justifyContent: 'space-around' }}
    >
      <div style={blockStyle}>
        <Typography style={titleStyle}>{formatMessage(strings.phrase.selectGroups)}</Typography>
        <Tree
          checkable
          switcherIcon={<Icon type="down" />}
          treeData={groupsWithDisabledSubgroups}
          checkedKeys={groupsObjectToArray(restriction.groups)}
          onCheck={keys => updateRestrictionsState('groups', groupsArrayToObject(keys))}
        />
      </div>
      <div style={{ ...blockStyle, height: 120 }}>
        <Typography style={titleStyle}>{formatMessage(strings.phrase.selectBrands)}</Typography>
        <p style={{ color: 'silver' }}>
          <Icon type="info-circle" style={{ marginRight: 8 }} />
          {formatMessage(brandsKey === 'brands' ? strings.description.fleetCardBrands : strings.description.fleetCardNotBrands)}
          <SwapButton onClick={() => swapKey('brands')} />
        </p>
        <MiniMultiChoose
          icon="shop"
          placeholder="all"
          options={brandOptions}
          defaultValue={restriction[brandsKey]}
          onChange={value => updateRestrictionsState(brandsKey, value)}
        />
      </div>
      <div style={{ ...blockStyle, height: 120 }}>
        <Typography style={titleStyle}>{formatMessage(strings.phrase.selectCountries)}</Typography>
        <p style={{ color: 'silver' }}>
          <Icon type="info-circle" style={{ marginRight: 8 }} />
          {formatMessage(countriesKey === 'countries' ? strings.description.fleetCardCountries : strings.description.fleetCardNotCountries)}
          <SwapButton onClick={() => swapKey('countries')} />
        </p>
        <MiniMultiChoose
          icon="global"
          placeholder="all"
          options={countryOptions}
          defaultValue={restriction[countriesKey]}
          onChange={value => updateRestrictionsState(countriesKey, value)}
        />
      </div>
    </Card>
  );
};

const EditFleetCard = (props) => {
  const {
    open,
    onOk,
    onCancel,
    card,
    groups,
    brands,
  } = props;
  const { formatMessage } = props.intl;
  const title = <div style={{ fontSize: 18 }}>{`${formatMessage(strings.phrase.editFleetCard)} : ${card.cleanName}`}</div>;
  const [restrictionsState, setRestrictionsState] = useState(card.restrictions);
  const addRuleDisabled = getNumberOfSubgroups(groups) === getSelectedSubgroups(restrictionsState).length;
  const groupsData = constructTreeFromGroups(groups);
  const brandOptions = brands.map(key => ({ key, name: stationBrands[key] }));
  const countryOptions = Object.keys(countries).map(key => ({ key, name: countries[key] }));
  const addRule = () => {
    setRestrictionsState([...restrictionsState, {
      groups: {}, brands: [], countries: [], notbrands: [], notcountries: [],
    }]);
  };
  return (
    <Modal
      width="80%"
      title={title}
      visible={open}
      onCancel={() => onCancel()}
      bodyStyle={{ maxHeight: '70vh', overflowY: 'auto' }}
      footer={[
        <TooltipButton
          key="addRule"
          onClick={addRule}
          type="danger"
          disabled={addRuleDisabled}
          tooltip={addRuleDisabled ? formatMessage(strings.description.addRuleDisabled) : null}
          style={{ marginRight: addRuleDisabled ? 8 : 0 }}
        >{formatMessage(strings.phrase.addRule)}
        </TooltipButton>,
        <Button key="cancel" onClick={() => onCancel()}> {formatMessage(strings.word.cancel)} </Button>,
        <Button key="save" onClick={() => onOk({ card: card.card, restrictions: restrictionsState })} type="primary"> {formatMessage(strings.word.save)} </Button>,
      ]}
    >
      {restrictionsState.map((restriction, index) => (
        <RestrictionBlock
          formatMessage={formatMessage}
          groupsData={groupsData}
          brandOptions={brandOptions}
          countryOptions={countryOptions}
          restriction={restriction}
          index={index}
          restrictionsState={restrictionsState}
          setRestrictionsState={setRestrictionsState}
          key={`restriction${index}`}
        />
        ))
      }
    </Modal>
  );
};

export default injectIntl(EditFleetCard);
