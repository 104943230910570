import React from 'react';
import { Tooltip, Avatar, Row, Col, Button, Card, Timeline, Icon, Spin, Tag, Tabs } from 'antd';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { push } from 'react-router-redux';
import moment from 'moment';
import 'moment-timezone';

import actions from '../../actions';
import paths from '../../ComponentsLayout/paths';
import DetailsMap from './DetailsMap';
import FuelLevelHistory from './FuelLevelHistory';
import * as strings from '../../helpers/defaultStrings';
import RecommendationJustification from './RecommendationJustification';

const { TabPane } = Tabs;

const COLORS = {
  pink: '#FF49A5',
  pinkrgb: [255, 65, 185],
  grey: 'rgba(0,0,0,.45)',
  dodgerbluergb: [30, 144, 255],
};
// TODO use that when possible
// const preferred = <Tooltip title='Preferred station'><Icon type='star' /></Tooltip>
// const discounted = <Tooltip title='Discounted price: 1.0€'><Icon type='reconciliation' /></Tooltip>

const mapsButton = ({ latitude, longitude }) => (
  <Button
    target="_blank"
    href={`https://maps.google.com/maps?q=${latitude},${longitude}&t=k`}
    icon="google"
    type="link"
    style={{ display: 'inline' }}
  />
);

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      truckPosition: null,
      openJustification: false,
    };
  }

  componentDidMount() {
    const { recommendation, compactEvents, getCompactEvents } = this.props;
    if (recommendation && !compactEvents.processing) {
      getCompactEvents(recommendation);
    }
  }

  componentDidUpdate(prevProps) {
    const { recommendation, getCompactEvents } = this.props;
    if (recommendation &&
      (!prevProps.recommendation
        || prevProps.recommendation.recommendationid !== recommendation.recommendationid)) {
      getCompactEvents(recommendation);
    }
  }

  setTruckPosition = truckPosition => this.setState({ truckPosition })

  renderStation = (station) => {
    const { formatMessage } = this.props.intl;
    return (
      <Row type="flex" gutter={8} align="middle">
        <Col><Avatar size="small" style={{ backgroundColor: COLORS.pink }}>{station.order}</Avatar></Col>
        <Col>
          <div>{station.name}</div>
          <div style={{ color: COLORS.grey }}>
            {[station.address, station.locality, station.country].filter(x => !!x).join(', ')}
            ({formatMessage(strings.word.price)}: {station.price} {station.currency})
            <Tooltip title={formatMessage(strings.phrase.clickToSeeOnGoogleMaps)}>
              {mapsButton(station)}
            </Tooltip>
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    const {
      recommendation, closeDetails, compactEvents, useInternalVehicleId,
    } = this.props;
    const { truckPosition, openJustification } = this.state;
    const { formatMessage } = this.props.intl;
    const timezone = moment.tz.guess();

    const hasPlanning = recommendation
      && recommendation.planning
      && Array.isArray(recommendation.planning)
      && recommendation.planning.length > 0;
    return recommendation && (
      <Card
        title={(
          <span>
            {recommendation.date.tz(timezone).format('lll')}
            <small style={{ color: COLORS.grey, fontWeight: 'lighter', marginLeft: 8 }}>
              {`${formatMessage(strings.word.truck)} ${recommendation.truckid} (`}
              {useInternalVehicleId
                ? <span>{formatMessage(strings.phrase.internalId)}: <strong>{recommendation.internalvehicleid}</strong></span>
                : <span>{formatMessage(strings.phrase.plateNumber)}: <Tag>{recommendation.platenumber}</Tag></span>
              }
              {')'}
            </small>
          </span>
        )}
        extra={<Icon type="close" style={{ cursor: 'pointer', color: 'silver' }} onClick={closeDetails} />}
        cover={
          <Spin spinning={compactEvents.processing}>
            <div style={{ height: 380, position: 'relative' }}>
              <DetailsMap
                recommendation={recommendation}
                compactEvents={compactEvents.items}
                compactEventsHash={compactEvents.items && `${compactEvents.truckid}${compactEvents.minTfu}${compactEvents.maxTfu}`}
                truckPosition={truckPosition}
              />
            </div>
          </Spin>
        }
        bodyStyle={{ paddingTop: 8, height: 'calc(100% - 456px)', overflowY: 'scroll' }}
        style={{ height: '100%' }}
      >
        <Tabs defaultActiveKey="fuelLevelHistory">
          <TabPane tab={formatMessage(strings.phrase.fuelLevelHistory)} key="fuelLevelHistory" forceRender>
            <Spin spinning={compactEvents.processing}>
              <FuelLevelHistory
                recommendation={recommendation}
                setTruckPosition={this.setTruckPosition}
              />
            </Spin>
          </TabPane>
          <TabPane tab={formatMessage(strings.phrase.recommendedStations)} key="recommendedStations">
            {recommendation.station1 && this.renderStation({ ...recommendation.station1, order: 1 })}
            <br />
            {recommendation.station2 && this.renderStation({ ...recommendation.station2, order: 2 })}
            <br />
            <Button onClick={() => this.setState({ openJustification: true })}>{formatMessage(strings.phrase.showJustification)}</Button>
            {openJustification &&
              <RecommendationJustification
                open={openJustification}
                onClose={() => this.setState({ openJustification: false })}
                recommendation={recommendation}
                compactEvents={compactEvents.items}
              />}
          </TabPane>
          <TabPane
            tab={hasPlanning ? `${formatMessage(strings.word.planning)}` : `${formatMessage(strings.word.planning)} (${formatMessage(strings.word.unavailable)})`}
            key="planning"
            disabled={!hasPlanning}
          >
            <Timeline>
              <Timeline.Item color={COLORS.pink}>{formatMessage(strings.word.recommendation)} {recommendation.date.tz(timezone).format('lll')}</Timeline.Item>
              {hasPlanning
              ? (recommendation.planning
                  .map(pt => (
                    <Timeline.Item key={JSON.stringify(pt)}>
                      {pt.plannedDate.tz(timezone).format('lll')}
                      {`: ${formatMessage(strings.phrase.orderN)} `}
                      <span style={{ color: 'dodgerblue' }}>{pt.order}</span>
                      {/* {`${pt.plannedDate.format('lll')}: Order n° ${pt.order}`} */}
                    </Timeline.Item>))
              ) : (
                <span style={{ color: COLORS.grey }}>{formatMessage(strings.phrase.noPlanningPoints)}</span>
              )}
            </Timeline>
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}

Details.propTypes = {
  intl: intlShape.isRequired,
};

function mapStateToProps(store) {
  const {
    recommendations: { items },
    compactEvents,
    settings: { items: { useInternalVehicleId } },
  } = store;
  return {
    items,
    compactEvents,
    useInternalVehicleId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeDetails: () => dispatch(push(paths.fuelBot)),
    getCompactEvents: recommendation => dispatch(actions.compactEvents.getCompactEvents(recommendation)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const recommendation = stateProps.items
    && ownProps.match
    && ownProps.match.params
    && ownProps.match.params.recommendationid
    && stateProps.items.find(e => e.recommendationid === ownProps.match.params.recommendationid);
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    recommendation,
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(injectIntl(Details));

