import React from 'react'
import { Provider } from 'react-redux'

import { configureAuth, configureAPI } from './configureAmplify'
import './App.css'
import configureStore from './configureStore'
import LocaleContainer from './LocaleContainer'

configureAuth()
configureAPI()

const store = configureStore

const App = () => (
  <Provider store={store}>
    <LocaleContainer />
  </Provider>
)
export default App
