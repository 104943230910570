function languageFromNavigator() {
  let language = navigator.language ||
  (navigator.languages && navigator.languages[0]) ||
  navigator.userLanguage;

  // Split locales with a region code
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
  let twoLetterLangCode = (language.length === 2 ? language : languageWithoutRegionCode);

  // If browser doesn't support Intl
  // and lang is no or nn (Norwegian Nynorsk)
  // we need to switch language to nb: Norwegian Bokmål and messages to no
  if ((!window.intl) && ((twoLetterLangCode === 'no') || (twoLetterLangCode === 'nn'))) {
    language = language.replace(twoLetterLangCode, 'nb');
    twoLetterLangCode = 'no';
  } else if ((window.intl) && ((twoLetterLangCode === 'nb') || (twoLetterLangCode === 'nn'))) {
    // If browser does support Intl
    // and lang is nb (Norwegian Bokmål) or nn (Norwegian Nynorsk)
    // we switch language and messages to std Norwegian (no)
    language = language.replace(twoLetterLangCode, 'no');
    twoLetterLangCode = 'no';
  }
  return twoLetterLangCode
}

export default languageFromNavigator

export const AVAILABLE_LANGUAGES = [
  { locale: 'ar', name: 'Arabic' },
  { locale: 'cs', name: 'Czech' },
  { locale: 'da', name: 'Danish' },
  { locale: 'de', name: 'German' },
  { locale: 'en', name: 'English' },
  { locale: 'es', name: 'Spanish' },
  { locale: 'fr', name: 'French' },
  { locale: 'it', name: 'Italian' },
  { locale: 'nl', name: 'Dutch' },
  { locale: 'no', name: 'Norwegian' },
  { locale: 'pl', name: 'Polish' },
  { locale: 'pt', name: 'Portuguese' },
  { locale: 'ro', name: 'Romanian' },
  { locale: 'ru', name: 'Russian' },
  { locale: 'sk', name: 'Slovak' },
  { locale: 'sl', name: 'Slovenian' },
  { locale: 'sv', name: 'Swedish' },
  { locale: 'tr', name: 'Turkish' },
]
