export const BASIS_STOPS = [
  [0.75, '#1a9850'],
  [0.80, '#66bd63'],
  [0.85, '#a6d96a'],
  [0.90, '#d9ef8b'],
  [0.95, '#ffffbf'],
  [1, '#fee08b'],
  [1.05, '#fdae61'],
  [1.1, '#f46d43'],
  [1.15, '#d73027'],
]

const NUMBER_SEGMENTS = BASIS_STOPS.length - 1

export function generateStationColors(unitPrices) {
  const validPrices = unitPrices.filter(price => !!price)
  const minPrice = Math.min(...validPrices)
  const maxPrice = Math.max(...validPrices)
  const priceDelta = (maxPrice - minPrice) / NUMBER_SEGMENTS
  const colorStops = []
  for (let i = 0; i <= NUMBER_SEGMENTS; i += 1) {
    colorStops.push([
      minPrice + (i * priceDelta),
      BASIS_STOPS[i][1],
    ])
  }
  return colorStops
}
