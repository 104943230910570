import React from 'react'
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Table, Avatar, Row, Col, List, Spin, Modal, Input } from 'antd'
import { WebMercatorViewport } from 'deck.gl'

import SearchStationMap from './SearchStationMap'
import * as strings from '../../../helpers/defaultStrings';
import actions from '../../../actions'
import SendMailBtn from './SendMailBtn'
import { getStrId, getIcon } from '../../../helpers/constants'
import EditStationModal from './EditStationModal';

const { Search } = Input;
// const { REACT_APP_MAPBOX_ACCESS_TOKEN  } = process.env

class AddStationBtn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRowKeys: [],
      selectedRows: [],
      boundingBox: null,
      openEdit: false,
      resultsState: props.results,
      stationToEdit: null,
    }
  }

  componentDidUpdate(prevProps) {
    const { results } = this.props
    if (results !== prevProps.results) {
      this.setState({ resultsState: results })
    }
  }

  saveBoundingBox = (viewState) => {
    const viewport = new WebMercatorViewport(viewState);
    const [swlongitude, swlatitude] = viewport.unproject([0, viewport.height]);
    const [nelongitude, nelatitude] = viewport.unproject([viewport.width, 0]);
    const boundingBox = {
      swlongitude, swlatitude, nelongitude, nelatitude,
    }
    this.setState({ boundingBox })
  }

  onOk() {
    const { addStations } = this.props
    const { selectedRows } = this.state
    addStations(selectedRows)
    this.closeModal()
  }

  onChangeSelectedRowKeys(selectedRowKeys, selectedRows) { this.setState({ selectedRowKeys, selectedRows }) }
  onSelect(row) {
    const { selectedRowKeys, selectedRows } = this.state
    if (row.type) {
      return
    }
    if (!selectedRowKeys.includes(row.id)) {
      this.onChangeSelectedRowKeys(
        [...selectedRowKeys, row.id],
        [...selectedRows, row],
      )
    } else {
      this.onChangeSelectedRowKeys(
        selectedRowKeys.filter(e => e !== row.id),
        selectedRows.filter(e => e.id !== row.id),
      )
    }
  }

  closeModal() {
    this.props.resetSearch()
    this.onChangeSelectedRowKeys([], [])
    this.props.close()
  }

  onEditClick = (item) => {
    this.setState({ openEdit: true, stationToEdit: item })
  }

  onEditOk = (station) => {
    const { selectedRows, resultsState } = this.state
    const stations = resultsState.map(s => (s.id === station.id ? station : s))
    this.setState({ openEdit: false, stationToEdit: null, resultsState: stations })
    if (selectedRows.length !== 0) {
      const newRows = selectedRows.map(s => (s.id === station.id ? station : s))
      this.setState({ selectedRows: newRows })
    }
  }

  onEditClose = () => {
    this.setState({ openEdit: false })
  }

  renderStationItem(item, clickToSelect) {
    const { formatMessage } = this.props.intl;
    const { selectedRowKeys } = this.state
    return (
      <List.Item.Meta
        avatar={<Avatar>{item.brand}</Avatar>}
        title={<span>{item.name} {item.type ? getIcon(item.type) : clickToSelect ? '' : (<Button size="small" onClick={() => this.onEditClick(item)} icon="edit" type="link" />)}</span>}
        description={(
          <div>
            {item.type ? (
              formatMessage(strings.phrase.thisStationIsAlreadyAddedInCategory, { category: formatMessage(getStrId(item.type)) })
            ) : (
              <div>
                <div>{item.address}</div>
                <div>{[item.zipcode, item.locality, item.country].filter(x => !!x).join(', ')}</div>
                {clickToSelect && (
                  <strong style={{ color: 'dodgerblue', display: 'block' }}>
                    {selectedRowKeys.includes(item.id) ? formatMessage(strings.word.selected) : formatMessage(strings.phrase.clickToSelect)}
                  </strong>
                )}
              </div>
            )}
          </div>
        )}
      />
    )
  }

  onSearhClick(type, value) {
    const { searchStations, searchHomeBaseStations, title } = this.props
    if (title && title.props && title.props.type !== 'homebase') {
      if (type === 'bbox') { searchStations({ bbox: value }) } else if (type === 'address') { searchStations({ address: value }) }
    } else {
      const param = {}
      param.limit = 2000
      if (type === 'bbox') {
        param.searchText = 'Gas Station Fuel'
        param.bbox = `${value.swlongitude},${value.swlatitude},${value.nelongitude},${value.nelatitude}`;
        searchHomeBaseStations(param);
      } else if (type === 'address') {
        param.searchText = value
        searchHomeBaseStations(param);
      }
    }
  }

  render() {
    const {
      selectedRowKeys, selectedRows, boundingBox, openEdit, stationToEdit, resultsState,
    } = this.state
    const {
      processing, resetSearch, open, title,
    } = this.props // searchStations,
    const { formatMessage } = this.props.intl
    return (
      <Modal
        width="80%"
        title={title}
        visible={open}
        okText={formatMessage(strings.phrase.addValueStations, { value: selectedRowKeys.length })}
        onOk={() => this.onOk()}
        okButtonProps={{ disabled: selectedRowKeys.length === 0 }}
        onCancel={() => this.closeModal()}
        cancelText={formatMessage(strings.word.cancel)}
      >
        <Spin spinning={processing}>
          <Row type="flex" align="middle" gutter={[8, 32]}>
            <Col span={10} style={{ height: '60vh', overflowY: 'scroll' }}>
              {(!resultsState || resultsState.length === 0) ? (
                <Row type="flex" gutter={[8, 8]} align="middle">
                  <Col>
                    <Button
                      type="primary"
                      icon="search"
                      hidden={title.props.type === 'homebase'}
                      onClick={() => this.onSearhClick('bbox', boundingBox)}
                      disabled={!boundingBox}
                    >
                      {formatMessage(strings.phrase.searchThisArea)}
                    </Button>
                  </Col>
                  <Col hidden={title.props.type === 'homebase'}>
                    {formatMessage(strings.word.or)}
                  </Col>
                  <Col>
                    <Search
                      placeholder={formatMessage(strings.phrase.searchAnAddress)}
                      enterButton
                      onSearch={str => this.onSearhClick('address', str)}
                    />
                  </Col>
                </Row>
              ) : (
                <div>
                  <Button icon="arrow-left" onClick={resetSearch} disabled={selectedRowKeys.length > 0}>
                    {formatMessage(strings.phrase.backToSearch)}
                  </Button>
                  <Table
                    dataSource={resultsState}
                    rowKey="id"
                    columns={[{ id: 'name', render: (_, item) => this.renderStationItem(item) }]}
                    showHeader={false}
                    pagination={{ pageSize: 100, hideOnSinglePage: true }}
                    rowSelection={{
                      selectedRowKeys,
                      onChange: (selectedRowKeys, selectedRows) => this.onChangeSelectedRowKeys(selectedRowKeys, selectedRows),
                      getCheckboxProps: record => ({ disabled: !!record.type }),
                    }}
                  />
                  <div style={{ margin: 8 }}>
                    {formatMessage(strings.phrase.cantFindYourStation)}
                    <SendMailBtn style={{ marginLeft: 4 }} />
                  </div>
                </div>)}
            </Col>
            <Col span={14} style={{ height: '60vh' }}>
              <SearchStationMap
                stations={resultsState}
                selectedStations={selectedRows}
                renderTooltip={item => this.renderStationItem(item, true)}
                color={[30, 144, 255]}
                onClick={e => this.onSelect(e)}
                saveViewState={vs => this.saveBoundingBox(vs)}
              />
            </Col>
          </Row>
        </Spin>
        {openEdit && <EditStationModal stationToEdit={stationToEdit} open={openEdit} close={this.onEditClose} addStations={this.onEditOk} />}
      </Modal>
    )
  }
}

AddStationBtn.propTypes = {
  intl: intlShape.isRequired,
};

function mapStateToProps(store) {
  const alreadySelectedStations = store.stations.items
  // adding type to result
  const { results, processing } = store.stations.search
  const resultsWithTypes = results
    .map((station) => {
      const existingStation = alreadySelectedStations.find(e => e.id === station.id)
      return existingStation
        ? { ...station, type: existingStation.type }
        : station
    })
  return {
    results: resultsWithTypes,
    processing,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    searchStations: params => dispatch(actions.stations.searchStations(params)),
    searchHomeBaseStations: params => dispatch(actions.stations.searchHomeBaseStations(params)),
    resetSearch: () => dispatch(actions.stations.resetSearch()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddStationBtn))
