import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import Home from '../Home/Home';

const { REACT_APP_ENV } = process.env;


class SendToken extends Component {
  componentDidMount() {
    const { match: { params: { token = '', env } }, authenticateUser } = this.props;
    if (REACT_APP_ENV.toString().trim().toLowerCase() === 'integration') {
      console.log(`MyTransics token: ${token} on env ${env}`);
    }
    authenticateUser(token, env);
  }

  render() {
    return <Home />
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authenticateUser: (token, env) => dispatch(actions.auth.authUser(token, env)),
  }
}

export default connect(undefined, mapDispatchToProps)(SendToken)
