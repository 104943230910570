import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Popconfirm, Row, Col, List, Spin, Collapse, Tag, Checkbox, Icon, Modal, Tooltip, Typography } from 'antd';
import SimplePointMap from '../../../helpers/SimplePointMap/SimplePointMap';
import * as strings from '../../../helpers/defaultStrings';
import actions from '../../../actions';
import SearchStationModal from './SearchStationModal';
import { preferredStationType, RenderedConstant, cleanFleetCardsName } from '../../../helpers/constants';
import { DiscountValue } from '../../../helpers';
import PreferredStationFilterForm from './PreferredStationFilterModel';
import crossfilter from 'crossfilter2';
import EditStationModal from './EditStationModal';
import EditMultipleStationsModal from './EditMultipleStationsModal';
import { isNil, toLower } from 'lodash';


const { Panel } = Collapse;

const COLHEIGHT = 'calc(100vh - 64px - 32px - 56px - 32px)';
const LISTHEIGHT = 'calc(100vh - 64px - 32px - 56px - 45px - 32px - 192px - 32px)';
const {
  HOMEBASE, TOAVOID, VOLUMEDISCOUNT, PREFERRED, PLATTS,
} = preferredStationType;
const preferredStationColors = {
  [PREFERRED]: [255, 165, 0], // orange
  [VOLUMEDISCOUNT]: [82, 196, 26], // green
  [HOMEBASE]: [30, 144, 255], // dodgerblue
  [TOAVOID]: [255, 92, 92], // pale red
  [PLATTS]: [28, 75, 172], // logo Blue
};
export const INITIAL_FILTER = {
  country: null,
  locality: null,
  name: null,
  zipcode: null,
};

class Stations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: PREFERRED,
      preferredStationModalOpen: false,
      toAvoidStationModalOpen: false,
      volumeDiscountStationModalOpen: false,
      homeBaseModalOpen: false,
      editStationModalOpen: false,
      stationToEdit: null,
      selectedPreferredStations: [],
      indeterminate: false,
      isPreferredStationMultiEditVisible: false,
      cfDimensions: null,
      filters: INITIAL_FILTER,
      isPreferredStationFilterApplied: false,
      filteredPreferredStations: [],
    };
  }

  /** ** Component hook section starts *** */
  componentDidMount() {
    this.props.getStations();
  }

  componentDidUpdate(prevProps) {
    const {
      stations: {
        processing, addingId, deletingId, error,
      }, intl, addSuccessMessage, addErrorMessage,
    } = this.props;
    const { formatMessage } = intl;
    if (!processing && prevProps.stations.processing) {
      if (error) addErrorMessage(formatMessage(strings.message.failedToGetStationList));
      this.initPreferedStationFilter();
    }
    if (!addingId && prevProps.stations.addingId) {
      if (error) addErrorMessage(formatMessage(strings.message.failedToSaveStations));
      else {
        addSuccessMessage(formatMessage(strings.message.stationsAreAdded));
        this.setState({ indeterminate: false, checkAll: false, selectedPreferredStations: [] });
        this.initPreferedStationFilter();
      }
    }
    if (!deletingId && prevProps.stations.deletingId) {
      if (error) addErrorMessage(formatMessage(strings.message.failedToDeleteStation));
      else {
        addSuccessMessage(formatMessage(strings.message.stationIsDeleted));
        this.setState({ indeterminate: false, checkAll: false, selectedPreferredStations: [] });
        this.initPreferedStationFilter();
      }
    }
  }
  /** ** Component hook section ends *** */
  shouldComponentUpdate(nextProps) {
    const { stations: { processing } } = this.props;
    const processed = processing !== nextProps.processing;
    return processed;
  }
  /** ** Preferred stations filter methods starts *** */

  initPreferedStationFilter() {
    const { stations: { items } } = this.props;
    const { filters } = this.state;
    if (items) {
      const sortByName = (a, b) => (a.name < b.name ? -1 : 1);
      const cf = crossfilter(items.filter(e => e.type === PREFERRED).sort(sortByName));
      const cfDimensions = {
        country: cf.dimension(d => d.country),
        locality: cf.dimension(d => d.locality),
        name: cf.dimension(d => d.name),
        zipcode: cf.dimension(d => d.zipcode),
      };
      this.setState(
        { cfDimensions },
        () => this.onPreferredStationFilter(filters),
      );
    }
  }

  onPreferredStationFilter = (filters) => {
    const { cfDimensions } = this.state;
    const { name: nameFilter, ...simpleFilters } = filters;
    // apply simple filters
    Object.entries(simpleFilters)
      .forEach(([key, value]) => cfDimensions[key].filter(value));
    // name partial filter
    if (!isNil(nameFilter)) {
      cfDimensions.name.filter(name => toLower(name).includes(toLower(nameFilter)));
    } else {
      cfDimensions.name.filter(null);
    }
    // update state
    const filteredPreferredStations = cfDimensions.name.top(Infinity);
    const options = {
      country: cfDimensions.country.group().all().filter(e => e.value).map(e => e.key),
      locality: cfDimensions.locality.group().all().filter(e => e.value).map(e => e.key),
      name: cfDimensions.name.group().all().filter(e => e.value).map(e => e.key),
      zipcode: cfDimensions.zipcode.group().all().filter(e => e.value).map(e => e.key),
    };
    this.setState({ filteredPreferredStations, options, filters });
  }

  applyPreferredStationFilter = () => {
    this.setState({ isPreferredStationFilterApplied: true, PreferredStationFilterModalOpen: false });
  }

  clearPreferredStationFilter = () => {
    this.setState({ isPreferredStationFilterApplied: false });
  }

  closePreferredStationFilterModal = () => {
    const { stations: { items } } = this.props;
    const { filteredPreferredStations } = this.state;

    if (items && filteredPreferredStations && (items.filter(e => e.type === PREFERRED).length !== filteredPreferredStations.length)) {
      this.setState({ isPreferredStationFilterApplied: true });
    } else {
      this.setState({ isPreferredStationFilterApplied: false });
    }
    this.setState({ PreferredStationFilterModalOpen: false });
  }

  removePreferredStationFilter = () => {
    this.setState({ isPreferredStationFilterApplied: false, filters: INITIAL_FILTER });
    this.PrefFilterForm.handleReset();
  }
  /** ** Preferred stations filter methods ends *** */

  /** ** CRUD/Show or hide models starts *** */
  onChangeActiveKey = activeKey => activeKey && this.setState({ activeKey })

  openPreferredStationModal = () => this.setState({ preferredStationModalOpen: true, activeKey: PREFERRED })
  closePreferredStationModal = () => this.setState({ preferredStationModalOpen: false })
  openToAvoidStationModal = () => this.setState({ toAvoidStationModalOpen: true, activeKey: TOAVOID })
  closeToAvoidStationModal = () => this.setState({ toAvoidStationModalOpen: false })
  openVolumeDiscountStationModal = () => this.setState({ volumeDiscountStationModalOpen: true, activeKey: VOLUMEDISCOUNT })
  closeVolumeDiscountStationModal = () => this.setState({ volumeDiscountStationModalOpen: false })
  openHomeBaseModal = () => this.setState({ homeBaseModalOpen: true, activeKey: HOMEBASE })
  closeHomeBaseModal = () => this.setState({ homeBaseModalOpen: false })
  openPlattsStationModal = () => this.setState({ plattsStationModalOpen: true, activeKey: PLATTS })
  closePlattsStationModal = () => this.setState({ plattsStationModalOpen: false })

  openPreferredStationFilterModal = () => this.setState({ PreferredStationFilterModalOpen: true })

  addPreferredStation = stations => this.props.addStations(stations.map(e => ({ ...e, type: PREFERRED })))
  addToAvoidStation = stations => this.props.addStations(stations.map(e => ({ ...e, type: TOAVOID })))
  addVolumeDiscountStation = stations => this.props.addStations(stations.map(e => ({ ...e, type: VOLUMEDISCOUNT })))
  addHomebaseStation = stations => this.props.addStations(stations.map(e => ({ ...e, type: HOMEBASE })))

  /** ** CRUD/Show or hide models starts *** */

  multideleteStations = () => {
    const { deleteStation } = this.props;
    const { selectedPreferredStations } = this.state;

    const delStationIds = [];
    selectedPreferredStations.forEach((station) => {
      delStationIds.push(station.id);
    });
    deleteStation(delStationIds);
  }

  onPreferredEditClick = () => {
    const { isPreferredStationMultiEditVisible } = this.state;
    this.setState({ isPreferredStationMultiEditVisible: !isPreferredStationMultiEditVisible });
  }

  onPreferredStationSelect = (event, item) => {
    const { stations: { items } } = this.props;
    const { selectedPreferredStations } = this.state;
    const preferredStationsCount = items.filter(e => e.type === PREFERRED).length;
    const selected_stations = selectedPreferredStations;
    const check = event.target.checked;
    if (check) {
      selected_stations.push(item);
      this.setState({
        selectedPreferredStations: selected_stations,
      });
    } else {
      const index = selected_stations.findIndex(x => x.id === item.id);
      if (index > -1) {
        selected_stations.splice(index, 1);
        this.setState({
          selectedPreferredStations: selected_stations,
        });
      }
    }
    if ((selected_stations.length > 0) && preferredStationsCount !== selected_stations.length) {
      this.setState({ indeterminate: true });
    } else {
      this.setState({ indeterminate: false });
    }

    if ((selected_stations.length > 0) && preferredStationsCount === selected_stations.length) {
      this.setState({ checkAll: true });
    }
  }

  onCheckAllChange(event, items) {
    if (event && event.target.checked) {
      this.setState({ indeterminate: false, selectedPreferredStations: items, checkAll: true });
    } else {
      this.setState({ indeterminate: false, selectedPreferredStations: [], checkAll: false });
    }
  }

  /** ** Preferred stations multi edit and delete ends *** */

  /** ** Component rendering section starts *** */
  getAddBtn(key) {
    const { formatMessage } = this.props.intl;
    const { selectedPreferredStations, isPreferredStationFilterApplied } = this.state;

    const showAddbutton = key === PREFERRED ? (!((selectedPreferredStations && selectedPreferredStations.length > 0))) : true;
    const showEditDeleteButton = key === PREFERRED ? (!!((selectedPreferredStations && selectedPreferredStations.length > 0))) : false;

    const open = {
      [PREFERRED]: this.openPreferredStationModal,
      [VOLUMEDISCOUNT]: this.openVolumeDiscountStationModal,
      [HOMEBASE]: this.openHomeBaseModal,
      [TOAVOID]: this.openToAvoidStationModal,
    };

    const editDeleteButtons = showEditDeleteButton ?
      (<div>
        <Button type="dashed" icon="edit" size="small" style={{ marginRight: 5 }} onClick={this.onPreferredEditClick} >
          {formatMessage(strings.word.edit)}
        </Button>
        <Popconfirm
          title={formatMessage(strings.phrase.areYouSureToRemoveTheDiscounts)}
          onConfirm={() => this.multideleteStations()}
          okText={formatMessage(strings.word.yes)}
          cancelText={formatMessage(strings.word.cancel)}
          placement="bottom"
          overlayClassName="editPopOver"
        >
          <Button type="dashed" icon="delete" size="small" style={{ marginRight: 5 }} >
            {formatMessage(strings.word.delete)}
          </Button>
        </Popconfirm>
      </div>) : '';

    return (
      <div style={{ display: 'flex' }}>
        {key === PREFERRED ? <div style={{ display: 'inline-flex', justifyContent: 'Center', alignItems: 'Center' }}>
          <Tooltip title={formatMessage(strings.phrase.filterPreferredStation)} placement="top">
            <Icon
              type="filter"
              style={{ paddingRight: 15 }}
              className={isPreferredStationFilterApplied ? 'filterapplied' : ''}
              onClick={() => this.openPreferredStationFilterModal()}
            />
          </Tooltip>
          <Tooltip title={formatMessage(strings.phrase.clearfilter)} placement="top">
            <Icon
              type="close"
              style={{ paddingRight: 15, display: isPreferredStationFilterApplied ? 'block' : 'none' }}
              className="filterapplied"
              onClick={() => this.removePreferredStationFilter()}
            />
          </Tooltip>
        </div> : ''}
        <Button
          type="dashed"
          icon="plus"
          size="small"
          hidden={!showAddbutton}
          onClick={(e) => { e.stopPropagation(); open[key](); }}
        >
          {formatMessage(strings.word.add)}
        </Button>
        {editDeleteButtons}
      </div>
    );
  }

  renderStation = (item) => {
    const { formatMessage } = this.props.intl;
    return (
      <List.Item.Meta
        title={item.type === HOMEBASE && !item.longitude && !item.latitude
          ? <span>{item.name}  <Tag color="cyan" style={{ marginLeft: 4 }}>{formatMessage(strings.phrase.pendingForApproval)}</Tag></span>
          : item.name}
        description={(
          <div>
            <div>{item.address || formatMessage(strings.phrase.noAddress)}</div>
            <div>{[item.zipcode, item.locality, item.country].filter(x => !!x).join(', ')}</div>
          </div>
        )}
      />
    );
  }

  renderStationItem = (item) => {
    const { formatMessage } = this.props.intl;
    const { deleteStation } = this.props;
    const { selectedPreferredStations } = this.state;
    const checked = selectedPreferredStations.some(x => (x.id === item.id));
    return (
      <List.Item
        key={item.id}
        actions={item.type !== PLATTS ? [
          <Button size="small" onClick={() => { this.setState({ editStationModalOpen: true, stationToEdit: item }); }} icon="edit" type="link" hidden={false} />,
          <Popconfirm
            title={formatMessage(strings.phrase.areYouSureToRemoveThisStation)}
            onConfirm={() => deleteStation([item.id])}
            okText={formatMessage(strings.word.yes)}
            cancelText={formatMessage(strings.word.cancel)}
          >
            <Button icon="delete" type="link" />
          </Popconfirm>,
        ] : []}
      >
        {(item.type !== PREFERRED && item.type !== PLATTS && item.type !== HOMEBASE) ? (
          this.renderStation(item)
        ) : (
          item.type === PREFERRED ?
            (<Row type="flex" style={{ width: '100%' }} align="middle">
              <Col span={2}><Checkbox checked={checked} onChange={e => this.onPreferredStationSelect(e, item)} /></Col>
              <Col span={12}>{this.renderStation(item)}</Col>
              <Col span={10}>{this.getDiscountComponent(item)}</Col>
            </Row>) :
            (<Row type="flex" style={{ width: '100%' }} align="middle">
              <Col span={12}>{this.renderStation(item)}</Col>
              <Col span={11} className="homebasecard">{this.getFixedPriceInfo(item)}</Col>
            </Row>)
        )}
      </List.Item>
    );
  }

  getDiscountComponent = (item) => {
    if (!item.discountType && !item.fleetCard) {
      return null;
    }
    return (
      <div style={{
 border: '1px dashed #d9d9d9', borderRadius: 4, padding: 6, textAlign: 'center',
}}
      >
        {item.discountType && <DiscountValue value={item.discountValue} type={item.discountType} currency={item.discountCurrency} />}
        {item.fleetCard && <Typography style={{ marginTop: 20 }}>{cleanFleetCardsName(this.props.paymentCards[item.fleetCard])}</Typography>}
      </div>
    );
  }

  getFixedPriceInfo = (item) => {
    if (!item.discountValue) {
      return null;
    }
    const { formatMessage } = this.props.intl;
    return (
      <div style={{
 border: '1px dashed #d9d9d9', borderRadius: 4, padding: 6, textAlign: 'center',
}}
      >
        {`${item.discountValue} ${item.discountCurrency} ${formatMessage(strings.phrase.euroPerLiterAsFixedPrice)}`}
      </div>
    );
  }

  render() {
    const {
      stations: {
        items, processing, addingId, deletingId,
      },
      currency,
      fleetCards,
      paymentCards,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const {
      activeKey, editStationModalOpen, preferredStationModalOpen, homeBaseModalOpen, toAvoidStationModalOpen,
      volumeDiscountStationModalOpen, indeterminate, selectedPreferredStations, checkAll, stationToEdit,
    } = this.state;
    const {
      filteredPreferredStations, options, PreferredStationFilterModalOpen, isPreferredStationMultiEditVisible,
    } = this.state;

    const sortByName = (a, b) => (a.name < b.name ? -1 : 1);
    const data = {
      [PREFERRED]: filteredPreferredStations.filter(e => e.type === PREFERRED).sort(sortByName),
      [VOLUMEDISCOUNT]: items.filter(e => e.type === VOLUMEDISCOUNT).sort(sortByName),
      [HOMEBASE]: items.filter(e => e.type === HOMEBASE).sort(sortByName),
      [TOAVOID]: items.filter(e => e.type === TOAVOID).sort(sortByName),
      [PLATTS]: items.filter(e => e.type === PLATTS).sort(sortByName),
    };
    const getPanelHeader = key => (
      <span>
        <Tooltip title={(indeterminate || !checkAll) ? formatMessage(strings.phrase.selectAll) : formatMessage(strings.phrase.deSelectAll)} placement="top">
          <Checkbox
            indeterminate={indeterminate}
            onChange={e => this.onCheckAllChange(e, data[PREFERRED])}
            style={{ display: (key !== PREFERRED || !selectedPreferredStations.length) ? 'none' : '', marginRight: 5 }}
            checked={checkAll}
          />
        </Tooltip>
        <RenderedConstant
          type={key}
          suffix={`(${data[key] ? ((key === PREFERRED && selectedPreferredStations.length > 0) ? `${selectedPreferredStations.length}/` : '') + data[key].length : 0})`}
          iconAfter
        />
      </span>
    );

    return (
      <Spin spinning={processing || !!addingId || !!deletingId}>
        {/* modals */}
        <SearchStationModal
          title={<RenderedConstant type={PREFERRED} prefix={formatMessage(strings.word.add)} />}
          close={this.closePreferredStationModal}
          open={preferredStationModalOpen}
          addStations={this.addPreferredStation}
        />
        <SearchStationModal
          title={<RenderedConstant type={TOAVOID} prefix={formatMessage(strings.word.add)} />}
          close={this.closeToAvoidStationModal}
          open={toAvoidStationModalOpen}
          addStations={this.addToAvoidStation}
        />
        <SearchStationModal
          title={<RenderedConstant type={VOLUMEDISCOUNT} prefix={formatMessage(strings.word.add)} />}
          close={this.closeVolumeDiscountStationModal}
          open={volumeDiscountStationModalOpen}
          addStations={this.addVolumeDiscountStation}
        />
        <SearchStationModal
          title={<RenderedConstant type={HOMEBASE} prefix={formatMessage(strings.word.add)} />}
          close={this.closeHomeBaseModal}
          open={homeBaseModalOpen}
          addStations={this.addHomebaseStation}
        />
        <Modal title={formatMessage(strings.phrase.preferredStationsFilter)} width={780} visible={PreferredStationFilterModalOpen} footer={null} onCancel={this.closePreferredStationFilterModal}>
          <PreferredStationFilterForm
            ref="PrefFilterForm"
            onRef={ref => (this.PrefFilterForm = ref)}
            onFilter={this.onPreferredStationFilter}
            options={options}
            applyFilter={this.applyPreferredStationFilter}
            onFilterClear={this.clearPreferredStationFilter}
          />
        </Modal>
        {editStationModalOpen &&
        <EditStationModal
          editNameAndDiscount={stationToEdit.type === PREFERRED}
          editFixedPrice={stationToEdit.type === HOMEBASE || stationToEdit.type === PLATTS}
          open={editStationModalOpen}
          stationToEdit={stationToEdit}
          close={() => this.setState({ stationToEdit: null, editStationModalOpen: false })}
          addStations={station => this.props.addStations([station])}
          settingsCurrency={currency}
          fleetCards={fleetCards}
          paymentCards={paymentCards}
        />}
        {isPreferredStationMultiEditVisible &&
        <EditMultipleStationsModal
          editNameAndDiscount
          open={isPreferredStationMultiEditVisible}
          stationsToEdit={selectedPreferredStations}
          close={() => this.setState({ selectedPreferredStations: [], isPreferredStationMultiEditVisible: false })}
          addStations={stations => this.props.addStations(stations)}
          settingsCurrency={currency}
          fleetCards={fleetCards}
          paymentCards={paymentCards}
        />
        }

        {/* 4 lists  */}

        <Row type="flex" gutter={16}>
          <Col span={10} style={{ height: COLHEIGHT }}>
            <Collapse accordion activeKey={activeKey} onChange={this.onChangeActiveKey}>
              <Panel
                header={getPanelHeader(PREFERRED)}
                key={PREFERRED}
                extra={this.getAddBtn(PREFERRED)}
              >
                <List
                  style={{ overflowY: 'scroll', height: LISTHEIGHT }}
                  itemLayout="horizontal"
                  dataSource={data[PREFERRED]}
                  renderItem={this.renderStationItem}
                />
              </Panel>
              <Panel
                header={getPanelHeader(VOLUMEDISCOUNT)}
                key={VOLUMEDISCOUNT}
                extra={this.getAddBtn(VOLUMEDISCOUNT)}
              >
                <List
                  style={{ overflowY: 'scroll', height: LISTHEIGHT }}
                  itemLayout="horizontal"
                  dataSource={data[VOLUMEDISCOUNT]}
                  renderItem={this.renderStationItem}
                />
              </Panel>
              <Panel
                header={getPanelHeader(HOMEBASE)}
                key={HOMEBASE}
                extra={this.getAddBtn(HOMEBASE)}
              >
                <List
                  style={{ overflowY: 'scroll', height: LISTHEIGHT }}
                  itemLayout="horizontal"
                  dataSource={data[HOMEBASE]}
                  renderItem={this.renderStationItem}
                />
              </Panel>
              <Panel
                header={getPanelHeader(TOAVOID)}
                key={TOAVOID}
                extra={this.getAddBtn(TOAVOID)}
              >
                <List
                  style={{ overflowY: 'scroll', height: LISTHEIGHT }}
                  itemLayout="horizontal"
                  dataSource={data[TOAVOID]}
                  renderItem={this.renderStationItem}
                />
              </Panel>
              <Panel
                header={getPanelHeader(PLATTS)}
                key={PLATTS}
              >
                <List
                  style={{ overflowY: 'scroll', height: LISTHEIGHT }}
                  itemLayout="horizontal"
                  dataSource={data[PLATTS]}
                  renderItem={this.renderStationItem}
                />
              </Panel>
            </Collapse>
          </Col>
          {/* right map */}
          <Col span={14}>
            <div style={{ height: COLHEIGHT }}>
              <SimplePointMap
                data={data[activeKey].filter(e => e.longitude || e.latitude)}
                renderTooltip={this.renderStation}
                color={preferredStationColors[activeKey]}
              />
            </div>
          </Col>
        </Row>
      </Spin>
    );
  }
}

Stations.propTypes = {
  intl: intlShape.isRequired,
};

function mapStateToProps(store) {
  return {
    stations: store.stations,
    discounts: store.discounts.items,
    currency: store.settings.items && store.settings.items.currency,
    fleetCards: store.fleetSettings.items && store.fleetSettings.items.cards,
    paymentCards: store.globalInformations.paymentCards && store.globalInformations.paymentCards.items
      ? store.globalInformations.paymentCards.items
      : {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStations: () => dispatch(actions.stations.getStations()),
    addStations: stations => dispatch(actions.stations.addStations(stations)),
    deleteStation: ids => dispatch(actions.stations.deleteStation(ids)),
    addSuccessMessage: msg => dispatch(actions.messages.addSuccessMessage(msg)),
    addErrorMessage: msg => dispatch(actions.messages.addErrorMessage(msg)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Stations));
