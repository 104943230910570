import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Spin } from 'antd';

import EuropeMap from './EuropeMap';

const DEFAULT_HEIGHT = 300;

const LoadingMap = () => (
  <div
    style={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
  >
    <Spin
      size="large"
    />
  </div>
);

class EuropeMapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null,
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  componentWillReceiveProps(nextProps) {
    const currentState = this.getChartState(nextProps);
    if (nextProps.data && this.state.chart) {
      this.state.chart.update(currentState);
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    // TODO understand why this isn't needed
    // this.state.chart.destroy();
    window.removeEventListener('resize', this.createChart);
  }

  /* eslint-disable class-methods-use-this */
  getChartState(props) {
    return {
      data: props.data,
      countryCodeAccessor: props.countryCodeAccessor,
      countryCodeType: props.countryCodeType || 'adm0_a3',
      valueAccessor: props.valueAccessor,
    };
  }

  /* eslint-enable class-methods-use-this */

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.chart);
    if (this.state.chart) {
      this.state.chart.destroy();
    }

    const margin = {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10,
    };

    const elWidth = el.offsetParent.offsetWidth;
    const height = this.props.height || DEFAULT_HEIGHT;

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: height - margin.top - margin.bottom,
    };

    // Initialise the chart, then render it without transitions.
    this.setState({
      chart: new EuropeMap(el, props),
    }, () => {
      this.state.chart.create();
      this.state.chart.update(this.getChartState(this.props));
      this.state.chart.preventTransitions();
    });
  }

  render() {
    return (
      <div
        ref="chart"
        style={{
          position: 'relative',
        }}
      >
        {this.props.loading && <LoadingMap />}
      </div>
    );
  }
}

export default EuropeMapComponent;
