import React from 'react';
import { Tag } from 'antd';

import splash from '../../assets/images/splash.jpg';
import txfuelbot from '../../assets/images/txfuelbot.png';

const { REACT_APP_VERSION } = process.env;

const homeStyle = {
  backgroundImage: `url(${splash})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const versionStyle = {
  position: 'absolute',
  bottom: '5px',
  right: '0px',
  cursor: 'default',
};

const Home = () => (
  <div style={homeStyle}>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <img
        src={txfuelbot}
        alt="tx-fuelbotix"
        style={{
          maxHeight: 100,
          maxWidth: '80%',
        }}
      />
      {REACT_APP_VERSION && <Tag style={versionStyle}>v{REACT_APP_VERSION}</Tag>}
    </div>
  </div>
)

export default Home
