import auth from './auth'
import settings from './settings'
import fleetSettings from './fleetSettings'
import messages from './messages'
import stations from './stations'
import discounts from './discounts'
import recommendations from './recommendations'
import recommendationSettings from './recommendationSettings'
import compactEvents from './compactEvents'
import globalInformations from './globalInformations'
import bulkUploadActions from './bulkUploadActions'
import groups from './groups'
import performanceIndicators from './performanceIndicators'

export default {
  auth,
  settings,
  fleetSettings,
  messages,
  stations,
  discounts,
  recommendations,
  recommendationSettings,
  compactEvents,
  globalInformations,
  bulkUploadActions,
  groups,
  performanceIndicators,
}
