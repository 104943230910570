import moment from 'moment'
import API from '@aws-amplify/api'

import actionTypes from './actionTypes'

const TFU_SPAN = 30

// mappings to rename/reform hardcoded data
const mapCompactEvents = e => ({
  eventid: e.Id,
  date: moment.utc(e.Timestamp, 'YYYY-MM-DD HH:mm:ss.SSS').local(),
  longitude: e.Longitude,
  latitude: e.Latitude,
  tfu: e.TotalFuelUsedDemiLiters && e.TotalFuelUsedDemiLiters / 2,
  fuelPercentage: e.FuelPercentage,
  speed: e.Speed,
})

/* eslint-disable max-len */
const getCompactEventsRequest = (truckid, minTfu, maxTfu) => ({
  type: actionTypes.GET_COMPACT_EVENTS_REQUEST, truckid, minTfu, maxTfu,
})
const getCompactEventsSuccess = (truckid, minTfu, maxTfu, items) => ({
  type: actionTypes.GET_COMPACT_EVENTS_SUCCESS, items, truckid, minTfu, maxTfu,
})
const getCompactEventsFailure = (truckid, minTfu, maxTfu, error) => ({
  type: actionTypes.GET_COMPACT_EVENTS_FAILURE, error, truckid, minTfu, maxTfu,
})

function getCompactEvents(recommendation) {
  const { truckid, tfu } = recommendation
  if (!truckid || !tfu) {
    return null;
  }
  const minTfu = tfu - TFU_SPAN
  const maxTfu = tfu + TFU_SPAN
  const url = `compactEvents/${truckid}`
  const queryStringParameters = { minTfu: minTfu * 2, maxTfu: maxTfu * 2 } // backend uses demi-liters
  return (dispatch) => {
    dispatch(getCompactEventsRequest(truckid, minTfu, maxTfu))
    return API.get('Backend', url, { queryStringParameters })
      .then((data) => {
        const mappedData = data
          .map(mapCompactEvents)
          .filter(e => e.latitude || e.longitude)
          .sort((a, b) => a.date - b.date)
        dispatch(getCompactEventsSuccess(truckid, minTfu, maxTfu, mappedData))
      })
      .catch(err => dispatch(getCompactEventsFailure(truckid, minTfu, maxTfu, err)))
  }
}


function loadMoreAfter() {
  return (dispatch, getState) => {
    const {
      minTfu, maxTfu, truckid, items,
    } = getState().compactEvents
    if (!truckid || !minTfu || !maxTfu || !items || !Array.isArray(items)) {
      return null;
    }
    const newMaxTfu = maxTfu + TFU_SPAN
    const url = `compactEvents/${truckid}`
    const queryStringParameters = { minTfu: maxTfu * 2, maxTfu: newMaxTfu * 2 }
    dispatch(getCompactEventsRequest(truckid, minTfu, newMaxTfu))
    return API.get('Backend', url, { queryStringParameters })
      .then((data) => {
        const mappedData = data
          .map(mapCompactEvents)
          .filter(e => e.latitude || e.longitude)
        const fullData = [...items, ...mappedData].sort((a, b) => a.date - b.date)
        dispatch(getCompactEventsSuccess(truckid, minTfu, newMaxTfu, fullData))
      })
      .catch(err => dispatch(getCompactEventsFailure(truckid, minTfu, newMaxTfu, err)))
  }
}


function loadMoreBefore() {
  return (dispatch, getState) => {
    const {
      minTfu, maxTfu, truckid, items,
    } = getState().compactEvents
    if (!truckid || !minTfu || !maxTfu || !items || !Array.isArray(items)) {
      return null;
    }
    const newMinTfu = minTfu - TFU_SPAN
    const url = `compactEvents/${truckid}`
    const queryStringParameters = { minTfu: newMinTfu * 2, maxTfu: minTfu * 2 }
    dispatch(getCompactEventsRequest(truckid, newMinTfu, maxTfu))
    return API.get('Backend', url, { queryStringParameters })
      .then((data) => {
        const mappedData = data
          .map(mapCompactEvents)
          .filter(e => e.latitude || e.longitude)
        const fullData = [...mappedData, ...items].sort((a, b) => a.date - b.date)
        dispatch(getCompactEventsSuccess(truckid, newMinTfu, maxTfu, fullData))
      })
      .catch(err => dispatch(getCompactEventsFailure(truckid, newMinTfu, maxTfu, err)))
  }
}

export default {
  getCompactEvents,
  loadMoreAfter,
  loadMoreBefore,
}
