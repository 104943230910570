import { defineMessages } from 'react-intl';

// Any string that takes values as arguments (in curly brackets within the string)
// should have Val appended to the end of it's name and id
// For example: phrase.capacityVal
// The id should follow the pattern of {stringType}.{stringName}
// For example: word.amount
// Add a description if there might be ambiguity
// most applicable to button text

export const word = defineMessages({
  add: { id: 'word.add', defaultMessage: 'Add' },
  address: { id: 'word.address', defaultMessage: 'Address' },
  any: { id: 'word.any', defaultMessage: 'Any' },
  apply: { id: 'word.apply', defaultMessage: 'Apply' },
  available: { id: 'word.available', defaultMessage: 'Available' },
  brand: { id: 'word.brand', defaultMessage: 'Brand' },
  brands: { id: 'word.brands', defaultMessage: 'Brands' },
  cancel: { id: 'word.cancel', defaultMessage: 'Cancel' },
  cancelled: { id: 'word.cancelled', defaultMessage: 'Cancelled' },
  card: { id: 'word.card', defaultMessage: 'Card' },
  cards: { id: 'word.cards', defaultMessage: 'Cards' },
  city: { id: 'word.city', defaultMessage: 'City' },
  clear: { id: 'word.clear', defaultMessage: 'Clear' },
  country: { id: 'word.country', defaultMessage: 'Country' },
  created: { id: 'word.created', defaultMessage: 'Created' },
  currency: { id: 'word.currency', defaultMessage: 'Currency' },
  date: { id: 'word.date', defaultMessage: 'Date' },
  delete: { id: 'word.delete', defaultMessage: 'Delete' },
  discount: { id: 'word.discount', defaultMessage: 'Discount' },
  discounts: { id: 'word.discounts', defaultMessage: 'Discounts' },
  discountType: { id: 'word.discountType', defaultMessage: 'Discount type' },
  driver: { id: 'word.driver', defaultMessage: 'Driver' },
  duplicate: { id: 'word.duplicate', defaultMessage: 'Duplicate' },
  edit: { id: 'word.edit', defaultMessage: 'Edit' },
  except: { id: 'word.except', defaultMessage: 'Except' },
  fuelBot: { id: 'word.fuelBot', defaultMessage: 'TX-FUELBOT' },
  home: { id: 'word.home', defaultMessage: 'Home' },
  language: { id: 'word.language', defaultMessage: 'Language' },
  legend: { id: 'word.legend', defaultMessage: 'Legend' },
  less: { id: 'word.less', defaultMessage: 'Less' },
  logOut: { id: 'word.logOut', defaultMessage: 'Log out' },
  locality: { id: 'word.locality', defaultMessage: 'Locality' },
  more: { id: 'word.more', defaultMessage: 'More' },
  name: { id: 'word.name', defaultMessage: 'Name' },
  or: { id: 'word.or', defaultMessage: 'or' },
  pause: { id: 'word.pause', defaultMessage: 'Pause' },
  planning: { id: 'word.planning', defaultMessage: 'Planning' },
  price: { id: 'word.price', defaultMessage: 'Price' },
  recommendation: { id: 'word.recommendation', defaultMessage: 'Recommendation' },
  remove: { id: 'word.remove', defaultMessage: 'Remove' },
  rerouting: { id: 'word.rerouting', defaultMessage: 'Rerouting' },
  reset: { id: 'word.reset', defaultMessage: 'Reset' },
  save: { id: 'word.save', defaultMessage: 'Save' },
  search: { id: 'word.search', defaultMessage: 'Search' },
  selected: { id: 'word.selected', defaultMessage: 'Selected' },
  settings: { id: 'word.settings', defaultMessage: 'Settings' },
  status: { id: 'word.status', defaultMessage: 'Status' },
  tfu: { id: 'word.tfu', defaultMessage: 'TFU' },
  time: { id: 'word.time', defaultMessage: 'Time' },
  truck: { id: 'word.truck', defaultMessage: 'Truck' },
  unavailable: { id: 'word.unavailable', defaultMessage: 'Unavailable' },
  unknown: { id: 'word.unknown', defaultMessage: 'Unknown' },
  yes: { id: 'word.yes', defaultMessage: 'Yes' },
  zipcode: { id: 'word.zipcode', defaultMessage: 'Zip code' },
  dismiss: { id: 'word.dismiss', defaultMessage: 'Dismiss' },
  map: { id: 'word.map', defaultMessage: 'Map' },
  countries: { id: 'word.countries', defaultMessage: 'Countries' },
  on: { id: 'word.on', defaultMessage: 'on' },
  addprice: { id: 'phrase.addprice', defaultMessage: 'Add price' },
  groups: { id: 'word.groups', defaultMessage: 'Groups' },
  subgroups: { id: 'word.subgroups', defaultMessage: 'Subgroups' },
  rule: { id: 'word.rule', defaultMessage: 'Rule' },
  none: { id: 'word.none', defaultMessage: 'None' },
});

export const phrase = defineMessages({
  acceptedBrands: { id: 'phrase.acceptedBrands', defaultMessage: 'Accepted brands' },
  acceptsPaymentCard: { id: 'phrase.acceptsPaymentCard', defaultMessage: 'accepts the payment card' },
  addHomebaseStation: { id: 'phrase.addHomebaseStation', defaultMessage: 'Add home base gas station' },
  addNewDiscount: { id: 'phrase.addNewDiscount', defaultMessage: 'Add new discount' },
  addStation: { id: 'phrase.addStation', defaultMessage: 'Add gas station' },
  addValueStations: { id: 'phrase.addValueStations', defaultMessage: 'Add {value} gas stations' },
  areYouSureToDeleteThisDiscount: { id: 'phrase.areYouSureToDeleteThisDiscount', defaultMessage: 'Are you sure to delete this discount?' },
  areYouSureToRemoveSelectedStations: { id: 'phrase.areYouSureToRemoveSelectedStations', defaultMessage: 'Are you sure to remove all the selected gas stations?' },
  areYouSureToRemoveThisStation: { id: 'phrase.areYouSureToRemoveThisStation', defaultMessage: 'Are you sure to remove this gas station?' },
  areYouSureToRemoveTheDiscounts: { id: 'phrase.areYouSureToRemoveTheDiscounts', defaultMessage: 'Are you sure to remove the discount for the selected gas stations?' },
  backToSearch: { id: 'phrase.backToSearch', defaultMessage: 'Back to "Search"' },
  brandsToAvoid: { id: 'phrase.brandsToAvoid', defaultMessage: 'Brands to avoid' },
  bulkUploadOption: { id: 'phrase.bulkUploadOption', defaultMessage: 'Bulk-upload option' },
  bulkUploadStations: { id: 'phrase.bulkUploadStations', defaultMessage: 'Bulk-upload stations' },
  cantFindYourStation: { id: 'phrase.cantFindYourStation', defaultMessage: 'Cannot find your gas station? Please ask us to add it to the list.' },
  clickOnARecommendationToInspect: { id: 'phrase.clickOnARecommendationToInspect', defaultMessage: 'Click on a recommendation to inspect it' },
  clickToSeeOnGoogleMaps: { id: 'phrase.clickToSeeOnGoogleMaps', defaultMessage: 'Click to see in Google Maps' },
  clickToSelect: { id: 'phrase.clickToSelect', defaultMessage: 'Click to select' },
  completedOn: { id: 'phrase.completedOn', defaultMessage: 'Completed on' },
  doesNotAcceptsPaymentCard: { id: 'phrase.doesNotAcceptsPaymentCard', defaultMessage: 'accepts all payment cards except' },
  driverId: { id: 'phrase.driverId', defaultMessage: 'Driver ID' },
  driverName: { id: 'phrase.driverName', defaultMessage: 'Driver name' },
  euroDiscount: { id: 'phrase.euroDiscount', defaultMessage: 'discount' },
  discountInEuroCents: { id: 'phrase.discountInEuroCents', defaultMessage: 'discount in Cents € / L' },
  discountInEuro: { id: 'phrase.discountInEuro', defaultMessage: 'discount in € / L' },
  euroPerLiterAsFixedPrice: { id: 'phrase.euroPerLiterAsFixedPrice', defaultMessage: '/l as fixed price' },
  exportToCSV: { id: 'phrase.exportToCSV', defaultMessage: 'Export to CSV' },
  fleetCards: { id: 'phrase.fleetCards', defaultMessage: 'Fleet cards' },
  template: { id: 'phrase.template', defaultMessage: 'Template' },
  fuelLevel: { id: 'phrase.fuelLevel', defaultMessage: 'Fuel level' },
  fuelLevelHistory: { id: 'phrase.fuelLevelHistory', defaultMessage: 'Fuel level history' },
  fuelPercent: { id: 'phrase.fuelPercent', defaultMessage: 'Fuel %' },
  hidePlanning: { id: 'phrase.hidePlanning', defaultMessage: 'Hide planning' },
  homebaseStations: { id: 'phrase.homebaseStations', defaultMessage: 'Home base gas stations' },
  ifTheStation: { id: 'phrase.ifTheStation', defaultMessage: 'If the gas station' },
  internalId: { id: 'phrase.internalId', defaultMessage: 'Internal ID' },
  invertSelection: { id: 'phrase.invertSelection', defaultMessage: 'Invert selection' },
  isFromTheBrand: { id: 'phrase.isFromTheBrand', defaultMessage: 'is from the brand' },
  isIn: { id: 'phrase.isIn', defaultMessage: 'is in' },
  isNotFromTheBrand: { id: 'phrase.isNotFromTheBrand', defaultMessage: 'is from any brand except' },
  isNotIn: { id: 'phrase.isNotIn', defaultMessage: 'is in all countries except' },
  loadMore: { id: 'phrase.loadMore', defaultMessage: 'Load more' },
  noAcceptedBrandAvailable: { id: 'phrase.noAcceptedBrandAvailable', defaultMessage: 'No accepted brands available.' },
  noAddress: { id: 'phrase.noAddress', defaultMessage: 'No address' },
  noBrands: { id: 'phrase.noBrands', defaultMessage: 'No brands' },
  noCards: { id: 'phrase.noCards', defaultMessage: 'No cards' },
  noConditions: { id: 'phrase.noConditions', defaultMessage: 'No conditions' },
  noFleetCardAvailable: { id: 'phrase.noFleetCardAvailable', defaultMessage: 'No fleet cards available.' },
  noPlanningPoints: { id: 'phrase.noPlanningPoints', defaultMessage: 'No planning points' },
  notInYourAcceptedBrandList: { id: 'phrase.notInYourAcceptedBrandList', defaultMessage: 'Not in your "Accepted brands" list.' },
  notInYourFleetCardList: { id: 'phrase.notInYourFleetCardList', defaultMessage: 'Not in your "Fleet cards" list.' },
  orderN: { id: 'phrase.orderN', defaultMessage: 'Order no.' },
  pendingForApproval: { id: 'phrase.pendingForApproval', defaultMessage: 'Pending for approval' },
  percentDiscount: { id: 'phrase.percentDiscount', defaultMessage: '% discount' },
  plannedFor: { id: 'phrase.plannedFor', defaultMessage: 'Planned for' },
  planningPoint: { id: 'phrase.planningPoint', defaultMessage: 'Planning point' },
  planningType: { id: 'phrase.planningType', defaultMessage: 'Planning type' },
  plateNumber: { id: 'phrase.plateNumber', defaultMessage: 'Plate number' },
  playRecommendation: { id: 'phrase.playRecommendation', defaultMessage: 'Play recommendation' },
  preferredStations: { id: 'phrase.preferredStations', defaultMessage: 'Preferred gas stations' },
  preferredStationsFilter: { id: 'phrase.preferredStationsFilter', defaultMessage: 'Preferred gas stations filter' },
  plattsStations: { id: 'phrase.plattsStations', defaultMessage: 'Platts Stations' },
  receivedOn: { id: 'phrase.receivedOn', defaultMessage: 'Received on' },
  recommendedStation: { id: 'phrase.recommendedStation', defaultMessage: 'Recommended gas station' },
  recommendedStations: { id: 'phrase.recommendedStations', defaultMessage: 'Recommended gas stations' },
  removeInvalidBrands: { id: 'phrase.removeInvalidBrands', defaultMessage: 'Remove invalid brands' },
  removeInvalidCards: { id: 'phrase.removeInvalidCards', defaultMessage: 'Remove invalid cards' },
  resetView: { id: 'phrase.resetView', defaultMessage: 'Reset view' },
  searchAnAddress: { id: 'phrase.searchAnAddress', defaultMessage: 'Search an address' },
  searchThisArea: { id: 'phrase.searchThisArea', defaultMessage: 'Search this area' },
  showPlanning: { id: 'phrase.showPlanning', defaultMessage: 'Show planning' },
  showingAllTotalRecommendations: { id: 'phrase.showingAllTotalRecommendations', defaultMessage: 'Showing all {total} recommendations.' },
  stationsToAvoid: { id: 'phrase.stationsToAvoid', defaultMessage: 'Gas stations to avoid' },
  stationsWithVolumeDiscount: { id: 'phrase.stationsWithVolumeDiscount', defaultMessage: 'Gas stations with volume discount' },
  thisRecommendationWasCancelled: { id: 'phrase.thisRecommendationWasCancelled', defaultMessage: 'This recommendation was cancelled.' },
  thisStationIsAlreadyAddedInCategory: { id: 'phrase.thisStationIsAlreadyAddedInCategory', defaultMessage: 'This gas station is already added to {category}.' },
  thisWillNeverBeTrue: { id: 'phrase.thisWillNeverBeTrue', defaultMessage: 'This will never be true.' },
  truckN: { id: 'phrase.truckN', defaultMessage: 'Truck #' },
  truckInternalId: { id: 'phrase.truckInternalId', defaultMessage: 'Internal truck ID' },
  useInternalVehicleId: { id: 'phrase.useInternalVehicleId', defaultMessage: 'Use internal vehicle ID' },
  useInternalDriverName: { id: 'phrase.useInternalDriverName', defaultMessage: 'Use driver name' },
  userGreetingVal: { id: 'phrase.userGreetingVal', defaultMessage: 'Hi {value },' },
  userSettings: { id: 'phrase.userSettings', defaultMessage: 'User settings' },
  otherSettings: { id: 'phrase.otherSettings', defaultMessage: 'Other settings' },
  fuelBotParameters: { id: 'phrase.fuelBotParameters', defaultMessage: 'TX-FUELBOT Parameters' },
  recommendationSettings: { id: 'phrase.recommendations', defaultMessage: 'Recommendation settings' },
  activateAppRecommendations: { id: 'phrase.activateAppRecommendations', defaultMessage: 'Activate app recommendations' },
  activateMessagesToDrivers: { id: 'phrase.activateMessagesToDrivers', defaultMessage: 'Activate messages to Drivers' },
  truck: { id: 'phrase.truck', defaultMessage: 'Truck' },
  valueOfTotalItems: { id: 'phrase.valueOfTotalItems', defaultMessage: '{value} of {total} items' },
  yourCards: { id: 'phrase.yourCards', defaultMessage: 'Your cards' },
  fuelPercentageThreshold: { id: 'phrase.fuelPercentageThreshold', defaultMessage: 'Fuel percentage threshold' },
  withPlanning: { id: 'phrase.withPlanning', defaultMessage: 'With Planning' },
  withoutPlanning: { id: 'phrase.withoutPlanning', defaultMessage: 'Without Planning' },
  truckWithPlanning: { id: 'phrase.truckWithPlanning', defaultMessage: 'Truck with Planning' },
  truckWithoutPlanning: { id: 'phrase.truckWithoutPlanning', defaultMessage: 'Truck without Planning' },
  lowerFuelPercentageAtWhichAMessageCanBeSent: { id: 'phrase.lowerFuelPercentageAtWhichAMessageCanBeSent', defaultMessage: 'Lower fuel % at which a message can be sent' },
  kmThresholdWithoutPlanning: { id: 'phrase.kmThresholdWithoutPlanning', defaultMessage: 'Search radius kilometer threshold' },
  bulkUpload: { id: 'phrase.bulkUpload', defaultMessage: 'Bulk-upload' },
  plattsPricing: { id: 'phrase.plattsPricing', defaultMessage: 'Platt`s/List pricing' },
  discountsPerStation: { id: 'phrase.discountsPerStation', defaultMessage: 'Discounts per station' },
  discountsListPrice: { id: 'phrase.discountsListPrice', defaultMessage: 'Discounts on list price' },
  exciseRefunds: { id: 'phrase.exciseRefunds', defaultMessage: 'Excise refunds' },
  yourCountries: { id: 'phrase.yourCountries', defaultMessage: 'Your countries' },
  noCountries: { id: 'phrase.noCountries', defaultMessage: 'No countries' },
  filterPreferredStation: { id: 'phrase.filterPreferredStation', defaultMessage: 'Filter preferred gas stations' },
  clearfilter: { id: 'phrase.clearfilter', defaultMessage: 'Clear filter' },
  selectAll: { id: 'phrase.selectAll', defaultMessage: 'Select all' },
  deSelectAll: { id: 'phrase.deSelectAll', defaultMessage: 'Deselect all' },
  copy: { id: 'phrase.copy', defaultMessage: 'Copy' },
  firstheadercolumn: { id: 'phrase.firstheadercolumn', defaultMessage: 'First header column' },
  templatename: { id: 'phrase.templatename', defaultMessage: 'Template name' },
  configurefiletemplate: { id: 'phrase.configurefiletemplate', defaultMessage: 'Configure file template' },
  stationid: { id: 'phrase.stationid', defaultMessage: 'Gas station ID' },
  periodStart: { id: 'phrase.periodStart', defaultMessage: 'Price start of period' },
  periodEnd: { id: 'phrase.periodEnd', defaultMessage: 'Price end of period' },
  product: { id: 'phrase.product', defaultMessage: 'Product' },
  price: { id: 'phrase.price', defaultMessage: 'Net price excl. VAT' },
  currency: { id: 'phrase.currency', defaultMessage: 'Currency' },
  fleetCard: { id: 'phrase.fleetCard', defaultMessage: 'Fleet card' },
  action: { id: 'phrase.action', defaultMessage: 'Action' },
  mapped: { id: 'phrase.mapped', defaultMessage: 'Mapped' },
  notmapped: { id: 'phrase.notmapped', defaultMessage: 'Not mapped' },
  newlymapped: { id: 'phrase.newlymapped', defaultMessage: 'Newly mapped' },
  linenumber: { id: 'phrase.linenumber', defaultMessage: 'Line number' },
  errordescription: { id: 'phrase.errordescription', defaultMessage: 'Error description' },
  errorinformation: { id: 'phrase.errorinformation', defaultMessage: 'Error information' },
  saveAndProcess: { id: 'phrase.saveAndProcess', defaultMessage: 'Save and process the data' },
  areYouSureToRemoveTemplateConfig: { id: 'phrase.areYouSureToRemoveTemplateConfig', defaultMessage: 'Are you sure to remove the file template configuration?' },
  totalgasstations: { id: 'phrase.totalgasstations', defaultMessage: 'Total gas stations' },
  validityDate: { id: 'phrase.validityDate', defaultMessage: 'Validity date' },
  editStation: { id: 'phrase.editStation', defaultMessage: 'Edit gas station' },
  stationsName: { id: 'phrase.stationsName', defaultMessage: 'Gas stations name' },
  yourGroups: { id: 'phrase.yourGroups', defaultMessage: 'Your groups' },
  resetDiscount: { id: 'phrase.resetDiscount', defaultMessage: 'Reset Discount' },
  selectFleetCard: { id: 'phrase.selectFleetCard', defaultMessage: 'Select fleet card' },
  kmThresholdWithPlanning: { id: 'phrase.kmThresholdWithPlanning', defaultMessage: 'Corridor search treshold' },
  totalRecommendations: { id: 'phrase.totalRecommendations', defaultMessage: 'Total recommendations' },
  totalDistance: { id: 'phrase.totalDistance', defaultMessage: 'Total distance covered' },
  averageDistance: { id: 'phrase.averageDistance', defaultMessage: 'Average distance / recommendation' },
  averagePrice: { id: 'phrase.averagePrice', defaultMessage: 'Average gas station price' },
  topStations: { id: 'phrase.topStations', defaultMessage: 'Top gas stations' },
  firstRecommendation: { id: 'phrase.firstRecommendation', defaultMessage: 'First recommendation' },
  secondRecommendation: { id: 'phrase.secondRecommendation', defaultMessage: 'Second recommendation' },
  recommendationsMap: { id: 'phrase.recommendationsMap', defaultMessage: 'Recommendations country map' },
  firstSuppliers: { id: 'phrase.firstSuppliers', defaultMessage: 'First supplier recommendation' },
  secondSuppliers: { id: 'phrase.secondSuppliers', defaultMessage: 'Second supplier recommendation' },
  addFleetCard: { id: 'phrase.addFleetCard', defaultMessage: 'Add new fleet card' },
  editFleetCard: { id: 'phrase.editFleetCard', defaultMessage: 'Edit fleet card' },
  selectGroups: { id: 'phrase.selectGroups', defaultMessage: 'Select your groups' },
  selectBrands: { id: 'phrase.selectBrands', defaultMessage: 'Select your brands' },
  selectCountries: { id: 'phrase.selectCountries', defaultMessage: 'Select your countries' },
  addRule: { id: 'phrase.addRule', defaultMessage: 'Add new rule' },
  allSubgroups: { id: 'phrase.allSubgroups', defaultMessage: 'All subgroups' },
  allCountries: { id: 'phrase.allCountries', defaultMessage: 'All countries' },
  allBrands: { id: 'phrase.allBrands', defaultMessage: 'All brands' },
  yourTimezone: { id: 'phrase.yourTimezone', defaultMessage: 'Your time zone' },
  stationsWithDiscount: { id: 'phrase.stationsWithDiscount', defaultMessage: 'Gas stations with discount' },
  trucksPath: { id: 'phrase.trucksPath', defaultMessage: 'Truck\'s path' },
  inferredRouting: { id: 'phrase.inferredRouting', defaultMessage: 'Inferred routing' },
  showJustification: { id: 'phrase.showJustification', defaultMessage: 'Show justification' },
  editMultipleStation: { id: 'phrase.editMultipleStation', defaultMessage: 'Edit multiple gas stations' },
});

export const description = defineMessages({
  acceptedBrandsSettingsInfoMessage: { id: 'description.acceptedBrandsSettingsInfoMessage', defaultMessage: 'This defines which gas stations are taken into account for the recommendations.' },
  discountSettingsInfoMessage: { id: 'description.discountSettingsInfoMessage', defaultMessage: 'The discounts you register here will be saved to compute the best price when suggesting gas stations.' },
  paymentCardsSettingsInfoMessage: { id: 'description.paymentCardsSettingsInfoMessage', defaultMessage: 'Register the cards that are used by your fleet. Only those payment cards will be taken into account.' },
  recommendationRecommendedStation: { id: 'description.recommendationRecommendedStation', defaultMessage: 'Recommendation: recommended gas station' },
  trajectoryTruckPlanningPoints: { id: 'description.trajectoryTruckPlanningPoints', defaultMessage: 'Trajectory / Planned stops' },
  newReleaseDescription: { id: 'description.newReleaseDescription', defaultMessage: 'Feature "A" has been added to the application.' },
  exciseRefundsSettingsInfoMessage: { id: 'description.exciseRefundsSettingsInfoMessage', defaultMessage: 'Please indicate below the countries in which you are eligible for a refund' },
  fleetCardGroups: { id: 'description.fleetCardGroups', defaultMessage: 'Define the groups for which the selected fleet card(s) will be applied.' },
  fleetCardSaveBeforeAssigningGroups: { id: 'description.fleetCardSaveBeforeAssigningGroups', defaultMessage: 'Please save or reset cards in order to assign groups.' },
  kmThresholdWithPlanningInfo: { id: 'description.kmThresholdWithPlanningInfo', defaultMessage: 'Maximum distance allowed out of the planning for a truck to join a fuel station' },
  fleetCardBrands: { id: 'description.fleetCardBrands', defaultMessage: 'This rule applies to the following brands:' },
  fleetCardNotBrands: { id: 'description.fleetCardNotBrands', defaultMessage: 'This rule applies to all brands, except for the following:' },
  fleetCardCountries: { id: 'description.fleetCardCountries', defaultMessage: 'This rule applies to the following countries:' },
  fleetCardNotCountries: { id: 'description.fleetCardNotCountries', defaultMessage: 'This rule applies to all countries, except for the following:' },
  addRuleDisabled: { id: 'description.addRuleDisabled', defaultMessage: 'You must have available subgroups to add a new rule.' },
  saveDiscountDisabled: { id: 'description.saveDiscountDisabled', defaultMessage: 'All discounts must have a fleet card specified.' },
});

export const message = defineMessages({
  discountAlwaysApplicable: { id: 'message.discountAlwaysApplicable', defaultMessage: 'This discount will always be applicable.' },
  discountNeverApplicableCurrentSettings: { id: 'message.discountNeverApplicableCurrentSettings', defaultMessage: 'With your current settings, this discount will never be applicable.' },
  discountUseless: { id: 'message.discountUseless', defaultMessage: 'This discount is useless (value is 0).' },
  failedToDeleteDiscount: { id: 'message.failedToDeleteDiscount', defaultMessage: 'Failed to delete the discount.' },
  failedToDeleteStation: { id: 'message.failedToDeleteStation', defaultMessage: 'Failed to delete the gas station.' },
  failedToGetDiscountList: { id: 'message.failedToGetDiscountList', defaultMessage: 'Failed to get the discount list.' },
  failedToGetSettings: { id: 'message.failedToGetSettings', defaultMessage: 'Failed to load the settings.' },
  failedToGetAvailableCards: { id: 'message.failedToGetAvailableCards', defaultMessage: 'Failed to load the list of available payment cards.' },
  failedToGetStationList: { id: 'message.failedToGetStationList', defaultMessage: 'Failed to get the gas station list.' },
  failedToSaveDiscount: { id: 'message.failedToSaveDiscount', defaultMessage: 'Failed to save the discount.' },
  failedToSaveSettings: { id: 'message.failedToSaveSettings', defaultMessage: 'Failed to save the settings.' },
  failedToSaveStations: { id: 'message.failedToSaveStations', defaultMessage: 'Failed to save the gas stations.' },
  noBrandWarning: { id: 'message.noBrandWarning', defaultMessage: 'The TX-FUELBOT system cannot recommend any gas stations if no brands are selected.' },
  noCardWarning: { id: 'message.noCardWarning', defaultMessage: 'If no cards are selected, no filter on payment cards will be used by the TX-FUELBOT system.' },
  noDataCurrentSelection: { id: 'message.noDataCurrentSelection', defaultMessage: 'No data in the current selection.' },
  pleaseInputTheAddress: { id: 'message.pleaseInputTheAddress', defaultMessage: 'Please enter the address.' },
  pleaseInputTheCountry: { id: 'message.pleaseInputTheCountry', defaultMessage: 'Please enter the country.' },
  pleaseInputTheLocality: { id: 'message.pleaseInputTheLocality', defaultMessage: 'Please enter the locality.' },
  pleaseInputTheZipcode: { id: 'message.pleaseInputTheZipcode', defaultMessage: 'Please enter the zip code.' },
  settingsAreSaved: { id: 'message.settingsAreSaved', defaultMessage: 'The settings were correctly saved.' },
  stationIsDeleted: { id: 'message.stationIsDeleted', defaultMessage: 'The gas station is successfully deleted.' },
  stationsAreAdded: { id: 'message.stationsAreAdded', defaultMessage: 'The gas stations were successfully added.' },
  theNameMustContainAtMost36Letters: { id: 'message.theNameMustContainAtMost36Letters', defaultMessage: 'The name may contain maximally 36 letters.' },
  thereIsAlreadyAHomebaseStationNamedValue: { id: 'message.thereIsAlreadyAHomebaseStationNamedValue', defaultMessage: 'There is already a home base gas station named "{value}".' },
  thisFieldIsMandatory: { id: 'message.thisFieldIsMandatory', defaultMessage: 'This field is mandatory.' },
  newReleaseTitle: { id: 'message.newReleaseTitle', defaultMessage: 'New feature(s) added!!' },
  failedToMapStation: { id: 'message.failedToMapStation', defaultMessage: 'Failed to map the gas station' },
  failedToGetMappedStationDetails: { id: 'message.failedToGetMappedStationDetails', defaultMessage: 'Failed to get the mapped gas station details' },
  stationMappedSuccessfully: { id: 'message.stationMappedSuccessfully', defaultMessage: 'The gas station was successfully mapped' },
  nofileuploaded: { id: 'message.nofileuploaded', defaultMessage: 'No file uploaded!' },
  unknownfileformatOnlyExcel: { id: 'message.unknownfileformatOnlyExcel', defaultMessage: 'Unknown file format. Only Excel files are accepted' },
  nodatainfile: { id: 'message.nodatainfile', defaultMessage: 'No data found in file!' },
  dragAndDropFileHere: { id: 'message.dragAndDropFileHere', defaultMessage: 'Drag \'n\' drop some files here, or click to select files' },
  failedToGetAvailableExciseRefunds: { id: 'message.failedToGetAvailableExciseRefunds', defaultMessage: 'Failed to load the list of available excise refunds' },
  failedToGetExciseRefunds: { id: 'message.failedToGetExciseRefunds', defaultMessage: 'Failed to load the excise refunds' },
  failedToSaveExciseRefunds: { id: 'message.failedToSaveExciseRefunds', defaultMessage: 'Failed to save the excise refunds' },
  exciseRefundsAreSaved: { id: 'message.exciseRefundsAreSaved', defaultMessage: 'The excise refunds were correctly saved' },
  duplicateStationID: { id: 'message.duplicateStationID', defaultMessage: 'The "Gas station ID" must be unique. This file contains duplicate IDs.' },
  invaliddateformat: { id: 'message.invaliddateformat', defaultMessage: 'Invalid date format!' },
  cannotbeempty: { id: 'message.cannotbeempty', defaultMessage: 'cannot be empty' },
  errorMsg: { id: 'message.errorMsg', defaultMessage: 'The following errors are found in the uploaded file. Please resolve all the errors and upload the file again.' },
  plattsMappingCompletedMsg: { id: 'message.plattsMappingCompletedMsg', defaultMessage: 'All your usual gas stations are now added to the system. To finalize the automatic ingestion of your prices, please ask your supplier to add the following email address to the list of recipients of your file:' },
  congratulations: { id: 'message.congratulations', defaultMessage: 'Congratulations!' },
  mappedcolumns: { id: 'message.mappedcolumns', defaultMessage: 'Mapped columns' },
  missing: { id: 'message.missing', defaultMessage: 'is/are missing' },
  isRequired: { id: 'message.isRequired', defaultMessage: 'is required' },
  invalidFile: { id: 'message.invalidFile', defaultMessage: 'The selected file is not a valid file' },
  duplicateTemplateName: { id: 'message.duplicateTemplateName', defaultMessage: "The 'Template name' already exists for the selected fleet card" },
  latestFileUploaded: { id: 'message.latestFileUploaded', defaultMessage: 'The latest file upload was' },
  failedToDeleteTemplateConfig: { id: 'message.failedToDeleteTemplateConfig', defaultMessage: 'Failed to delete the file template configuration' },
  fileTemplateConfigIsDeleted: { id: 'message.fileTemplateConfigIsDeleted', defaultMessage: 'The file template configuration is successfully deleted' },
  warningMessageForFleetCardDelete: { id: 'message.warningMessageForFleetCardDelete', defaultMessage: 'Please be aware that all the information linked to a card that you remove, will be deleted as well' },
  failedToGetGroups: { id: 'message.failedToGetGroups', defaultMessage: 'Failed to get the groups' },
  noDriverInfo: { id: 'message.noDriverInfo', defaultMessage: 'No information about the driver.' },
  groupModification: { id: 'message.groupModification', defaultMessage: 'We detected some changes in your groups, please edit this rule.' },
  subgroupModification: { id: 'message.subgroupModification', defaultMessage: 'We detected some changes in your subgroups, please edit this rule.' },
});

// more specific groups:
export const alert = defineMessages({
  sessionExpiringTitle: { id: 'alert.sessionExpiringTitle', defaultMessage: 'Logout notice' },
  sessionExpiringDescriptionVal: { id: 'alert.sessionExpiringDescriptionVal', defaultMessage: 'You will be logged out in {value} seconds.' },
  sessionExpiringActionDetails: { id: 'alert.sessionExpiringActionDetails', defaultMessage: 'If you would like to stay logged in, please renew your session by clicking the button below' },
  sessionExpiringLoggingOut: { id: 'alert.sessionExpiringLoggingOut', defaultMessage: 'Logging out...' },
  sessionRenew: { id: 'alert.sessionRenew', defaultMessage: 'Renew session' },
});

export const mail = defineMessages({
  body1: { id: 'mail.body1', defaultMessage: 'Please add this gas station:' },
  body2: { id: 'mail.body2', defaultMessage: 'google maps link' },
  body3: { id: 'mail.body3', defaultMessage: '{name} (skyId: {skyId})' },
  sendMail: { id: 'mail.sendMail', defaultMessage: 'Send email' },
  subject: { id: 'mail.subject', defaultMessage: 'Impossible to find a gas station' },
});
